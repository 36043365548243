import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
  ContractView,
  ContractSignStatus,
  ContractActivity,
  Contract,
  ContractSigner,
} from '@tokeet-frontend/signature'
import { map, sortBy, uniqBy, find } from 'lodash'

@Component({
  selector: 'app-contract-timeline',
  templateUrl: './contract-timeline.component.html',
  styleUrls: ['./contract-timeline.component.scss'],
})
export class ContractTimelineComponent implements OnInit, OnChanges {
  @Input() contract: ContractView

  signerStatuses = ContractSignStatus

  timeline: ContractActivity[] = []
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contract']) {
      this.processActivities(this.contract)
    }
  }

  getItemColorClass(action: ContractSignStatus) {
    switch (action) {
      case ContractSignStatus.Sent:
        return 'avater-cst-purple'
      case ContractSignStatus.Opened:
        return 'avater-cst-info'
      case ContractSignStatus.Signed:
        return 'avater-cst-success'
      case ContractSignStatus.Decline:
        return 'avater-cst-danger'
    }
  }

  processActivities(contract: ContractView) {
    if (!contract) {
      return
    }

    this.timeline = map(uniqBy(sortBy(contract.activities, 'date'), (item) => `${item.user}-${item.action}`))
  }

  getSigner(user: string, contract: Contract): ContractSigner {
    return find((contract && contract.signers) || [], (signer) => signer.role === user)
  }
}
