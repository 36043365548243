<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/listing-icon1.webp" alt="" height="40" />
    <div>
      <h6 class="mb-0">Connecting</h6>
      <div class="text-sm">
        This section will prepare you for connecting Airbnb with AdvanceCM. <br />
        Please read through these instructions carefully before proceeding.
      </div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #linkProperty>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Link your property
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>The next step in the process will be to link an existing AdvanceCM rental to your Airbnb room ID.</p>

            <p class="text-semi-bold">Please take note of the following:</p>

            <p>
              A Airbnb property ID may include more than one room ID. These rooms should have their own rates and
              availability calendar and not be listed as inventory available on the same property ID. You can confirm
              this by checking your rates and availability calendar on Airbnb and ensuring that the Rooms to Sell
              section shows either 1 or 0 but never more than 1.
            </p>
            <p>
              If your property shows more than 1 room available within a property ID on the same calendar you will need
              to contact your Airbnb account manager to have them split these rentals into their own rates calendars.
            </p>
            <div class="d-flex align-items-center gap-3">
              <mat-form-field2 class="w-50" [showLabel]="false">
                <app-select
                  [formControl]="rentalIdCtrl"
                  [items]="rentals$ | async"
                  bindLabel="name"
                  bindValue="id"
                  [multiple]="false"
                  [required]="true"
                  [searchable]="true"
                  [disabled]="!!connection.rentalId"
                  placeholder="Select Rental to Link"
                >
                </app-select>
              </mat-form-field2>
              <div class="flex-fill"></div>
              <button
                class="btn btn-light-info"
                [disabled]="rentalIdCtrl.valid || !!connection.rentalId"
                (click)="onImportListing()"
              >
                <i class="cs-icon cs-icon-download"></i> Import
              </button>
              <button
                class="btn btn-light-info"
                [disabled]="rentalIdCtrl.invalid || !!connection.rentalId"
                (click)="onLink()"
              >
                <i class="cs-icon cs-icon-link"></i> Link
              </button>
              <button class="btn btn-light-info" (click)="syncType.open()" [disabled]="!isLinked">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #syncType [disabled]="!isLinked">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Set Sync type
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              please click Change Synchronization button below, and choose one of the options from the Synchronization
              Category drop-down option.
            </p>

            <ul>
              <li>
                <b>Rates and Availability Only</b>: This option is the most flexible and allows editing of listing
                details directly on AirBnb. We recommend using this option as many listing details cannot be currently
                edited by pushing from AdvanceCM.
              </li>
              <li>
                <b>Full Synchronization</b>: This option will require that you update listing details from AdvanceCM and
                many of the options to edit on AirBnb will be disabled. At this time not all listing details can be
                pushed from AdvanceCM so it is recommended that you use Rates and Availability only option to maintain
                the most flexibility.
              </li>
            </ul>

            <div class="d-flex ali-items-center gap-3">
              <button class="btn btn-light-info" (click)="onChangeSyncType()">
                <i class="far fa-sync"></i> Change Synchronization
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="reviewErrors.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #reviewErrors [disabled]="!isLinked">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Troubleshooting AirBnb Review Errors
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              After setting your synchronization type you may have received an error from AirBnb calling out a
              recommended change to your listing details.
            </p>
            <p>
              To review, the following criteria must be met before AirBnb will allow the listing to connect to a channel
              manager.
            </p>

            <ul>
              <li>A minimum of 7 photos (3 of them being high quality).</li>
              <li>A listing Title that does not exceed 50 characters in length.</li>
              <li>A listing Summary(Description) that is a minimum of 50 characters but no more than 500.</li>
              <li>
                The listing is not a co-host listing. If this is a co-host listing there will be additional steps in the
                process for you to complete later but you can proceed beyond this step.
              </li>
            </ul>
            <p>
              Please ensure that you address the required change mentioned in the error message and then return to this
              page to repeat the step of selecting a synchronization type.
            </p>
            <p>
              If you have received no errors and instead received a success message saying Sync category updated
              successfully please hit Continue to proceed.
            </p>
            <div class="d-flex ali-items-center gap-3">
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="importBookings.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #importBookings [disabled]="!isLinked">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">4</div>
              Import Bookings
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Now that AdvanceCM is connected to Airbnb you will need to import your bookings. Please note that
              AdvanceCM can only import current and future bookings. Only limited historical information is provided
              when importing from Airbnb.
            </p>

            <p>
              Future bookings which were created in the past will be imported but bookings in which the departure date
              has already passed will not be.
            </p>

            <p>Hit the Import button below and continue to the next step in the process.</p>
            <div class="d-flex ali-items-center gap-3">
              <button class="btn btn-light-info" (click)="onImport()">
                <i class="cs-icon cs-icon-download"></i> Import
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="pushAvailability.open()" [disabled]="!isBookingImported">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #pushAvailability [disabled]="!isLinked || !isBookingImported">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">5</div>
              Push Availability
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              In this step you will be pushing the availability that shows on your AdvanceCM calendar to Airbnb. Please
              hit the Push Availability button.
            </p>

            <p>
              If you receive an error stating “Unable to publish to channel” this is most likely due to attempting to
              push an empty calendar.
            </p>

            <p>
              Please navigate to your AdvanceCM calendar and create a hold event or allow the system some time to import
              bookings from Airbnb before trying again.
            </p>
            <div class="d-flex ali-items-center gap-3">
              <button class="btn btn-light-info" (click)="onPushAvailability()">
                <i class="cs-icon cs-icon-upload"></i> Push Availability
              </button>
              <div class="flex-fill"></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end gap-3">
  <button
    class="btn btn-secondary-primary"
    [disabled]="isProcessing || !isLinked || !isBookingImported || !isAvailabilityPushed"
    (click)="coHost.emit()"
  >
    <i class="fas fa-home"></i>
    Co-Host
  </button>
  <button
    class="btn btn-secondary-info"
    [disabled]="isProcessing || !isLinked || !isBookingImported || !isAvailabilityPushed"
    (click)="next.emit()"
  >
    Next
    <i class="far fa-spin fa-spinner" *ngIf="isProcessing"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isProcessing"></i>
  </button>
</div>
