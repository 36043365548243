import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { forkJoin, of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap, toArray } from 'rxjs/operators'
import {
  AddCustomCode,
  AddCustomCodeComplete,
  DeleteCustomCode,
  DeleteCustomCodeComplete,
  GetAllCustomCodes,
  GetAllCustomCodesComplete,
  UpdateCustomCode,
  UpdateCustomCodeComplete,
} from '@tv3/store/custom-codes/custom-code.actions'
import { CustomCodeService } from '@tv3/store/custom-codes/custom-code.service'
import { CustomCode } from '@tv3/store/custom-codes/custom-code.model'
import * as R from 'ramda'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CustomCodeEffects {
  @Effect()
  allCustomCodes$ = this.actions$.pipe(
    ofType(GetAllCustomCodes),
    switchMap(() =>
      this.customCodes.getAll().pipe(
        map((customCodes) => GetAllCustomCodesComplete({ codes: customCodes })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteCustomCodes$ = this.actions$.pipe(
    ofType(DeleteCustomCode),
    switchMap(({ ids }) => {
      return of(...ids).pipe(
        concatMap((id) => this.customCodes.delete(id, true)),
        toArray(),
        tap(() => this.toaster.success(`Custom Code(s) deleted successfully!`)),
        map(() => DeleteCustomCodeComplete({ ids }))
      )
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  @Effect()
  addCustomCodes$ = this.actions$.pipe(
    ofType(AddCustomCode),
    switchMap(({ request }) =>
      this.customCodes.create(request).pipe(
        map((code) => AddCustomCodeComplete({ code })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateCustomCodes$ = this.actions$.pipe(
    ofType(UpdateCustomCode),
    switchMap(({ request }) =>
      this.customCodes.edit(request.pkey, request).pipe(
        map((code) => UpdateCustomCodeComplete({ update: { id: code.pkey, changes: code } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private toaster: Toaster, private customCodes: CustomCodeService) {}
}
