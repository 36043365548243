import { Injectable } from '@angular/core'
import { PhotoUploaderComponent } from './photo-uploader.component'
import { DialogService, isSomething } from '@tokeet-frontend/tv3-platform'
import { filter } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class PhotoUploaderService {
  constructor(private dialog: DialogService) {}

  open(data: { isSelfieRequired: boolean; isIDRequired: boolean }) {
    return this.dialog
      .openFixedDialog(PhotoUploaderComponent, {
        width: '600px',
        data,
      })
      .afterClosed()
      .pipe(filter(isSomething))
  }
}
