import { createAction, props } from '@ngrx/store'

import { Contract } from './contract.model'
import { Update } from '@ngrx/entity'
import { ShareContractRequest } from './models/share.request'
import { ContractListParams } from './models/list.request'

export const SearchContracts = createAction('[Contract] Load Contracts', props<{ params: ContractListParams }>())
export const SearchContractsComplete = createAction(
  '[Contract] Load Contracts Complete',
  props<{ items: Contract[]; tagStats; statusStats; pagination }>()
)

export const FetchBookingContracts = createAction('[Contract] Load Booking Contracts', props<{ bookingId: string }>())
export const FetchBookingContractsComplete = createAction(
  '[Contract] Load Booking Contracts Complete',
  props<{ items: Contract[] }>()
)

export const RefreshContractStats = createAction(
  '[Contract] Refresh Contract Stats',
  props<{ params: ContractListParams }>()
)
export const RefreshContractStatsComplete = createAction(
  '[Contract] Refresh Contract Stats Complete',
  props<{ tagStats; statusStats; pagination }>()
)

export const GetContract = createAction('[Contract] Load Contract', props<{ id: string; skipError?: boolean }>())
export const GetContractComplete = createAction('[Contract] Load Contract Complete', props<{ item: Contract }>())
export const ContractNotFound = createAction('[Contract] Load Contract Error 404', props<{ id: string }>())

export const CreateContract = createAction(
  '[Contract] Create Contract',
  props<{ payload: Contract; silent?: boolean }>()
)
export const CreateContractComplete = createAction('[Contract] Create Contract Complete', props<{ item: Contract }>())

export const UpdateContract = createAction(
  '[Contract] Update Contract',
  props<{ id: string; payload: Partial<Contract>; message?: string; silent?: boolean }>()
)
export const UpdateContractComplete = createAction(
  '[Contract] Update Contract Complete',
  props<{ update: Update<Contract> }>()
)

export const DeleteContract = createAction('[Contract] Delete Contract', props<{ id: string }>())
export const DeleteContractComplete = createAction('[Contract] Delete Contract Complete', props<{ id: string }>())

export const DeleteMultipleContract = createAction('[Contract] Delete Multiple Contract', props<{ ids: string[] }>())
export const DeleteMultipleContractComplete = createAction(
  '[Contract] Delete Multiple Contract Complete',
  props<{ ids: string[] }>()
)

export const CancelContract = createAction('[Contract] Cancel Contract', props<{ id: string }>())
export const CancelContractComplete = createAction(
  '[Contract] Cancel Contract Complete',
  props<{ id: string; changes: Partial<Contract> }>()
)

export const MarkContractAsCompleted = createAction('[Contract] Mark as Completed', props<{ id: string }>())
export const MarkContractAsCompletedComplete = createAction(
  '[Contract] Mark as Completed Complete',
  props<{ id: string; changes: Partial<Contract> }>()
)

export const SendContract = createAction('[Contract] Send Contract', props<{ id: string }>())
export const SendContractComplete = createAction(
  '[Contract] Send Contract Complete',
  props<{ id: string; changes: Partial<Contract> }>()
)

export const ShareContract = createAction(
  '[Contract] Shared Contract',
  props<{ id: string; payload: ShareContractRequest }>()
)
export const ShareContractComplete = createAction('[Contract] Shared Contract Complete')
