import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './custom-code.reducer'
import * as state from './custom-code.state'
import { CustomCode, toCustomCodeView } from './custom-code.model'
import * as R from 'ramda'
import {
  selectAllRentals,
  selectRentalEntities,
  selectUserEntities,
  selectUsers,
  uniqueProperties,
} from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { User } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export const customCodeState = createFeatureSelector<state.State>('custom-codes')

export const selectAllCustomCodes = createSelector(customCodeState, reducer.selectAll)

export const isCustomCodeLoaded = createSelector(customCodeState, (state) => state.isLoaded)

export const selectUniqueCodeNames = createSelector(selectAllCustomCodes, (codes) => {
  return uniqueProperties('nameFormatted')(codes)
})

export const selectAllCustomCodeViews = createSelector(
  selectAllCustomCodes,
  selectUsers,
  selectRentalEntities,
  (codes, users, rentalEntities) => {
    return lodash.map(codes, (code) => {
      const creator = R.find((u: User) => u.id === code.creator, users)
      const rentals = R.map((rentalId: string) => {
        const rental: Rental = rentalEntities[rentalId]
        return rental?.name
      }, code.rentals || []).filter((t) => !!t)
      const view = toCustomCodeView(code)
      view.creatorView = creator?.name
      view.rentalNames = rentals.join(', ')
      return view
    })
  }
)

export const selectRentalsForCode = (code?: CustomCode) =>
  createSelector(selectAllCustomCodes, selectRentalEntities, selectAllRentals, (codes, entities, rentals) => {
    if (R.isNil(code)) {
      return rentals
    }
    const allRentalsForCodeName = R.pipe(
      R.filter(R.propEq('name', code.name)),
      R.map(R.view(R.lensProp('rentals'))),
      R.flatten,
      R.uniq
    )(codes)
    const result = R.concat(
      code.rentals,
      R.without(
        allRentalsForCodeName,
        R.map((r: Rental) => r.id, rentals)
      )
    )
    return R.reject(R.isNil)(R.map((id: string) => entities[id], result))
  })
