import { Injectable } from '@angular/core'
import { SignDrawerComponent } from './sign-drawer.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class SignDrawerService {
  constructor(private dialog: DialogService) {}

  public open(contractId: string, role: string) {
    return this.dialog.openDrawer(SignDrawerComponent, {
      data: { contractId, role },
      panelClass: 'drawer-full-80',
    })
  }
}
