import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { ResourceGroup, ResourceGroupType } from './group.model'
import { UpdateGroupPayload } from './group.service'

export const LoadGroups = createAction('[Group] Load Groups')
export const LoadGroupsComplete = createAction(
  '[Template Group] Load Groups Complete',
  props<{ items: ResourceGroup[] }>()
)

export const CreateGroup = createAction('[Group] Create Group', props<{ name: string; groupType: ResourceGroupType }>())
export const CreateGroupComplete = createAction('[Group] Create Group Complete', props<{ item: ResourceGroup }>())

export const UpdateGroup = createAction(
  '[Group] Update Group',
  props<{ id: string; payload: UpdateGroupPayload; message?: string }>()
)
export const UpdateGroupComplete = createAction(
  '[Group] Update Group Complete',
  props<{ update: Update<ResourceGroup> }>()
)

export const DeleteGroup = createAction('[Group] Delete Group', props<{ id: string }>())
export const DeleteGroupComplete = createAction('[Group] Delete Group Complete', props<{ id: string }>())
