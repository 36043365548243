<label [class.required]="field.required">
  <input
    type="text"
    #input
    [class.has-value]="!!input.value"
    [formControl]="ctrl"
    [required]="field.required"
    [placeholder]="field.label || ''"
    [ngStyle]="fieldStyle"
    [maxlength]="textLimit"
  />
  <i class="cs-icon cs-icon-textbox field-icon"></i>
</label>
