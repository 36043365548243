import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { AuthService } from '@tv3/services/auth.service'

@Component({
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss'],
  host: { class: 'modal-content' },
})
export class PhotoUploaderComponent implements OnInit {
  title = 'Upload'
  message = ''
  limit = 1

  uploadedData = []

  constructor(
    private dialog: MatDialogRef<PhotoUploaderComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: { isSelfieRequired: boolean; isIDRequired: boolean }
  ) {
    this.init()
  }

  ngOnInit() {}

  close() {
    this.dialog.close()
  }

  init() {
    if (this.data.isIDRequired && this.data.isSelfieRequired) {
      this.title = 'Selfie and Government ID Required'
      this.message = `You are required to upload a photo of selfie, or your national ID (passport, state ID, or driver's license). Please upload it before submitting this document. You may only upload <strong>PNG</strong> or <strong>JPG</strong> file formats, and the file size must be less than <strong>20MB</strong>.`
      this.limit = 2
    } else if (this.data.isSelfieRequired) {
      this.title = 'Selfie Required'
      this.message = `You are required to upload a photo of selfie. Please upload it before submitting this document. You may only upload <strong>PNG</strong> or <strong>JPG</strong> file formats, and the file size must be less than <strong>20MB</strong>.`
    } else {
      this.title = 'Government ID Required'
      this.message = `You are required to upload a photo of your national ID (passport, state ID, or driver's license). Please upload it before submitting this document. You may only upload <strong>PNG</strong> or <strong>JPG</strong> file formats, and the file size must be less than <strong>20MB</strong>.`
    }
  }

  getUploadBoxOptions(): UploadBoxOptions {
    return {
      uploadUrl: `${environment.signatureApiUrl}/contracts/inapp/files`,
      maxSize: 10 * 1024 * 1024 * 2, // 20MB,
      maxUploads: this.limit,
      autoUpload: true,
      allowedFileExtensions: ['.png', '.jpg', '.jpeg'],
      token: this.authService.token,
      guide: this.message,
    }
  }

  onFilesUploaded(data: any[]) {
    this.uploadedData = data
  }

  onClose() {
    this.dialog.close(this.uploadedData)
  }
}
