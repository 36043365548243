<mat-form-field2 class="mat-block" [showLabel]="showLabel" [hidePlaceholder]="showPlaceholder === false">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <app-select
    [items]="rentals$ | async"
    [formControl]="ctrl"
    [bindLabel]="getRentalLabel"
    bindValue="id"
    [multiple]="multiple"
    [searchable]="true"
    [showBlankItem]="showBlankItem"
    [required]="ctrl | required"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [showMasterCheckbox]="showMasterCheckbox"
    [showCloseButton]="showCloseButton"
    (openedChange)="openedChange.emit($event)"
    (selectionChange)="selectedValuesChange.emit($event)"
    (selectedChange)="selectedItemsChange.emit($event)"
  ></app-select>
  <mat-error *ngIf="ctrl.touched && ctrl.hasError('required')">
    {{ placeholder }} is <strong>required</strong>
  </mat-error>
  <mat-error *ngIf="ctrl.hasError('atLeast')"> You must select at least 2 rentals </mat-error>
</mat-form-field2>
