import { createAction, props } from '@ngrx/store'
import { UserSignature } from './user-signature.model'

export const GetCurrentUser = createAction('[User] Get Current User')
export const GetCurrentUserComplete = createAction('[User] Get Current User Complete', props<{ user: UserSignature }>())

export const UpdateCurrentSignatureUser = createAction(
  '[User] Update Current Signature User',
  props<{ payload: Partial<UserSignature>; message?: string }>()
)
export const UpdateCurrentSignatureUserComplete = createAction(
  '[User] Update Current Signature User Complete',
  props<{ user: UserSignature }>()
)
