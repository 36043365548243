import { createSelector } from '@ngrx/store'
import * as R from 'ramda'
import { RateMappingResponse } from './rental-rate-mapping.model'
import { Rental } from './rental.models'
import { selectRentalEntities, selectAllRentals, rentalState } from './rental.selectors'

export const isSavingRateMapping = createSelector(rentalState, (state) => state.isSavingRateMapping)

export const selectRentalRateMappings = (rentalId: string) =>
  createSelector(selectRentalEntities, (rentalsById) => {
    return rentalsById[rentalId]?.rateMap || []
  })

export const selectAllRateMappings = createSelector(selectAllRentals, (rentals: Rental[]) => {
  return R.pipe(
    R.map((r: Rental) => r.rateMap || []),
    R.flatten
  )(rentals)
})

export const selectRateMappingForChannel = createSelector(
  selectAllRateMappings,
  (items: RateMappingResponse[], props) => {
    return R.filter((rateMap: RateMappingResponse) => rateMap.channel_id === props.id, items)
  }
)
