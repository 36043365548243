<div class="doc">
  <div class="d-flex justify-content-center mt-5" *ngIf="isLoading">
    <app-loading-section size="3rem"></app-loading-section>
  </div>
  <pdf-viewer
    [src]="pdfUrl | signatureSecurePdf : reload | async"
    [render-text]="false"
    [original-size]="false"
    [fit-to-page]="true"
    [autoresize]="true"
    (after-load-complete)="onPdfLoaded()"
    style="display: block"
  >
  </pdf-viewer>
</div>
