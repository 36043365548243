import { UserSignature } from './user-signature.model'

export interface State {
  isLoaded: boolean
  currentUser: UserSignature
}

export const initialState: State = {
  isLoaded: false,
  currentUser: null,
}
