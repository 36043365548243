import { Component, OnInit } from '@angular/core'
import { BaseFieldComponent } from '../base-field.component'

@Component({
  templateUrl: './signdate.component.html',
  styleUrls: ['./signdate.component.scss', '../global.scss'],
})
export class SigndateFieldComponent extends BaseFieldComponent implements OnInit {
  constructor() {
    super()
  }

  ngOnInit() {
    const date = new Date()
    this.field.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` // YYYY-MM-DD;
  }
}
