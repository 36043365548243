import { Injectable } from '@angular/core'
import { ContractDrawerComponent, ContractDrawerTabs } from './contract-drawer.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ContractDrawerService {
  constructor(private dialog: DialogService) {}

  public open(contractId: string, tab?: ContractDrawerTabs) {
    return this.dialog.openDrawer(ContractDrawerComponent, {
      data: { contractId, tab },
      panelClass: 'drawer-full-80',
    })
  }
}
