import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ShareContractDialogService } from './dialogs/share/share-contract-dialog.service'
import { RenameContractDialogService } from './dialogs/rename/rename-contract-dialog.service'
import { AssignInquiryDialogService } from './dialogs/assign/assign-inquiry-dialog.service'
import {
  ContractService,
  ContractView,
  CancelContract,
  SendContract,
  MarkContractAsCompleted,
  ContractSigner,
  DeleteContract,
  SignatureFileService,
} from '@tokeet-frontend/signature'
import { ConfirmDialogService, ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ContractHelperService {
  constructor(
    private store: Store<any>,
    private fileService: SignatureFileService,
    private contractService: ContractService,
    private confirmDialog: ConfirmDialogService,
    private shareContractDialog: ShareContractDialogService,
    private renameContractDialog: RenameContractDialogService,
    private assignInquiryDialog: AssignInquiryDialogService
  ) {}

  onDownloadContract(item: ContractView) {
    this.fileService.downloadToLocal(item.pdf_file_url, `${item.name}.pdf`).subscribe()
  }

  onCancel(item: ContractView) {
    this.confirmDialog
      .confirm({
        title: 'Cancel?',
        body: 'Are you sure that you want to cancel this document?',
      })
      .subscribe(() => {
        this.store.dispatch(CancelContract({ id: item.id }))
      })
  }

  onResend(item: ContractView) {
    this.store.dispatch(SendContract({ id: item.id }))
  }

  onComplete(item: ContractView) {
    this.confirmDialog
      .confirm({
        title: 'Mark as Completed',
        body: 'Are you sure that you want to mark this document as completed?',
      })
      .subscribe(() => {
        this.store.dispatch(MarkContractAsCompleted({ id: item.id }))
      })
  }

  onShare(contract: ContractView) {
    this.shareContractDialog.open(contract)
  }

  onRename(contract: ContractView) {
    this.renameContractDialog.open(contract)
  }

  onAssignInquiry(contract: ContractView) {
    return this.assignInquiryDialog.open(contract)
  }

  generateLink(contract: ContractView, signer: ContractSigner, cb: (link: string) => void) {
    this.contractService.generateSignLink(contract.id, signer.role).subscribe(
      (link) => {
        cb(link)
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }

  openSignEditor(contract: ContractView, signer: ContractSigner) {
    this.generateLink(contract, signer, (link) => {
      window.open(link, '_blank')
    })
  }

  onDelete(item: ContractView) {
    this.confirmDialog.confirm().subscribe(() => this.store.dispatch(DeleteContract({ id: item.id })))
  }
}
