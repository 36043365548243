import { Pipe, PipeTransform } from '@angular/core'
import { Observable, of } from 'rxjs'
import { SignatureFileService } from '../services/file.service'

@Pipe({
  name: 'signatureSecurePdf',
})
export class SignatureSecurePdfPipe implements PipeTransform {
  constructor(private fileService: SignatureFileService) {}

  transform(url: string, reload: boolean = false) {
    if (!url) {
      return of(null)
    }
    return new Observable<any>((observer) => {
      this.fileService.download(url, reload).subscribe((blobRes) => {
        const blob = new Blob([blobRes], { type: 'application/pdf' })
        observer.next(URL.createObjectURL(blob))
        observer.complete()
      })
    })
  }
}
