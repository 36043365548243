<div class="empty-table-container" [ngSwitch]="type">
  <ng-container *ngIf="isFiltering">
    <img src="assets/images/empty_icons/no-result.svg" alt="" class="no-result" />
    <h5 class="no-result">No Results Found</h5>
    <p class="no-result">We couldn't find any results. Change selected filters or try later</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsMessages">
    <img src="assets/images/empty_icons/messages.svg" />
    <p>No messages available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsNotes">
    <img src="assets/images/empty_icons/file-signature.svg" />
    <p>No comments available</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.InquiryDetailsChecklistNotes">
    <img src="assets/images/empty_icons/checklist-notes.svg" />
    <p>No notes available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsPayments">
    <img src="assets/images/empty_icons/invoices.svg" alt="" />
    <p>No invoices available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsFlights">
    <img src="assets/images/empty_icons/arrival.svg" />
    <p>No flights available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsContracts">
    <img src="assets/images/empty_icons/file-signature.svg" />
    <p>No contracts available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsExtras">
    <img class="empty-image" src="assets/images/empty_icons/expenses.svg" />
    <p>No extras available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsCharges">
    <img class="empty-image" src="assets/images/empty_icons/invoices.svg" />
    <p>No charges available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsExpenses">
    <img class="empty-image" src="assets/images/empty_icons/expenses.svg" />
    <p>No expenses available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiryDetailsTasks">
    <img class="empty-image" src="assets/images/empty_icons/tasks.svg" />
    <p>No tasks available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.AdditionalGuests">
    <img class="empty-image" src="assets/images/empty_icons/guests.svg" />

    <h5 class="empty-header">Additional Guests</h5>

    <p>
      Assign additional guests to an inquiry. You can assign existing guests by using select box and clicking Add, or
      create a new guest using Create New.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RuleConditions">
    <i class="cs-icon cs-icon-bolt empty-icon"></i>

    <h5 class="empty-header">Automation Conditions</h5>

    <p>
      This is a list of conditions which are required for your automation to run. Conditions determine if your
      automation executes when a certain event occurs. All conditions listed must be true for the automation to execute.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Automations">
    <img class="empty-image" src="assets/images/empty_icons/automations.svg" />

    <h5 class="empty-header">View All Your Automations in One Place</h5>

    <p>
      Monitor and manage your workflows seamlessly. All your automation tasks are displayed here for easy access and
      updates.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.BookingAutomations">
    <img class="empty-image" src="assets/images/empty_icons/bookings-automation.svg" />

    <h5 class="empty-header">No Booking Automation Set</h5>

    <p>
      Streamline your booking process by setting up automation for key actions when a booking is received or updated.
      Automatically send booking confirmations, create tasks for your team, update calendars, or notify guests of
      important details. Booking automation helps you manage reservations efficiently, ensuring a seamless experience
      for both you and your guests.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.TaskAutomations">
    <img class="empty-image" src="assets/images/empty_icons/tasks-automation.svg" />

    <h5 class="empty-header">No Task Automation Set</h5>

    <p>
      Create task automations to streamline your workflow and save time. Set up automatic task creation or updates when
      specific events occur, such as new bookings, payment confirmations, or guest check-ins. Automations ensure that
      tasks are assigned, updated, and tracked without manual input, allowing for a more efficient task management
      process.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.ExpenseAutomations">
    <img class="empty-image" src="assets/images/empty_icons/expenses-automation.svg" />

    <h5 class="empty-header">No Expense Automation Set</h5>

    <p>
      Take control of your operational costs by automating expense tracking and management. Set up automation to record
      recurring expenses, such as cleaning fees, utility bills, or maintenance costs, whenever specific events occur.
      This helps you maintain accurate financial records and reduce manual input, ensuring your expenses are always
      up-to-date.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.ReviewAutomations">
    <img class="empty-image" src="assets/images/empty_icons/review-automation.svg" />

    <h5 class="empty-header">No Review Automation Set</h5>

    <p>
      Boost your property's reputation by automating guest review requests. Set up automation to send personalized
      review invitations after guest check-outs, ensuring you consistently gather feedback. Automating this process
      helps you build trust with future guests while saving time and improving response rates.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.DocumentAutomations">
    <img class="empty-image" src="assets/images/empty_icons/document-automations.svg" />

    <h5 class="empty-header">Let Automations Work for You</h5>
    <p>Create powerful document workflows and let automations take the hassle out of manual tasks.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.DataFeed">
    <img class="empty-image" src="assets/images/empty_icons/feed.svg" />

    <h5 class="empty-header">Securely extract data from AdvanceCM.</h5>

    <p>
      Extract data from AdvanceCM using a single URL and without any programming. Data feeds allow you to download your
      AdvanceCM data easily and securely with SSL encryption. Create a new data feed and share the URL with anyone you
      like. Delete or pause the data feed and the URL stops working. Data feeds keep you in full control of your data.
      You may customize your data feed on the fly using query parameters. See our help center for more details.
    </p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.UpcomingArrivals">
    <img class="empty-image" src="assets/images/empty_icons/arrival.svg" />

    <h5 class="empty-header">No Upcoming Arrivals</h5>

    <p>This section will show the 20 most recent arrivals within the next 30 days.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.UpcomingDepartures">
    <img class="empty-image" src="assets/images/empty_icons/departure.svg" />

    <h5 class="empty-header">No Upcoming Departures</h5>

    <p>
      This section will show the departures within 30 days, including bookings checked out today but not yet updated.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.PastDueInvoices">
    <img class="empty-image" src="assets/images/empty_icons/past_due.svg" />
    <h5 class="empty-header">Past Due Invoices</h5>

    <p>This section will show the 20 most recent past due invoices within the past 60 days.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InvoiceRuleActivityLog">
    <img class="empty-image" src="assets/images/empty_icons/activity_feed.svg" />

    <h5 class="empty-header">Activity Logs</h5>

    <p>No activity logged. Your invoice activity will display in this table.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.PaymentGateways">
    <img class="empty-image" src="assets/images/empty_icons/gateways.svg" />

    <h5 class="empty-header">Payment Gateways</h5>

    <p>
      AdvanceCM allows you to easily accept credit card payments from your guests by connecting multiple payment
      gateways. You may even connect multiple accounts from the same gateway. If you do not have a payment gateway don't
      worry, signing up only takes a few minutes. Simply click the green Add Gateway button at the top right. Once
      you've connected one of the payment gateways AdvanceCM will display a payment button on all your invoices so
      guests can pay quickly and easily.
    </p>

    <p>If you need more help simply click on the red help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.PaymentGatewayLog">
    <img class="empty-image" src="assets/images/empty_icons/activity_feed.svg" />

    <h5 class="empty-header">Activity Logs</h5>

    <p>
      No activity logged. Your payment activity will display in this table once you start collecting payments via this
      gateway.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.PaymentRules">
    <img class="empty-image" src="assets/images/empty_icons/payment_rule.svg" />

    <h5 class="empty-header">Payment Rules</h5>

    <p>
      You may create rules for how your payments are processed. Specify which gateway to use for which channel or rental
      and turn on auto billing for some channels. Rules override your default gateway setting and affect which gateway
      is used to process your invoice payments.
    </p>

    <p>
      <a target="_blank" href="https://help.tokeet.com/invoices-and-billing/payment-gateways/payment-rules"
        >Click here</a
      >
      to see how it works. If you need more help simply click on the red help circle in the lower left of the screen.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.UserSettings">
    <img class="empty-image" src="assets/images/empty_icons/add_user.svg" />

    <h5 class="empty-header">Add More Users</h5>

    <p>You may add users to your account by clicking the ADD button at the top right above the table.</p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Templates">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">Create your Template.</h5>

    <p>You can create different types of templates to send to your guests via email or Push Notification.</p>

    <p>Create a template now by clicking the ADD button above.</p>

    <p>If you need more help simply click on the red help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.AutomataTriggers">
    <img class="empty-image" src="assets/images/empty_icons/automations.svg" />

    <h5 class="empty-header">Triggers</h5>

    <p>
      Triggers allow you to automate your rental management and save time. A trigger performs a specific action when an
      event occurs. For example, triggers may be used to notify your cleaners when a new booking is received or to
      automatically reply to guests. Replying quickly to guests will increase your search rankings and chances of
      getting more bookings.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Invoices">
    <img class="empty-image" src="assets/images/empty_icons/invoices.svg" />

    <h5 class="empty-header">You haven't created any invoices.</h5>

    <p>
      To create an invoice simply go to any Inquiry Detail and click the Create Invoice button. This will create a new
      invoice which you can send to your guest for payment. The invoice will be automatically populated with all the
      needed information. It's quick and easy, give it a try.
    </p>

    <p>
      <a target="_blank" href="https://help.tokeet.com/invoices-and-billing/invoice-management/invoicing-payments"
        >Click here</a
      >
      for a short video on how it works. If you need more help simply click on the help circle in the lower right of the
      screen.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Charges">
    <img class="empty-image" src="assets/images/empty_icons/invoices.svg" />

    <h5 class="empty-header">You haven't created any charges.</h5>

    <p>
      All charges will show up here. You can manually add a new charge by clicking the ADD button above. You can also
      export your charges to a CSV file.
    </p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.ChannelConnections">
    <img class="empty-image" src="assets/images/empty_icons/connections.svg" />

    <h5 class="empty-header">You haven't connected to any channels.</h5>

    <p>To connect to a channel simply click the Add Channel button.</p>

    <p>
      <a target="_blank" href="https://help.tokeet.com/channel-manager/tokeet-channel-manager-overview"
        ><span class="fas fa-video"></span> Click here</a
      >
      for a short video on how it works. If you need more help simply click on the red help circle in the lower right of
      the screen.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiriesList">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />

    <h5 class="empty-header">Your inquiries inbox.</h5>

    <p>
      This is your inquiries list where you may view all your reservation inquiries. You can manually enter a new
      inquiry using the ADD button above or have inquiries generated from your marketing channels. In order for
      inquiries from your marketing channels (Flipkey, VRBO, etc) to show in AdvanceCM you must enter your AdvanceCM
      rental email address into your marketing channel's contact section. You will find your AdvanceCM rental email
      address in the Rental Settings of each rental. Also, you can use an inquiry widget to capture inquiries on your
      website.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiriesInbox">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />

    <h5 class="empty-header">Your inquiries inbox.</h5>

    <p>
      This is your inquiries inbox where you may view all your reservation inquiries. You can manually enter a new
      inquiry using the round green plus button at the top left or have inquiries generated from your marketing
      channels. In order for inquiries from your marketing channels (Flipkey, VRBO, etc) to show in AdvanceCM you must
      enter your AdvanceCM rental email address into your marketing channel's contact section. You will find your
      AdvanceCM rental email address in the Rental Settings of each rental. Also, you can use an inquiry widget to
      capture inquiries on your website.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InquiriesInboxList">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />

    <h5 class="empty-header">No inquiries match your search</h5>

    <p>
      We couldn't find what you're looking for, <br />
      please modify your search criteria to view inquiries.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.InquiryNotesList">
    <img class="empty-image" src="assets/images/empty_icons/file-signature.svg" />

    <h5 class="empty-header">No Comments</h5>
    <p>
      There are no comments about this inquiry. Keep comments about your inquiry so you don't forget the important
      things.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RentalsList">
    <img class="empty-image" src="assets/images/empty_icons/rentals.svg" />

    <h5 class="empty-header">Add your rentals.</h5>

    <p>
      Rental settings are an essential and required component of your AdvanceCM account. Once you've added a Rental you
      can then specify your rental rates, currency, and other rental details. It's time to add your first rental in
      AdvanceCM. You may add rentals by clicking the ADD button above, or by importing them from your channels. Go to
      the Channels section to import your rentals.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestsList">
    <img class="empty-image" src="assets/images/empty_icons/guests.svg" />

    <h5 class="empty-header">Grow your guestbook</h5>
    <p>
      This is your guest list where you can view all your guest contacts. You may enter guests manually by clicking the
      ADD button above. Guests will also be automatically added to your guest list whenever they send an inquiry to your
      AdvanceCM email address or inquire online via your inquiry widget. We recommend uploading guests via csv or vcard
      files, so you don't have to enter anything manually.
    </p>
    <br />
    <p class="text-left" style="line-height: 24px">
      <span class="text-left"
        ><a target="_blank" href="https://help.tokeet.com/handling-inquiries/guest-list-overview" style="color: #00bcd3"
          ><i class="fal fa-video-camera"></i> Click here</a
        >
        for a short video on how it works. </span
      >If you need more help simply click on the red help circle in the lower left of the screen.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestNotesList">
    <img class="empty-image" src="assets/images/empty_icons/file-signature.svg" />

    <h5 class="empty-header">No Guest Notes</h5>
    <p>There are no notes about this guest. Keep notes about your guests so you don't forget the important things.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestInteractionsList">
    <img class="empty-image" src="assets/images/empty_icons/comments.svg" />

    <h5 class="empty-header">No Guest Interactions</h5>
    <p>
      You haven't recorded any interactions with this guest. Use AdvanceCM as your complete CRM and record all important
      guest interactions for future reference.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestFilesList">
    <img class="empty-image" src="assets/images/empty_icons/file-alt.svg" />

    <h5 class="empty-header">No Guest Files</h5>
    <p>You haven't saved any files related to this guest. You should upload important guest documents here.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InvoiceRules">
    <img class="empty-image" src="assets/images/empty_icons/invoice-automation.svg" />

    <h5 class="empty-header">No Invoice Automation Set</h5>

    <p>
      Automate your invoice management and save valuable time with invoice automations. Set up automations to trigger
      specific actions, such as generating, sending, or updating invoices, when key events occur—like bookings,
      payments, or cancellations. This ensures your invoices are always accurate and timely, freeing you to focus on
      managing your business instead of manual billing tasks.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InvoiceAssociatedRules">
    <img class="empty-image" src="assets/images/empty_icons/invoice_rules.svg" />

    <h5 class="empty-header">Invoice Automations</h5>

    <p>
      Invoice automations allow you to automate your invoice management and save time. Invoice automation performs a
      specific action when an event occurs.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InvoiceTemplates">
    <img class="empty-image" src="assets/images/empty_icons/expenses.svg" />

    <h5 class="empty-header">Invoice Templates</h5>

    <p>
      Templates contain the content which will be used in your automatic invoices. For example, templates contain the
      body of the email message which your rules will send. Create a template by clicking the 'Create Booking Template'
      button above.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestEmailsList">
    <img class="empty-image" src="assets/images/empty_icons/guest-messages.svg" />

    <h5 class="empty-header">No Guest Messages</h5>
    <p>
      You haven't messaged this guest or received a message from them. Keep the communication going to ensure guest
      satisfaction.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestBookingsList">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />

    <h5 class="empty-header">No bookings available</h5>

    <p>
      You haven't created any bookings yet, but we know you will soon. To create a booking click the Add button on the
      top right part of the overlay. This will mark the Inquiry as booked and display it in your calendar. It will also
      update all of your channel's calendars, if you've uploaded your calendar URL in those channels. For more help
      click the help circle in the lower left of the screen.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GuestOtherBookingsList">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />
    <p>No other bookings</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.FileCabinet">
    <img class="empty-image" src="assets/images/empty_icons/cabinet.svg" />

    <h5 class="empty-header">Smarter file storage.</h5>

    <p>
      The AdvanceCM File Cabinet is a smarter way to attach files to your messages. Whenever you attach a file to a
      message sent to any guest AdvanceCM saves that file to your File Cabinet. The file can then be attached to
      subsequent messages to any guest without needing to re-upload the attachment. Even better, the file can be used by
      any other user in your AdvanceCM account. Click the Upload button above to upload a new file to your File Cabinet.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.BillingHistory">
    <p>No billing activity recorded</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.CreditChargeHistory">
    <p>No charge activity recorded</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.CalendarSidebarRentals">
    <img class="empty-image" src="assets/images/empty_icons/standard-calendar.svg" />

    <h5 class="empty-header">Get Started with Your First Rental!</h5>

    <p>
      It looks like you haven't added any rentals yet. Start by creating a new rental to manage bookings, rates, and
      availability in one place.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.CalendarContentRentals">
    <img class="empty-image" src="assets/images/empty_icons/planning-calendar.svg" />

    <h5 class="empty-header">Your Calendar Awaits!</h5>

    <p>
      Once you add rentals, this calendar will come alive. You can view availability, track bookings, and effortlessly
      manage all your properties right here.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.StandardCalendar">
    <img class="empty-image" src="assets/images/empty_icons/standard-calendar.svg" />

    <h5 class="empty-header">No Rentals Added Yet!</h5>

    <p>Start by adding your first rental property to manage bookings and reservations effortlessly.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Agenda">
    <img class="empty-image" src="assets/images/empty_icons/agenda.svg" />

    <h5 class="empty-header">Nothing Scheduled Yet!</h5>

    <p>
      No events are currently scheduled. Use the filters above to set date ranges, select rentals, or add a new task to
      stay on top of your agenda.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Hold">
    <img class="empty-image" src="assets/images/empty_icons/hold-events.svg" />

    <h5 class="empty-header">No Hold Events Yet</h5>

    <p>Looks like there aren't any hold events scheduled.</p>

    <p>create a new hold event and manage availability for your rentals.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Expenses">
    <img class="empty-image" src="assets/images/empty_icons/expenses.svg" />

    <h5 class="empty-header">Looking for your expenses?</h5>

    <p>
      All expenses will show up here. You can manually add a new expense by clicking the ADD button above. You can also
      export your expenses to a CSV file or upload a CSV file to save time on manual entry.
    </p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Extras">
    <img class="empty-image" src="assets/images/empty_icons/expenses.svg" />

    <h5 class="empty-header">Looking for your extras?</h5>

    <p>
      All extras will show up here. You can manually add a new extra by clicking the ADD button above. You can also
      export your extras to a CSV file.
    </p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Tasks">
    <img class="empty-image" src="assets/images/empty_icons/tasks.svg" />

    <h5 class="empty-header">Add More Tasks</h5>

    <ng-container *ngxPermissionsOnly="['canAddTasks']">
      <p>You may add tasks to your account by clicking the ADD button at the top right above the table.</p>

      <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
    </ng-container>

    <ng-container *ngxPermissionsExcept="['canAddTasks']">
      <p>
        Any tasks assigned to you will be presented here. You can open a task by clicking on it and mark it as completed
        once you have done so.
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="types.Incidents">
    <img class="empty-image" src="assets/images/empty_icons/tasks.svg" />

    <h5 class="empty-header">Add More Incidents</h5>

    <p>You may add incident to your account by clicking the ADD button at the top right above the table.</p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Inventories">
    <img class="empty-image" src="assets/images/empty_icons/tasks.svg" />

    <h5 class="empty-header">Add More Inventories</h5>

    <p>You may add inventory to your account by clicking the ADD button at the top right above the table.</p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.CommentsList">
    <img class="empty-image" src="assets/images/empty_icons/no-conversation.svg" />
    <h5 class="empty-header">No Comments Yet</h5>
    <p class="text-light">Start the conversation by adding your first comment below.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.EmailOptions">
    <img class="empty-image" src="assets/images/empty_icons/email-forwarding.svg" />

    <h5 class="empty-header">No Email Forwarding</h5>

    <p>Please Add your email forwardings to see your them here</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.SlackChannelConfig">
    <i class="fal fa-plug empty-icon"></i>

    <h5 class="empty-header">Connect your slack channels.</h5>

    <p>
      This is a list of your connected Slack channels. Use the Connect button to assign a AdvanceCM event to a specific
      channel in your slack account. Once a connection is made, AdvanceCM will send a message to the specified channel
      whenever that event occurs.
    </p>

    <p>If you need more help simply click on the help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.SharedContent">
    <img class="empty-image" src="assets/images/empty_icons/share.svg" />

    <h5 class="empty-header">Shared Content.</h5>

    <p>
      This is your shared content list where you can view all shares that were previously created. You may share content
      by clicking the SHARE button above, or disable and delete existing shared content.
    </p>

    <p>If you need more help simply click on the red help circle in the lower left of the screen.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.CustomCodesList">
    <i class="fal fa-book empty-icon"></i>

    <h5 class="empty-header">Custom Codes</h5>

    <p>
      Custom codes allow you to create custom information fields that can be inserted into templates. Each custom code
      can be related to a specific rental or a group of rentals. When the same custom code is used for multiple rentals
      AdvanceCM will automatically pull the respective custom code for the rental.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RentalRates">
    <img class="empty-image" src="assets/images/empty_icons/rates.svg" />

    <h5 class="empty-header">No Rates Created</h5>

    <p>
      You haven't created any rates yet. Click on the add rates button above to create a rate of your choice. Need more
      advanced rate management? Try <a href="https://www.rategenie.io" target="_blank">Rategenie</a> today. Rategenie is
      AdvanceCM's best-in-class dynamic pricing tool.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.GroupRates">
    <img class="empty-image" src="assets/images/empty_icons/rates.svg" />
    <h5 class="empty-header">Group Rates</h5>

    <p>No group rates available.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.ChannelAdjustments">
    <img class="empty-image" src="assets/images/empty_icons/box-up.svg" />

    <h5 class="empty-header">Channel Rate Automations</h5>

    <p>No channel rate automations available.</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.MinStayAutomations">
    <img class="empty-image" src="assets/images/empty_icons/box-up.svg" />

    <h5 class="empty-header">Min Stay Automations</h5>

    <p>No min stay automations available.</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.GapNightAutomations">
    <img class="empty-image" src="assets/images/empty_icons/box-up.svg" />

    <h5 class="empty-header">Gap Night Automations</h5>

    <p>No gap night automations available.</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.LastMinuteAutomations">
    <img class="empty-image" src="assets/images/empty_icons/box-up.svg" />

    <h5 class="empty-header">Last Minute Automations</h5>

    <p>No last minute automations available.</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.RentalRateMappings">
    <img class="empty-image" src="assets/images/empty_icons/rate-mappings.svg" />

    <h5 class="empty-header">No Rate Mapping Found</h5>

    <p>
      Rate mappings allow you to specify which AdvanceCM rates should be pushed to specific channel rates. This setting
      is required for automatic rate pushes and allows you to update all linked channels with one click.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.MessageFeed">
    <img class="empty-image" src="assets/images/empty_icons/messages.svg" />

    <h5 class="empty-header">No Messages Yet</h5>

    <p>You don't have any messages yet. When a message arrives, you'll see it here. Stay tuned!</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.MessageTable">
    <img class="empty-image" src="assets/images/empty_icons/messages2.svg" />

    <h5 class="empty-header">No Messages Yet</h5>

    <p>Your inbox is currently empty.</p>
    <p>Messages will appear here once you start communicating with your guests.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.MonthlyOccupancyRateChart">
    <i class="fal fa-chart-line empty-icon"></i>

    <p>
      Your monthly occupancy rate will appear here once we have imported bookings to your AdvanceCM account. The bar
      graph, which can be filtered by rental and/or year will show each month and the occupancy rate for each.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.BookingMtdChart">
    <i class="fal fa-chart-line empty-icon"></i>

    <p>
      Your number of bookings per month will show here once we have imported bookings to your AdvanceCM account. The
      graph can be filtered by rental and/or year.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RevenueMtdChart">
    <i class="fal fa-chart-line empty-icon"></i>

    <p>
      Your monthly revenue will show here in the form of a bar graph once we have imported bookings to your AdvanceCM
      account. The graph can be filtered by rental and/or year.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.ActivityFeed">
    <img class="empty-image" src="assets/images/empty_icons/activity_feed.svg" />

    <p>
      Your important account activity will show here. This will allow you to easily stay on top of new bookings,
      cancellations, and invoice payments.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Websites">
    <img class="empty-image" src="assets/images/empty_icons/window.svg" />

    <h5 class="empty-header">Create a website in 5 minutes.</h5>

    <p>
      It's time to create your rental website with AdvanceCM. You can create a website by clicking the Create button
      above.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.WebsitePages">
    <img class="empty-image" src="assets/images/empty_icons/file-invoice.svg" />

    <h5 class="empty-header">Add an additional webpage.</h5>

    <p>
      You may add additional pages to your website simply by creating a new page here. Your additional pages will be
      linked from the homepage of your website.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.WebsiteRentals">
    <img class="empty-image" src="assets/images/empty_icons/file-invoice.svg" />

    <h5 class="empty-header">Website rentals.</h5>

    <p>
      You may select which rentals you would like to include in this website. However, you must first create some
      rentals in the Rentals section.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.Stealths">
    <img class="empty-image" src="assets/images/empty_icons/stealth.svg" />

    <h5 class="empty-header">Stealth Automation.</h5>

    <p>
      Stealth mode unlists your Airbnb rental during specific hours of the day and hides it from prying eyes. It will be
      re-listed at a time you specify and when you are more likely to be seen by potential guests.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.AutomationLogs">
    <img class="empty-image" src="assets/images/empty_icons/bell.svg" />

    <h5 class="empty-header">Automation Logs</h5>

    <p>No activity logged. This trigger has not been fired recently.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.LosDiscounts">
    <img class="empty-image" src="assets/images/empty_icons/los-discount.svg" />

    <h5 class="empty-header">No LOS discounts yet</h5>

    <p>You have not created any discounts, please click <b>Add</b> button to add new discount</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.DiscountCodes">
    <img class="empty-image" src="assets/images/empty_icons/discount-codes.svg" />

    <h5 class="empty-header">No Discount codes yet</h5>

    <p>You have not created any discounts, please click <b>Add</b> button to add new discount</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RentalDiscounts">
    <img class="empty-image" src="assets/images/empty_icons/discounts.svg" />

    <h5 class="empty-header">No Discount yet</h5>

    <p>You have not created any discounts, please click <b>Add</b> button to add new discount</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.DiscountCodeActivities">
    <img class="empty-image" src="assets/images/empty_icons/discount-codes.svg" />

    <h5 class="empty-header">No Discount activities yet</h5>

    <p>No activity logged. This discount has not been applied.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.DiscountCodeLogs">
    <img class="empty-image" src="assets/images/empty_icons/discount-codes.svg" />

    <h5 class="empty-header">No Discount logs yet</h5>

    <p>No logs yet. This discount has not been modified.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.BookingFormulas">
    <img class="empty-image" src="assets/images/empty_icons/tasks.svg" />

    <h5 class="empty-header">No Booking formulas yet</h5>

    <p>You have not created any formulas, please click <b>Add</b> button to add new formula</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.PushAllRentals">
    <i class="empty-icon fal fa-share-alt"></i>

    <h5 class="empty-header">No Listings Connected</h5>

    <p>Please link at least one listing to your channel for using this feature</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.MappedRentals">
    <img class="empty-image" src="assets/images/empty_icons/mapped_rentals.svg" />
    <h5 class="empty-header">No Mapped Rentals</h5>

    <p>
      No mapped rentals found. Either your accommodation id is wrong or you need to complete connection via the channel.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AirbnbReviews">
    <img class="empty-image" src="assets/images/empty_icons/reviews.svg" />

    <h5 class="empty-header">No Reviews</h5>

    <p>Please link your channel to see your reviews here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AirbnbOpportunities">
    <img class="empty-image" src="assets/images/empty_icons/opportunities.svg" />
    <h5 class="empty-header">No Opportunities</h5>

    <p>Please link your channel to see your opportunities here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AirbnbNotifications">
    <img class="empty-image" src="assets/images/empty_icons/notifications.svg" />
    <h5 class="empty-header">No Notifications</h5>

    <p>Please link your channel to see your notifications here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.Commissions">
    <img class="empty-image" src="assets/images/empty_icons/commissions.svg" />
    <h5 class="empty-header">No Commissions</h5>

    <p>You have not created any commissions, please click <b>Add</b> button to add new commission</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.Integrations">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />
    <h5 class="empty-header">No Integrations match your search</h5>

    <p>
      We couldn't find what you're looking for, <br />
      please modify your search criteria to view integrations
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.InventorySearch">
    <img class="empty-image" src="assets/images/empty_icons/inventory-search.svg" />
    <h5 class="empty-header">Enter Filter Values to Populate the Rental List</h5>

    <p>
      The rental list will populate here once you enter your criteria in the fields above. Choose your dates, specify
      the number of guests, and use the search bar to find rentals that match your needs.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.RentalSearch">
    <img class="empty-image" src="assets/images/empty_icons/search.svg" />
    <h5 class="empty-header">No Rentals match your search</h5>

    <p>
      We couldn't find what you're looking for, <br />
      please modify your search criteria to view rentals
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.NoRentals">
    <img class="empty-image" src="assets/images/empty_icons/rentals.svg" />
    <h5 class="empty-header">No Rentals</h5>

    <p>Add rental from the <b>Rentals</b> menu in the top.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.NoBookings">
    <img class="empty-image" src="assets/images/empty_icons/calendar.svg" />
    <h5 class="empty-header">No Bookings Added</h5>

    <p>
      There is no bookings to show here, <br />
      please click <b>Edit</b> button to add bookings.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.NoRates">
    <img class="empty-image" src="assets/images/empty_icons/rates.svg" />
    <h5 class="empty-header">No Rates Added</h5>

    <p>
      There is no rates to show here, <br />
      please click <b>Edit</b> button to add rates.
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.ImportedCalendars">
    <img class="empty-image" src="assets/images/empty_icons/calendar.svg" />
    <h5 class="empty-header">No Imported Calendars</h5>

    <p>Please import a calendar to see your it here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.ExportedCalendars">
    <img class="empty-image" src="assets/images/empty_icons/calendar.svg" />
    <h5 class="empty-header">No Exported Calendars</h5>

    <p>Please create an exported calendar to see your it here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.ConnectionActivities">
    <img class="empty-image" src="assets/images/empty_icons/activity_feed.svg" />

    <h5 class="empty-header">Activity Logs</h5>

    <p>No activity log. You should push rates and availability to ensure your connection is working correctly.</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.Reviews">
    <i class="empty-icon far fa-comments-alt"></i>

    <h5 class="empty-header">No Reviews</h5>

    <p>Please link your channel to see your reviews here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIDocuments">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No documents</h5>

    <p>Please upload your documents to see them here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIWebsites">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No Websites</h5>

    <p>Please add your websites to see them here</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIIgnoreBookings">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No Ignored Bookings</h5>

    <p>Please add your bookings to exclude them from Advance Intelligence automated responses</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIIgnoreGuests">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No Ignored Guests</h5>

    <p>Please add your guests to exclude them from Advance Intelligence automated responses</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIIgnoreRentals">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No Ignored Rentals</h5>

    <p>Please add your rentals to exclude them from Advance Intelligence automated responses</p>
  </ng-container>
  <ng-container *ngSwitchCase="types.AIIgnoreGuestsRegexp">
    <img class="empty-image" src="assets/images/empty_icons/templates.svg" />

    <h5 class="empty-header">No Ignored Regex Patterns</h5>

    <p>
      Please add your regex to exclude the guests from Advance Intelligence automated responses when emails are matched
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="types.SmartDevices">
    <img class="empty-image" src="assets/images/empty_icons/smart-devices.svg" />

    <h5 class="empty-header">Link locks to get started!</h5>

    <p>Here you'll find all the locks you've linked to AdvanceCM rentals.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.TaskChecklist">
    <img src="assets/images/empty_icons/tasks.svg" />
    <p>No checklist items available</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.ChannelAccounts">
    <img src="assets/images/empty_icons/no-accounts.svg" />
    <h5 class="empty-header">No accounts available.</h5>
  </ng-container>

  <ng-container *ngSwitchCase="types.ActivityList">
    <img class="empty-image" src="assets/images/empty_icons/no-history.svg" />
    <h5 class="empty-header">No History Available</h5>
    <p>As you make changes or updates, the history of your actions will appear here for tracking and review.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.AccessCodeLogs">
    <img class="empty-image" src="assets/images/empty_icons/no-history.svg" />
    <h5 class="empty-header">No History Available</h5>
    <p>You can see the history when the code was used.</p>
  </ng-container>

  <ng-container *ngSwitchCase="types.SignatureDocuments">
    <img class="empty-image" src="assets/images/empty_icons/contracts.svg" />
    <h5 class="empty-header">Streamline Your Agreements with Ease</h5>
    <p>
      Digitise your document signing process effortlessly. Start by adding your first document to simplify workflows and
      enhance collaboration.
    </p>
  </ng-container>

  <ng-container *ngSwitchCase="types.SignatureTemplates">
    <img class="empty-image" src="assets/images/empty_icons/document-templates.svg" />
    <h5 class="empty-header">Streamline Your Document Workflow</h5>
    <p>Create reusable templates for contracts, forms, and more to save time and maintain consistency.</p>
  </ng-container>

  <!-- Custom Content -->
  <ng-content></ng-content>
</div>
