<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">Create Channel Listing</h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <p>
          Create a listing on this channel from one of your AdvanceCM rentals. First, select the channel account in
          which you would like to create the new listing.
        </p>
        <app-select-search
          bindLabel="propertyId"
          [inline]="false"
          [items]="accounts$ | async"
          [ctrl]="form.get('account')"
          [multiple]="false"
          placeholder="Channel Account ID"
        >
          <ng-container class="error-container">
            <mat-error> Value is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
        <p class="text-danger" *ngIf="!(isActiveAccount$ | async)" style="margin-top: -15px">
          You can’t create a listing on the selected Airbnb account because it doesn’t have an active Airbnb listing. To
          create a listing it is mandatory that you already have an active listing on Airbnb.
        </p>

        <p>Now select the AdvanceCM rental you would like to publish.</p>
        <app-rental-select [ctrl]="form.get('rental')" [multiple]="false" placeholder="AdvanceCM Rental">
        </app-rental-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button
      (click)="save(form)"
      [disabled]="!(isActiveAccount$ | async) || form.invalid"
      type="button"
      class="btn btn-secondary-info create-btn"
    >
      <i class="far fa-plus"></i> Create
    </button>
  </div>
</form>
