import { Component, OnInit } from '@angular/core'
import {
  ConfirmDialogService,
  DataCheckerService,
  Destroyable,
  openWindow,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import {
  availableMessageChannels,
  DisconnectMessagingChannel,
  LoadMessagingChannelConfigs,
  MessagingChannelGuard,
  MessagingChannelKeys,
  MessagingChannelsConfig,
  MessagingChannelsService,
  selectMessagingChannels,
} from '@tokeet-frontend/message'
import { select, Store } from '@ngrx/store'
import { MatDialogRef } from '@angular/material/dialog'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { map } from 'rxjs/operators'
import { LoadActivePlans } from '@tv3/store/plan/plan.actions'
import { AppOverviewDialogService } from '@tokeet-frontend/billing'
import { interval } from 'rxjs'
import { isNumber } from 'lodash'
import { HttpClient } from '@angular/common/http'

export enum MessagingSettingsTabs {
  Channels,
  Charges,
}

@Component({
  selector: 'app-messaging-settings',
  templateUrl: './messaging-settings.component.html',
  styleUrls: ['./messaging-settings.component.scss'],
})
export class MessagingSettingsComponent extends Destroyable implements OnInit {
  tabs = MessagingSettingsTabs
  activeTab = MessagingSettingsTabs.Channels

  channelConfig: MessagingChannelsConfig
  items = availableMessageChannels

  isMessagingSubscribed = false

  facebookPages: { name: string }[]
  constructor(
    private store: Store<any>,
    private confirm: ConfirmDialogService,
    private messagingChannelsService: MessagingChannelsService,
    private appOverviewDialog: AppOverviewDialogService,
    public dialogRef: MatDialogRef<MessagingSettingsComponent>,
    private dataChecker: DataCheckerService,
    private http: HttpClient
  ) {
    super()
    this.dataChecker.check([MessagingChannelGuard])
  }

  async ngOnInit() {
    try {
      const response = await this.http.get<{ name: string }[]>('@api/v1/message/facebook/pages').toPromise()
      this.facebookPages = response?.['response']
    } catch (e) {}

    interval(2000)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.store.dispatch(LoadMessagingChannelConfigs())
      })

    this.store.pipe(select(selectMessagingChannels), untilDestroy(this)).subscribe((d) => {
      this.channelConfig = d
    })

    this.store
      .pipe(select(selectActiveProductPlanView(ProductsForPlan.Messaging)), untilDestroy(this))
      .subscribe((sub) => {
        this.isMessagingSubscribed = sub?.isValid
      })
  }

  onConnect(channel: MessagingChannelKeys) {
    // Special handling for Facebook
    if (channel === 'facebook') {
      this.confirm
        .confirmExtra({
          title: 'Is your Facebook page managed by a business account?',
          body: 'If your Facebook page is managed through a business account, please check the box below.',
          extra: 'My page is managed by a business account',
          confirmText: 'Proceed',
          cancelText: 'Cancel',
        })
        .subscribe((result) => {
          // User made a choice (didn't close dialog)
          const type = result.isChecked ? 'business' : 'personal'
          this.messagingChannelsService.loginChannel(channel, type).subscribe((url) => {
            openWindow(url, null, 1000)
          })
        })
    } else {
      // Original behavior for other channels
      this.messagingChannelsService.loginChannel(channel).subscribe((url) => {
        openWindow(url, null, 1000)
      })
    }
  }

  onDisconnect(channel: MessagingChannelKeys) {
    this.confirm.confirm({ title: 'Warning!', body: 'Are you sure you want to disconnnect this?' }).subscribe(() => {
      this.store.dispatch(DisconnectMessagingChannel({ channel }))
    })
  }

  onSubscribe() {
    this.appOverviewDialog
      .open(ProductsForPlan.Messaging)
      .afterClosed()
      .subscribe((res) => {
        this.store.dispatch(LoadActivePlans({}))
      })
  }

  close() {
    this.dialogRef.close()
  }
}
