import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { debounceTime, filter, take } from 'rxjs/operators'
import { ContractHelperService } from '../contract-helper.service'
import {
  ContractStatus,
  ContractView,
  GetContract,
  selectContractViewById,
  MarkContractAsCompletedComplete,
  ContractNotFound,
} from '@tokeet-frontend/signature'
import { Destroyable, Toaster, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export enum ContractDrawerTabs {
  Details,
  Options,
}

@Component({
  selector: 'app-contract-drawer',
  templateUrl: './contract-drawer.component.html',
  styleUrls: ['./contract-drawer.component.scss'],
})
export class ContractDrawerComponent extends Destroyable implements OnInit {
  tabs = ContractDrawerTabs
  currentTab = ContractDrawerTabs.Details

  contractStatus = ContractStatus
  contract: ContractView

  isCompleted = false

  constructor(
    private dialog: MatDialogRef<ContractDrawerComponent>,
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    public helperService: ContractHelperService,
    @Inject(MAT_DIALOG_DATA) private data: { contractId: string; tab?: ContractDrawerTabs }
  ) {
    super()
    this.currentTab = this.data?.tab || ContractDrawerTabs.Details
  }

  ngOnInit(): void {
    this.store.dispatch(GetContract({ id: this.data.contractId, skipError: true }))
    this.store
      .pipe(select(selectContractViewById, { id: this.data.contractId }), filter(isSomething), untilDestroy(this))
      .subscribe((contract) => {
        this.contract = contract
      })

    this.actions.pipe(ofType(MarkContractAsCompletedComplete), debounceTime(1000), untilDestroy(this)).subscribe(() => {
      this.isCompleted = true
    })

    this.actions
      .pipe(
        ofType(ContractNotFound),
        filter(({ id }) => id === this.data?.contractId),
        take(1)
      )
      .subscribe(() => {
        this.toaster.error(
          'Document Not Found',
          'Unable to open this document. This can happen if the document has been deleted from the inbox.'
        )
        this.close()
      })
  }

  close() {
    this.dialog.close()
  }
}
