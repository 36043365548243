<div class="modal-header">
  <h5 class="modal-title">Add Discount Code</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body bg-none p-0 m-0">
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="p-3">
      <div class="row">
        <div class="col-md-6">
          <label class="form-field-label">Type <span class="text-danger">*</span></label>
          <div class="d-flex gap-3">
            <button
              class="btn flex-1"
              (click)="form.get('type').setValue('flat')"
              [ngClass]="form.get('type').value == 'flat' ? 'btn-success' : 'btn-outline-success'"
              matTooltip="Exact value set in Amount field will be discounted from booking total"
            >
              <i class="fas fa-dollar-sign"></i> Flat
            </button>
            <button
              class="btn flex-1"
              (click)="form.get('type').setValue('pct')"
              [ngClass]="form.get('type').value == 'pct' ? 'btn-info' : 'btn-outline-info'"
              matTooltip="Percentage of booking total will be discounted"
            >
              <i class="fas fa-percent"></i> Percentage
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="d-block" matTooltip="Enter the name for this discount" matTooltipPosition="above">
            <input matInput placeholder="Name" formControlName="name" type="text" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('maxlength')"> Name must be at most 50 characters </mat-error>
            <mat-error *ngIf="form.get('name').hasError('allWhitespace')"> Name is invalid </mat-error>
            <mat-hint align="end"> {{ form.get('name').value.length || 0 }}/50</mat-hint>
          </mat-form-field2>
        </div>
        <div class="col-sm-12">
          <mat-form-field2
            class="d-block"
            matTooltip="Enter the description for this discount"
            matTooltipPosition="above"
          >
            <textarea
              matInput
              placeholder="Description"
              formControlName="description"
              type="text"
              rows="2"
              required
              cdkTextareaAutosize
              [cdkAutosizeMaxRows]="4"
            ></textarea>
            <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('required')">
              Description is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('description').hasError('maxlength')">
              Description must be at most 150 characters
            </mat-error>
            <mat-hint align="end"> {{ form.get('description').value.length || 0 }}/150</mat-hint>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="p-3 border-top">
      <div class="row">
        <div class="col-md-3">
          <mat-form-field2 class="d-block">
            <input
              matInput
              matTooltip="Enter the discount amount"
              matTooltipPosition="above"
              type="number"
              [placeholder]="'Amount' + (form.get('type').value === 'pct' ? '(%)' : '')"
              formControlName="amount"
              required
            />
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('percent')">
              Amount should be greater than <b>0</b> and less than <b>100</b>
            </mat-error>
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('positive')">
              Amount should be greater than <b>0</b>
            </mat-error>
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
              Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2 class="d-block">
            <input
              matInput
              matTooltip="Enter the minimum booking total required for the discount"
              matTooltipPosition="above"
              type="number"
              placeholder="Booking Total"
              formControlName="booking_total_min"
            />
            <mat-error
              *ngIf="form.get('booking_total_min').touched && form.get('booking_total_min').hasError('positive')"
            >
              Amount should be greater than <b>0</b>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2 class="d-block">
            <input
              matInput
              matTooltip="Enter value for number of uses allowed for this discount"
              matTooltipPosition="above"
              type="number"
              placeholder="Max Uses"
              formControlName="max_uses"
              required
            />
            <mat-error *ngIf="form.get('max_uses').touched && form.get('max_uses').hasError('min')">
              Max Uses should not be less than <strong>{{ item?.bookings?.length || 1 }}</strong>
            </mat-error>
            <mat-error *ngIf="form.get('max_uses').touched && form.get('max_uses').hasError('required')">
              Max Uses is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2 class="d-block">
            <mat-label>Min Stay <span class="text-light">(nights)</span></mat-label>
            <input
              matInput
              matTooltip="Enter the minimum length of stay required for the discount"
              matTooltipPosition="above"
              type="number"
              placeholder="Min Stay"
              formControlName="los"
            />
            <mat-error *ngIf="form.get('los').touched && form.get('los').hasError('min')">
              Minimum Stay should not be less than <strong>1</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Select the start date for this discount"
            matTooltipPosition="above"
          >
            <input
              matInput
              [matDatepicker]="startPicker"
              [max]="maxStartDate.value"
              [min]="now"
              placeholder="Start"
              formControlName="start"
              (focus)="startPicker.open()"
              tabindex="4"
              readonly
            />
            <i matSuffix (click)="startPicker.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>

        <div class="col-sm-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Select the end date for this discount"
            matTooltipPosition="above"
          >
            <input
              matInput
              [matDatepicker]="endPicker"
              [min]="minEndDate.value"
              placeholder="Expires"
              formControlName="expires"
              (focus)="endPicker.open()"
              tabindex="4"
              readonly
            />
            <i matSuffix (click)="endPicker.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="form.get('expires').touched && form.get('expires').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('expires').hasError('matDatepickerMin')">
              Min value is
              <strong>{{ form.get('expires').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="d-block">
            <input
              matInput
              matTooltip="Enter email address of the guest"
              matTooltipPosition="above"
              type="email"
              placeholder="Email"
              formControlName="email"
            />
            <mat-error *ngIf="form.get('email').hasError('email')"> Email is <strong>invalid</strong></mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-rental-select
            [ctrl]="form.get('rental_id')"
            [multiple]="false"
            matTooltip="Click here to select the rental for which this discount will be applied. If no Rental is selected, the discount will apply on all Rental(s)"
            placeholder="Rental"
          ></app-rental-select>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer border-top">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
