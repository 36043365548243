import { GetCurrentUserComplete, UpdateCurrentSignatureUserComplete } from './user-signature.actions'
import { initialState, State } from './user-signature.state'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(GetCurrentUserComplete, (state, { user }) => ({ ...state, currentUser: user, isLoaded: true })),
  on(UpdateCurrentSignatureUserComplete, (state, { user }) => ({ ...state, currentUser: user }))
)

export function userReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}
