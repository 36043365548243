<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/listing-icon1.webp" alt="" height="40" />
    <div>
      <h6 class="mb-0">Connecting</h6>
      <div class="text-sm">
        This section will prepare you for connecting Expedia with AdvanceCM. <br />
        Please read through these instructions carefully before proceeding.
      </div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #linkProperty>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Link your property
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>The next step in the process will be to link an existing AdvanceCM rental to your Expedia room ID.</p>

            <p class="text-semi-bold">Please take note of the following:</p>

            <p>
              A Expedia property ID may include more than one room ID. These rooms should have their own rates and
              availability calendar and not be listed as inventory available on the same property ID. You can confirm
              this by checking your rates and availability calendar on Expedia and ensuring that the Rooms to Sell
              section shows either 1 or 0 but never more than 1.
            </p>
            <p>
              If your property shows more than 1 room available within a property ID on the same calendar you will need
              to contact your Expedia account manager to have them split these rentals into their own rates calendars.
            </p>
            <div class="d-flex align-items-center gap-3">
              <mat-form-field2 class="w-50" [showLabel]="false">
                <app-select
                  [formControl]="rentalIdCtrl"
                  [items]="rentals$ | async"
                  bindLabel="name"
                  bindValue="id"
                  [multiple]="false"
                  [required]="true"
                  [searchable]="true"
                  [disabled]="!!connection.rentalId"
                  placeholder="Select Rental to Link"
                >
                </app-select>
              </mat-form-field2>
              <div class="flex-fill"></div>
              <button
                class="btn btn-light-info"
                [disabled]="rentalIdCtrl.invalid || !!connection.rentalId"
                (click)="onLink()"
              >
                <i class="cs-icon cs-icon-link"></i> Link
              </button>
              <button class="btn btn-light-info" (click)="importBookings.open()" [disabled]="!isLinked">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #importBookings [disabled]="!isLinked">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Import Bookings
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Now that AdvanceCM is connected to Expedia you will need to import your bookings. Please note that
              AdvanceCM can only import current and future bookings. Only limited historical information is provided
              when importing from Expedia.
            </p>

            <p>
              Future bookings which were created in the past will be imported but bookings in which the departure date
              has already passed will not be.
            </p>

            <p>Hit the Import button below and continue to the next step in the process.</p>
            <div class="d-flex ali-items-center gap-3">
              <button class="btn btn-light-info" (click)="onImport()">
                <i class="cs-icon cs-icon-download"></i> Import
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="pushAvailability.open()" [disabled]="!isBookingImported">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #pushAvailability [disabled]="!isLinked || !isBookingImported">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Push Availability
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              In this step you will be pushing the availability that shows on your AdvanceCM calendar to Expedia. Please
              hit the Push Availability button.
            </p>

            <p>
              If you receive an error stating “Unable to publish to channel” this is most likely due to attempting to
              push an empty calendar.
            </p>

            <p>
              Please navigate to your AdvanceCM calendar and create a hold event or allow the system some time to import
              bookings from Expedia before trying again.
            </p>
            <div class="d-flex ali-items-center gap-3">
              <button class="btn btn-light-info" (click)="onPushAvailability()">
                <i class="cs-icon cs-icon-upload"></i> Push Availability
              </button>
              <div class="flex-fill"></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end">
  <button
    class="btn btn-secondary-info"
    [disabled]="isProcessing || !isLinked || !isBookingImported || !isAvailabilityPushed"
    (click)="next.emit()"
  >
    Next
    <i class="far fa-spin fa-spinner" *ngIf="isProcessing"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isProcessing"></i>
  </button>
</div>
