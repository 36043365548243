import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PdfViewerModule } from 'ng2-pdf-viewer'

import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component'
import { SignatureEditorDialogService } from './signature-editor/signature-editor-dialog.service'
import { SignatureEditorDialogComponent } from './signature-editor/signature-editor-dialog.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { SignatureLibraryModule } from '@tokeet-frontend/signature'
import { AngularDraggableModule } from 'angular2-draggable'
import { DragulaModule } from 'ng2-dragula'
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap'
import { SignatureDrawerComponent } from './signature-editor/components/signature-drawer/signature-drawer.component'
import { SignatureTexterComponent } from './signature-editor/components/signature-texter/signature-texter.component'
import { SignatureUploaderComponent } from './signature-editor/components/signature-uploader/signature-uploader.component'

const components = [
  //
  PdfPreviewComponent,
  SignatureDrawerComponent,
  SignatureTexterComponent,
  SignatureUploaderComponent,
  SignatureEditorDialogComponent,
]

const services = [
  //
  SignatureEditorDialogService,
]

const modules = [
  //
  PdfViewerModule,
  SharedModule,
  SignatureLibraryModule,
  AngularDraggableModule,
  DragulaModule,
  NgbAccordionModule,
]

@NgModule({
  declarations: [...components],
  imports: [CommonModule, ...modules],
  providers: [...services],
  exports: [...components, ...modules],
})
export class DocumentSharedModule {}
