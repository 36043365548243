import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { CustomCode } from '@tv3/store/custom-codes/custom-code.model'
import { tap } from 'rxjs/operators'
import { AddCustomCodeRequest, UpdateCustomCodeRequest } from '@tv3/interfaces/requests/custom-code.request'

@Injectable({
  providedIn: 'root',
})
export class CustomCodeService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  getAll(): Observable<CustomCode[]> {
    const url = `@api/customcode/all/`

    return this.http.get<CustomCode[]>(url)
  }

  delete(id: string, noMessage = false, message = 'Custom Code deleted successfully!'): Observable<CustomCode> {
    const url = `@api/customcode/${id}`

    return this.http.delete<CustomCode>(url).pipe(tap(() => !noMessage && this.toast.success(message)))
  }

  create(request: AddCustomCodeRequest): Observable<CustomCode> {
    const url = `@api/customcode/`

    return this.http
      .post<CustomCode>(url, request)
      .pipe(tap(() => this.toast.success('Custom Code created successfully!')))
  }

  edit(id: string, request: UpdateCustomCodeRequest): Observable<CustomCode> {
    const url = `@api/customcode/${id}`

    return this.http
      .put<CustomCode>(url, request)
      .pipe(tap(() => this.toast.success('Custom Code edited successfully!')))
  }
}
