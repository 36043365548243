<mat-form-field2 class="mat-block" [showLabel]="showLabel" [hidePlaceholder]="showPlaceholder === false">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <app-select
    [items]="users$ | async"
    [formControl]="ctrl"
    bindLabel="name"
    bindValue="id"
    [multiple]="multiple"
    [searchable]="true"
    [required]="ctrl | required"
    [placeholder]="placeholder"
    (selectedChange)="onUsersSelected($event)"
    [disabled]="disabled"
  >
    <ng-template selectItem let-item="item">
      {{ item.name }} <span class="text-muted">({{ item.primaryEmail }})</span>
    </ng-template>
  </app-select>
  <mat-error *ngIf="ctrl.touched && ctrl.hasError('required')">
    {{ placeholder }} is <strong>required</strong>
  </mat-error>
</mat-form-field2>
