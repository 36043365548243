import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { TextboxFieldComponent } from './fields/textbox/textbox.component'
import { SignatureFieldComponent } from './fields/signature/signature.component'
import { InitialsFieldComponent } from './fields/initials/initials.component'
import { CheckboxFieldComponent } from './fields/checkbox/checkbox.component'
import { SigndateFieldComponent } from './fields/signdate/signdate.component'
import { DatadicFieldComponent } from './fields/datadic/datadic.component'

import * as lodash from 'lodash'

import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import { BaseFieldComponent } from './fields/base-field.component'
import { Contract, ContractSignField, Resource } from '@tokeet-frontend/signature'
import { SignPrepareService } from '@tv3/services/sign-prepare.service'
import { GetUserSignatureData } from '../../shared/signature-editor/signature-editor-dialog.component'

@Component({
  selector: 'app-sign-editor',
  templateUrl: './sign-editor.component.html',
  styleUrls: ['./sign-editor.component.scss'],
})
export class SignEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('fieldContainer', {
    read: ViewContainerRef,
    static: true,
  })
  fieldContainerRef: ViewContainerRef
  @ViewChild('scrollableElement', {
    read: ElementRef,
    static: true,
  })
  scrollableElement: ElementRef
  @ViewChild('zoomContent', {
    read: ElementRef,
    static: true,
  })
  zoomContentRef: ElementRef

  get zoomContainerRef() {
    return this.scrollableElement
  }

  @Input() contract: Contract

  @Output() sign: EventEmitter<ContractSignField[]> = new EventEmitter()
  @Output() decline: EventEmitter<void> = new EventEmitter()

  @Input() isProcessing: false
  isLoading = true

  zoomController: PanzoomObject

  private fieldTypes = {
    signature: SignatureFieldComponent,
    initials: InitialsFieldComponent,
    checkbox: CheckboxFieldComponent,
    textbox: TextboxFieldComponent,
    signdate: SigndateFieldComponent,
    datadic: DatadicFieldComponent,
  }

  private fieldComponents: ComponentRef<any>[] = []
  private currentHighlightedComponentIndex = -1

  constructor(private prepareSign: SignPrepareService, private render: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.zoomController = Panzoom(this.zoomContentRef.nativeElement, {
      increment: 0.1,
      minScale: 0.5,
      maxScale: 2,
      origin: '0 0',
      // contain: 'inside',
      cursor: 'default',
      overflow: 'scroll',
      disablePan: true,
      setTransform: (elem, { scale, x, y }) => {
        this.zoomController.setStyle('transform', `scale(${scale}) translate3d(0px, 0px, 0px)`)
      },
    })
  }

  isFieldFilled(field: ContractSignField) {
    if (field.type === 'checkbox') {
      return field.value !== Resource.CHECKBOX_UNCHECKED_DATA_URI && !!field.value
    }

    return !!field.value
  }

  getUnfilledRequiredFieldsCount() {
    const { fields = [] } = this.contract || {}
    return lodash.filter(fields, (field) => field.required && !this.isFieldFilled(field)).length
  }

  getInvalidFields() {
    return lodash.filter(this.fieldComponents, (fieldComponent) => {
      const field = fieldComponent.instance?.field
      if (field?.required && !this.isFieldFilled(field)) {
        return true
      }

      return fieldComponent.instance?.isFieldValueInvalid || false
    })
  }

  isFullFilled() {
    return !this.getUnfilledRequiredFieldsCount() && !this.getInvalidFields()?.length
  }

  nextField() {
    const currentHighlightedCom = this.fieldComponents[this.currentHighlightedComponentIndex]
    if (currentHighlightedCom) {
      currentHighlightedCom.instance.isHighlighted = false
    }

    const nextIndex = (this.currentHighlightedComponentIndex =
      ++this.currentHighlightedComponentIndex % this.fieldComponents.length)
    const fieldComponent = this.fieldComponents[nextIndex]

    // scroll to required field
    if (fieldComponent) {
      fieldComponent.instance.isHighlighted = true
      this.scrollIntoView(fieldComponent.location.nativeElement)
    }
  }

  renderContractFields() {
    const { fields = [] } = this.contract
    lodash
      .sortBy(fields, (t) => t.y)
      .forEach((field) => {
        this.addField(field.type, { ...lodash.pick(field, ['x', 'y', 'width', 'height']) }, field)
      })
  }

  autoSign(data: GetUserSignatureData) {
    const coms = this.fieldComponents
    lodash.forEach(coms, (com: ComponentRef<any>) => {
      const field = com.instance
      if (field instanceof SignatureFieldComponent || field instanceof InitialsFieldComponent) {
        field.setValue(data)
      }
    })
  }

  addField(type, rect, field) {
    const componentRef = this.prepareSign.appendTo<BaseFieldComponent>(this.fieldTypes[type], this.fieldContainerRef)
    const { location: elementRef, instance } = componentRef
    if (instance instanceof SignatureFieldComponent || instance instanceof InitialsFieldComponent) {
      instance.valueChange.subscribe((data: GetUserSignatureData) => {
        this.autoSign(data)
      })
    }

    // set bounds element
    instance.field = field
    instance.fieldStyle = {
      width: `${rect.width}px`,
      height: `${rect.height}px`,
    }

    this.fieldComponents.push(componentRef)

    // zet z axis to show data token on top
    this.render.setStyle(elementRef.nativeElement, 'transform', `translate3d(${rect.x}px, ${rect.y}px, 1000px)`)
  }

  signContract() {
    this.sign.emit(this.contract.fields)
  }

  declineContract() {
    this.decline.emit()
  }

  onContractPDFLoaded() {
    this.isLoading = false
    this.renderContractFields()
  }

  scrollIntoView(element: HTMLElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  isMinWindow() {
    return window.innerWidth < 816
  }

  getPageWidthRatio() {
    const containerRect = this.zoomContainerRef.nativeElement.getBoundingClientRect()
    const contentRect = this.zoomContentRef.nativeElement.getBoundingClientRect()
    const widthScale = containerRect.width / contentRect.width
    return widthScale
  }

  onZoomToFitScreen() {
    const widthScale = this.getPageWidthRatio()
    this.zoomController.zoom(widthScale)
    // setTimeout(() => this.scrollIntoView(), 300);
  }

  onResetZoom() {
    this.zoomController.reset()
  }

  public modifyAnchorElementAttributes(event: CustomEvent): void {
    // https://github.com/VadimDez/ng2-pdf-viewer/issues/417
    // @ts-ignore
    const textLayer = <HTMLElement>(event.source && event.source.textLayerDiv)
    if (!textLayer) {
      return
    }
    const annotationLayer = textLayer.nextElementSibling
    if (!annotationLayer) {
      return
    }
    const anchorElements = annotationLayer.getElementsByTagName('a')

    for (let i = 0; i < anchorElements.length; i++) {
      anchorElements[i].setAttribute('target', '_blank')
    }
  }
}
