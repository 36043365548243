<div class="filters-box" [formGroup]="filters">
  <app-rental-select
    matTooltip="Only show discounts that specify the selected rentals."
    [ctrl]="filters.get('rentals')"
    placeholder="Rentals"
    [inline]="true"
    *ngIf="!rental?.id"
  ></app-rental-select>
  <mat-form-field2 class="field-categories" [showLabel]="false">
    <mat-select
      formControlName="discountType"
      placeholder="Discount Type"
      matTooltip="Only show discounts that specify the selected discount type."
      matTooltipPosition="above"
    >
      <mat-option [value]="">All</mat-option>
      <mat-option value="los">LOS Discount</mat-option>
      <mat-option value="code">Discount Code</mat-option>
    </mat-select>
  </mat-form-field2>
  <mat-form-field2 class="field-categories" [showLabel]="false">
    <mat-select
      formControlName="type"
      placeholder="Type"
      matTooltip="Only show discounts that specify the selected type."
      matTooltipPosition="above"
    >
      <mat-option [value]="">All</mat-option>
      <mat-option value="flat">Flat</mat-option>
      <mat-option value="pct">Percent</mat-option>
    </mat-select>
  </mat-form-field2>

  <app-cancel-filters
    [filterControl]="filters"
    [isFiltering]="filters | isFiltering : (rental ? ['rentals'] : [])"
    [ignoreFields]="rental ? ['rentals'] : []"
    [searchCom]="search"
  ></app-cancel-filters>
  <button class="btn btn-secondary-primary" (click)="onRefresh()" matTooltip="Refresh List" matTooltipPosition="above">
    <i class="far fa-sync"></i>
  </button>

  <div class="flex-fill"></div>
  <app-table-search
    (search)="onSearch($event)"
    #search
    matTooltip="Search by name, type or amount."
    matTooltipPosition="above"
  ></app-table-search>
  <div class="btn-group" *ngIf="showAddBtn" dropdown [isDisabled]="1 | isReadonlyRole">
    <button type="button" class="btn btn-secondary-info dropdown-toggle" dropdownToggle>
      <span class="far fa-plus"></span> Add Discount
    </button>
    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <li>
        <button type="button" (click)="onAddLosDiscount()">
          <i class="cs-icon cs-icon-los-discount fa-fw"></i>
          LOS Discount
        </button>
      </li>
      <li>
        <button type="button" (click)="onAddDiscountCode()">
          <i class="cs-icon cs-icon-discount-code fa-fw text-xs"></i>
          Discount Code
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="channelName" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="text-truncate">{{ element.name }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="discountTypeView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Discount Type</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.discountTypeView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type === 'pct' ? 'Percent' : 'Flat' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type === 'pct' ? element.amount + '%' : (element.amount | number : '.2-2') }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.startView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="end">End</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.endView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips" matTooltip="You can perform multiple actions using this button">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li *ngIf="element.discountType === 'los'">
                <button (click)="onEdit(element)" type="button"><i class="fal fa-file-alt"></i> View</button>
              </li>
              <li *ngIf="element.discountType === 'code'">
                <button
                  (click)="onEdit(element)"
                  type="button"
                  matTooltip="Edit this Discount"
                  matTooltipPosition="left"
                >
                  <i class="fal fa-edit"></i> Edit
                </button>
              </li>
              <li *ngIf="element.discountType === 'los'">
                <button
                  (click)="onDelete(element)"
                  type="button"
                  matTooltip="Delete this Discount"
                  matTooltipPosition="left"
                >
                  <i class="cs-icon cs-icon-trash"></i>Delete
                </button>
              </li>
              <li
                *ngIf="element.discountType === 'code'"
                [matTooltip]="
                  element.isUsed
                    ? 'Used Discount Code can not be deleted. You can deactivate this code if you do not want to use it anymore'
                    : ''
                "
                matTooltipPosition="left"
              >
                <button
                  (click)="onDelete(element)"
                  type="button"
                  [disabled]="element.isUsed"
                  matTooltip="Delete this Discount"
                  matTooltipPosition="left"
                >
                  <i class="cs-icon cs-icon-trash"></i>Delete
                </button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEdit(row)" class="clickable"></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white"></app-empty-table>
  </table>
</div>
