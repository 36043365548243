import { Injectable } from '@angular/core'
import { TaskView } from '@tv3/store/task/task.model'
import { AlertDialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class TaskViewDialogService {
  constructor(private alertDialog: AlertDialogService) {}

  open(task: TaskView) {
    this.alertDialog.open({
      title: 'Task Details',
      body: `
        <table class="table table-striped">
          <tr><td width="110"><strong>Name</strong></td><td> ${task.name}</td></tr>
          <tr><td><strong>User</strong></td><td> ${task.usersView}</td></tr>
          <tr><td><strong>Created</strong></td><td> ${task.createdView}</td></tr>
          <tr><td><strong>Start Date</strong></td><td> ${task.startView}</td></tr>
          <tr><td><strong>Due Date</strong></td><td> ${task.dueView}</td></tr>
          <tr><td><strong>Status</strong></td><td> ${task.statusView}</td></tr>
          <tr><td><strong>Inquiry</strong></td><td> ${task.inquiryView}</td></tr>
          <tr><td><strong>Comments</strong></td><td> ${task.notes}</td></tr>
        </table>
      `,
    })
  }
}
