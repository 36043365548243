import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BaseFieldComponent } from '../base-field.component'
import {
  GetUserSignatureData,
  SignatureEditorType,
} from '@tv3/containers/documents/shared/signature-editor/signature-editor-dialog.component'
import { SignatureEditorDialogService } from '@tv3/containers/documents/shared/signature-editor/signature-editor-dialog.service'

@Component({
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss', '../global.scss'],
})
export class SignatureFieldComponent extends BaseFieldComponent implements OnInit {
  @Output() valueChange = new EventEmitter<GetUserSignatureData>()

  signatureStyle: any = {}

  constructor(private signatureEditorDialog: SignatureEditorDialogService) {
    super()
  }

  ngOnInit() {
    if (this.field.value) {
      const img = new Image()
      img.onload = () => {
        this.setValue({ signature: this.field.value, size: { width: img.width, height: img.height } })
      }
      img.src = this.field.value
    }
  }

  setValue({ signature, size }: GetUserSignatureData) {
    this.field.value = signature
    if (size.width / this.field.width > size.height / this.field.height) {
      this.signatureStyle = { width: '100%' }
    } else {
      this.signatureStyle = { height: '100%' }
    }
  }

  showAddSignature() {
    this.signatureEditorDialog
      .open(SignatureEditorType.GetUserSignature)
      .afterClosed()
      .subscribe((data: GetUserSignatureData) => {
        this.setValue(data)
        this.valueChange.emit(data)
      })
  }
}
