import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { ContractTemplate } from './template.model'

export interface State extends EntityState<ContractTemplate> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<ContractTemplate> = createEntityAdapter<ContractTemplate>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
