import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { Contract } from './contract.model'
import { SignaturePagination } from '../pagination'

export interface State extends EntityState<Contract> {
  isLoading: boolean
  statusStats: { [key: string]: number }
  tagStats: { [key: string]: number }
  pagination: SignaturePagination
}

export const adapter: EntityAdapter<Contract> = createEntityAdapter<Contract>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoading: true,
  statusStats: {},
  tagStats: {},
  pagination: {
    total: 0,
    offset: 0,
    limit: 10,
  },
})
