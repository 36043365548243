import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { AddRentalDialogComponent } from '@tv3/containers/rentals/add/add-rental-dialog.component'
import { DeleteRentalDialogComponent } from '@tv3/containers/rentals/delete/delete-rental-dialog.component'
import { DeleteRentalOverlayComponent } from './delete-rental-overlay/delete-rental-overlay.component'
import { CopyRentalInfoDialogComponent } from './copy-rental-info-dialog/copy-rental-info-dialog.component'
import { ParentChildRentalDialogComponent } from '@tv3/containers/rentals/parent-child-rental-dialog/parent-child-rental-dialog.component'

const components = []

const dialogs = [
  AddRentalDialogComponent,
  DeleteRentalDialogComponent,
  DeleteRentalOverlayComponent,
  CopyRentalInfoDialogComponent,
  ParentChildRentalDialogComponent,
]

const modules = [SharedModule, CommonModule]

const services = []

@NgModule({
  imports: [...modules],
  declarations: [...components, ...dialogs],
  exports: [...components, ...dialogs, ...modules],
  providers: [...services],
})
export class RentalsSharedModule {}
