import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, Observer } from 'rxjs'
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators'
import SignaturePad from 'signature_pad'
import { SignatureEditorSize, SignatureHelperService } from '../signature-helper.service'
import { selectCurrentSignatureUser, UserSignatureType } from '@tokeet-frontend/signature'
import { Destroyable, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-signature-uploader',
  templateUrl: './signature-uploader.component.html',
  styleUrls: ['./signature-uploader.component.scss'],
})
export class SignatureUploaderComponent extends Destroyable implements OnInit, AfterViewInit {
  @Input() editorSize: SignatureEditorSize

  @ViewChild('signatureCanvas', { static: true }) signatureCanvas: ElementRef
  @ViewChild('uploadForm', { static: true }) uploadForm: ElementRef
  signaturePad: SignaturePad

  isFileUploaded = false

  constructor(private store: Store<any>, private toaster: Toaster, private signatureHelper: SignatureHelperService) {
    super()
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectCurrentSignatureUser),
        debounceTime(300),
        tap((user) => {
          if (!user.attributes?.signature_type) {
            this.reset()
          }
        }),
        filter((user) => user.attributes?.signature_type === UserSignatureType.Image),
        switchMap((user) => {
          this.isFileUploaded = true
          return this.drawSignatureOnPad(user.signature)
        }),
        untilDestroy(this)
      )
      .subscribe((user) => {})
  }

  ngAfterViewInit() {
    const canvas = this.signatureCanvas.nativeElement
    this.signaturePad = new SignaturePad(canvas)
    this.signaturePad.off()
  }

  readSignatureImage(file: File) {
    return new Observable((observer: Observer<string>) => {
      const fileReader = new FileReader()
      fileReader.onload = (event: any) => {
        let d = event.target.result
        d = d.replace('data:;', 'data:' + file.type + ';')
        observer.next(d)
        observer.complete()
      }
      fileReader.readAsDataURL(file)
    })
  }

  drawSignatureOnPad(signature: string) {
    const canvas = this.signatureCanvas.nativeElement
    const tmpCanvas: HTMLCanvasElement = document.createElement('canvas')
    tmpCanvas.width = canvas.width
    tmpCanvas.height = canvas.height

    return this.signatureHelper.drawImage(tmpCanvas, signature).pipe(
      tap(() => {
        this.signaturePad.fromDataURL(tmpCanvas.toDataURL(), { ratio: 1 })
      })
    )
  }

  onSignatureFileSelected(fileList: FileList) {
    const signature: File = fileList.item(0)
    const type = signature.type
    if (type.indexOf('image') < 0) {
      this.toaster.warning('Please upload signature image.')
      this.uploadForm.nativeElement.reset()
      return
    }

    this.readSignatureImage(signature)
      .pipe(
        tap(() => this.signaturePad.clear()),
        switchMap((data) => this.drawSignatureOnPad(data)),
        tap(() => (this.isFileUploaded = true))
      )
      .subscribe()
  }

  isEmpty() {
    return this.signaturePad.isEmpty()
  }

  reset() {
    this.signaturePad.clear()
    this.uploadForm.nativeElement.reset()
    this.isFileUploaded = false
  }

  getSignature() {
    return this.signaturePad.toDataURL()
  }
}
