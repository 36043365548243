<div class="modal-header">
  <h5 class="modal-title">{{ data.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <app-loading-section *ngIf="!loadedWrap.isImageLoaded"></app-loading-section>
  <ngb-carousel [interval]="-1" (slid)="activeImageIndex = $event.current" [class.d-none]="!loadedWrap.isImageLoaded">
    <ng-template ngbSlide *ngFor="let item of data.images; let index = index" id="{{ index }}">
      <div class="picsum-img-wrapper">
        <img
          *ngIf="watchLoading | call : (item.url | signatureSecureImage : true) : loadedWrap | async as data"
          [src]="data.image"
          class="image"
          [class.d-none]="isImageInvalid"
          (error)="onImageLoadError()"
        />
      </div>
      <div class="carousel-caption d-none d-md-block">
        <h3 *ngIf="item.title">{{ item.title }}</h3>
        <p *ngIf="item.description">{{ item.description }}</p>
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="alert alert-danger mt-3" *ngIf="isImageInvalid">
    Unable to load the image, because it is an invalid file type or it dose not exist.
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="close()">Cancel</button>

  <button type="button" class="btn btn-secondary-info" (click)="onDownload()" [disabled]="isDownloading">
    <i class="far fa-cloud-download" *ngIf="!isDownloading"></i>
    <i class="fal fa-spin fa-spinner" *ngIf="isDownloading"></i>
    Download
  </button>
</div>
