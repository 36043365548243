import { Component, Input } from '@angular/core'

declare const window: any

@Component({
  selector: 'app-page-help',
  templateUrl: './page-help.component.html',
  styleUrls: ['./page-help.component.scss'],
})
export class PageHelpComponent {
  @Input() articleId: string
  @Input() categoryId: string
  @Input() tooltipText = 'Click here to see the associated help article.'
  @Input() infoTooltip: string
  @Input() docId: string

  @Input() videoId: string

  onHelpDocs() {
    if (!this.articleId) return

    const url = 'https://advance.cm/help/article/' + this.articleId

    window.open(url, '_blank')
  }
}
