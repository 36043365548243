<div class="modal-header">
  <h5 class="modal-title"><i class="far fa-box-up"></i> {{ isEdit ? 'Edit' : 'Add' }} Gap Night Automation</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body bg-none p-0">
  <form [formGroup]="form" (submit)="onSubmit(form)" [formEditablePermission]="form">
    <div class="container-box p-3">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field2 class="mat-block">
            <input type="text" placeholder="Name" matInput formControlName="name" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('allNumbers')"> Name cannot be numbers alone. </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6">
          <mat-form-field2 matTooltip="This automation will apply to only selected dates">
            <app-mat-daterange-input
              placeholder="Date Range"
              formControlName="range"
              [minDate]="minDate"
              [required]="true"
              [showQuickActions]="false"
              [ranges]="usedRange$ | async"
            ></app-mat-daterange-input>
            <mat-error *ngIf="form.get('range').touched && form.get('range').hasError('required')">
              Date Range is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('range').hasError('overlap')">
              Date Range is <strong>overlapping</strong> with other automation
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6">
          <app-rental-select
            [ctrl]="form.get('rentals')"
            placeholder="Rentals"
            class="mat-block"
            matTooltip="Select Rental you want the gap night automation to apply to"
          >
          </app-rental-select>
        </div>
      </div>
    </div>

    <div class="container-box p-3 mt-3" formArrayName="gaps">
      <div class="row mb-3 border-bottom" *ngFor="let item of itemsForm.controls; let i = index" [formGroupName]="i">
        <div class="col-lg-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="The maximum number of nights between bookings that will be adjusted. Example, if you set the value to 5, the rule will apply to gaps of 1,2,3,4 & 5 nights"
          >
            <input matInput type="number" formControlName="gaps_required" placeholder="Gap Nights" required />
            <mat-error *ngIf="item.get('gaps_required').touched && item.get('gaps_required').hasError('required')">
              Gap nights is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="item.get('gaps_required').hasError('positive') || item.get('gaps_required').hasError('integer')"
            >
              Gap nights should be positive integer
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6 d-flex">
          <mat-form-field2
            class="mat-block"
            matTooltip="The minimum nights requirement for the affected dates, This can be set as a flat number or the number based on how many nights are in the gap"
          >
            <mat-select formControlName="min_nights" placeholder="Min Nights">
              <mat-option *ngFor="let t of minNightsOptions" [value]="t.value">{{ t.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="item.get('min_nights').touched && item.get('min_nights').hasError('required')">
              Min nights is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="item.get('min_nights').hasError('positive') || item.get('min_nights').hasError('integer')"
            >
              Min nights should be positive integer
            </mat-error>
          </mat-form-field2>
          <button class="btn btn-secondary-danger align-input-row-end ml-3" (click)="onRemoveItem(i)" *ngIf="i">
            <i class="cs-icon cs-icon-trash"></i>
          </button>
        </div>
        <div class="col-lg-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Click here to set a positive or negative flat fee adjustment that will be made to rates of gap nights"
          >
            <input matInput type="number" formControlName="adjust_abs" placeholder="Adjust Fee" required />
            <mat-error *ngIf="item.get('adjust_abs').touched && item.get('adjust_abs').hasError('required')">
              Adjust Fee is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Click here to set a positive or negative percentage adjustment that will be made to rates of gap nights"
          >
            <input matInput type="number" formControlName="adjust_pct" placeholder="Adjust Percent" required />
            <mat-error *ngIf="item.get('adjust_pct').touched && item.get('adjust_pct').hasError('required')">
              Adjust Percent is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
      <div>
        <button class="btn btn-secondary-info" (click)="onAddItem()"><i class="far fa-plus"></i> Add New Item</button>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="onSubmit(form)"
    [disabled]="form.disabled || (1 | isReadonlyRole)"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i *ngIf="!form.disabled" class="cs-icon cs-icon-save"></i>
    <i *ngIf="form.disabled" class="far fa-spinner fa-spin"></i>
    Save
  </button>
</div>
