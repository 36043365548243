import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectUsers, User } from '@tokeet-frontend/tv3-platform'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
  @Input() ctrl: FormControl
  @Input() placeholder = 'Select Users'
  @Input() label = 'Select Users'
  @Input() multiple = true
  @Input() showLabel = true
  @Input() showPlaceholder = true
  @Input() disabled = false

  users$ = this.store.pipe(select(selectUsers))

  @Output() selected = new EventEmitter<User[]>() // Event emitter for users selected

  constructor(private store: Store<any>) {}

  ngOnInit(): void {}

  onUsersSelected(users: User[]) {
    this.selected.emit(users)
  }
}
