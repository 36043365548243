import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Contract, UpdateContract } from '@tokeet-frontend/signature'
import { SaveForm, selectSome } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as lodash from 'lodash'
import { FetchInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { selectInquiriesByIds } from '@tv3/store/inquiry/inquiry.selectors'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-assign-inquiry',
  templateUrl: './assign-inquiry-dialog.component.html',
  styleUrls: ['./assign-inquiry-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AssignInquiryDialogComponent implements OnInit {
  inquiryIdCtrl = new FormControl()
  isProcessing = false

  get inquiryId(): string {
    return lodash.get(this.data?.contract, 'attributes.inquiry_id')
  }

  selectedBooking: Inquiry = null

  constructor(
    private dialogRef: MatDialogRef<AssignInquiryDialogComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) private data: { contract: Contract }
  ) {}

  ngOnInit() {
    if (this.inquiryId) {
      this.inquiryIdCtrl.setValue(this.inquiryId)
      this.store.dispatch(FetchInquiries({ ids: [this.inquiryId] }))
      this.store
        .pipe(
          selectSome(selectInquiriesByIds([this.inquiryId])),
          map((items) => lodash.head(items))
        )
        .subscribe((inquiry: Inquiry) => {
          this.selectedBooking = inquiry
        })
    }
  }

  close() {
    this.dialogRef.close()
  }

  onBookingSelected(item: Inquiry) {
    this.selectedBooking = item
  }

  @SaveForm()
  onOkClicked(ctrl: FormControl) {
    this.store.dispatch(
      UpdateContract({
        id: this.data.contract.id,
        payload: {
          attributes: {
            ...this.data.contract.attributes,
            guest_id: this.selectedBooking?.guestId,
            rental_id: this.selectedBooking?.rentalId,
            inquiry_id: this.selectedBooking?.id,
          },
        },
      })
    )
    this.close()
  }
}
