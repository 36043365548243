<div class="timeline-title"><i class="cs-icon cs-icon-time-running"></i> Activity Log</div>
<div class="timeline-content">
  <div class="action-item" *ngFor="let item of timeline">
    <div class="action-icon" [ngClass]="item.action | signStatusBGColor">
      <i [ngClass]="item.action | signStatusIcon : false : 1"></i>
    </div>
    <div class="action-content">
      <div class="action-name text-capitalize">
        <ng-container [ngSwitch]="item.action">
          <ng-container *ngSwitchCase="'sent'"> Received by </ng-container>
          <ng-container *ngSwitchCase="'delivering'"> Delivering </ng-container>
          <ng-container *ngSwitchCase="'failed'"> Failed to deliver </ng-container>
          <ng-container *ngSwitchDefault> {{ item.action }} by </ng-container>
        </ng-container>
      </div>
      <div class="action-user">{{ getSigner(item.user, contract)?.name }}</div>
      <div class="action-date">{{ item.date | epoch : 'DD-MMM-YYYY | h:mm a' }}</div>
    </div>
  </div>
</div>
