<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/holidu/tokeet-holidu.webp" alt="" height="45" />
        <div>
          <h6 class="mb-0">Holidu</h6>
          <div class="text-sm">
            Connect multiple Holidu accounts and sync bookings, prices, guest messages, and more instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3">
        <p>
          Please read this carefully before proceeding. This step-by-step process will guide you through the necessary
          steps to connect AdvanceCM with Holidu. Be sure to read any tooltips for guidance.
        </p>
        <div class="alert alert-secondary-warning2 align-items-start">
          <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
          <div class="alert-content">
            <div class="text-semi-bold">Important Notes</div>
            <ul class="pl-3">
              <li>
                After registering on the Holidu Partner Portal and signing the contract, Holidu will ask you to push
                your properties via AdvanceCM to their platform.
              </li>
              <li>
                Once the properties are pushed, Holidu will review your data, submit a test booking, and if everything
                is correct, they will set your properties live.
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex gap-3" [formGroup]="form">
          <mat-form-field2 class="mat-block">
            <mat-label
              >Provider ID <span *ngIf="form.get('providerId').enabled" class="custom-required-star">*</span>
              <i
                matTooltip="Provider ID will be generated by Holidu Onboarding team and shared with connectivity partner as new partners are onboarded to push the inventory into Holidu platform"
                matTooltipPosition="above"
                class="fal fa-question-circle"
              ></i>
            </mat-label>
            <input type="text" formControlName="providerId" matInput required />
            <mat-error *ngIf="form.get('providerId').touched && form.get('providerId').hasError('required')">
              Provider ID is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('providerId').touched && form.get('providerId').hasError('minlength')">
              Provider ID must be <strong>at least 2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('providerId').touched && form.get('providerId').hasError('maxlength')">
              Provider ID must be <strong>at most 30</strong> characters long
            </mat-error>
          </mat-form-field2>

          <button
            *ngIf="status?.connected"
            (click)="onDisconnect()"
            class="btn btn-secondary-danger align-input-row-end"
            type="button"
          >
            <span class="cs-icon cs-icon-trash"></span>
            Disconnect
          </button>
          <button
            *ngIf="!status?.connected"
            (click)="onSave(form)"
            class="btn btn-secondary-info align-input-row-end"
            type="button"
            [disabled]="isConnecting"
          >
            <i class="cs-icon cs-icon-link" *ngIf="!isConnecting"></i>
            <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>

            Connect
          </button>
        </div>
        <p>
          The Holidu Onboarding team will generate a Provider ID and share it with the connectivity partner. This ID is
          necessary for new partners to push their inventory into the Holidu platform.
        </p>

        <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
          <button class="btn btn-secondary-info" (click)="next.emit()" [disabled]="!status?.connected || isConnecting">
            Continue
            <i class="cs-icon cs-icon-arrow-right-long text-xs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/holidu/step1.webp" alt="" class="w-100" />
  </div>
</div>
