<div class="filters-box">
  <mat-form-field2
    class="d-block"
    [showLabel]="false"
    matTooltip="Filter the below discounts by type."
    matTooltipClass="text-center"
    matTooltipPosition="above"
  >
    <mat-select placeholder="Type" [formControl]="filters.get('type')">
      <mat-option value="all">All</mat-option>
      <mat-option value="pct">Percent</mat-option>
      <mat-option value="flat">Flat</mat-option>
    </mat-select>
  </mat-form-field2>
  <app-rental-select
    [ctrl]="filters.get('rentals')"
    placeholder="Rentals"
    matTooltip="Filter the below discounts by rentals."
    [inline]="true"
  >
  </app-rental-select>
  <app-cancel-filters
    [isFiltering]="filters | isFiltering"
    [filterControl]="filters"
    [searchCom]="search"
  ></app-cancel-filters>

  <app-button
    (click)="onRefresh()"
    icon="far fa-sync"
    class="btn btn-secondary-primary"
    [loading]="isRefreshing$ | async"
    matTooltip="Refresh"
    matTooltipPosition="above"
  >
  </app-button>

  <div class="flex-fill"></div>

  <app-table-search
    #search
    (search)="onSearch($event)"
    [filterGroup]="filterGroup"
    matTooltip="Search by any values visible in the table below"
    matTooltipClass="text-center"
    matTooltipPosition="above"
  ></app-table-search>
  <button type="button" class="btn btn-secondary-info" (click)="onAdd()" [disabled]="1 | isReadonlyRole">
    <i class="far fa-plus"></i> Add Discount Code
  </button>
</div>
<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table
      #table
      matSort
      matSortActive="name"
      [trackBy]="trackById"
      matSortDirection="asc"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="text-truncate" [matTooltip]="element.name" matTooltipPosition="above">{{ element.name }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>type</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type === 'pct' ? 'Percent' : 'Flat' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type === 'pct' ? element.amount + '%' : (element.amount | number : '.2-2') }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.code }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rental</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.rentalView || 'All Rentals' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.startView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expiresView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="expires">Expires</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.expiresView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="usedView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Used</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.usedView }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="leftView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Left</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.leftView }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            #statusToggle
            [disabled]="1 | isReadonlyRole"
            (click)="$event.stopPropagation()"
            [id]="element.id"
            [value]="element.status === 1"
            [inline]="true"
            (valueChange)="onStatusChange(element, $event, statusToggle)"
          ></app-form-switch>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> EDIT </mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom left" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i>Edit</button>
              </li>

              <li
                [matTooltip]="
                  !!element.last_used
                    ? 'Used Discount Code can not be deleted. You can deactivate this code if you do not want to use it anymore'
                    : ''
                "
                matTooltipPosition="left"
              >
                <button (click)="onDelete(element)" type="button" [disabled]="!!element.last_used">
                  <i class="cs-icon cs-icon-trash"></i>Delete
                </button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEdit(row)" class="clickable"></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white"></app-empty-table>
  </table>
</div>
