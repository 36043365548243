import { Component, OnInit } from '@angular/core'

import { BaseFieldComponent } from '../base-field.component'
import { Resource, Settings } from '@tokeet-frontend/signature'

@Component({
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss', '../global.scss'],
})
export class CheckboxFieldComponent extends BaseFieldComponent implements OnInit {
  constructor() {
    super()
  }

  ngOnInit() {
    if (!this.field.value) {
      this.field.value = Resource.CHECKBOX_UNCHECKED_DATA_URI
    }

    if (Settings.CHECKBOX_FIELD_WIDTH / this.field.width > Settings.CHECKBOX_FIELD_WIDTH / this.field.height) {
      this.fieldStyle = { width: '100%' }
    } else {
      this.fieldStyle = { height: '100%' }
    }
  }

  toggleCheckbox() {
    this.field.value =
      this.field.value === Resource.CHECKBOX_UNCHECKED_DATA_URI
        ? Resource.CHECKBOX_CHECKED_DATA_URI
        : Resource.CHECKBOX_UNCHECKED_DATA_URI
  }
}
