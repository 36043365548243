import { Component, Input, OnInit, Inject, Output, EventEmitter, HostBinding } from '@angular/core'
import { FileItem, FileUploader } from 'ng2-file-upload'
import { FileSizePipe } from '../../pipes'
import { FileUploaderFactoryService, Toaster, CustomFileUploaderOptions } from '../../services'
import { ENVIRONMENT } from '../../tokens'

export interface UploadBoxOptions {
  uploadUrl?: string
  maxSize?: number
  maxUploads?: number
  autoUpload?: boolean
  allowedFileExtensions?: string[]
  guide?: string
  cloudinary?: boolean
  token?: string
  image?: string
  resetAfterFailed?: boolean
  resetAfterCompleted?: boolean
}

export const defaultUploadBoxOptions: UploadBoxOptions = {
  maxSize: 10 * 1024 * 1024, // 10MB,
  maxUploads: 1,
  autoUpload: false,
  cloudinary: false,
}

@Component({
  selector: 'app-upload-box',
  templateUrl: './UploadBox.component.html',
  styleUrls: ['./UploadBox.component.scss'],
})
export class UploadBoxComponent implements OnInit {
  @Input() options: UploadBoxOptions = defaultUploadBoxOptions

  @Input() image: string
  @Input() imageHeight: string = '80px'

  @Input() showFileList = true
  @Input() showUploadingBar = true

  @HostBinding('class')
  @Input()
  direction: 'v' | 'h' = 'h'

  @Output() complete = new EventEmitter<any[]>()
  @Output() afterAddingAll = new EventEmitter<FileItem[]>()
  @Output() afterAddingFile = new EventEmitter<FileItem>()
  @Output() afterFileUploaded = new EventEmitter<FileItem>()
  @Output() completeItem = new EventEmitter<any>()

  get isUploading() {
    return this.uploader?.isUploading
  }

  get isPendingEmpty() {
    return !this.uploader?.queue?.length
  }

  uploader: FileUploader

  files: FileItem[] = []
  uploadedFileResults: any[] = []

  acceptFileExtensions: string

  private fileSizePipe = new FileSizePipe()
  constructor(
    private fileUploaderFactory: FileUploaderFactoryService,
    @Inject(ENVIRONMENT) private environment,
    protected toast: Toaster
  ) {}

  ngOnInit(): void {
    if (!this.options.allowedFileExtensions && this.options.cloudinary) {
      this.options.allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.jfif']
    }

    if (this.options.allowedFileExtensions) {
      this.acceptFileExtensions = this.options.allowedFileExtensions.join(',')
    }

    this.uploader = this.fileUploaderFactory.create(
      this.options.cloudinary ? this.getCloudinaryUploadOptions() : this.getTokeetUploadOptions(),
      {
        onAfterAddingFile: async (fileItem) => {
          if (this.options.cloudinary) {
            fileItem.withCredentials = false
          }
          this.files.push(fileItem)
          this.afterAddingFile.emit(fileItem)
        },
        onAfterAddingAll: (fileItems) => {
          this.afterAddingAll.emit(fileItems)
        },
        onCompleteItem: (fileItem, res) => {
          this.afterFileUploaded.emit(fileItem)
          let resObj
          try {
            resObj = JSON.parse(res)
            this.uploadedFileResults = this.uploadedFileResults.concat(resObj)
          } catch (e) {}
          this.completeItem.emit(resObj || res)
        },
        onErrorItem: (item, response, status, headers) => {
          if (this.options.resetAfterFailed) {
            this.uploader.removeFromQueue(item)
          }
          this.toast.error('Unable to upload file', '', !status ? null : JSON.parse(response))
        },
        onCompleteAll: () => {
          this.complete.emit(this.uploadedFileResults)
          if (this.options.resetAfterCompleted) {
            this.uploader.clearQueue()
            this.uploadedFileResults = []
            this.files = []
          }
        },
      }
    )
  }

  getTokeetUploadOptions(): CustomFileUploaderOptions {
    return {
      url: this.options.uploadUrl || `${this.environment.apiUrl}/attachment`,
      autoUpload: this.options.autoUpload,
      maxFileSize: this.options.maxSize,
      queueLimit: this.options.maxUploads,
      allowedFileExtensions: this.options.allowedFileExtensions,
      authToken: this.options.token,
    }
  }

  getCloudinaryUploadOptions(): CustomFileUploaderOptions {
    return {
      url: `${this.environment.config.cloudinaryURL}/${this.environment.config.cloudinaryCloudName}/upload`,
      allowedFileExtensions: this.options.allowedFileExtensions,
      autoUpload: this.options.autoUpload,
      maxFileSize: this.options.maxSize,
      queueLimit: this.options.maxUploads,
      additionalParameter: {
        upload_preset: this.environment.config.cloudinaryUploadPreset,
        tags: 'ablum',
        context: 'photo=' + ('title' || ''),
      },
    }
  }

  getGuideText() {
    if (this.options.guide) {
      return this.options.guide
    }
    return `You can upload <b>${
      this.options.maxUploads ?? 'multiple'
    }</b> file(s) at one time and file size should be less than <b>${this.fileSizePipe.transform(
      this.options.maxSize,
      0
    )}</b>.`
  }

  reset() {
    this.uploader.clearQueue()
  }

  onRemoveFile(file: FileItem, index: number) {
    this.uploader.removeFromQueue(file)
    this.files.splice(index, 1)
  }

  upload() {
    this.uploader.uploadAll()
  }
}
