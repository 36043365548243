import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { ResourceGroup } from './group.model'
import { httpRawItemsToDataItems, httpRawItemToDataItem } from '@tokeet-frontend/tv3-platform'

export type UpdateGroupPayload = Partial<Pick<ResourceGroup, 'name' | 'access'>>

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private http: HttpClient) {}

  list(): Observable<ResourceGroup[]> {
    const url = `@signatureApi/groups`
    return this.http
      .get<{ items: any[] }>(url)
      .pipe(map(({ items }) => httpRawItemsToDataItems<ResourceGroup>(items, 'group_id')))
  }

  create(name: string, type: 'template' | 'document'): Observable<ResourceGroup> {
    const url = `@signatureApi/groups`
    return this.http.post<any>(url, { name, type }).pipe(map((t) => httpRawItemToDataItem(t, 'group_id')))
  }

  update(id: string, payload: UpdateGroupPayload): Observable<ResourceGroup> {
    const url = `@signatureApi/groups/${id}`
    return this.http.put<any>(url, payload).pipe(map((t) => httpRawItemToDataItem(t, 'group_id')))
  }

  delete(id: string): Observable<any> {
    const url = `@signatureApi/groups/${id}`
    return this.http.delete<any>(url)
  }
}
