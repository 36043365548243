import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { TaskStatusComponent } from '@tv3/containers/tasks/shared/task-status/task-status.component'
import { TaskDialogComponent } from '@tv3/containers/tasks/shared/task-dialog/task-dialog.component'
import { TaskConversationsComponent } from './shared/task-dialog/task-conversations/task-conversations.component'
import { TaskHistoryComponent } from './shared/task-dialog/task-history/task-history.component'
import { TaskChecklistComponent } from './shared/task-dialog/task-checklist/task-checklist.component'
import { TaskInquiryDetailsComponent } from './shared/task-dialog/task-inquiry-details/task-inquiry-details.component'
import { TaskStarBtnComponent } from '@tv3/containers/tasks/shared/task-star-btn/task-star-btn.component'
import { CommentsLibraryModule } from '@tokeet-frontend/comments'
import { ActivityLibraryModule } from '@tokeet-frontend/activity'
import { InquirySharedModule } from '../inquiries/overlays/inquiry-overlay/inquiry-shared.module'
import { DefaultTaskViewComponent } from './shared/default-task-view/default-task-view.component'
import { TaskListPopupComponent } from './calendar-view/task-list-popup/task-list-popup.component'
import { IncidentsStoreModule } from '@tokeet-frontend/incident'
import { TaskViewDialogService } from '@tv3/containers/tasks/shared/task-view-dialog.service'

const components = [
  //
  TaskStatusComponent,
  TaskStarBtnComponent,
  DefaultTaskViewComponent,
]

const dialogs = [
  //
  TaskConversationsComponent,
  TaskInquiryDetailsComponent,
  TaskHistoryComponent,
  TaskChecklistComponent,
  TaskDialogComponent,
  TaskListPopupComponent,
]

const modules = [
  //
  SharedModule,
  CommentsLibraryModule,
  ActivityLibraryModule,
  InquirySharedModule,
  IncidentsStoreModule,
]

const services = [TaskViewDialogService]

@NgModule({
  imports: [
    //
    CommonModule,
    ...modules,
  ],
  declarations: [...components, ...dialogs],
  exports: [...components, ...dialogs, ...modules],
  providers: [...services],
})
export class TasksSharedModule {}
