export enum SignFieldType {
  Signature = 'signature',
  Initials = 'initials',
  Signdate = 'signdate',
  Textbox = 'textbox',
  Checkbox = 'checkbox',
  DataDic = 'datadic',
  Prefill = 'prefill',
}

export interface SignFieldDefAttributes {
  key?: string // *|INQUIRY:ADULTS|*
  field?: string // num_adults
  data_type?: string // number, date, time ...
  fill_before_sent?: boolean
  prefill_type?: 'user' // fill value when this field is added.
  prefill_data_id?: string // fill value when this field is added.
}

export interface SignFieldDef {
  type: SignFieldType
  required: boolean // is required field
  label: string // label for this field
  x: number
  y: number
  width: number
  height: number
  role: string // role who will sign this field
  value?: string // if the role to sign this field is me, the value for this field will filled automatically when create template.
  attributes: SignFieldDefAttributes
  font?: string
  font_size?: number
  readonly?: boolean
}

export type SignFieldRect = Pick<SignFieldDef, 'x' | 'y' | 'width' | 'height'>
