import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, switchMapTo, tap } from 'rxjs/operators'
import { SignaturePreferenceService } from './preference.service'
import {
  LoadSignaturePreferenceAction,
  LoadSignaturePreferenceCompleteAction,
  UpdateSignaturePreferenceAction,
  UpdateSignaturePreferenceCompleteAction,
} from './preference.actions'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class SignaturePreferenceEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSignaturePreferenceAction),
      switchMapTo(
        this.account.get().pipe(
          map((preference) => LoadSignaturePreferenceCompleteAction({ preference })),
          catchError((error) => of(ActionFailed(error)))
        )
      )
    )
  )

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSignaturePreferenceAction),
      switchMap(({ payload }) => {
        return this.account.update(payload).pipe(
          map((preference) => UpdateSignaturePreferenceCompleteAction({ preference })),
          tap(() => this.toaster.success('Preferences updated successfully')),
          catchError((error) => of(ActionFailed(error)))
        )
      })
    )
  )

  constructor(private actions$: Actions, private toaster: Toaster, private account: SignaturePreferenceService) {}
}
