import { of, timer } from 'rxjs'
import { exhaustMap, map, switchMap } from 'rxjs/operators'
import { Component, OnInit } from '@angular/core'
import { Notification, NotificationLinkTypes } from '@tv3/store/notification/notification.model'
import { NotificationService } from '@tv3/store/notification/notification.service'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { NotificationDetailService } from '@tv3/services/notification-detail.service'
import { Store } from '@ngrx/store'
import { orderBy } from 'lodash'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends Destroyable implements OnInit {
  allNotifications: Notification[] = []
  notifications: Notification[] = []
  unreadCount = 0
  showCount = 10

  isOpen = false

  theme = {
    booked: {
      clickable: true,
      color: 'info',
      icon: 'cs-icon cs-icon-calendar-check2',
    },
    cancelled: {
      clickable: true,
      color: 'danger',
      icon: 'cs-icon cs-icon-calendar-times',
    },
    inquiry: {
      clickable: true,
      color: 'warning',
      icon: 'cs-icon cs-icon-calendar-pencil',
    },
    paid: {
      clickable: true,
      color: 'success',
      icon: 'cs-icon cs-icon-dollar-check',
    },
    signed: {
      clickable: true,
      color: 'indigo',
      icon: 'cs-icon cs-icon-pen-sign',
    },
    rejected: {
      clickable: true,
      color: 'danger',
      icon: 'cs-icon cs-icon-times',
    },
    succeeded: {
      clickable: true,
      color: 'success',
      icon: 'cs-icon cs-icon-dollar-check',
    },
    failed: {
      clickable: true,
      color: 'danger',
      icon: 'cs-icon cs-icon-alert',
    },
  }

  tabs = [
    {
      id: 'all',
      amount: 0,
      title: 'All',
    },
    {
      id: 'inquiry',
      amount: 0,
      title: 'Inquiry',
    },
    {
      id: 'booked',
      amount: 0,
      title: 'Booking',
    },
    {
      id: 'cancelled',
      amount: 0,
      title: 'Cancellation',
    },
    {
      id: 'paid',
      amount: 0,
      title: 'Paid',
    },
    {
      id: 'signed',
      amount: 0,
      title: 'Signed',
    },
    {
      id: 'rejected',
      amount: 0,
      title: 'Rejection',
    },
  ]

  activeTab

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    private notificationDetailService: NotificationDetailService
  ) {
    super()
  }

  ngOnInit() {
    this.activeTab = this.tabs[0]

    of(true)
      .pipe(
        switchMap(() => timer(100, 1000 * 60)),
        exhaustMap(() => this.notificationService.getUnreadCount()),
        untilDestroy(this)
      )
      .subscribe((data) => {
        this.unreadCount = data.newcount
      })
  }

  onShown() {
    this.isOpen = !this.isOpen
    this.notificationService
      .all()
      .pipe(map((n2) => orderBy([...n2], ['date'], ['desc'])))
      .subscribe((notifications) => {
        this.allNotifications = notifications.filter((i) => i.type && i.linkType != NotificationLinkTypes.HoldEvent)
        const stats = this.allNotifications.reduce((res, i) => ({ ...res, [i.type]: (res[i.type] || 0) + 1 }), {})
        this.tabs[0].amount = this.allNotifications.length
        this.tabs.slice(1).forEach((tab) => (tab.amount = stats[tab.id] || 0))
        this.filter()
      })
  }

  onReadAll() {
    this.unreadCount = 0
    this.notificationService.readAll()
  }

  onOpen(notification: Notification) {
    this.notificationDetailService.openDetail(notification)
  }

  onTabClick(tab) {
    this.activeTab = tab
    this.filter()
  }

  filter() {
    this.notifications = this.allNotifications
      .filter((i) => this.activeTab.id === 'all' || i.type === this.activeTab.id)
      .slice(0, this.showCount)
  }

  getTitleHtml(title: string, color: string) {
    return title.replace(/^\s*\w+/, `<span class="text-semi-bold text-${color}">$&</span>`)
  }
}
