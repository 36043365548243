import * as lodash from 'lodash'
import { epochToView } from '@tokeet-frontend/tv3-platform'

export interface CustomCode {
  pkey: string

  account: number
  name: string
  description: string
  body: string
  archived: number
  created: number
  creator: string

  attributes: { tags: string[] }
  rentals: string[]
}

export interface CustomCodeView extends CustomCode {
  nameFormatted: string

  createdView: string
  tagView: string
  rentalNames: string
  creatorView: string
}

export function toCustomCodeView(code: CustomCode): CustomCodeView {
  const name = String(code.name)

  return {
    ...code,

    nameFormatted: `*|CUSTOM:${name.toUpperCase()}|*`,
    createdView: epochToView(code.created),
    tagView: lodash.get(code, 'attributes.tags', []).join(', '),
    rentalNames: '',
    creatorView: '',
  }
}
