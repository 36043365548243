import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromContract from './contract.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ContractEffects } from './contract.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('contract', fromContract.contractReducer),
    EffectsModule.forFeature([ContractEffects]),
  ],
  declarations: [],
})
export class SignatureContractStoreModule {}
