<div class="d-flex justify-content-between align-items-center p-3">
  <h6 class="mb-0">Imported Calendars</h6>
  <button
    matTooltip="Save an import URL which can be used to pull in availability from an outside channel site's calendar to the AdvanceCM rental specified."
    matTooltipPosition="above"
    type="button"
    class="btn btn-secondary-info ml-2"
    (click)="onAdd()"
    [disabled]="(isUpdating$ | async) || (1 | isReadonlyRole)"
  >
    <span class="far fa-plus" aria-hidden="true"></span>
    Add
  </button>
</div>
<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
      <ng-container matColumnDef="customICalChannelName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Channel Name</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.customICalChannelName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Linked Date</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.created | epoch : dateFormat }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-truncate" [title]="element.rentalName">
          <span [matTooltip]="element.rentalName" matTooltipPosition="above">
            {{ element.rentalName || '....' | truncate : 25 }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastsync">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last Sync</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.lastsync | epoch : dateFormat }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="imported">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Imported</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.imported }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="skipped">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Skipped</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.skipped }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button type="button" (click)="onDetails(element)">
                  <i class="fal fa-edit"></i>Connection Details
                </button>
              </li>
              <li *ngIf="element.rental">
                <button type="button" (click)="onRentalDetail(element)">
                  <i class="fal fa-home"></i>Rental Details
                </button>
              </li>
              <li>
                <button (click)="onRefresh(element)" type="button"><i class="far fa-sync"></i> Manual Refresh</button>
              </li>
              <li>
                <button (click)="onDelete(element)" type="button">
                  <i class="cs-icon cs-icon-trash"></i>Delete Calendar
                </button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onDetails(row)"
        class="clickable"
        [ngClass]="{ 'row-danger': row.past && row.status !== 2 }"
      ></mat-row>
    </mat-table>
    <app-empty-table *ngIf="isEmptyTable$ | async" type="ImportedCalendars"></app-empty-table>
    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="15"
      [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>
  </table>
</div>
