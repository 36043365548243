import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { TaskResponse, TaskView, defaultTaskColors, CreateTaskPayload } from '@tv3/store/task/task.model'
import * as fromRoot from '@tv3/store/state'
import * as moment from 'moment'
import { SearchService } from '@tv3/store/search/search.service'
import {
  asLocalDate,
  Destroyable,
  isSomething,
  selectAllRentals,
  selectUsers,
  untilDestroy,
  Attachment,
  getAttachments,
  AttachmentService,
  selectAttachmentsById,
  addAttachmentsComplete,
  asUTCEpoch,
  DataEntityType,
  ConfirmDialogService,
  Toaster,
  validateForm,
  distinctObjectUntilChangedByFields,
  notAllNumbersValidator,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { AddTask, AddTaskComplete, DeleteTask, UpdateTask } from '@tv3/store/task/task.actions'
import { UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import { filter, map, startWith, switchMap } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { isUpdatingTasks, selectAllTasks, selectTaskLists, selectTaskViewById } from '@tv3/store/task/task.selectors'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { LoadInquiryDetails } from '@tv3/store/inquiry/inquiry.actions'
import { EntityAttributesComponent } from '@tokeet-frontend/custom-attrs'
import { AddEntityTags, SetEntityTags } from '@tokeet-frontend/tags'
import {
  createRecurringForm,
  getRecurringFormData,
  setRecurringFormData,
} from '@tv3/shared/recurring-form/recurring-form.component'
import {
  createTaskChecklistForm,
  getTaskChecklistFormData,
  setTaskChecklistFormData,
} from './task-checklist/task-checklist.component'
import * as R from 'ramda'
import { selectAllIncidents } from '@tokeet-frontend/incident'

export enum TaskDataTabs {
  Details,
  Conversations,
  Recurring,
  InquiryDetails,
  History,
  Attributes,
  Checklist,
}

@Component({
  selector: 'app-add-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class TaskDialogComponent extends Destroyable implements OnInit {
  @ViewChild('attrs') attrs: EntityAttributesComponent
  isEdit = false
  tabs = TaskDataTabs
  activeTab = TaskDataTabs.Details

  uploadBoxOptions: UploadBoxOptions = {
    autoUpload: true,
    resetAfterFailed: true,
    maxSize: 1024 * 1024 * 10,
    maxUploads: 1,
    token: this.authService.token,
    allowedFileExtensions: ['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', 'xlsx'],
    guide:
      'You can only upload one file that is 10MB or smaller, only PNG, JPG, JPEG, PDF, WORD and EXCEL file types are allowed',
  }

  recurringForm = createRecurringForm(this.fb)
  checklistForm = createTaskChecklistForm(this.fb)

  defaultTaskList = 'My Tasks'

  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100), notAllNumbersValidator]],
    list: [''],
    user_ids: [[], [Validators.required]],
    inquiry_id: [],
    rental_id: [],
    incident_id: [],
    start: ['', [Validators.required]],
    due: ['', [Validators.required]],
    timezone: [moment.tz.guess()],
    color: [defaultTaskColors[0].color, [Validators.required]],
    tags: [[]],
    notes: ['', [Validators.maxLength(500)]],
    recurrenceRule: this.recurringForm,
    status: [1],
    repeat: [0],
  })

  minDateDefault: Date

  colors = defaultTaskColors.map((c) => c.color)
  users$ = this.store.pipe(select(selectUsers))
  rentals$ = this.store.pipe(select(selectAllRentals))
  incidents$ = this.store.pipe(select(selectAllIncidents))
  isUpdatingTasks$ = this.store.pipe(select(isUpdatingTasks))

  get bgColor() {
    return defaultTaskColors.find((c) => c.color === this.form.get('color').value)?.bg
  }

  task: TaskView

  attachments: Attachment[] = []

  taskLists$ = this.store.pipe(select(selectTaskLists))

  filteredLists = this.form.get('list').valueChanges.pipe(
    startWith(''),
    switchMap((value) =>
      this.taskLists$.pipe(
        map((lists) => R.filter((n) => n.toLowerCase().includes(value), lists)),
        map((lists) =>
          R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))(lists)
        )
      )
    )
  )

  constructor(
    protected fb: FormBuilder,
    protected searchService: SearchService,
    protected store: Store<fromRoot.State>,
    private actions: Actions<any>,
    private authService: AuthService,
    private confirm: ConfirmDialogService,
    private toaster: Toaster,
    private amplitudeService: AmplitudeService,
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { task?: TaskView; defaults?: Partial<CreateTaskPayload>; tab?: TaskDataTabs }
  ) {
    super()
    this.activeTab = this.data.tab || TaskDataTabs.Details
    this.task = this.data.task
    this.isEdit = !!this.task
    if (!this.isEdit) {
      this.minDateDefault = new Date()
    }
  }

  ngOnInit() {
    if (this.isEdit) {
      // this.form.disable()
      this.store.dispatch(getAttachments({ ids: this.task.attachments || [] }))
      const task$ = this.store.pipe(select(selectTaskViewById(this.task.pkey)), untilDestroy(this))
      this.setFormData(this.task)
      task$
        .pipe(switchMap((task) => this.store.pipe(select(selectAttachmentsById(task.attachments || [])))))
        .subscribe((attachments) => (this.attachments = attachments))
      task$
        .pipe(
          filter((t) => !!t.inquiry_id),
          distinctObjectUntilChangedByFields(['inquiry_id'])
        )
        .subscribe((task) => {
          this.store.dispatch(LoadInquiryDetails({ id: task.inquiry_id }))
        })
    } else if (isSomething(this.data.defaults)) {
      this.setFormData(this.data.defaults)
    }

    this.actions.pipe(ofType(AddTaskComplete), untilDestroy(this)).subscribe(({ task }) => {
      const { tags } = this.form.getRawValue()
      if (isSomething(tags)) {
        this.store.dispatch(AddEntityTags({ entityType: DataEntityType.Task, entityId: task.pkey, tags }))
      }
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }

  setFormData(task: Partial<TaskResponse>) {
    this.form.patchValue({
      ...task,
      color: task.color || defaultTaskColors[0].color,
      start: asLocalDate(task.start || task.created),
      due: asLocalDate(task.due),
    })
    setRecurringFormData(this.recurringForm, task.recurrenceRule)
    setTaskChecklistFormData(this.fb, this.checklistForm, task)
  }

  onSelectInquiry(item: Inquiry) {
    this.form.get('rental_id').setValue(item.rentalId, { emitEvent: false })
    this.form.get('inquiry_id').setValue(item.id, { emitEvent: false })
  }

  onUploadComplete(data: any[]) {
    const attachments = lodash.map(data, (d) => Attachment.deserialize(d))
    this.store.dispatch(addAttachmentsComplete({ attachments }))
    this.attachments = [...this.attachments, ...attachments]
    if (this.isEdit) {
      this.store.dispatch(
        UpdateTask({
          id: this.task.pkey,
          form: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    }
  }

  onDownloadAttachment(item: Attachment) {
    this.attachmentService.downloadFile(item).subscribe()
  }

  onDeleteAttachment(item: Attachment) {
    this.confirm.confirm().subscribe(() => {
      this.attachments = lodash.filter(this.attachments, (i) => i.id !== item.id)
      this.store.dispatch(
        UpdateTask({
          id: this.task.pkey,
          form: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    })
  }

  onDelete() {
    this.confirm
      .confirm({
        title: 'Delete this task?',
        body: 'Are you sure you want to delete this task?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteTask({ id: this.task.pkey }))
        this.close()
      })
  }

  create() {
    const payload = this.form.getRawValue()
    this.store.dispatch(
      AddTask({
        form: {
          ...payload,
          start: asUTCEpoch(payload.start),
          due: asUTCEpoch(payload.due),
          attachments: this.attachments.map((t) => t.id),
          recurrenceRule: getRecurringFormData(this.recurringForm),
          checklist: getTaskChecklistFormData(this.checklistForm),
        },
      })
    )
    this.amplitudeService.logEvent('add-task')
  }

  update() {
    const { tags, ...payload } = this.form.getRawValue()
    this.store.dispatch(
      UpdateTask({
        form: {
          ...payload,
          start: asUTCEpoch(payload.start),
          due: asUTCEpoch(payload.due),
          attachments: this.attachments.map((t) => t.id),
          recurrenceRule: getRecurringFormData(this.recurringForm),
          checklist: getTaskChecklistFormData(this.checklistForm),
        },
        id: this.task.pkey,
      })
    )
    this.store.dispatch(SetEntityTags({ entityType: DataEntityType.Task, entityId: this.task.pkey, tags }))
    this.close()
  }

  onSave(form: FormGroup) {
    const isFormValid = validateForm(form, false)

    if (!isFormValid || form.invalid) {
      this.toaster.warning('Please fill out all the required fields', 'Invalid details')
      this.activeTab = this.tabs.Details
      return
    }

    if (!this.validateSubforms()) return

    const { timezone } = form.getRawValue()

    const doSave = () => {
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    }

    if (!this.isEdit || !this.task?.timezone || timezone === this.task?.timezone) {
      doSave()
    } else {
      this.confirm
        .confirm({
          title: 'Warning!',
          body: `Your current TimeZone is ${moment.tz.guess()}. Do you want to Proceed?`,
        })
        .subscribe(() => {
          doSave()
        })
    }
  }

  onSaveAttrs() {
    this.attrs.save()
    this.close()
  }

  validateSubforms() {
    this.checklistForm.markAllAsTouched()

    if (this.checklistForm.invalid) {
      this.toaster.warning('Please check checklist data and correct.', 'Invalid checklist data')
      this.activeTab = this.tabs.Checklist
      return false
    }

    return true
  }
}
