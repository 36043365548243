import { Pipe, PipeTransform } from '@angular/core'
import { availableTemplateTypes, TemplateTypes } from '../store'
import * as lodash from 'lodash'

@Pipe({
  name: 'templateIcon',
})
export class TemplateIconPipe implements PipeTransform {
  transform(value: TemplateTypes, color = false): string {
    const type = lodash.find(availableTemplateTypes, { id: value })
    if (color) {
      return `${type.icon} ${type.colorClass}`
    }

    return type.icon
  }
}
