<div class="modal-header">
  <h5 class="modal-title">Select Rental to Copy</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="row">
    <div class="col-sm-8">
      <app-rental-select
        [items]="data.rentals"
        [ctrl]="form.get('rental')"
        [multiple]="false"
        placeholder="Select Rental"
      >
      </app-rental-select>
    </div>
  </div>
  <p>Please select which information you want to copy.</p>
  <div class="row">
    <div class="col-sm-6">
      <mat-checkbox formControlName="checkInInstructions">Check-in Instructions</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="checkOutInstructions">Check-out Instructions</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="directionInstructions">Directions</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="houseRules">House Rules</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="specialInstructions">Special Instructions</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="paymentTerms">Payment Terms</mat-checkbox>
    </div>
    <div class="col-sm-6">
      <mat-checkbox formControlName="paymentInstructions">Payment Instructions</mat-checkbox>
    </div>
  </div>
  <p *ngIf="form.touched && form.invalid" class="text-danger">
    Please select at least one item to copy from other rental.
  </p>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Select
  </button>
</div>
