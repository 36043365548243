import { Component, HostBinding, Input } from '@angular/core'
import { ContractSignField } from '@tokeet-frontend/signature'
import { Destroyable } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'base-field-component',
  template: '',
})
export class BaseFieldComponent extends Destroyable {
  @HostBinding('class') classes = 'panzoom-exclude'
  @HostBinding('class.highlight') isHighlighted = false

  @Input()
  protected _field: ContractSignField
  public get field(): ContractSignField {
    return this._field
  }
  public set field(value: ContractSignField) {
    this._field = value
  }

  @Input() fieldStyle: { width?: string; height?: string } = {}

  constructor() {
    super()
  }
}
