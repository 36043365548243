<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header gap-3">
      <div class="modal-title">
        <ng-container *ngIf="parentRentalId | rentalChildren | async; let children">
          <app-rental-name
            [rentalId]="parentRentalId"
            [image]="true"
            [imageSize]="35"
            *ngIf="!children.length"
          ></app-rental-name>
          <app-parent-child-rental-select
            [parentId]="parentRentalId"
            [ctrl]="selectedRentalCtrl"
            *ngIf="children.length"
          ></app-parent-child-rental-select>
        </ng-container>
      </div>
      <div class="flex-fill"></div>
      <ng-container *ngIf="tab$.value === tabs.Calendar">
        <button
          [disabled]="1 | isReadonlyRole"
          matTooltip="This button will enable users to quickly define a fixed rate for selected dates within the calendar view."
          matTooltipPosition="below"
          type="button"
          class="btn btn-secondary-danger"
          (click)="onAddStandardRate()"
        >
          <span class="cs-icon cs-icon-dollar"></span> Add Standard Rate
        </button>
        <button
          [disabled]="1 | isReadonlyRole"
          matTooltip="This button will provide users with the option to apply dynamic pricing rules for the selected dates."
          matTooltipPosition="below"
          type="button"
          class="btn btn-secondary-primary"
          (click)="onAddDynamicRate()"
        >
          <span class="cs-icon cs-icon-dollar-sync"></span> Add Dynamic Rate
        </button>
      </ng-container>
      <ng-container *ngIf="tab$.value === tabs.Basic">
        <button
          [disabled]="1 | isReadonlyRole"
          matTooltip="A Rental can be deleted only when all associated hold events and inquiries/bookings have been deleted."
          matTooltipPosition="below"
          (click)="basic?.onDelete()"
          type="button"
          class="btn btn-secondary-danger"
        >
          <span class="cs-icon cs-icon-trash"></span> Delete
        </button>
        <app-button
          (click)="basic?.save()"
          icon="cs-icon cs-icon-save"
          [class]="'success' | buttonClass"
          [disabled]="1 | isReadonlyRole"
          [loading]="basic?.isSaving$ | async"
        >
          Save
        </app-button>
      </ng-container>
      <app-button
        *ngIf="tab$.value === tabs.Info"
        (click)="detail?.save()"
        icon="cs-icon cs-icon-save"
        [class]="'success' | buttonClass"
        [disabled]="1 | isReadonlyRole"
        [loading]="detail?.isSavingDetailInfo$ | async"
      >
        Save
      </app-button>
      <app-button
        *ngIf="tab$.value === tabs.Gallery"
        (click)="gallery?.onUpload()"
        icon="fal fa-upload"
        [disabled]="1 | isReadonlyRole"
        [class]="'success' | buttonClass"
      >
        Upload
      </app-button>
      <ng-container *ngIf="tab$.value === tabs.Instructions">
        <button
          (click)="instructions?.copy()"
          matTooltip="Click here to copy Instructions, Rules, Terms or Directions from any other rental."
          matTooltipPosition="below"
          class="btn btn-secondary-primary"
          [disabled]="1 | isReadonlyRole"
        >
          <i class="fal fa-copy"></i>
          Copy Terms
        </button>
        <app-button
          (click)="instructions.save()"
          icon="cs-icon cs-icon-save"
          [class]="'success' | buttonClass"
          [disabled]="1 | isReadonlyRole"
          [loading]="instructions?.isSaving$ | async"
        >
          Save
        </app-button>
      </ng-container>
      <app-button
        (click)="custom?.save()"
        *ngIf="tab$.value === tabs.Custom"
        icon="cs-icon cs-icon-save"
        [class]="'success' | buttonClass"
        [disabled]="1 | isReadonlyRole"
        [loading]="custom?.isSavingCustom$ | async"
      >
        Save
      </app-button>
      <app-button
        (click)="location?.save()"
        *ngIf="tab$.value === tabs.Location"
        icon="cs-icon cs-icon-save"
        [class]="'success' | buttonClass"
        [disabled]="1 | isReadonlyRole"
        [loading]="location?.isSaving$ | async"
      >
        Save
      </app-button>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>

    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li
        [class.active]="tab$.value === tabs.Calendar"
        matTooltip="View the dates booked on your rental."
        matTooltipPosition="below"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Calendar)"> Calendar </a>
      </li>
      <li
        [class.active]="tab$.value === tabs.Basic"
        matTooltip="Set name, address and other basic information of rental."
        matTooltipPosition="below"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Basic)"> Basic Information </a>
      </li>
      <li
        matTooltip="Specify amenities and other rental values."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Info"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Info)"> Detail Information </a>
      </li>
      <li
        matTooltip="Upload images or view images imported from listing channels."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Gallery"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Gallery)"> Gallery </a>
      </li>
      <li
        matTooltip="Set the Check-in/Check-out time and add rental details for use with message templates."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Instructions"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Instructions)">
          <i
            *ngIf="hasIncompleteCheckInOut"
            matTooltip="This rental has no check-in, check-out or time-zone settings. Please add these details to ensure that any future automations and message templates show the correct times to your guests."
            matTooltipPosition="left"
            class="fal fa-exclamation-triangle"
          ></i>
          Rental Instructions
        </a>
      </li>
      <li
        matTooltip="Add rental specific data for use with message templates."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Custom"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Custom)"> Custom Information </a>
      </li>
      <li
        matTooltip="Set and view location of this rental on map"
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Location"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Location)"> Location </a>
      </li>
      <li
        matTooltip="Add unique/custom fields in any of the sections"
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Attributes"
      >
        <a href="javascript:;" (click)="tab$.next(tabs.Attributes)"> Custom Attributes </a>
      </li>
      <li
        dropdown
        class="dropdown"
        container="body"
        [class.active]="
          tab$.value === tabs.RatesTable ||
          tab$.value === tabs.RateMappings ||
          tab$.value === tabs.Discounts ||
          tab$.value === tabs.Settings
        "
        [matTooltip]="
          hasIncompleteBaseRate ? incompleteBaseRateMessage : 'Adjust the Base Rate settings for your rental.'
        "
        matTooltipPosition="below"
      >
        <a href="javascript:;" dropdownToggle class="dropdown-toggle">
          Rates <i *ngIf="hasIncompleteBaseRate" class="fal fa-exclamation-triangle text-danger"></i>
        </a>
        <ul *dropdownMenu class="dropdown-menu">
          <li>
            <button (click)="tab$.next(tabs.RatesTable)" [class.active]="tab$.value === tabs.RatesTable" type="button">
              Rates Table
            </button>
          </li>
          <li>
            <button
              (click)="tab$.next(tabs.RateMappings)"
              [class.active]="tab$.value === tabs.RateMappings"
              type="button"
            >
              Rate Mappings
            </button>
          </li>
          <li>
            <button (click)="tab$.next(tabs.Discounts)" [class.active]="tab$.value === tabs.Discounts" type="button">
              Discounts
            </button>
          </li>
          <li>
            <button
              (click)="tab$.next(tabs.Settings)"
              [class.active]="tab$.value === tabs.Settings"
              type="button"
              [matTooltip]="hasIncompleteBaseRate ? incompleteBaseRateMessage : ''"
              matTooltipPosition="left"
            >
              Settings <i *ngIf="hasIncompleteBaseRate" class="fal fa-exclamation-triangle text-danger"></i>
            </button>
          </li>
        </ul>
      </li>
    </ul>

    <ul class="nav drawer-right-navbar">
      <li [class.active]="tab$.value === tabs.Taxes" matTooltip="Tax / VAT" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Taxes)">
          <i class="fal fa-hand-holding-usd"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.Fees" matTooltip="Fees" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Fees)">
          <i class="fal fa-money-bill"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.PaymentSchedule" matTooltip="Payment Schedule" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.PaymentSchedule)">
          <i class="fal fa-badge-percent"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.Rooms" matTooltip="Rooms" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Rooms)">
          <i class="fal fa-bed"></i>
        </a>
      </li>
      <li
        [class.active]="tab$.value === tabs.UserRestrictions"
        *ngIf="!(isRestrictingUsers$ | async)"
        matTooltip="User Restrictions"
        matTooltipPosition="left"
      >
        <a (click)="tab$.next(tabs.UserRestrictions)">
          <i class="fal fa-user-lock"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.OwnerAccess" matTooltip="Owner Access" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.OwnerAccess)">
          <i class="fal fa-user-shield"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.Tags" matTooltip="Manage Tags" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Tags)">
          <i class="fal fa-tags"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.Connections" matTooltip="Connections" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Connections)">
          <i class="cs-icon cs-icon-links-cog"></i>
        </a>
      </li>
      <li [class.active]="tab$.value === tabs.Calendars" matTooltip="Calendars" matTooltipPosition="left">
        <a (click)="tab$.next(tabs.Calendars)">
          <i class="cs-icon cs-icon-calendar-link"></i>
        </a>
      </li>
    </ul>
    <div class="modal-body">
      <div *ngIf="rental" class="tab-content">
        <section [ngSwitch]="tab$.value" class="rental-settings-page">
          <app-rental-rates-calendar
            #calendar
            *ngSwitchCase="tabs.Calendar"
            [rental]="rental"
            [showEvents]="true"
          ></app-rental-rates-calendar>

          <app-rental-details-basic-info
            #basic
            *ngSwitchCase="tabs.Basic"
            [rental]="rental"
          ></app-rental-details-basic-info>
          <app-rental-details-detail-info
            #detail
            *ngSwitchCase="tabs.Info"
            [rental]="rental"
          ></app-rental-details-detail-info>
          <app-rental-details-image-gallery
            #gallery
            *ngSwitchCase="tabs.Gallery"
            [rental]="rental"
            [highlightedItems]="data.highlightedImages"
          ></app-rental-details-image-gallery>

          <app-rental-details-instructions
            #instructions
            *ngSwitchCase="tabs.Instructions"
            [rental]="rental"
          ></app-rental-details-instructions>
          <app-rental-details-custom-info
            #custom
            *ngSwitchCase="tabs.Custom"
            [rental]="rental"
          ></app-rental-details-custom-info>

          <app-rental-location #location *ngSwitchCase="tabs.Location" [rental]="rental"></app-rental-location>
          <div class="bg-white p-3 rounded-bottom" *ngSwitchCase="tabs.Attributes">
            <app-entity-attributes
              #attrs
              entityType="rental"
              [entityId]="rental.id"
              [showActions]="true"
            ></app-entity-attributes>
          </div>
          <app-edit-rental-taxes *ngSwitchCase="tabs.Taxes" [rental]="rental"></app-edit-rental-taxes>
          <app-rental-rooms *ngSwitchCase="tabs.Rooms" [rental]="rental"></app-rental-rooms>
          <app-rental-payment-schedule
            *ngSwitchCase="tabs.PaymentSchedule"
            [rental]="rental"
          ></app-rental-payment-schedule>
          <app-rental-fees *ngSwitchCase="tabs.Fees" [rental]="rental"></app-rental-fees>
          <app-rental-user-restrictions
            *ngSwitchCase="tabs.UserRestrictions"
            [rental]="rental"
          ></app-rental-user-restrictions>
          <app-rental-owner-access *ngSwitchCase="tabs.OwnerAccess" [rental]="rental"></app-rental-owner-access>
          <app-rental-tags *ngSwitchCase="tabs.Tags" [rental]="rental"></app-rental-tags>
          <app-rental-connections *ngSwitchCase="tabs.Connections" [rental]="rental"></app-rental-connections>
          <app-rental-calendars *ngSwitchCase="tabs.Calendars" [rental]="rental"></app-rental-calendars>
          <app-rental-rates-table
            class="container-box d-block"
            [rental]="rental"
            *ngSwitchCase="tabs.RatesTable"
          ></app-rental-rates-table>
          <app-rental-rate-mappings-table
            class="container-box d-block"
            [defaultRental]="rental"
            *ngSwitchCase="tabs.RateMappings"
          ></app-rental-rate-mappings-table>
          <app-rental-rate-settings
            class="container-box d-block"
            [rental]="rental"
            *ngSwitchCase="tabs.Settings"
          ></app-rental-rate-settings>
          <app-discounts-table
            class="container-box d-block"
            *ngSwitchCase="tabs.Discounts"
            [showAddBtn]="true"
            [rental]="rental"
          >
          </app-discounts-table>
        </section>
      </div>
    </div>
  </div>
</div>
