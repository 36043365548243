import { Injectable } from '@angular/core'
import { Observable, Observer } from 'rxjs'

export interface SignatureEditorSize {
  width: number
  height: number
}

@Injectable({ providedIn: 'root' })
export class SignatureHelperService {
  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  // https://github.com/szimek/signature_pad/blob/gh-pages/js/app.js
  setCanvasRatio(canvas: HTMLCanvasElement) {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    // This part causes the canvas to be cleared
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
  }

  drawImage(canvas: HTMLCanvasElement, data: string, margin = 0, canvasScale = 1) {
    return new Observable((observer: Observer<void>) => {
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
      const canvasWith = canvas.width / canvasScale - 2 * margin
      const canvasHeight = canvas.height / canvasScale - 2 * margin

      const img = new Image()
      img.src = data
      img.onload = () => {
        const scale = Math.min(canvasWith / img.width, canvasHeight / img.height)
        const w = img.width * scale
        const h = img.height * scale
        const left = canvasWith / 2 - w / 2 + margin
        const top = canvasHeight / 2 - h / 2 + margin

        ctx.drawImage(img, left, top, w, h)

        observer.next()
        observer.complete()
      }
    })
  }

  trim(dataURI: string): Observable<{ data: string; height: number; width: number }> {
    return new Observable((observer: Observer<{ data: string; height: number; width: number }>) => {
      if (!dataURI) {
        observer.error(new Error('Invalid data URI'))
        return
      }

      const img = new Image()
      let croppedCanvas: HTMLCanvasElement | null = document.createElement('canvas')
      let croppedCtx: CanvasRenderingContext2D | null = croppedCanvas.getContext('2d', { willReadFrequently: true })

      const cleanup = () => {
        if (croppedCanvas) {
          // Set dimensions to 0 to free memory
          croppedCanvas.width = 0
          croppedCanvas.height = 0
        }
        // Clear references
        croppedCanvas = null
        croppedCtx = null
      }

      if (!croppedCtx) {
        cleanup()
        observer.error(new Error('Failed to get canvas context'))
        return
      }

      const handleError = (error: Error) => {
        cleanup()
        observer.error(error)
      }

      img.onerror = () => handleError(new Error('Failed to load image'))

      img.onload = () => {
        try {
          if (!croppedCanvas || !croppedCtx) {
            throw new Error('Canvas context lost')
          }

          // Set initial dimensions
          croppedCanvas.width = img.width
          croppedCanvas.height = img.height
          croppedCtx.drawImage(img, 0, 0)

          let imageData: ImageData
          try {
            imageData = croppedCtx.getImageData(0, 0, img.width, img.height)
          } catch (e) {
            throw new Error('Failed to get image data. The image might be tainted or too large.')
          }

          const { data, width, height } = imageData

          // Find bounds
          let minX = width
          let minY = height
          let maxX = -1
          let maxY = -1

          // Scan in steps of 4 (RGBA)
          for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
              const alpha = data[(y * width + x) * 4 + 3]
              if (alpha > 0) {
                minX = Math.min(minX, x)
                minY = Math.min(minY, y)
                maxX = Math.max(maxX, x)
                maxY = Math.max(maxY, y)
              }
            }
          }

          // Check if signature is empty
          if (maxX < minX || maxY < minY) {
            cleanup()
            observer.next({ data: dataURI, width: 0, height: 0 })
            observer.complete()
            return
          }

          // Add padding
          const padding = 1
          minX = Math.max(0, minX - padding)
          minY = Math.max(0, minY - padding)
          maxX = Math.min(width - 1, maxX + padding)
          maxY = Math.min(height - 1, maxY + padding)

          // Calculate new dimensions
          const newWidth = maxX - minX + 1
          const newHeight = maxY - minY + 1

          // Crop the image
          let croppedData: ImageData
          try {
            croppedData = croppedCtx.getImageData(minX, minY, newWidth, newHeight)
          } catch (e) {
            throw new Error('Failed to crop image data')
          }

          croppedCanvas.width = newWidth
          croppedCanvas.height = newHeight
          croppedCtx.putImageData(croppedData, 0, 0)

          // Convert to high-quality PNG
          const result = {
            data: croppedCanvas.toDataURL('image/png', 1.0),
            width: newWidth,
            height: newHeight,
          }

          cleanup()
          observer.next(result)
          observer.complete()
        } catch (error) {
          handleError(error instanceof Error ? error : new Error('Failed to process image'))
        }
      }

      img.src = dataURI
    })
  }
}
