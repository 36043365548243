import { createAction, props } from '@ngrx/store'
import { SignaturePreference, UpdateSignaturePreferencePayload } from './preference.model'

export const LoadSignaturePreferenceAction = createAction('[SignaturePreference] Load Start')

export const LoadSignaturePreferenceCompleteAction = createAction(
  '[SignaturePreference] Load Complete',
  props<{ preference: SignaturePreference }>()
)

export const UpdateSignaturePreferenceAction = createAction(
  '[SignaturePreference] Update Start',
  props<{ payload: UpdateSignaturePreferencePayload }>()
)

export const UpdateSignaturePreferenceCompleteAction = createAction(
  '[SignaturePreference] Update Complete',
  props<{ preference: SignaturePreference }>()
)
