<div class="modal-header">
  <div class="d-flex align-items-center gap-2">
    <h5 class="modal-title"><i class="fal fa-home"></i> Add Parent-child Rental</h5>
    <app-page-help articleId="xsMarHt8GB6MQoRps2Vvzz"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-rental-select
    [ctrl]="form.get('parent_id')"
    [multiple]="false"
    [rentals$]="availableParents$"
    label="Select Parent Rental"
    placeholder="Parent Rental"
  >
  </app-rental-select>

  <app-rental-select
    [ctrl]="form.get('children')"
    [multiple]="true"
    [rentals$]="availableChildren$"
    label="Select Child Rentals"
    placeholder="Child Rentals"
  >
  </app-rental-select>

  <div class="alert alert-secondary-info">
    <i class="alert-icon cs-icon cs-icon-info-circle"></i>
    <div class="alert-content">
      All child units are fully customizable and can have the same level of detail and settings as the parent rental,
      allowing you to tailor each unit individually.
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info" [disabled]="loading$ | async">
    <span class="cs-icon cs-icon-save" *ngIf="!(loading$ | async)"></span>
    <span class="far fa-spinner fa-spin" *ngIf="loading$ | async"></span>
    Save
  </button>
</div>
