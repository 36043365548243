import { adapter, initialState, State } from './custom-code.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddCustomCodeComplete,
  DeleteCustomCodeComplete,
  GetAllCustomCodesComplete,
  UpdateCustomCodeComplete,
} from '@tv3/store/custom-codes/custom-code.actions'

export const reducer = createReducer(
  initialState,
  on(GetAllCustomCodesComplete, (state, { codes }) => adapter.setAll(codes, R.merge(state, { isLoaded: true }))),
  on(DeleteCustomCodeComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(AddCustomCodeComplete, (state, { code }) => adapter.addOne(code, state)),
  on(UpdateCustomCodeComplete, (state, { update }) => adapter.updateOne(update, state))
)

export function customCodeReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
