import { Injectable } from '@angular/core'
import { AssignInquiryDialogComponent } from './assign-inquiry-dialog.component'
import { Contract } from '@tokeet-frontend/signature'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AssignInquiryDialogService {
  constructor(private dialog: DialogService) {}

  public open(contract: Contract) {
    return this.dialog.openFixedDialog(AssignInquiryDialogComponent, {
      width: '500px',
      data: { contract },
    })
  }
}
