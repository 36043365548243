import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Contract, ShareContract } from '@tokeet-frontend/signature'
import { Destroyable, untilDestroy, SaveForm, User, selectAllUsers } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-share',
  templateUrl: './share-contract-dialog.component.html',
  styleUrls: ['./share-contract-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ShareContractDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    title: ['', [Validators.required]],
    message: ['', [Validators.required]],
    recipients: [[], [Validators.required]],
  })

  tokeetUsers: User[] = []

  constructor(
    private dialogRef: MatDialogRef<ShareContractDialogComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: { contract: Contract }
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectAllUsers), untilDestroy(this)).subscribe((users) => {
      this.tokeetUsers = users
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { title, message, recipients: recipientUserIds } = this.form.getRawValue()

    const recipients = recipientUserIds
      .map((id) => {
        return this.tokeetUsers.find((u) => u.id === id)
      })
      .filter((u) => !!u)
      .map((u) => ({ name: u.name, email: u.primaryEmail }))

    const currentUser = this.authService.currentUser
    const from = `${currentUser.name} <${currentUser.primaryEmail}>`
    this.store.dispatch(
      ShareContract({
        id: this.data.contract.id,
        payload: { from, title, message, recipients },
      })
    )
    this.close()
  }
}
