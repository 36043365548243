import { Component, Inject, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { select, Store } from '@ngrx/store'
import {
  asLocalDate,
  asUTCEpoch,
  compactObject,
  CURRENT_USER,
  Destroyable,
  getRecurringDates,
  notAllSpacesValidator,
  Rental,
  selectAllRentals,
  sortAscend,
  untilDestroy,
  User,
} from '@tokeet-frontend/tv3-platform'
import { holdEventTypes } from '@tv3/constants/hold-event-types'
import {
  createRecurringForm,
  getRecurringFormData,
  setRecurringFormData,
} from '@tv3/shared/recurring-form/recurring-form.component'
import { CalendarEvent, CreateHoldEventpayload } from '@tv3/store/calendar/calendar.model'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { NgxRolesService } from 'ngx-permissions'
import { BehaviorSubject, merge } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

export function createHoldEventForm(fb: FormBuilder, isEdit = true) {
  return fb.group({
    title: ['', [Validators.required, notAllSpacesValidator]],
    type: [],
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
    expires: [''],
    rentalIds: [[], isEdit ? [] : [Validators.required]],
    rental_id: ['', isEdit ? [Validators.required] : []],
    guest_id: [],
    adults: [],
    children: [],
    description: [],
    rolling: [0],
    color: [],
    recurrenceRule: createRecurringForm(fb, false),
    repeat: [0],
  })
}

export function setHoldEventFormData(form: FormGroup, data: Partial<CalendarEvent> & { rentalIds?: string[] }) {
  data = lodash.isFunction(data.serialize) ? data.serialize() : data
  form.patchValue({
    ...data,
    start: asLocalDate(data.start),
    end: asLocalDate(data.end),
    expires: asLocalDate(data.expires),
  })
  setRecurringFormData(form.get('recurrenceRule') as FormGroup, data.recurrenceRule, false)
}

export function getHoldEventFormData(
  form: FormGroup,
  attachments: string[] = [],
  recurring = true
): CreateHoldEventpayload[] {
  const { rentalIds, ...data } = form.getRawValue()
  const item: CreateHoldEventpayload = {
    ...data,
    source: 'tokeet',
    // adjust time the same as tv3
    start: asUTCEpoch(data.start, 'start') + 11 * 3600,
    end: asUTCEpoch(data.end, 'start') + 11 * 3600,
    rolling: data.rolling || 0,
    expires: data.expires ? asUTCEpoch(data.expires, 'start') : 0,
    attachments,
    recurrenceRule: getRecurringFormData(form.get('recurrenceRule') as FormGroup),
  }
  let items: CreateHoldEventpayload[] = []

  if (rentalIds?.length) {
    items = rentalIds.map((rental_id: string) => ({ ...item, rental_id }))
  } else {
    items = [item]
  }
  if (item.repeat && recurring) {
    return getRecurringEvents(items)
  } else {
    return items
  }
}

export function getRecurringEvents(items: CreateHoldEventpayload[]) {
  return lodash.flatten(
    lodash.map(items, (item): CreateHoldEventpayload[] => {
      const starts = getRecurringDates(item.start, item.recurrenceRule)
      const ends = getRecurringDates(item.end, item.recurrenceRule)
      const expires = item.expires ? getRecurringDates(item.expires, item.recurrenceRule) : null
      return lodash.map(starts, (start, i) => ({
        ...item,
        start,
        end: ends[i],
        expires: expires ? expires[i] : 0,
      }))
    })
  )
}

export function isHoldEventChanged(h1: CreateHoldEventpayload, h2: CreateHoldEventpayload) {
  const fields = [
    'title',
    'type',
    'start',
    'end',
    'expires',
    'rental_id',
    'guest_id',
    'adults',
    'children',
    'description',
    'rolling',
    'color',
  ]

  return !lodash.isEqual(compactObject(lodash.pick(h1, fields)), compactObject(lodash.pick(h2, fields)))
}

@Component({
  selector: 'app-hold-event-form',
  templateUrl: './hold-event-form.component.html',
  styleUrls: ['./hold-event-form.component.scss'],
})
export class HoldEventFormComponent extends Destroyable implements OnInit {
  @Input() form: FormGroup

  @Input() isEdit = true

  types = sortAscend('name')(holdEventTypes)
  rentals$ = this.store.pipe(select(selectAllRentals)).pipe(map((rentals) => this.filterRentals(rentals)))

  minExpirationDate = moment().startOf('day').toDate()

  minEndDate = new BehaviorSubject<Date>(null)
  maxStartDate = new BehaviorSubject<Date>(null)
  constructor(
    private store: Store<any>,
    private roleService: NgxRolesService,
    @Inject(CURRENT_USER) private user$: BehaviorSubject<User>
  ) {
    super()
  }

  ngOnInit(): void {
    merge(this.form.get('start').valueChanges, this.form.get('end').valueChanges)
      .pipe(startWith(null), untilDestroy(this))
      .subscribe(() => {
        this.setStartEndMinMax()
      })
  }

  setStartEndMinMax() {
    if (this.form.get('start').value) {
      const start = moment(this.form.get('start').value).add(1, 'days').startOf('day')
      this.minEndDate.next(start.toDate())
    }

    if (this.form.get('end').value) {
      const end = moment(this.form.get('end').value)
      this.maxStartDate.next(end.subtract(1, 'days').endOf('day').toDate())
    }
  }

  isOwner() {
    return this.roleService.getRole('OWNER')
  }

  filterRentals(rentals: Rental[]) {
    if (this.isOwner()) {
      rentals = rentals.filter((rental) => (rental.owners || []).includes(this.user$.value.id))
    }

    return rentals
  }

  onRollingChanged({ checked }: MatCheckboxChange) {
    this.form.patchValue({ rolling: !checked ? 0 : moment().unix() })
  }

  onChangeColor(color) {
    this.form.patchValue({ color })
  }
}
