<form [formGroup]="form" [formEditablePermission]="form" class="container-box">
  <div class="px-3 py-2 border-bottom">
    <div class="d-flex align-items-center gap-3">
      <div class="title">
        <img [src]="channel?.logoColor" [alt]="channel?.friendlyName" />
      </div>
      <div class="flex-fill"></div>
      <button
        type="button"
        class="btn btn-secondary-light"
        *ngIf="(channel | isAirBnBV2) && (isBoolean | call : isAirBnBV2PropertyVisible)"
        (click)="onToggleAirBnBVisible()"
        [disabled]="(isUpdating$ | async) || form.invalid || (1 | isReadonlyRole)"
      >
        <ng-container *ngIf="isAirBnBV2PropertyVisible"><i class="fal fa-eye"></i>Visible</ng-container>
        <ng-container *ngIf="!isAirBnBV2PropertyVisible"><i class="fal fa-eye-slash"></i>Not Visible</ng-container>
      </button>

      <!-- Dropdown button -->
      <div
        class="btn-group"
        dropdown
        placement="bottom"
        [isDisabled]="1 | isReadonlyRole"
        *ngIf="connection.rental || isAirBnBV1 || isAirBnBV2 || hasChannelContentApi"
      >
        <button type="button" class="btn btn-secondary-warning dropdown-toggle" dropdownToggle>
          <span class="cs-icon cs-icon-bolt"></span> Actions
        </button>
        <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <li *ngIf="!connection.rental">
            <button type="button" (click)="onLink()"><i class="fal fa-link"></i> Link</button>
          </li>
          <ng-container *ngIf="connection.rental">
            <li>
              <button type="button" (click)="onUnlink()"><i class="fal fa-unlink"></i> Unlink</button>
            </li>
            <li>
              <button type="button" (click)="onPushRates()"><i class="fal fa-arrow-up"></i> Push Rates</button>
            </li>
            <li>
              <button type="button" (click)="onPushAvailability()">
                <i class="fal fa-arrow-up"></i> Push Availability
              </button>
            </li>
            <li *ngIf="!isTiketLikeChannel">
              <button type="button" (click)="onImportBookings()">
                <i class="fal fa-arrow-down"></i> Import Bookings
              </button>
            </li>
          </ng-container>

          <ng-container *ngIf="isAirBnBV2 || isAirBnBV1">
            <li class="dropdown-divider"></li>
            <li>
              <button *ngIf="hasBookingPreferences" type="button" (click)="onUpdateBookingPrefAirBnB()">
                <i class="fal fa-check-circle"></i> Booking Preferences
              </button>
            </li>
            <li *ngIf="isAirBnBV2">
              <button *ngIf="hasBookingSettings" type="button" (click)="onUpdateBookingSettingsAirBnBV2()">
                <i class="fal fa-check-circle"></i> Booking Settings
              </button>
            </li>
            <li *ngIf="isAirBnBV2">
              <button *ngIf="hasPriceSettings" type="button" (click)="onUpdatePriceSettingsAirBnBV2()">
                <i class="fal fa-check-circle"></i> Price Settings
              </button>
            </li>
            <li *ngIf="isAirBnBV2">
              <button *ngIf="hasTaxSettings" type="button" (click)="onUpdateTaxSettingsAirBnBV2()">
                <i class="fal fa-check-circle"></i> Tax Settings
              </button>
            </li>
          </ng-container>
          <ng-container *ngIf="isAirBnBV2 && (isTokeetPaidSub$ | async)">
            <li class="dropdown-divider"></li>
            <li>
              <button type="button" (click)="onUpdateSyncCategoryAirBnBV2()">
                <i class="fal fa-exchange"></i> Change Synchronization
              </button>
            </li>
            <li>
              <button type="button" (click)="onSyncImageAirBnBV2()"><i class="fal fa-images"></i> Sync Images</button>
            </li>
            <li>
              <button type="button" (click)="onUpdateListingDetailsAirBnBV2()">
                <i class="fal fa-list"></i> Update Listing Details
              </button>
            </li>
            <li>
              <button type="button" (click)="onSetReviewStatusAirBnBV2()">
                <i class="fal fa-check-square"></i> Set Review Status
              </button>
            </li>
            <li>
              <button type="button" (click)="onPushRoomsAirBnBV2()"><i class="fal fa-bed"></i> Sync Rooms</button>
            </li>
            <li class="dropdown-divider"></li>
            <li>
              <button type="button" (click)="onDeleteListingAirBnBV2()" class="text-danger">
                <i class="cs-icon cs-icon-trash"></i> Delete AirBnb Listing
              </button>
            </li>
          </ng-container>

          <ng-container *ngIf="hasChannelContentApi">
            <li class="dropdown-divider"></li>
            <li>
              <button type="button" (click)="onEditListDetails()">
                <i class="fal fa-edit"></i> Edit Listing Details
              </button>
            </li>
            <li *ngIf="isBDC">
              <button type="button" (click)="onBDCViewSummary()"><i class="fal fa-file-alt"></i> View Summary</button>
            </li>
          </ng-container>
        </ul>
      </div>

      <button
        type="button"
        class="btn btn-secondary-info"
        (click)="onSave()"
        [disabled]="(isUpdating$ | async) || form.invalid || (1 | isReadonlyRole)"
      >
        <i class="cs-icon cs-icon-save"></i>
        Save
      </button>
    </div>
  </div>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <div class="text-uppercase text-light mb-3">Channel Information</div>
        <div class="d-flex">
          <app-rental-select
            [multiple]="false"
            placeholder="Linked Rental"
            [ctrl]="form.get('rental')"
            class="mat-block"
          >
          </app-rental-select>
          <a
            href="javascript:;"
            (click)="onRentalDetails(connection.rental)"
            *ngIf="connection.rental"
            class="ml-2 btn btn-light-primary align-input-row-end"
          >
            <i class="cs-icon cs-icon-rental2"></i>
          </a>
        </div>
        <div class="d-flex align-items-center gap-5">
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-channels text-info text-xxl mt-1"></i>
            <div>
              <div class="text-light">Channel</div>
              <div>{{ channel?.friendlyName }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-grid-check text-warning text-xxl mt-1"></i>
            <div>
              <div class="text-light">Type</div>
              <div>{{ connection?.typeText }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-calendar-plus text-indigo text-xxl mt-1"></i>
            <div>
              <div class="text-light">Date Linked</div>
              <div>{{ connection?.linkDate | epoch : 'DD-MMM-YYYY • hh:mm A' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 border-left">
        <div class="text-uppercase text-light mb-3">Room Information</div>
        <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr">
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-property text-primary text-xxl fa-fw mt-1"></i>
            <div>
              <div class="text-light">Property Name</div>
              <div>{{ connection.propertyName }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-property-number text-primary text-xxl fa-fw mt-1"></i>
            <div>
              <div class="text-light">Property ID</div>
              <div>{{ connection.propertyId }}</div>
            </div>
          </div>

          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-room text-indigo text-xl fa-fw mt-1"></i>
            <div>
              <div class="text-light">Room Name</div>
              <div>{{ connection.roomName }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-room text-indigo text-xl fa-fw mt-1"></i>
            <div>
              <div class="text-light">Room ID</div>
              <div>{{ connection.roomId }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
