import { adapter, initialState, State } from './template.state'
import {
  CreateContractTemplate,
  CreateContractTemplateComplete,
  DeleteContractTemplateComplete,
  DeleteMultipleContractTemplateComplete,
  GetContractTemplateComplete,
  LoadContractTemplatesComplete,
  UpdateContractTemplate,
  UpdateContractTemplateComplete,
} from './template.actions'
import { Action, createReducer, on } from '@ngrx/store'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadContractTemplatesComplete, (state, { items }) => adapter.upsertMany(items, { ...state, isLoaded: true })),
  on(GetContractTemplateComplete, (state, { item }) => adapter.upsertOne(item, state)),
  on(CreateContractTemplate, UpdateContractTemplate, (state) => ({ ...state, isUpdating: true })),
  on(CreateContractTemplateComplete, (state, { item }) => adapter.upsertOne(item, { ...state, isUpdating: false })),
  on(UpdateContractTemplateComplete, (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })),
  on(DeleteContractTemplateComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteMultipleContractTemplateComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(ActionFailed, (state) => ({ ...state, isUpdating: false }))
)

export function templateReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
