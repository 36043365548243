<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Automated Rates</div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li [class.active]="tab === tabs.ChannelAutomations">
        <a
          (click)="tab = tabs.ChannelAutomations"
          matTooltip="Channel rate automations will apply to all rentals if no rental specific channel automations are available."
          matTooltipPosition="below"
        >
          Channel Adjustments
        </a>
      </li>
      <li [class.active]="tab === tabs.GroupRates">
        <a
          *ngIf="showGroupRates"
          (click)="tab = tabs.GroupRates"
          matTooltip="Group rates are rates which apply to multiple rentals at once."
          matTooltipPosition="below"
        >
          Group Rates
        </a>

        <a
          *ngIf="!showGroupRates"
          (click)="$event.preventDefault()"
          class="tone-down"
          matTooltip="You need more than one rental to apply group rates."
          matTooltipPosition="below"
        >
          Group Rates
        </a>
      </li>
      <ng-container *ngIf="showDiscounts">
        <li [class.active]="tab === tabs.LosDiscounts">
          <a
            (click)="tab = tabs.LosDiscounts"
            matTooltip="Discount your rates based on guest's Length Of Stay"
            matTooltipPosition="below"
          >
            LOS Discounts
          </a>
        </li>
        <li [class.active]="tab === tabs.DiscountCodes">
          <a
            (click)="tab = tabs.DiscountCodes"
            matTooltip="Allow guest to avail discounts for Webready bookings based on discount codes"
            matTooltipPosition="below"
          >
            Discount Codes
          </a>
        </li>
      </ng-container>
      <li [class.active]="tab === tabs.MinStayAutomations">
        <a
          (click)="tab = tabs.MinStayAutomations"
          matTooltip="Do you have a different minimum night stay requirement on the weekends than during the week? Set the minimum night stay you want to apply to each day of the week."
          matTooltipPosition="below"
        >
          Min. Night Stay
        </a>
      </li>
      <li [class.active]="tab === tabs.GapNightAutomations">
        <a
          (click)="tab = tabs.GapNightAutomations"
          matTooltip="Do you want to convert more orphan days into booked nights? Set the rate and minimum night stay adjustments for booking gaps up to the number of nights you specify."
          matTooltipPosition="below"
        >
          Gap Night
        </a>
      </li>
      <li [class.active]="tab === tabs.DynamicRatesAutomations">
        <a
          (click)="tab = tabs.DynamicRatesAutomations"
          matTooltip="Dynamic rates will gradually and automatically adjust from a low rate to a high rate as your overall rental occupancy level changes. They can also instantly update your channels as the rate changes."
          matTooltipPosition="below"
        >
          Dynamic Rates
        </a>
      </li>
      <li [class.active]="tab === tabs.RatePushAutomations" matTooltip="Coming Soon" matTooltipPosition="below">
        <a (click)="tab = tabs.RatePushAutomations" class="disabled"> Rate Push </a>
      </li>
      <li [class.active]="tab === tabs.LastMinuteAutomations">
        <a
          (click)="tab = tabs.LastMinuteAutomations"
          matTooltip="Do you want to maximize your booking opportunities through the current day? Set the number of nights from today that you want to adjust your nightly rate up or down. The minimum night stay for those nights can also be adjusted in order to further increase appeal."
          matTooltipPosition="below"
        >
          Last Minute
        </a>
      </li>
    </ul>

    <div class="modal-body bg-none">
      <ng-container [ngSwitch]="tab">
        <app-channel-automations *ngSwitchCase="tabs.ChannelAutomations"> </app-channel-automations>

        <app-group-rates-table *ngSwitchCase="tabs.GroupRates" class="d-block container-box"> </app-group-rates-table>

        <app-los-discount-table *ngSwitchCase="tabs.LosDiscounts" class="d-block container-box">
        </app-los-discount-table>

        <app-discount-codes-table *ngSwitchCase="tabs.DiscountCodes" class="d-block container-box">
        </app-discount-codes-table>
        <app-min-stay-automations *ngSwitchCase="tabs.MinStayAutomations"> </app-min-stay-automations>
        <app-gap-night-automations *ngSwitchCase="tabs.GapNightAutomations"> </app-gap-night-automations>
        <app-last-minute-automations *ngSwitchCase="tabs.LastMinuteAutomations"></app-last-minute-automations>
        <app-rate-push-automations
          *ngSwitchCase="tabs.RatePushAutomations"
          class="d-block container-box"
        ></app-rate-push-automations>
        <app-dynamic-rates-table *ngSwitchCase="tabs.DynamicRatesAutomations" class="d-block container-box">
        </app-dynamic-rates-table>

        <div *ngSwitchDefault>Coming Soon</div>
      </ng-container>
    </div>
  </div>
</div>
