import { Injectable } from '@angular/core'
import { RenameContractDialogComponent } from './rename-contract-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { Contract } from '@tokeet-frontend/signature'

@Injectable()
export class RenameContractDialogService {
  constructor(private dialog: DialogService) {}

  public open(contract: Contract) {
    return this.dialog.openFixedDialog(RenameContractDialogComponent, {
      width: '500px',
      data: { contract },
    })
  }
}
