export enum ContractSignStatus {
  Decline = 'declined',
  Signed = 'signed',
  Opened = 'opened',
  Sent = 'sent',
  Delivering = 'delivering',
  Failed = 'failed',
}

export enum ContractSignerType {
  Custom = 0,
  Guest = 1,
  Staff = 2,
}

export enum ContractStatus {
  Pending = 'pending',
  Completed = 'completed',
  Declined = 'declined',
  Canceled = 'cancelled',
  Expired = 'expired',
  Voided = 'voided',
}

export const availableContractStatuses: {
  id: ContractStatus
  name: string
  icon: string
  colorClass: string
}[] = [
  {
    id: ContractStatus.Pending,
    name: 'Pending',
    icon: 'far fa-clock',
    colorClass: 'text-warning',
  },
  {
    id: ContractStatus.Completed,
    name: 'Completed',
    icon: 'far fa-signature',
    colorClass: 'text-success',
  },
  {
    id: ContractStatus.Declined,
    name: 'Declined',
    icon: 'far fa-ban',
    colorClass: 'text-danger',
  },
  {
    id: ContractStatus.Canceled,
    name: 'Canceled',
    icon: 'fal fa-times-circle',
    colorClass: 'text-muted',
  },
  {
    id: ContractStatus.Expired,
    name: 'Expired',
    icon: 'fal fa-hourglass-end',
    colorClass: 'text-muted',
  },
]

export const availableContractEventTypes = [
  {
    id: ContractSignStatus.Sent,
    name: 'Sent',
    icon: 'far fa-paper-plane',
    icon2: 'cs-icon cs-icon-mail-receive',
    color: 'text-primary',
    bgColor: 'bg-primary',
  },
  {
    id: ContractSignStatus.Opened,
    name: 'Opened',
    icon: 'far fa-envelope-open',
    icon2: 'cs-icon cs-icon-mail-open',
    color: 'text-warning',
    bgColor: 'bg-warning',
  },
  {
    id: ContractSignStatus.Signed,
    name: 'Signed',
    icon: 'far fa-signature',
    icon2: 'cs-icon cs-icon-sign',
    color: 'text-success',
    bgColor: 'bg-success',
  },
  {
    id: ContractSignStatus.Decline,
    name: 'Declined',
    icon: 'far fa-ban',
    icon2: 'cs-icon cs-icon-times',
    color: 'text-danger',
    bgColor: 'bg-danger',
  },
  {
    id: ContractSignStatus.Delivering,
    name: 'Delivering',
    icon: 'far fa-clock',
    icon2: 'far fa-clock',
    color: 'text-default',
    bgColor: 'bg-info',
  },
  {
    id: ContractSignStatus.Failed,
    name: 'Error',
    icon: 'far fa-exclamation-triangle',
    icon2: 'far fa-exclamation-triangle',
    color: 'text-danger',
    bgColor: 'bg-danger',
  },
]
