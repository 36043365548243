import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ContractSignResponse } from './model/contract-sign.response'
import { ContractSignField } from '@tokeet-frontend/signature'

@Injectable({ providedIn: 'root' })
export class SignService {
  constructor(private http: HttpClient) {}

  get(id: string, role: string) {
    const url = `@signatureApi/contracts/inapp/${id}`
    return this.http.put<ContractSignResponse>(url, { role })
  }

  sign(
    id: string,
    role: string,
    data: { photo_urls: string[]; fields: Pick<ContractSignField, 'field_id' | 'value'>[] }
  ): Observable<any> {
    const url = `@signatureApi/contracts/inapp/${id}/sign`
    return this.http.put<any>(url, { role, ...data })
  }

  decline(id: string, role: string, reason: string): Observable<any> {
    const url = `@signatureApi/contracts/inapp/${id}/decline`
    return this.http.put<any>(url, { role, reason })
  }
}
