<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/gvr/tokeet-gvr.webp" alt="" height="45" />
        <div>
          <h6 class="mb-0">Google Vacation Rentals</h6>
          <div class="text-sm">
            Connect multiple Google Vacation Rentals accounts and sync bookings, prices, guest messages, and more
            instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3 d-flex flex-column" style="min-height: 450px">
        <div class="alert alert-secondary-info">
          <i class="cs-icon cs-icon-pray alert-icon" style="font-size: 45px"></i>
          <div class="alert-content">
            We’re excited to guide you through the process of connecting your properties with Google Vacation Rentals
            (GVR). This step-by-step process will ensure a seamless integration, allowing you to reach a broader
            audience.
          </div>
        </div>
        <p>
          Please read carefully and follow the instructions provided. If you have any questions, refer to the tooltips
          for additional <a href="#">guide</a>.
        </p>
        <div class="flex-fill"></div>
        <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
          <button (click)="onConnect()" type="button" class="btn btn-secondary-info" *ngIf="!status?.connected">
            <i class="cs-icon cs-icon-link" *ngIf="!isConnecting"></i>
            <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
            Connect
          </button>

          <button
            (click)="onDisconnect()"
            type="button"
            *ngIf="status?.connected"
            class="btn btn-secondary-danger action-btn continue-btn ml-3"
          >
            <i class="fal fa-unlink"></i> Disconnect
          </button>

          <button class="btn btn-secondary-info" (click)="next.emit()" *ngIf="status?.connected">
            Continue
            <i class="cs-icon cs-icon-arrow-right-long text-xs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/gvr/step1.webp" alt="" class="w-100" />
  </div>
</div>
