export const DEFAULT_NEW_FOLDER_NAME = 'form.label.newGroup'

export enum ResourceGroupType {
  Template = 'template',
  Document = 'document',
}
export interface ResourceGroup {
  id: string
  group_id: string
  account: string
  name: string
  type: ResourceGroupType
  access: string[] // user ids
  // An epoch timestamp with the date and time the offer was created.
  created: number
  updated: number
  attributes: any
}
