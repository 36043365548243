import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import {
  Channel,
  ChannelNameTokens,
  DataCheckerService,
  isSomething,
  RateMappingResponse,
  Rental,
  selectRentalEntities,
  selectRentalTags,
  Toaster,
  untilDestroy,
  updateRateMappings,
  toggleRateMappingsSync,
} from '@tokeet-frontend/tv3-platform'
import { MatDialogRef } from '@angular/material/dialog'
import { FormBuilder } from '@angular/forms'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs'
import { catchError, concatMap, finalize, map, take, tap, toArray } from 'rxjs/operators'
import { MatSort } from '@angular/material/sort'
import { ActionFailed, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { ConnectionGuard } from '@tv3/guards/connection.guard'
import { selectAllAPIConnectionViews, selectConnectionsLoaded } from '@tv3/store/connection/connection.selectors'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { HttpErrorResponse } from '@angular/common/http'
import { LoadConnections, LoadConnectionsComplete } from '@tv3/store/connection/connection.actions'
import { RentalRatesDialogTab } from '../../rental-rates-dialog/rental-rates-dialog.component'
import { RentalRatesDialogService } from '../../rental-rates-dialog/rental-rates-dialog.service'

interface PushConnectionRateItem {
  id: string
  channel: Channel
  rental: Rental
  connection: ConnectionView
  status?: 'requesting' | 'scheduled' | 'done' | 'error'
  errorText?: string

  rentalName: string
  rentalAddress: string
  channelName: string
  propertyName: string
  frequencyView: string

  rateMappings: RateMappingResponse[]
  implicitRateMappings: RateMappingResponse[]

  isRateMappingImplicit: boolean
  isLinked: boolean
  isPushRatesEnabled: boolean
  isPushAvailabilityEnabled: boolean
  isRentalBaseRateReady: boolean
  isDoable: boolean
}

const implicitRateMappingChannelsName = [ChannelNameTokens.AirBnBV2API]
const ratesPushEnabledChannelsName = [
  ChannelNameTokens.ExpediaAPI,
  ChannelNameTokens.AgodaAPI,
  ChannelNameTokens.BookingAPI,
  ChannelNameTokens.AirBnBV2API,
  ChannelNameTokens.Tiket,
  ChannelNameTokens.Trip,
]

@Component({
  selector: 'app-channel-rate-mappings-table',
  templateUrl: './channel-rate-mappings-table.component.html',
  styleUrls: ['./channel-rate-mappings-table.component.scss'],
})
export class ChannelRateMappingsTableComponent extends SelectableRow<PushConnectionRateItem> implements OnInit {
  @Input() showActions = false

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  isEmptyTable$ = isEmptyTable(this.dataSource)
  isLoading$ = this.store.pipe(
    select(selectConnectionsLoaded),
    map((loaded) => !loaded)
  )

  filters = this.fb.group({
    channels: [],
    rentals: [],
    tags: [],
    taggedIds: [],
  })

  rentalTags$ = this.store.pipe(select(selectRentalTags))

  items: PushConnectionRateItem[] = []

  displayedColumns = ['select', 'channelName', 'rentalName', 'propertyName', 'status', 'expandCtrl'] // 'frequencyView'
  expandedRowItem: PushConnectionRateItem
  isExpandableRow = (i: number, row: PushConnectionRateItem) => {
    return row.isLinked && row.isRentalBaseRateReady
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private actions$: Actions,
    private toaster: Toaster,
    private dialogRef: MatDialogRef<ChannelRateMappingsTableComponent>,
    private dataCheckerService: DataCheckerService,
    private connectionService: ConnectionService,
    private connectionHelperService: ConnectionHelperService,
    private rentalRatesDialog: RentalRatesDialogService
  ) {
    super()
    this.dataCheckerService.check([ChannelGuard, ConnectionGuard])
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    combineLatest([
      this.store.pipe(select(selectRentalEntities)),
      this.store.pipe(
        select(selectAllAPIConnectionViews),
        map((items) => R.filter((c) => !!c.rentalId, items)), // linked
        map((items) => R.filter((c) => ratesPushEnabledChannelsName.includes(c.channel?.name), items)) // enabled
      ),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([rentalsById, connections]) => {
        const items = lodash.map(connections, (conn): PushConnectionRateItem => {
          const rental = rentalsById[conn.rentalId]
          const channel = conn.channel
          const isLinked = !!rental
          const isRateMappingImplicit = implicitRateMappingChannelsName.includes(channel?.name)
          const isPushRatesEnabled = isLinked && (rental?.rateMap?.length > 0 || isRateMappingImplicit)
          const isPushAvailabilityEnabled = isLinked

          const rateMappings: RateMappingResponse[] = lodash.filter(rental?.rateMap, (r) => {
            return r.api === channel.name && r.propertyId == conn.propertyId && r.roomId == conn.roomId
          })
          let implicitRateMappings: RateMappingResponse[] = []
          if (implicitRateMappingChannelsName.includes(channel.name)) {
            implicitRateMappings = this.getImplicitRateMappings(conn, channel)
          }

          return {
            id: conn.id,
            rental: rental,
            channel: channel,
            connection: conn,
            rentalName: rental?.name || 'N/A',
            rentalAddress: rental?.address?.country || 'N/A',
            propertyName: conn.roomName,
            channelName: channel?.friendlyName,
            frequencyView: lodash.compact(lodash.map(rateMappings, (r) => r.frequency || '')).join(', '),
            rateMappings,
            implicitRateMappings,
            isLinked,
            isRateMappingImplicit,
            isPushRatesEnabled,
            isPushAvailabilityEnabled,
            isRentalBaseRateReady: this.isRentalBaseRateReady(rental),
            isDoable: isRateMappingImplicit ? isLinked : isPushAvailabilityEnabled && isPushRatesEnabled,
          }
        })

        this.items = items
        this.filterItems()
      })

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.filterItems()
    })
  }

  get channels$() {
    return R.pipe(
      R.map((i: PushConnectionRateItem) => i.channel),
      R.uniq,
      R.sortBy((i: Channel) => i.friendlyName)
    )(this.items)
  }

  get rentals$() {
    return R.pipe(
      R.map((i: PushConnectionRateItem) => i.rental),
      R.uniq,
      R.sortBy((i: Rental) => i.name)
    )(this.items)
  }

  filterItems() {
    const filters = this.filters.getRawValue()
    this.dataSource.data = lodash
      .chain(this.items)
      .filter((c) => {
        if (!isSomething(filters.channels)) return true
        return lodash.includes(filters.channels, c.channel?.id)
      })
      .filter((c) => {
        if (!isSomething(filters.rentals)) return true
        return lodash.includes(filters.rentals, c.rental?.id)
      })
      .filter((c) => {
        if (!isSomething(filters.tags) && !isSomething(filters.taggedIds)) return true
        return (
          lodash.includes(filters.taggedIds, c.rental?.id) ||
          lodash.intersection(c.rental?.tags, filters.tags).length > 0
        )
      })
      .value()
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(LoadConnectionsComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(LoadConnections({ force: true }))
  }

  getImplicitRateMappings(connection: ConnectionView, channel: Channel): RateMappingResponse[] {
    if (!implicitRateMappingChannelsName.includes(channel.name)) {
      return []
    }
    return [
      {
        category: 'default',
        rateid: connection.propertyId as string,
        roomId: connection.roomId as number,
        pricemodel: null,
        maxguest: null,
        maxPersons: null,
        propertyId: connection.propertyId as number,
        rental_id: connection.rentalId,
        channel_id: connection.channelId,
        api: channel.name,
      } as RateMappingResponse,
    ]
  }

  isRowSelectable(item: PushConnectionRateItem) {
    return item.isPushRatesEnabled && item.isPushAvailabilityEnabled
  }

  isRentalBaseRateReady(rental: Rental) {
    const baseRate = rental?.baseRate
    return baseRate && baseRate.nightly > 0
  }

  getItemWarningInfo(item: PushConnectionRateItem) {
    if (!item.isLinked) {
      return 'This property should be linked to a AdvanceCM rental.'
    }
    if (!item.isRentalBaseRateReady) {
      return `A Base Rate must be set in order to properly push rates to your connected channels. Please click rental and enter the required values.`
    }
    if (item.isRateMappingImplicit) {
      return
    }
    if (!item.isPushRatesEnabled) {
      return `Please create rate mappings for this property within AdvanceCM. Rate mappings added in Rategenie will not be considered.`
    }
  }

  onRentalClick(item: PushConnectionRateItem) {
    if (!item.rental) {
      return
    }
    this.rentalRatesDialog.openSide({ rental: item.rental, tab: RentalRatesDialogTab.Settings })
  }

  onExpandItem(item: PushConnectionRateItem) {
    if (!this.isExpandableRow(0, item)) {
      return
    }
    this.expandedRowItem = this.expandedRowItem?.id === item.id ? null : item
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  close() {
    this.dialogRef.close()
  }

  resetRequestStatusForAll() {
    const items = this.dataSource.data
    lodash.forEach(items, (item) => {
      item.status = undefined
    })
  }

  onLink(item: PushConnectionRateItem) {
    this.connectionHelperService.linkRental(item.channel?.name, item.connection).subscribe()
  }

  private pushRates(rateMappings: RateMappingResponse[]) {
    return of(...rateMappings).pipe(
      concatMap((rateMap) => {
        return this.connectionService.pushRates(rateMap.api as ChannelNameTokens, rateMap.channel_id, rateMap)
      }),
      toArray()
    )
  }

  onPushRate() {
    const items = this.getSelected()
    if (!items.length) {
      this.toaster.warning("You don't select any rental.")
      return
    }
    this.resetRequestStatusForAll()
    of(...items)
      .pipe(
        concatMap((item) => {
          item.status = 'requesting'
          return this.pushRates(isSomething(item.rateMappings) ? item.rateMappings : item.implicitRateMappings).pipe(
            tap(() => {
              item.status = 'done'
            }),
            catchError((res: HttpErrorResponse) => {
              this.store.dispatch(ActionFailed({ error: res }))
              item.status = 'error'
              item.errorText = res?.error?.error || ''
              return EMPTY
            })
          )
        }),
        toArray()
      )
      .subscribe(() => {
        this.toaster.success(
          'All rental rates are pushed, please check status column to know the result for each rental.'
        )
      })
  }

  onPushAvailability() {
    const items = this.getSelected()
    if (!items.length) {
      this.toaster.warning("You don't select any rental.")
      return
    }
    this.resetRequestStatusForAll()
    of(...items)
      .pipe(
        concatMap((item) => {
          const conn = item.connection?.connection
          const channel = item.channel
          item.status = 'requesting'
          return this.connectionService.pushAvailability(channel.name, conn.channelId, conn).pipe(
            tap(() => {
              item.status = 'done'
            }),
            catchError((res: HttpErrorResponse) => {
              if (res?.status !== 424) {
                this.store.dispatch(ActionFailed({ error: res }))
                item.status = 'error'
                item.errorText = res?.error?.error || ''
              } else {
                item.status = 'done'
              }
              return EMPTY
            })
          )
        }),
        toArray()
      )
      .subscribe(() => {
        this.toaster.success(
          'All rentals availability are pushed, please check status column to know the result for each rental.'
        )
      })
  }

  // onSetFrequency(frequency: 'daily' | 'weekly') {
  //   const items = lodash.flatten(this.getSelected('rateMappings'))
  //   if (!items.length) return
  //   this.store.dispatch(
  //     updateRateMappings({
  //       items: items.map((t) => ({ rentalId: t.rental_id, mappingId: t.key, payload: { frequency } })),
  //     })
  //   )
  // }

  onEnableAutoSync() {
    const items = lodash.flatten(this.getSelected('rateMappings'))
    if (!items.length) return
    this.store.dispatch(
      toggleRateMappingsSync({
        items: items.map((t) => ({
          rentalId: t.rental_id,
          mappingId: t.key,
          enabled: true,
        })),
      })
    )
  }

  onDisableAutoSync() {
    const items = lodash.flatten(this.getSelected('rateMappings'))
    if (!items.length) return
    this.store.dispatch(
      toggleRateMappingsSync({
        items: items.map((t) => ({
          rentalId: t.rental_id,
          mappingId: t.key,
          enabled: false,
        })),
      })
    )
  }
}
