<div class="modal-header">
  <h5 class="modal-title d-flex gap-2 align-items-center">
    <i class="cs-icon cs-icon-calculator"></i> Cost Estimator
    <app-page-help videoId="-" articleId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body bg-none p-0">
  <div class="row" [formGroup]="form">
    <div class="col-md-6">
      <app-rental-select [ctrl]="form.get('rentalId')" [multiple]="false" placeholder="Rental"> </app-rental-select>
    </div>
    <div class="col-md-6">
      <app-select-search
        [items]="channels$ | async"
        [ctrl]="form.get('channelId')"
        [multiple]="false"
        bindValue="channelId"
        bindLabel="label"
        placeholder="Channel"
        [inline]="false"
      >
      </app-select-search>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input
              matInput
              [matDatepicker]="arriveDate"
              placeholder="Arrive"
              [max]="maxStartDate"
              [required]="true"
              (focus)="arriveDate.open()"
              formControlName="arrive"
              readonly
            />
            <i matSuffix (click)="arriveDate.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #arriveDate></mat-datepicker>
            <mat-error *ngIf="form.get('arrive').touched && form.get('arrive').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input
              matInput
              [matDatepicker]="departDate"
              placeholder="Depart"
              [required]="true"
              [min]="minEndDate"
              (focus)="departDate.open()"
              formControlName="depart"
              readonly
            />
            <i matSuffix (click)="departDate.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #departDate></mat-datepicker>
            <mat-error *ngIf="form.get('depart').touched && form.get('depart').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="number" min="1" step="1" formControlName="adults" matInput required placeholder="Adults" />
            <mat-error *ngIf="form.get('adults').touched && form.get('adults').hasError('required')">
              Adults field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('adults').hasError('pattern')">
              Adults field must be a <strong>whole number</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="number" min="0" step="1" formControlName="children" matInput placeholder="Children" />
            <mat-error *ngIf="form.get('children').touched && form.get('children').hasError('pattern')">
              Children field must be a <strong>whole number</strong>
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input type="number" [formControl]="baseChargeCtrl" matInput placeholder="Base Charge" />
      </mat-form-field2>
    </div>
    <div class="col-md-3">
      <app-select-search
        [items]="rateCategories"
        [ctrl]="form.get('category')"
        [multiple]="false"
        placeholder="Rate Category"
        [inline]="false"
      >
      </app-select-search>
    </div>

    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <input type="text" [formControl]="form.get('discount_code')" matInput placeholder="Discount Code" />
      </mat-form-field2>
    </div>

    <div *ngIf="estimatedCost.value?.extraGuests as extraguests" class="col-sm-6">
      <app-form-input
        label="Additional Guests Fee"
        [labelHint]="estimatedCost.value?.currency"
        [value]="extraguests"
        [disabled]="true"
      ></app-form-input>
    </div>

    <div *ngIf="estimatedCost.value?.auditDiscounts as auditDiscounts" class="col-sm-6">
      <div class="row" *ngFor="let item of auditDiscounts">
        <div class="col-md-12 discount-holder">
          <app-form-input
            label="{{ item.disc_type === 'lengthofstay' ? 'Los Discount' : 'Discount Code' }}"
            [labelHint]="estimatedCost.value?.currency"
            [value]="item.disc_amount"
            [disabled]="true"
          >
            <ng-container *ngIf="item.disc_type === 'lengthofstay'">
              <form-field-affix *fieldSuffix>
                <a (click)="onViewDiscount(item)" matTooltip="View Discount">
                  <i class="fas fa-eye"></i>
                </a>
              </form-field-affix>
            </ng-container>
          </app-form-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="estimatedCost.value?.taxes as taxes">
    <div *ngFor="let item of taxes">
      <div class="row">
        <div class="col-md-3">
          <app-form-input label="Tax Name" [value]="item.name" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Tax Rate"
            labelHint="{{ item.type === 'flat' ? estimatedCost.value?.currency : '%' }}"
            [value]="item.rate"
            [disabled]="true"
          ></app-form-input>
        </div>
        <div class="col-md-3 pl-0" [matTooltip]="modalityLabels[item.modality]">
          <app-form-input label="Modality" [value]="modalityLabels[item.modality]" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Tax Amount"
            [labelHint]="estimatedCost.value?.currency"
            [value]="item.amount"
            [disabled]="true"
          >
          </app-form-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="estimatedCost.value?.bookingFees as fees">
    <div *ngFor="let item of fees">
      <div class="row">
        <div class="col-md-3">
          <app-form-input label="Fee Name" [value]="item.name" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Fee Rate"
            labelHint="{{ item.type === 'flat' ? estimatedCost.value?.currency : '%' }}"
            [value]="item.rate"
            [disabled]="true"
          ></app-form-input>
        </div>
        <div class="col-md-3 pl-0" [matTooltip]="modalityLabels[item.modality]">
          <app-form-input label="Modality" [value]="modalityLabels[item.modality]" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Fee Amount"
            [labelHint]="estimatedCost.value?.currency"
            [value]="item.amount"
            [disabled]="true"
          >
          </app-form-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="potentialFees?.length">
    <h6>These booking fees may apply</h6>
    <div *ngFor="let item of potentialFees">
      <div class="row">
        <div class="col-md-3">
          <app-form-input label="Fee Name" [value]="item.name" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Fee Rate"
            labelHint="{{ item.type === 'flat' ? estimatedCost.value?.currency : '%' }}"
            [value]="item.amount"
            [disabled]="true"
          ></app-form-input>
        </div>
        <div class="col-md-3 pl-0" [matTooltip]="modalityLabels[item.modality]">
          <app-form-input label="Modality" [value]="modalityLabels[item.modality]" [disabled]="true"></app-form-input>
        </div>
        <div class="col-md-3 pl-0">
          <app-form-input
            label="Fee Amount"
            labelHint="{{ item.type === 'flat' ? estimatedCost.value?.currency : '%' }}"
            [value]="item.amount"
            [disabled]="true"
          ></app-form-input>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer border-top">
  <div *ngIf="estimatedCost.value as cost" class="text-success text-xxxl">
    {{ cost.total | currency : ' ' : 'code' : '.0-2' }}
  </div>
  <div class="flex-fill"></div>

  <button type="button" class="btn btn-secondary-primary" (click)="close()">Done <i class="fas fa-check"></i></button>
</div>
