<div class="modal-header">
  <h5 class="modal-title"><i class="fas fa-share-alt"></i> {{ isEdit ? 'Edit Rate Mapping' : 'Add Rate Mapping' }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body bg-none p-0">
  <div class="alert alert-secondary-info">
    Please select a AdvanceCM rate category then a channel rate category to map. The selected AdvanceCM rate category
    will be pushed to the selected channel rate category during automatic and manual rate pushes.
  </div>
  <form [formGroup]="form" (submit)="onSubmit(form)">
    <div class="container-box p-3">
      <div class="row">
        <div class="col-6">
          <app-rental-select [ctrl]="form.get('rentalId')" [multiple]="false" placeholder="Select Rental">
          </app-rental-select>
        </div>

        <div class="col-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="category" [required]="true" placeholder="AdvanceCM rate category">
              <mat-option *ngFor="let t of categories" [value]="t.id">{{ t.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('category').touched && form.get('category').hasError('required')">
              Category is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-6">
          <mat-form-field2 class="mat-block">
            <mat-select
              formControlName="propertyId"
              [required]="true"
              placeholder="Select Channel"
              (selectionChange)="onChannelSelected()"
            >
              <mat-option *ngFor="let t of connections" [value]="t.propertyId">{{ t.channelFriendlyName }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('propertyId').touched && form.get('propertyId').hasError('required')">
              Channel is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>

        <div class="col-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="rateid" [required]="true" placeholder="Channel rate category">
              <mat-option *ngFor="let t of getRoomRateOptions()" [value]="t.id">{{ t.name }}</mat-option>
            </mat-select>
            <span *ngIf="loadingRoomRates[selectedChannelId]" matSuffix class="fas fa-spinner fa-spin"></span>
            <mat-error *ngIf="form.get('rateid').touched && form.get('rateid').hasError('required')">
              Channel rate category is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
      <div *ngIf="selectedConnection && getRoomRateOptions(); let rates">
        <div class="alert alert-secondary-warning" *ngIf="!rates?.length">
          No channel rate categories available for {{ selectedConnection.channelFriendlyName }}.
        </div>
      </div>
    </div>
    <!--    <div class="container-box p-3 mt-3">-->
    <!--      <h6>Frequency Options</h6>-->
    <!--      <div class="row">-->
    <!--        <div class="col-6">-->
    <!--          <mat-form-field2 class="mat-block">-->
    <!--            <mat-select formControlName="frequency" placeholder="Frequency" required>-->
    <!--              <mat-option *ngFor="let item of frequencyOptions" [value]="item.id">{{ item.label }}</mat-option>-->
    <!--            </mat-select>-->
    <!--            <mat-error *ngIf="form.get('frequency').touched && form.get('frequency').hasError('required')">-->
    <!--              Frequency is <strong>required</strong>-->
    <!--            </mat-error>-->
    <!--          </mat-form-field2>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light" tabindex="202">
    <i class="far fa-times"></i> Cancel
  </button>
  <button
    (click)="onSubmit(form)"
    [disabled]="form.disabled || (isSavingRateMapping$ | async)"
    type="button"
    class="btn btn-secondary-info"
  >
    <i *ngIf="!(isSavingRateMapping$ | async)" class="cs-icon cs-icon-save"></i>
    <i *ngIf="isSavingRateMapping$ | async" class="far fa-spinner fa-spin"></i>
    Save
  </button>
</div>
