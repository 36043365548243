<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center gap-1">
    <i class="{{ isGroupRate ? 'fas fa-hands-usd' : 'far fa-calculator' }}"></i> {{ dialogTitle }}
    <app-page-help categoryId="-" videoId="-" articleId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body" #modalBody>
  <form [formGroup]="form" (submit)="onSubmit(form)" [formEditablePermission]="form">
    <div class="row" *ngIf="!isGroupRate && !data.rental && data.rentals">
      <div class="col-sm-6">
        <app-rental-select
          [multiple]="false"
          [items]="data.rentals"
          [ctrl]="form.get('rentalId')"
          placeholder="Select Rental"
        >
        </app-rental-select>
      </div>
    </div>

    <div class="row" *ngIf="isGroupRate && data.rentals">
      <div class="col-sm-6">
        <app-rental-select
          [items]="data.rentals"
          [ctrl]="form.get('rentalIds')"
          placeholder="Select Rentals"
          matTooltip="Select the rentals that this rate will be applied to. Group Rates are applied to multiple rentals at once."
        >
        </app-rental-select>
      </div>
    </div>

    <h5>{{ isGroupRate ? 'GROUP' : 'RENTAL' }} RATE DETAILS</h5>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required formControlName="name" tabindex="2" placeholder="{{ textRate }} Name" />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            {{ textRate }} Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-sm-6">
        <mat-form-field2
          *ngIf="!isPromotionalRate"
          class="mat-block"
          matTooltip="Rate categories are used to group multiple rates together. Rates in a Rate Category cannot overlap. When updating a channel you push a single rate category. All rates in that category will be pushed to the channel."
          matTooltipPosition="right"
        >
          <input
            type="text"
            matInput
            [matAutocomplete]="auto"
            required
            placeholder="Rate Category"
            formControlName="category"
            tabindex="3"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let category of filteredCategories$ | async" [value]="category">{{
              category
            }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('category').touched && form.get('category').hasError('required')">
            Category is <strong>required</strong>
          </mat-error>
        </mat-form-field2>

        <app-select-search
          *ngIf="isPromotionalRate"
          class="mat-block"
          [items]="categories"
          [ctrl]="form.get('categories')"
          matTooltip="Please select at least one rate category to apply this promotional rate."
          matTooltipPosition="above"
          placeholder="Apply to categories"
          [inline]="false"
        >
          <mat-error
            class="error-container"
            *ngIf="form.get('categories').touched && form.get('categories').hasError('required')"
          >
            Category is <strong>required</strong>
          </mat-error>
        </app-select-search>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input
            matInput
            [matDatepicker]="startPicker"
            [min]="minDateDefault"
            [max]="form.get('end').value || maxDateDefault"
            placeholder="From:"
            [required]="true"
            (focus)="startPicker.open()"
            formControlName="start"
            tabindex="4"
            readonly
          />
          <i matSuffix (click)="startPicker.open()" class="far fa-calendar-alt"></i>
          <mat-datepicker #startPicker></mat-datepicker>
          <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input
            matInput
            [matDatepicker]="endPicker"
            [min]="form.get('start').value || minDateDefault"
            [max]="maxDateDefault"
            placeholder="Until:"
            [required]="true"
            (focus)="endPicker.open()"
            formControlName="end"
            tabindex="5"
            readonly
          />
          <i matSuffix (click)="endPicker.open()" class="far fa-calendar-alt"></i>
          <mat-datepicker #endPicker></mat-datepicker>
          <mat-error *ngIf="form.get('end').touched && form.get('end').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('end').hasError('matDatepickerMin')">
            Min value is <strong>{{ form.get('end').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>

    <ng-container *ngIf="rateType === 'standard' || rateType === 'promotion'">
      <h5>ENTER FEES BELOW. PLEASE USE NUMBERS ONLY.</h5>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field2 class="mat-block">
            <input
              type="number"
              min="0"
              matInput
              placeholder="Nightly {{ currencySymbol }}"
              formControlName="nightly"
              tabindex="11"
              (change)="roundDecimal('nightly')"
            />
          </mat-form-field2>
        </div>

        <div class="col-sm-4">
          <mat-form-field2 class="mat-block">
            <input
              matTooltip="The weekly value entered here will apply only to bookings created manually in AdvanceCM, or on an AdvanceCM Website or Booking Engine."
              matTooltipPosition="above"
              type="number"
              min="0"
              matInput
              placeholder="Weekly {{ currencySymbol }}"
              formControlName="weekly"
              tabindex="12"
              (change)="roundDecimal('weekly')"
            />
          </mat-form-field2>
        </div>

        <div class="col-sm-4">
          <mat-form-field2 class="mat-block">
            <input
              matTooltip="The monthly value entered here will apply only to bookings created manually in AdvanceCM, or on an AdvanceCM Website or Booking Engine."
              matTooltipPosition="above"
              type="number"
              min="0"
              matInput
              placeholder="Monthly {{ currencySymbol }}"
              formControlName="monthly"
              tabindex="13"
              (change)="roundDecimal('monthly')"
            />
          </mat-form-field2>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="rateType === 'dynamic'">
      <div class="title-outer">
        <h5
          matTooltip="A Dynamic Rate is a nightly price range for your rental which increases as your rental's occupancy increases.
          Your rental will be set to the low rate until the low occupnacy level is reached.
          AdvanceCM will then increase your rate towards the high rate until the high occupancy level is reached.
          The rate will be set at the high rate for occupancy levels above the high occupancy level threshold."
          matTooltipPosition="above"
        >
          CONFIGURE DYNAMIC RATE BELOW. PLEASE USE NUMBERS ONLY. <i class="fas fa-info-circle"></i>
        </h5>
      </div>

      <div class="d-flex align-items-center gap-2">
        <mat-form-field2
          class="mat-block"
          matTooltip="The lowest nightly rate that can be set."
          matTooltipPosition="above"
        >
          <input
            type="number"
            min="0"
            matInput
            required
            placeholder="Low Rate {{ currencySymbol }}"
            formControlName="lowRate"
            tabindex="11"
            (change)="roundDecimal('lowRate')"
          />
          <mat-error *ngIf="form.get('lowRate').touched && form.get('lowRate').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2
          class="mat-block"
          matTooltip="The highest nightly rate that can be set."
          matTooltipPosition="above"
        >
          <input
            type="number"
            min="0"
            matInput
            required
            placeholder="High Rate {{ currencySymbol }}"
            formControlName="highRate"
            tabindex="12"
            (change)="roundDecimal('highRate')"
          />
          <mat-error *ngIf="form.get('highRate').touched && form.get('highRate').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2
          class="mat-block"
          matTooltip="The minimum occupancy level before increasing the Low Rate."
          matTooltipPosition="above"
        >
          <input
            type="number"
            min="0"
            matInput
            required
            placeholder="Low Occ (%)"
            formControlName="lowThreshold"
            tabindex="13"
            (change)="roundDecimal('lowThreshold')"
          />
          <mat-error *ngIf="form.get('lowThreshold').touched && form.get('lowThreshold').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2
          class="mat-block"
          matTooltip="The occupancy level at which the High Rate is reached."
          matTooltipPosition="above"
        >
          <input
            type="number"
            min="0"
            matInput
            required
            placeholder="High Occ (%)"
            formControlName="highThreshold"
            tabindex="14"
            (change)="roundDecimal('highThreshold')"
          />
          <mat-error *ngIf="form.get('highThreshold').touched && form.get('highThreshold').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2
          class="mat-block"
          matTooltip="The interval used for calculating occupancy and setting rates."
          matTooltipPosition="above"
        >
          <mat-select required placeholder="Rate interval" formControlName="thresholdType" [tabIndex]="15">
            <mat-option value="weekly">Weekly</mat-option>
            <mat-option value="monthly">Monthly</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('thresholdType').touched && form.get('thresholdType').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            min="1"
            step="1"
            matInput
            placeholder="Minimum Stay (nights)"
            formControlName="minimumStay"
            tabindex="31"
            (change)="roundInteger('minimumStay')"
          />
          <mat-error *ngIf="form.get('minimumStay').touched && form.get('minimumStay').hasError('min')">
            Value must be <strong>at least 1</strong>
          </mat-error>
          <mat-error *ngIf="form.get('minimumStay').touched && form.get('minimumStay').hasError('digits')">
            Value is invalid
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            min="1"
            step="1"
            matInput
            placeholder="Maximum Stay (nights)"
            formControlName="maximumStay"
            tabindex="32"
            (change)="roundInteger('maximumStay')"
          />
          <mat-error *ngIf="form.get('maximumStay').touched && form.get('maximumStay').hasError('min')">
            Value must be <strong>at least 1</strong>
          </mat-error>
          <mat-error *ngIf="form.get('maximumStay').touched && form.get('maximumStay').hasError('digits')">
            Value is invalid
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            min="1"
            step="1"
            matInput
            placeholder="Maximum Guests"
            formControlName="maximumGuests"
            tabindex="33"
            (change)="roundInteger('maximumGuests')"
          />
          <mat-error *ngIf="form.get('maximumGuests').touched && form.get('maximumGuests').hasError('min')">
            Value must be <strong>at least 1</strong>
          </mat-error>
          <mat-error *ngIf="form.get('maximumGuests').touched && form.get('maximumGuests').hasError('digits')">
            Value is invalid
          </mat-error>
        </mat-form-field2>
      </div>
    </div>

    <div class="title-outer">
      <h5
        class="clickable"
        (click)="groupCollapsed.additionalGuest = !groupCollapsed.additionalGuest"
        matTooltip="Use this option if you charge for each guest over a certain number of guests. For example, you may allow up to 4 guests in your rental but your rates are set for 2 guests and every guest over 2 pays an additional fee. This is not supported by every channel."
        matTooltipPosition="above"
      >
        ADDITIONAL GUEST FEE <i class="fas {{ groupCollapsed.additionalGuest ? 'fa-plus' : 'fa-minus' }}"></i>
      </h5>
    </div>
    <h6>Increase your rates based on the number of guests.</h6>

    <div class="row" [hidden]="groupCollapsed.additionalGuest">
      <div class="col-md-8">
        <div class="row">
          <div class="col-6">
            <mat-form-field2 class="mat-block" matTooltip="Use a fixed additional fee." matTooltipPosition="above">
              <input
                type="number"
                matInput
                placeholder="Fee {{ currencySymbol }}"
                formControlName="additionalFeeAmount"
                min="0"
                tabindex="41"
                (change)="roundDecimal('additionalFeeAmount')"
              />
              <mat-hint>per person, per night</mat-hint>
            </mat-form-field2>
          </div>
          <div class="col-6" *ngIf="rateType === 'dynamic'">
            <mat-form-field2
              class="mat-block"
              matTooltip="Use a percentage of the dynamic rate as your additional fee."
              matTooltipPosition="above"
            >
              <input
                type="number"
                matInput
                placeholder="or Fee (%)"
                formControlName="additionalFeePercent"
                min="0"
                tabindex="42"
                (change)="roundDecimal('additionalFeePercent')"
              />
              <mat-hint>per person, per night</mat-hint>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            matInput
            placeholder="Guest Count"
            formControlName="additionalFeeThreshold"
            min="0"
            step="1"
            tabindex="43"
            (change)="roundInteger('additionalFeeThreshold')"
          />
          <mat-error
            *ngIf="form.get('additionalFeeThreshold').touched && form.get('additionalFeeThreshold').hasError('digits')"
          >
            Value is invalid
          </mat-error>
          <mat-hint>above this many guests</mat-hint>
        </mat-form-field2>
      </div>
    </div>

    <div class="hr"></div>

    <div class="title-outer">
      <h5
        class="clickable"
        (click)="groupCollapsed.daySpecifics = !groupCollapsed.daySpecifics"
        matTooltip="Use this option to override the above rates for specific days of the week. For example, if you want a special rate for Wednesdays while every other day uses the above rates."
        matTooltipPosition="above"
      >
        DAY SPECIFIC PRICING <i class="fas {{ groupCollapsed.daySpecifics ? 'fa-plus' : 'fa-minus' }}"></i>
      </h5>
    </div>
    <h6>Set rates for specific days in this rate period. They will override the above rates.</h6>

    <ng-container formArrayName="daySpecifics" *ngFor="let item of daySpecificsControl.controls; let index = index">
      <div
        class="row-day-specific gap-2"
        [ngClass]="groupCollapsed.daySpecifics ? 'd-none' : 'd-flex'"
        [formGroupName]="index"
      >
        <button
          type="button"
          (click)="onChangeDaySpecificItem(index)"
          class="btn btn-circle align-input-row-end"
          [class]="index === 0 ? 'btn-secondary-primary' : 'btn-secondary-danger'"
          [disabled]="index === 0 && daySpecificsControl.controls.length === specificDaysLimit"
        >
          <i [class]="index === 0 ? 'fas fa-plus' : 'cs-icon cs-icon-trash'"></i>
        </button>
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            matInput
            placeholder="Nightly {{ currencySymbol }}"
            formControlName="price"
            min="0"
            [attr.tabindex]="50 + index * 4 + 1"
            (change)="roundDecimal('price', index)"
          />
        </mat-form-field2>
        <mat-form-field2
          class="mat-block"
          matTooltip="Additional guests fee, per person / per night."
          matTooltipPosition="above"
        >
          <input
            type="number"
            matInput
            placeholder="PP / PN {{ currencySymbol }}"
            formControlName="thresholdFee"
            min="0"
            [attr.tabindex]="50 + index * 4 + 2"
            (change)="roundDecimal('thresholdFee', index)"
          />
        </mat-form-field2>
        <mat-form-field2 class="mat-block" matTooltip="Additional guests fee threshold." matTooltipPosition="above">
          <input
            type="number"
            matInput
            placeholder="Guest Count"
            formControlName="threshold"
            min="0"
            step="1"
            [attr.tabindex]="50 + index * 4 + 3"
            (change)="roundInteger('threshold', index)"
          />
          <mat-error *ngIf="item.get('threshold').touched && item.get('threshold').hasError('digits')">
            Value is invalid
          </mat-error>
        </mat-form-field2>
        <mat-form-field2 class="mat-block">
          <mat-select placeholder="Apply to days" formControlName="days" multiple [tabIndex]="50 + index * 4 + 4">
            <mat-option *ngFor="let day of specificDays" [value]="day.id" [disabled]="isDaySelected(index, day.id)">{{
              day.name
            }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light" tabindex="202">
    <i class="far fa-times"></i> Cancel
  </button>
  <button
    (click)="onSubmit(form)"
    [disabled]="form.disabled || (1 | isReadonlyRole)"
    type="button"
    class="btn btn-secondary-info create-btn"
    tabindex="201"
  >
    <i *ngIf="!form.disabled" class="fal {{ isEdit ? 'fa-save' : 'fa-plus' }}"></i>
    <i *ngIf="form.disabled" class="far fa-spinner fa-spin"></i>
    {{ isEdit ? 'Save' : 'Create' }}
  </button>
</div>
