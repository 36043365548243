export enum TemplateTypes {
  Contract = 'contract',
  Document = 'document',
  Form = 'form',
}

export const templateTypeOptions = [
  { value: TemplateTypes.Contract, label: 'Contract' },
  { value: TemplateTypes.Document, label: 'Printout' },
  { value: TemplateTypes.Form, label: 'Form' },
]
