import { Injectable } from '@angular/core'
import { DeclineConfirmComponent } from './decline-confirm.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class DeclineConfirmDialogService {
  constructor(private dialog: DialogService) {}

  public open() {
    return this.dialog.openFixedDialog(DeclineConfirmComponent, {
      width: '500px',
    })
  }
}
