<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-money-check"></i> Update Booking Details</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <app-rental-select
      [multiple]="false"
      [ctrl]="form.get('rentalId')"
      placeholder="Rentals"
      class="max-mat"
    ></app-rental-select>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="adults" placeholder="Adults:">
            <mat-option *ngFor="let option of adultsOptions" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>

      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="children" placeholder="Children:">
            <mat-option *ngFor="let option of childrenOptions" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input
            matInput
            placeholder="Arrive:"
            [matDatepicker]="guestArrivePicker"
            [required]="true"
            [min]="minDateDefault"
            [max]="maxArriveDate.value || maxDateDefault"
            (focus)="guestArrivePicker.open()"
            formControlName="guestArrive"
            readonly
          />
          <i matSuffix (click)="guestArrivePicker.open()" class="cs-icon cs-icon-calendar"></i>
          <mat-datepicker #guestArrivePicker></mat-datepicker>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input
            matInput
            placeholder="Depart:"
            [matDatepicker]="guestDepartPicker"
            [min]="minDepartDate.value || minDateDefault"
            [max]="maxDateDefault"
            [required]="true"
            (focus)="guestDepartPicker.open()"
            formControlName="guestDepart"
            readonly
          />
          <i matSuffix (click)="guestDepartPicker.open()" class="cs-icon cs-icon-calendar"></i>
          <mat-datepicker #guestDepartPicker></mat-datepicker>
          <mat-hint align="end">
            {{ inquiry.guestDepart | dateDiff : inquiry.guestArrive }} Days,
            {{ inquiry.guestDepart | dateDiff : inquiry.guestArrive : 'nights' }} Nights
          </mat-hint>
          <mat-error *ngIf="form.get('guestDepart').hasError('matDatepickerMin')">
            Min value is
            <strong>{{ form.get('guestDepart').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>

    <div class="row">
      <div
        class="col-sm-6 check-container"
        [matTooltip]="
          form.get('checkIn').disabled
            ? 'Please set check-in/checkout-out times for current rental before you can edit check-in time'
            : ''
        "
        matTooltipPosition="above"
      >
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            matInput
            atp-time-picker
            changeToMinutes="true"
            formControlName="checkIn"
            placeholder="Check-in Time"
          />
          <span matSuffix><i class="fal fa-clock"></i></span>
        </mat-form-field2>
      </div>
      <div
        class="col-sm-6 check-container"
        [matTooltip]="
          form.get('checkOut').disabled
            ? 'Please set check-in/checkout-out times for current rental before you can edit check-out time'
            : ''
        "
        matTooltipPosition="above"
      >
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            matInput
            atp-time-picker
            changeToMinutes="true"
            formControlName="checkOut"
            placeholder="Check-out Time"
          />
          <span matSuffix><i class="fal fa-clock"></i></span>
        </mat-form-field2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <app-timezone-select
          matTooltip="Edit default timezone in rental settings."
          matTooltipPosition="above"
          [ctrl]="form.get('timezone')"
        ></app-timezone-select>
      </div>
      <div class="col-sm-6">
        <a
          href="javascript:;"
          matTooltip="Setting Rental Check-in/Check-out times on the rental instructions page will not update bookings already created or imported into AdvanceCM. Once these settings are in place newly created or imported bookings will use these times."
          matTooltipPosition="above"
          (click)="editRentalTimezone()"
          class="align-input-row-end d-block"
        >
          <i class="fa fa-edit"></i> Edit Rental Check-in/Check-out times
        </a>
      </div>
    </div>
    <div class="row">
      <div [class.disabled]="inquiry.sourceType !== sourceTypes.Tokeet" class="col-md-6">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="channel" placeholder="Source:" [required]="true">
            <mat-option *ngFor="let channel of channels$ | async" [value]="channel.id">{{ channel.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('channel').touched && form.get('channel').hasError('required')">
            Channel is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light">
    <i class="far fa-times"></i>
    Cancel
  </button>
  <button (click)="onSave(form)" [disabled]="isUpdatingBooking$ | async" type="button" class="btn btn-secondary-info">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
