<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 w-100">
        <div class="d-flex align-items-center gap-2">
          <h6 class="modal-title">Sign Document</h6>
          <div class="required-fields-label">
            <ng-container *ngIf="editor?.getUnfilledRequiredFieldsCount() as count">
              Required fields:
              <span class="bg-primary rounded-circle required-field-count">{{ count }}</span>
            </ng-container>
          </div>
        </div>
        <div class="flex-fill"></div>
        <button
          type="button"
          class="btn btn-secondary-light"
          [disabled]="isProcessing || editor?.isLoading"
          (click)="editor?.nextField()"
        >
          Next <i class="far fa-chevron-double-right"></i>
        </button>
        <button
          type="button"
          class="btn btn-secondary-danger"
          [disabled]="isProcessing || editor?.isLoading"
          (click)="editor?.declineContract()"
        >
          <i class="far fa-minus-circle"></i> Decline
        </button>
        <button
          type="button"
          class="btn btn-secondary-info"
          [disabled]="isProcessing || editor?.isLoading || !editor?.isFullFilled()"
          (click)="editor?.signContract()"
        >
          <i class="far fa-pen-alt"></i> Sign It
        </button>
      </div>
      <a (click)="close()" class="cstm-close" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <!-- /.modal-header -->
    <div class="modal-body">
      <app-sign-editor
        #editor
        [contract]="contract"
        [isProcessing]="isProcessing"
        (sign)="onSignContract($event)"
        (decline)="onDeclineContract()"
      ></app-sign-editor>
    </div>
  </div>
</div>
