import { CustomCode } from './custom-code.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<CustomCode> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<CustomCode> = createEntityAdapter<CustomCode>({
  sortComparer: false,
  selectId: (item) => item.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
