import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './gateways.reducer'
import { isSomething, selectAllUsers, User } from '@tokeet-frontend/tv3-platform'
import { PaymentGateway, PaymentGatewayView, PaymentGatewayServiceIdentities } from './gateways.model'
import { selectAllPaymentGatewayTypes } from '../types/gateway-type.selectors'
import { GatewaysState } from './gateways.state'
import { PaymentGatewayType } from '../types/gateway-type.model'

export const paymentGatewayState = createFeatureSelector<GatewaysState>('gateways')

export const selectAllPaymentGateways = createSelector(paymentGatewayState, reducer.selectGatewaysAll)

export const selectPaymentGatewaysLoaded = createSelector(paymentGatewayState, (s: GatewaysState) => s.isLoaded)

export const selectAllEnabledPaymentGateways = createSelector(selectAllPaymentGateways, (items) =>
  items?.filter((t) => !!t.status)
)

const toPaymentGatewayView = (gateway: PaymentGateway, types: PaymentGatewayType[], users) => {
  const paymentGatewaysType = types.find((pgt) => pgt.id === gateway.service)
  const owner: User = users.find((u) => u.id === gateway.owner_id)
  const creator: User = users.find((u) => u.id === gateway.creator)

  const pkeyParts = gateway.pkey.split('-')
  const identity =
    pkeyParts[pkeyParts.length - 1].length < 12 ? pkeyParts[pkeyParts.length - 2] : pkeyParts[pkeyParts.length - 1]

  return {
    ...gateway,
    type: paymentGatewaysType,
    gatewayName: paymentGatewaysType?.name,
    identity,
    ownerName: owner?.name,
    creatorName: creator?.name,
  } as PaymentGatewayView
}

export const selectAllPaymentGatewayViews = createSelector(
  selectAllPaymentGateways,
  selectAllPaymentGatewayTypes,
  selectAllUsers,
  (paymentGateways, paymentGatewaysTypes, users) => {
    return paymentGateways.map((paymentGateway) => {
      return toPaymentGatewayView(paymentGateway, paymentGatewaysTypes, users)
    })
  }
)

export const selectPaymentGatewayByService = (service: PaymentGatewayServiceIdentities) =>
  createSelector(selectAllPaymentGateways, (items) => {
    return items.filter((t) => t.service === service)
  })

export const selectPaymentGatewayViewById = (id) =>
  createSelector(
    selectAllPaymentGateways,
    selectAllPaymentGatewayTypes,
    selectAllUsers,
    (paymentGateways, paymentGatewaysTypes, users) => {
      const paymentGateway = paymentGateways.find((p) => p.pkey === id)
      return toPaymentGatewayView(paymentGateway, paymentGatewaysTypes, users)
    }
  )
