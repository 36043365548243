import { Directive } from '@angular/core'
import { SelectionModel } from '@angular/cdk/collections'
import * as R from 'ramda'
import { ManagedTable } from './managed-table'
import { MatTableDataSource } from '@angular/material/table'
import { IStorage } from '../storage'
import { isAllSelected, masterToggle } from './table-selection-utils'

@Directive()
export abstract class SelectableRow<T = any> extends ManagedTable {
  selection = new SelectionModel<T>(true, [])

  dataSource = new MatTableDataSource<T>([])

  renderedData: T[]

  selectableRowIdPropName: string

  constructor(protected storage?: IStorage, protected tableType?: string) {
    super(storage, tableType)
    this.dataSource['_renderData'].subscribe((rows) => {
      this.renderedData = rows
    })
    this.dataSource['_data'].subscribe(() => {
      this.selection.clear()
    })
  }

  protected isRowSelectable(row: T) {
    return true
  }

  getTableData(onlyRenderedData: boolean) {
    return (onlyRenderedData ? this.renderedData : this.dataSource.filteredData) || []
  }

  isAnySelected(filter?: (t: T) => boolean) {
    let selectedItems = R.filter(this.isRowSelectable, this.selection.selected)
    selectedItems = filter ? selectedItems.filter(filter) : selectedItems
    return !R.isEmpty(selectedItems)
  }

  isAllSelected(onlyRenderedData = true) {
    return (
      this.isAnySelected() && isAllSelected(this.selection, this.getTableData(onlyRenderedData), this.isRowSelectable)
    )
  }

  masterToggle(onlyRenderedData = true) {
    masterToggle(
      this.selection,
      this.getTableData(onlyRenderedData),
      this.dataSource,
      this.isRowSelectable,
      this.selectableRowIdPropName
    )
  }

  unselectAll() {
    this.selection.clear()
  }

  clearSelected() {
    this.selection.clear()
  }

  getSelected(): T[]
  getSelected<K extends keyof T>(field: K, filter?: (t: T) => boolean): T[K][]
  getSelected<K extends keyof T>(field?: K, filter?: (t: T) => boolean): T[] | T[K][] {
    let selectedItems = this.selection.selected || []
    selectedItems = R.filter(this.isRowSelectable, selectedItems)
    selectedItems = filter ? selectedItems.filter(filter) : selectedItems

    return field ? R.map((t) => t[field], selectedItems) : selectedItems
  }
}
