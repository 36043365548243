import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { ContractTemplate } from './template.model'

export const LoadContractTemplates = createAction('[Contract Template] Load Contracts')
export const LoadContractTemplatesComplete = createAction(
  '[Contract Template] Load Contracts Complete',
  props<{ items: ContractTemplate[] }>()
)

export const GetContractTemplate = createAction('[Contract Template] Load Contract Template', props<{ id: string }>())
export const GetContractTemplateComplete = createAction(
  '[Contract Template] Load Contract Template Complete',
  props<{ item: ContractTemplate }>()
)

export const CreateContractTemplate = createAction(
  '[Contract Template] Create Contract Template',
  props<{ payload: ContractTemplate }>()
)
export const CreateContractTemplateComplete = createAction(
  '[Contract Template] Create Contract Template Complete',
  props<{ item: ContractTemplate }>()
)

export const UpdateContractTemplate = createAction(
  '[Contract Template] Update Contract Template',
  props<{ id: string; payload: Partial<ContractTemplate>; message?: string; silent?: boolean }>()
)
export const UpdateContractTemplateComplete = createAction(
  '[Contract Template] Update Contract Template Complete',
  props<{ update: Update<ContractTemplate> }>()
)

export const DeleteContractTemplate = createAction(
  '[Contract Template] Delete Contract Template',
  props<{ id: string }>()
)
export const DeleteContractTemplateComplete = createAction(
  '[Contract Template] Delete Contract Template Complete',
  props<{ id: string }>()
)

export const DeleteMultipleContractTemplate = createAction(
  '[Contract Template] Delete Multiple Contract Templates',
  props<{ ids: string[] }>()
)
export const DeleteMultipleContractTemplateComplete = createAction(
  '[Contract Template] Delete Multiple Contract Templates Complete',
  props<{ ids: string[] }>()
)
