import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { ResourceGroup } from './group.model'

export interface State extends EntityState<ResourceGroup> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<ResourceGroup> = createEntityAdapter<ResourceGroup>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
