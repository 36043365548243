import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SignDrawerComponent } from './sign-drawer.component'
import { SignEditorComponent } from './sign-editor/sign-editor.component'

import { DeclineConfirmComponent } from './dialogs/decline-confirm/decline-confirm.component'
import { LegalConfirmComponent } from './dialogs/legal-confirm/legal-confirm.component'

import { BaseFieldComponent } from './sign-editor/fields/base-field.component'
import { TextboxFieldComponent } from './sign-editor/fields/textbox/textbox.component'
import { SigndateFieldComponent } from './sign-editor/fields/signdate/signdate.component'
import { SignatureFieldComponent } from './sign-editor/fields/signature/signature.component'
import { InitialsFieldComponent } from './sign-editor/fields/initials/initials.component'
import { DatadicFieldComponent } from './sign-editor/fields/datadic/datadic.component'
import { CheckboxFieldComponent } from './sign-editor/fields/checkbox/checkbox.component'
// import { NationalPhoneFieldComponent } from './sign-editor/fields/national-phone-field/national-phone-field.component';

import { PhotoUploaderComponent } from './dialogs/photo-uploader/photo-uploader.component'
import { DocumentSharedModule } from '../shared/document-shared.module'

const CONFIRM_COMPONENTS = [DeclineConfirmComponent, LegalConfirmComponent, PhotoUploaderComponent]
const SIGN_EDITOR_FIELDS = [
  BaseFieldComponent,
  TextboxFieldComponent,
  SigndateFieldComponent,
  SignatureFieldComponent,
  InitialsFieldComponent,
  DatadicFieldComponent,
  CheckboxFieldComponent,
  // NationalPhoneFieldComponent,
]

@NgModule({
  imports: [CommonModule, DocumentSharedModule],
  declarations: [SignDrawerComponent, SignEditorComponent, ...CONFIRM_COMPONENTS, ...SIGN_EDITOR_FIELDS],
  providers: [],
  exports: [],
})
export class SignModule {}
