import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Contract, UpdateContract } from '@tokeet-frontend/signature'
import { SaveForm } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-rename-contract-dialog',
  templateUrl: './rename-contract-dialog.component.html',
  styleUrls: ['./rename-contract-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class RenameContractDialogComponent implements OnInit {
  form = this.fb.group({
    name: [],
  })

  constructor(
    private dialog: MatDialogRef<RenameContractDialogComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) private data: { contract: Contract }
  ) {}

  ngOnInit() {
    this.form.patchValue({ name: this.data.contract.name })
  }

  close() {
    this.dialog.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { name } = this.form.getRawValue()

    this.store.dispatch(
      UpdateContract({
        id: this.data.contract.id,
        payload: { name },
        message: 'Document renamed successfully.',
      })
    )
    this.close()
  }
}
