import { SignaturePreference } from './preference.model'

export interface State {
  isLoaded: boolean
  preference: SignaturePreference
}

export const initialState: State = {
  isLoaded: false,
  preference: undefined,
}
