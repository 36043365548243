<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <app-rental-select [multiple]="false" placeholder="Rental" [ctrl]="form.get('rentalId')"> </app-rental-select>
    </div>
    <div class="col-sm-6"></div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput required placeholder="Phone" type="text" formControlName="phone" />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput required placeholder="Address" type="text" formControlName="address" />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="City" type="text" formControlName="city" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="State / Province / Region" type="text" formControlName="state" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Postal Code" type="text" formControlName="zip" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <ng-select
        [items]="countryList"
        [selectOnTab]="true"
        [clearable]="false"
        bindLabel="name"
        bindValue="id"
        formControlName="country_code"
        placeholder="Country"
        appendTo="body"
        class="mat-block"
        required
      >
      </ng-select>
    </div>
    <div class="col-sm-6" formGroupName="gps">
      <div class="d-flex align-items-center">
        <mat-form-field2 class="mat-block mr-2">
          <input matInput required placeholder="Latitude" type="text" formControlName="lat" />
        </mat-form-field2>

        <mat-form-field2 class="mat-block mr-2">
          <input matInput required placeholder="Longitude" type="text" formControlName="long" />
        </mat-form-field2>
        <a
          href="javascript:;"
          (click)="onRentalLocation(rental)"
          *ngIf="rental"
          matTooltip="Mark on map"
          matTooltipPosition="above"
        >
          <i class="fal fa-map-marked clickable"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-12 clearfix"></div>
    <div class="col-sm-3 check-container">
      <mat-form-field2 class="mat-block">
        <input
          type="text"
          matInput
          atp-time-picker
          changeToMinutes="true"
          formControlName="checkin"
          placeholder="Check-in Time"
          required
        />
        <span matSuffix><i class="fal fa-clock"></i></span>
        <mat-error> Check-in time is <strong>required</strong> </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-3 check-container">
      <mat-form-field2 class="mat-block">
        <input
          type="text"
          matInput
          atp-time-picker
          changeToMinutes="true"
          formControlName="checkout"
          placeholder="Check-out Time"
          required
        />
        <span matSuffix><i class="fal fa-clock"></i></span>
        <mat-error> Check-out time is <strong>required</strong> </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-3 zone-box">
      <mat-form-field2 class="mat-block app-select-field" floatLabel="always">
        <app-select
          required
          [items]="timezoneList"
          [searchable]="true"
          formControlName="timezone"
          placeholder="TimeZone:"
        >
          <ng-container class="error-container">
            <mat-error> Time zone is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select>
      </mat-form-field2>
    </div>
    <div class="col-sm-12 clearfix"></div>
    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <mat-select formControlName="size_metric" placeholder="Size Metric" required>
          <mat-option value="SQFT">SQFT</mat-option>
          <mat-option value="SQM">SQM</mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <input
          type="text"
          matInput
          formControlName="size"
          placeholder="Size ({{ form.get('size_metric').value }}):"
          required
        />
      </mat-form-field2>
    </div>
    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <mat-select formControlName="sleep_max" placeholder="Sleep Max" required>
          <mat-option *ngFor="let q of quantities; let i = index" [value]="i">{{ q }}</mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
  </div>
</form>
