<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/step-icon2.webp" alt="" height="40" />
    <div>
      <h6 class="mb-0">Request Channel Manager Access</h6>
      <div class="text-sm">This section can take between 10 minutes and 2 hrs depending on the volume of requests.</div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #settings>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Login to Account
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="text-bold">Log into Agoda</div>
            <ul class="pl-3">
              <li>Access your Agoda.com account.</li>
            </ul>
            <div class="text-bold">Send a Message</div>
            <ul class="pl-3">
              <li>Use the "Contact Us" form to request AdvanceCM as your channel manager</li>
              <li>Include your Agoda Hotel ID.</li>
              <li>Set the request type to "Channel Manager Settings."</li>
            </ul>
            <div class="text-bold">Await Confirmation</div>
            <ul class="pl-3">
              <li>Wait for confirmation from Agoda that AdvanceCM has been designated as your channel manager.</li>
              <li>Proceed to the next step once you receive confirmation.</li>
            </ul>
            <p>For detailed steps, follow this <a href="">guide</a>.</p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="enterPropertyId.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #enterPropertyId>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Enter Property ID
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <p>Please enter your property or hotel ID below.</p>
            <mat-form-field2 [showLabel]="false" class="w-50">
              <input matInput placeholder="Enter Property ID" [formControl]="propertyIdCtrl" required />
              <mat-error *ngIf="propertyIdCtrl.touched && propertyIdCtrl.hasError('required')">
                Property ID is required
              </mat-error>
            </mat-form-field2>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="messaging.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #messaging>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Messaging
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="text-bold">Log into the Agoda Extranet</div>
            <ul class="pl-3">
              <li>Access the Agoda Extranet.</li>
            </ul>
            <div class="text-bold">Contact Agoda Support</div>
            <ul class="pl-3">
              <li>Use the live chat or help centre widget to send a message.</li>
              <li>Request that guest information be included with your reservations sent to AdvanceCM.</li>
            </ul>
            <div class="text-bold">Await Confirmation</div>
            <ul class="pl-3">
              <li>Wait for Agoda support to confirm that guest info will be sent to the channel manager.</li>
              <li>Ensure this step is completed so your guests receive messages from AdvanceCM.</li>
            </ul>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div style="max-width: 450px">
      <img src="/assets/images/channel-wizards/agoda/step3.webp" alt="" class="w-100" />
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button
    class="btn btn-secondary-info"
    matTooltip="Please enter property ID to connect to Agoda"
    [disabled]="propertyIdCtrl.invalid || isConnecting"
    (click)="onConnect(propertyIdCtrl)"
  >
    Connect
    <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isConnecting"></i>
  </button>
</div>
