<div class="d-flex flex-fill flex-column">
  <div class="toolbar-container filters-box p-0 mb-3 border-bottom-0">
    <ng-container *ngTemplateOutlet="tmplToolbar"></ng-container>
  </div>
  <div class="d-flex flex-fill flex-column container-box">
    <div class="calendar-container">
      <ng-container *ngTemplateOutlet="tmplCalendar"></ng-container>

      <div
        class="btn-navigate btn-navigate-prev"
        (click)="navigate('prev')"
        [ngStyle]="{
          'left.px': config.sidebarWidth + 1,
          'height.px': config.headerHeight - 2
        }"
      >
        <i class="fal fa-chevron-left"></i>
      </div>
      <div
        class="btn-navigate btn-navigate-next"
        (click)="navigate('next')"
        [ngStyle]="{
          'height.px': config.headerHeight - 2
        }"
      >
        <i class="fal fa-chevron-right"></i>
      </div>
      <div
        class="sidebar-shadow"
        [ngStyle]="{ 'left.px': config.sidebarWidth, 'bottom.px': calendar?.scrollbarSizes.content || 0 }"
      ></div>
    </div>
    <mat-paginator2
      #paginator
      (page)="onChangePage($event)"
      [length]="filteredRentals.length"
      [class.hidden]="!filteredRentals.length"
      [pageIndex]="activePage"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100, 200]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>
  </div>
</div>

<ng-template #tmplToolbar>
  <div class="d-flex align-items-center gap-2">
    <ng-content select="[title]"></ng-content>
  </div>

  <div class="spacer"></div>

  <div class="btn-holder gap-3">
    <button
      class="btn btn-secondary-primary"
      (click)="onRefresh()"
      matTooltip="Refresh Calendar"
      matTooltipPosition="above"
    >
      <i class="far fa-sync"></i>
    </button>
    <ng-container *ngTemplateOutlet="tmplFieldAvailability"></ng-container>
    <button class="btn btn-outline-light" (click)="gotoToday()" matTooltip="Go to Today" matTooltipPosition="above">
      Today
    </button>

    <app-select-nav class="field-years" (previous)="onChangeYear('prev')" (next)="onChangeYear('next')">
      <mat-select [(ngModel)]="selectedYear" (selectionChange)="onChangeYear()">
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </app-select-nav>

    <app-select-nav class="field-months" (previous)="onChangeMonth('prev')" (next)="onChangeMonth('next')">
      <mat-select [(ngModel)]="selectedMonth" (selectionChange)="onChangeMonth()">
        <mat-option *ngFor="let month of months" [value]="month">
          {{ month | epochUTC : 'MMM' }}
        </mat-option>
      </mat-select>
    </app-select-nav>
    <app-cancel-filters
      [isFiltering]="filters | isFiltering : isFilteringIgnoreFields"
      (click)="clearFilters()"
    ></app-cancel-filters>
    <div class="btn-group dropdown-filters" dropdown [autoClose]="autoCloseDropdownFilters">
      <button
        type="button"
        class="btn btn-secondary-primary dropdown-toggle"
        [class.btn-filters-active]="!!filtersCount"
        dropdownToggle
      >
        <span class="fal fa-filter"></span>
        Filters
      </button>
      <ul
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        (click)="$event.stopPropagation()"
        style="max-width: 400px"
      >
        <li>
          <app-rental-select
            [ctrl]="filters.get('rentals')"
            [showCloseButton]="true"
            (openedChange)="autoCloseDropdownFilters = !$event"
            placeholder="Rentals"
            class="w-auto"
            matTooltip="Select rentals to filter list."
            matTooltipPosition="above"
          >
          </app-rental-select>
        </li>
        <li>
          <app-entity-filter-tags
            matTooltip="Select rental tags to filter list."
            matTooltipPosition="above"
            [inline]="false"
            [tags]="filterTags"
            [ctrl]="filters.get('tags')"
            [entityIdsCtrl]="filters.get('taggedIds')"
            (openedChange)="autoCloseDropdownFilters = !$event"
            type="rental"
            placeholder="Tags"
          ></app-entity-filter-tags>
        </li>
        <li>
          <app-select-search
            [inline]="false"
            [items]="filterChannels"
            [ctrl]="filters.get('channels')"
            [showCloseButton]="true"
            (openedChange)="autoCloseDropdownFilters = !$event"
            placeholder="Channels"
            class="w-auto"
            matTooltip="Select channels to only see bookings from those channels."
            matTooltipPosition="above"
          >
          </app-select-search>
        </li>
      </ul>
    </div>
    <div *ngIf="isOwner" class="btn-group">
      <button
        type="button"
        class="btn btn-secondary-info"
        (click)="onAddHoldEvent()"
        [disabled]="1 | isReadonlyRole"
        matTooltip="Block dates on your calendar and make them unavailable for booking."
        matTooltipPosition="left"
      >
        <span class="fal fa-calendar-plus"></span> Add Hold
      </button>
    </div>
    <ng-template *ngxPermissionsOnly="['canSeeCalendarActions']" [ngTemplateOutlet]="addActions"></ng-template>
  </div>
</ng-template>

<ng-template #tmplCalendar>
  <ngx-multi-event-calendar
    #calendar
    [config]="config"
    [items]="filteredRentals"
    [events]="filteredEvents"
    [showCoveredCells]="false"
    [loading]="loading"
    [getEventID]="getEventID"
    [getEventTimeRange]="getEventTimeRange"
    (viewportChange)="onViewportChange($event)"
    (selectionChange)="onSelectionChange($event)"
    [class.calendar-empty]="!filteredRentals.length"
  >
    <div class="calendar-sidebar-header px-2" *ngxMultiEventCalendarSidebarHeader>
      <mat-form-field2 [showLabel]="false" class="mat-block" matTooltip="Search by rental name or rental's nickname">
        <i matPrefix class="fal fa-search"></i>
        <input matInput placeholder="Search" [formControl]="filters.get('search')" />

        <mat-spinner *ngIf="loading" matSuffix diameter="16"></mat-spinner>
      </mat-form-field2>
    </div>

    <div class="calendar-header-cell" *ngxMultiEventCalendarHeaderCell="let date" [ngClass]="date | epochUTC : 'ddd'">
      <div class="subtext">{{ date | epochUTC : 'ddd' }}</div>
      <div class="text">
        <ng-template #dateView> {{ date | epochUTC : 'DD' }}</ng-template>
        <span *ngIf="date | isUTCTimeStartOf : 'month'; else dateView">{{ date | epochUTC : 'MMM' }}</span>
      </div>
    </div>

    <div
      class="calendar-sidebar-cell d-flex align-items-center gap-2 pr-1 w-100 clickable"
      *ngxMultiEventCalendarSidebarCell="let rental"
      [ngStyle]="{
        'border-left-color': rental.color || defaultRentalColor,
        'padding-left': rental.parent_id ? '22px' : '0.25rem'
      }"
      #sidebarCell
      (click)="onOpenRental(rental)"
      [matTooltip]="rental.nickname || rental.name"
      matTooltipPosition="right"
    >
      <div
        class="text-xs text-center text-uppercase position-absolute"
        style="transform: rotate(270deg); left: -22px"
        [style.width.px]="sidebarCell.offsetHeight"
      >
        <div class="text-white bg-info parent-label" *ngIf="rental.parent_id === rental.id">Parent</div>
        <div class="text-info bg-secondary-info child-label" *ngIf="rental.parent_id && rental.id !== rental.parent_id">
          Child
        </div>
      </div>
      <app-rental-image [rental]="rental" [size]="40"></app-rental-image>
      <div class="flex-fill text-truncate">
        <div class="text-truncate">
          {{ rental.nickname || rental.name }}
        </div>
        <div class="d-flex align-items-center gap-2 text-sm">
          <app-rental-status-icon [rental]="rental" *ngxPermissionsOnly="['canEditRental']"></app-rental-status-icon>
          <span class="">{{ rental.bedrooms || 0 }} <i class="fas fa-bed-front"></i></span>
          <span class="">{{ rental.bathrooms || 0 }} <i class="fas fa-bath"></i></span>
        </div>
      </div>
    </div>

    <div
      class="calendar-event-cell clickable"
      *ngxMultiEventCalendarEventCell="let event; let rental = item; let overlaps = overlaps"
      (click)="showEventDetails(event)"
      [appBookingDetails]="event"
      [appBookingDetailsRental]="rental"
      [title]="renderEventTooltip(event, overlaps)"
      [style.borderLeftColor]="event.color"
    >
      <div class="calendar-event">
        <img
          *ngIf="!event.isHoldEvent"
          draggable="false"
          class="icon"
          [src]="event.source | inquirySourceIcon"
          alt=""
        />
        <div class="text">
          {{ event.titleView }}
        </div>
      </div>
    </div>
    <ng-container *ngxMultiEventCalendarContentCell="let rental = item; let date = date">
      <ng-container *ngxPermissionsOnly="['canSeeRates']">
        <ng-container *ngIf="!isBooked(rental.id, date)">
          <div
            *ngIf="rentalRatesByCategory?.[rental.id]?.['default']?.[date]; let rate"
            class="text-center text-truncate p-1 flex-1"
            [ngbPopover]="tmplRateTooltip"
            #popover="ngbPopover"
            container="body"
            placement="auto"
            popoverClass="popover-fill-content dropdown-shadow"
            triggers="manual"
            [autoClose]="true"
            (mouseenter)="popover.open({ rental: rental, date: date, rate: rate })"
            (mouseleave)="popover.close()"
          >
            {{ rate.price | rate : rate.currency }}
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loading && !filteredRentals.length">
      <app-empty-table
        *ngxMultiEventCalendarEmptySidebar
        [type]="tableTypes.CalendarSidebarRentals"
        [isFiltering]="rentals.length"
        class="empty-list"
        [style.opacity]="rentals.length ? 0 : 1"
      >
        <app-button
          *ngIf="!(1 | isReadonlyRole) && !rentals.length"
          (click)="onAddRental()"
          icon="far fa-plus"
          class="btn btn-secondary-info mt-4"
        >
          Add Rental
        </app-button>
      </app-empty-table>

      <app-empty-table
        *ngxMultiEventCalendarEmptyContent
        [type]="tableTypes.CalendarContentRentals"
        class="empty-events"
      >
      </app-empty-table>
    </ng-container>
  </ngx-multi-event-calendar>
</ng-template>

<ng-template #tmplFieldAvailability>
  <div class="btn-group">
    <button
      matTooltip="Show rentals available during selected dates."
      matTooltipPosition="above"
      class="btn calender-ctrl-btn text-nowrap"
      [ngClass]="{ active: filterAvailabilityType === availabilityTypes.available }"
      aria-controls="available"
      aria-selected="false"
      (click)="onChangeFilterAvailabilityType(availabilityTypes.available)"
    >
      Available
    </button>
    <button
      matTooltip="Show rentals booked during selected dates."
      matTooltipPosition="above"
      class="btn calender-ctrl-btn text-nowrap"
      [ngClass]="{ active: filterAvailabilityType === availabilityTypes.booked }"
      aria-controls="booked"
      aria-selected="true"
      (click)="onChangeFilterAvailabilityType(availabilityTypes.booked)"
    >
      Booked
    </button>
  </div>

  <div class="btn-group">
    <button
      class="btn btn-outline-light"
      matTooltip="Only show rentals with availability for these selected dates."
      [ngbPopover]="tmplDateRangePicker"
      container="body"
      placement="bottom"
      autoClose="outside"
      popoverClass="popover-fill-content"
      #popoverDatePicker="ngbPopover"
    >
      <ng-container *ngIf="!filterAvailabilityDateRange; else dateRange">
        <i class="fal fa-calendar-exclamation"></i> Range
      </ng-container>
      <ng-template #dateRange>
        {{
          (filterAvailabilityDateRange.from | epochUTC : 'DD/MM/YYYY') +
            ' - ' +
            (filterAvailabilityDateRange.to | epochUTC : 'DD/MM/YYYY')
        }}
      </ng-template>
      <ng-template #tmplDateRangePicker>
        <app-date-range-picker
          [selection]="filterAvailabilityDateRange"
          (selectionChange)="onChangeFilterDateRange($event); popoverDatePicker.close()"
          (click)="$event.stopPropagation()"
        >
        </app-date-range-picker>
      </ng-template>
    </button>
    <button class="btn btn-outline-danger" *ngIf="filterAvailabilityDateRange" (click)="onChangeFilterDateRange(null)">
      <i class="far fa-times"></i>
    </button>
  </div>
</ng-template>

<ng-template #tmplRateTooltip let-rental="rental" let-date="date" let-rate="rate">
  <app-rate-popover-v2
    [date]="date"
    [rate]="rate"
    [rental]="rental"
    [ratesByCategory]="getRentalCategoryRates(rental.id, date)"
  >
  </app-rate-popover-v2>
</ng-template>

<ng-template #addActions>
  <div class="btn-group" dropdown [isDisabled]="1 | isReadonlyRole">
    <button type="button" class="btn btn-secondary-info dropdown-toggle" dropdownToggle>
      <span class="fal fa-calendar-plus"></span> Add
    </button>
    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <li>
        <button
          type="button"
          (click)="onAddInquiry()"
          matTooltip="Manually create a new inquiry."
          matTooltipPosition="left"
        >
          <i class="fal fa-calendar-day fa-fw"></i> Add Inquiry
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onAddBooking()"
          matTooltip="Manually create a new booking."
          matTooltipPosition="left"
        >
          <i class="fal fa-bed fa-fw"></i> Add Booking
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onAddHoldEvent()"
          matTooltip="Block dates on your calendar and make them unavailable for booking."
          matTooltipPosition="left"
        >
          <i class="fal fa-bookmark fa-fw"></i> Add Hold Event
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onImportCalendar()"
          matTooltip="Import an external iCal URL and add those events to your calendar."
          matTooltipPosition="left"
        >
          <i class="cs-icon cs-icon-download fa-fw"></i> Import Calendar
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onShareCalendar()"
          matTooltip="Share your calendar with external persons via a webpage."
          matTooltipPosition="left"
        >
          <i class="fal fa-share fa-fw"></i> Share Calendar
        </button>
      </li>
      <li class="dropdown-divider"></li>
      <li>
        <button
          type="button"
          (click)="onAddStandardRate()"
          matTooltip="Standard rates are flat, constant rates which do not change over time."
          matTooltipPosition="left"
        >
          <i class="far fa-calculator fa-fw"></i> Add Standard Rate
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onAddDynamicRate()"
          matTooltip="Dynamic rates will gradually and automatically adjust from a low rate to a high rate as your overall rental occupancy level changes. They can also instantly update your channels as the rate changes."
          matTooltipPosition="left"
        >
          <i class="far fa-calculator fa-fw"></i> Add Dynamic Rate
        </button>
      </li>
      <li class="dropdown-divider"></li>
      <li>
        <button
          type="button"
          (mouseover)="isAutomatedRateMenuOpen = !isAutomatedRateMenuOpen"
          (click)="onAutomatedRates()"
          cdkOverlayOrigin
          #automatedRatesTrigger="cdkOverlayOrigin"
        >
          <i class="cs-icon cs-icon-dollar-circle text-lg fa-fw" style="pointer-events: none"></i> Automated Rates
        </button>
        <ng-template
          cdkConnectedOverlay
          (overlayOutsideClick)="isAutomatedRateMenuOpen = false"
          [cdkConnectedOverlayOffsetX]="-15"
          [cdkConnectedOverlayOffsetY]="-175"
          [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' }]"
          [cdkConnectedOverlayOrigin]="automatedRatesTrigger"
          [cdkConnectedOverlayOpen]="isAutomatedRateMenuOpen"
        >
          <div>
            <ul class="dropdown-menu show position-relative" style="top: 0" (click)="isAutomatedRateMenuOpen = false">
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.ChannelAutomations)"
                  matTooltip="Automatically adjust the default rate category to push an adjusted rate to the API channels and rentals that you specify. These settings only apply to the default rate category."
                  matTooltipPosition="left"
                >
                  Channel Rate Automation
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.GroupRates)"
                  matTooltip="Add Group Rates which apply to multiple rentals at once"
                  matTooltipPosition="left"
                >
                  Group Rates
                </button>
              </li>
              <li *ngIf="showDiscounts">
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.LosDiscounts)"
                  matTooltip="Discount your rates based on guest's Length Of Stay"
                  matTooltipPosition="left"
                >
                  LOS Discounts
                </button>
              </li>
              <li *ngIf="showDiscounts">
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.DiscountCodes)"
                  matTooltip="Allow guest to avail discounts for Webready bookings based on discount codes"
                  matTooltipPosition="left"
                >
                  Discount Codes
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.MinStayAutomations)"
                  matTooltip="Set the minimum night stay you want to apply to each day of the week."
                  matTooltipPosition="left"
                >
                  Min. Night Stay
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.GapNightAutomations)"
                  matTooltip="Set the price adjustments for booking gaps up to the number of nights you specify."
                  matTooltipPosition="left"
                >
                  Gap Night Automation
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.DynamicRatesAutomations)"
                  matTooltip="Dynamic Rates will automatically adjust from low to high rates based on changing occupancy. Channel rates will be updated instantly when rate changes"
                  matTooltipPosition="left"
                >
                  Dynamic Rates
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.RatePushAutomations)"
                  matTooltip="Coming Soon"
                  matTooltipPosition="left"
                  disabled
                >
                  Rate Push Automation
                </button>
              </li>
              <li>
                <button
                  type="button"
                  (click)="onAddPrice(automatedRatesTabs.LastMinuteAutomations)"
                  matTooltip="Set the number of nights from today that you want to adjust your nightly price up or down."
                  matTooltipPosition="left"
                >
                  Last Minute Automation
                </button>
              </li>
            </ul>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</ng-template>
