<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Trip.com with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create or Import Rentals
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-semi-bold">To connect your AdvanceCM rental to Trip.com, follow these steps:</div>
              <ul class="pl-3 lh-lg">
                <li>Ensure you have manually created or imported a rental from Airbnb before proceeding.</li>
                <li>
                  If you use both Airbnb and Trip.com, connect to Airbnb first to have rentals imported to AdvanceCM..
                </li>
                <li>
                  Follow this
                  <a target="_blank" href="https://help.tokeet.com/rental-settings/how-do-i-add-and-update-rentals"
                    >guide</a
                  >
                  for detailed instructions.
                </li>
                <li>
                  One AdvanceCM rental can connect to multiple channels. Combined availability from all channels will be
                  synced and pushed out.
                </li>
              </ul>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="blocked.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #blocked>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Blocked Dates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                After connecting AdvanceCM with Trip.com your availability will be determined solely by what shows on
                your AdvanceCM rental calendar.
              </p>

              <div class="alert alert-secondary-warning2 align-items-start">
                <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
                <div class="alert-content">
                  <div class="text-semi-bold">Important Notes</div>
                  <ul class="pl-3">
                    <li>
                      Replicate any manual hold events from your channels onto the AdvanceCM calendar to keep dates
                      blocked. Hold events set on Trip.com are not imported.
                    </li>
                    <li>
                      For detailed instructions, please refer to this
                      <a target="_blank" href="https://help.tokeet.com/calendars/what-is-a-hold-event">guide</a> on how
                      to add a hold event.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="prepare.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #prepare>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Preparing Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Moving forward, you must push rates from AdvanceCM to Trip.com, as direct edits on the Trip.com rates
                calendar will not be possible.
              </p>

              <div class="text-smi-bold">Pushing Rates</div>
              <ul class="pl-3 lh-lg">
                <li>Pushing rates to Trip.com is a required step in the connection wizard.</li>
                <li>
                  Prepare your rates using this
                  <a target="_blank" href="https://help.tokeet.com/rates-management/rates-overview">guide</a> before
                  beginning.
                </li>
              </ul>
              <div class="text-smi-bold">Creating Rates</div>
              <ul class="pl-3 lh-lg">
                <li>
                  You can create a rate within the connection wizard and make changes after the connection is complete.
                </li>
                <li>
                  If you have a straightforward plan (e.g., one standard rate), adding the rate within the connection
                  wizard is recommended.
                </li>
              </ul>
              <div class="text-smi-bold">Additional Guest Fees</div>
              <ul class="pl-3 lh-lg">
                <li>Ensure your Trip.com rates are set to accept additional guest fees if applicable.</li>
                <li>Finalize your rate plans on Trip.com.</li>
              </ul>
              <div class="alert alert-secondary-warning2 align-items-start">
                <img
                  src="/assets/images/integrations/rategenie.png"
                  alt="Rategenie"
                  class="alert-icon"
                  style="height: 1.5rem"
                />
                <div class="alert-content">
                  <b class="text-nowrap">Recommended Tool:</b> We highly recommend using
                  <a href="https://www.rategenie.io/" target="_blank">Rategenie</a> for rental rate management, as it is
                  the most advanced dynamic pricing application in the industry.
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Next
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
