import { AddressPipe } from './address.pipe'
import { AmountPlaceholderPipe } from './amount-placeholder.pipe'
import { AsyncSchedulePipe } from './async-schedule.pipe'
import { CallPipe } from './call.pipe'
import { CapitalizePipe } from './capitalize.pipe'
import { EpochPipe } from './epoch.pipe'
import { EpochUTCPipe } from './epoch-utc.pipe'
import { FileSizePipe } from './file-size.pipe'
import { FullnamePipe } from './fullname.pipe'
import { LanguageNamePipe } from './language-name.pipe'
import { RentalNamePipe } from './rental-name.pipe'
import { SecureImagePipe } from './secure-image.pipe'
import { SnakeCasePipe } from './snake-case.pipe'
import { StartCasePipe } from './start-case.pipe'
import { TokeetCurrencyPipe } from './tokeet-currency.pipe'
import { TruncatePipe } from './truncate.pipe'
import { UserRolePipe } from './user-role.pipe'
import { html2TextPipe } from './html2text.pipe'
import { TitleCasePipe } from './title-case.pipe'
import { UsernamePipe } from './user-name.pipe'
import { ImageUrlPipe } from './image-url.pipe'
import { ResizeImgPipe } from './resize-img.pipe'
import { MinPipe, MaxPipe } from './min-max.pipe'
import { IsFilteringPipe } from './is-filtering.pipe'
import { RequiredPipe } from './required.pipe'
import { RentalChildrenPipe } from './rental-children.pipe'

export * from './address.pipe'
export * from './amount-placeholder.pipe'
export * from './async-schedule.pipe'
export * from './call.pipe'
export * from './capitalize.pipe'
export * from './epoch.pipe'
export * from './epoch-utc.pipe'
export * from './file-size.pipe'
export * from './fullname.pipe'
export * from './html2text.pipe'
export * from './language-name.pipe'
export * from './rental-name.pipe'
export * from './image-url.pipe'
export * from './resize-img.pipe'
export * from './secure-image.pipe'
export * from './snake-case.pipe'
export * from './start-case.pipe'
export * from './title-case.pipe'
export * from './tokeet-currency.pipe'
export * from './truncate.pipe'
export * from './user-role.pipe'
export * from './user-name.pipe'
export * from './min-max.pipe'
export * from './is-filtering.pipe'
export * from './required.pipe'
export * from './rental-children.pipe'

export const pipes = [
  AddressPipe,
  AmountPlaceholderPipe,
  AsyncSchedulePipe,
  CallPipe,
  CapitalizePipe,
  EpochPipe,
  EpochUTCPipe,
  FileSizePipe,
  FullnamePipe,
  html2TextPipe,
  LanguageNamePipe,
  ImageUrlPipe,
  ResizeImgPipe,
  RentalNamePipe,
  SecureImagePipe,
  SnakeCasePipe,
  StartCasePipe,
  TitleCasePipe,
  TokeetCurrencyPipe,
  TruncatePipe,
  UserRolePipe,
  UsernamePipe,
  MinPipe,
  MaxPipe,
  IsFilteringPipe,
  RequiredPipe,
  RentalChildrenPipe,
]
