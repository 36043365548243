import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { ContractTemplate } from './template.model'
import { SignFieldDef } from './sign-field'
import { httpRawItemsToDataItems, httpRawItemToDataItem, omitViewFields } from '@tokeet-frontend/tv3-platform'

export interface TemplatePreviewPayload {
  fields: SignFieldDef[]
  pdf_file_url: string
  font: string
  font_size: number
}

@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(private http: HttpClient) {}

  list(): Observable<ContractTemplate[]> {
    const url = `@signatureApi/templates?limit=1000`
    return this.http
      .get<{ items: any[] }>(url)
      .pipe(map(({ items }) => httpRawItemsToDataItems<ContractTemplate>(items, 'template_id')))
  }

  create(payload: Partial<ContractTemplate>): Observable<any> {
    const url = `@signatureApi/templates`
    return this.http.post<any>(url, payload).pipe(map((t) => httpRawItemToDataItem(t, 'template_id')))
  }

  get(id: string): Observable<any> {
    const url = `@signatureApi/templates/${id}`
    return this.http.get<any>(url).pipe(map((t) => httpRawItemToDataItem(t, 'template_id')))
  }

  update(id: string, payload: Partial<ContractTemplate>): Observable<any> {
    const url = `@signatureApi/templates/${id}`
    return this.http.put<any>(url, omitViewFields(payload)).pipe(map((t) => httpRawItemToDataItem(t, 'template_id')))
  }

  preview(payload: TemplatePreviewPayload): Observable<Blob> {
    const url = `@signatureApi/templates/preview`
    return this.http.post(url, payload, { responseType: 'blob' })
  }

  delete(id: string): Observable<any> {
    const url = `@signatureApi/templates/${id}`
    return this.http.delete<any>(url)
  }
}
