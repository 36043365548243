import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Store } from '@ngrx/store'
import { map, uniqBy, sortBy, groupBy, reduce, keyBy } from 'lodash'
import { ClipboardService } from 'ngx-clipboard'
import { filter, finalize } from 'rxjs/operators'
import { ImageViewerDialogService } from '../../../dialogs/image-viewer/image-viewer-dialog.service'
import {
  ContractView,
  ContractSignStatus,
  ContractActivity,
  ContractService,
  ContractSigner,
  ContractSignerType,
  ContractStatus,
} from '@tokeet-frontend/signature'
import { Toaster, ActionFailed, selectOnce, selectUserById } from '@tokeet-frontend/tv3-platform'
import { SignDrawerService } from '@tv3/containers/documents/sign/sign-drawer.service'
import { OpenGuestOverlay } from '@tv3/store/overlay/overlay.actions'
import { UserOverlayService } from '@tv3/containers/settings/user-settings/overlays/user-overlay.service'

@Component({
  selector: 'app-signer-status',
  templateUrl: './signer-status.component.html',
  styleUrls: ['./signer-status.component.scss'],
})
export class SignerStatusComponent implements OnInit, OnChanges {
  @Input() contract: ContractView

  signerStatuses = ContractSignStatus

  activities: { [role: string]: { [action: string]: ContractActivity } } = {}

  isGeneratingSignLink = false
  constructor(
    private store: Store<any>,
    private toaster: Toaster,
    private imageViewerDialog: ImageViewerDialogService,
    private editUserDrawer: UserOverlayService,
    private contractService: ContractService,
    private clipboardService: ClipboardService,
    private signDrawer: SignDrawerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contract']) {
      this.processActivities(this.contract)
    }
  }

  onSignerClick(signer: ContractSigner) {
    if (signer.type === ContractSignerType.Guest) {
      this.store.dispatch(OpenGuestOverlay({ guestId: signer.id }))
    } else if (signer.type === ContractSignerType.Staff) {
      this.store
        .pipe(
          selectOnce(selectUserById(signer.id)),
          filter((u) => !!u)
        )
        .subscribe((user) => {
          this.editUserDrawer.openSide(user)
        })
    }
  }

  processActivities(contract: ContractView) {
    if (!contract) {
      this.activities = {}
      return
    }

    const activities = uniqBy(sortBy(contract.activities, 'date'), (item) => `${item.user}-${item.action}`)

    const activitiesByRole = groupBy(activities, 'user')

    this.activities = reduce(
      activitiesByRole,
      (res, activities, role) => {
        res[role] = keyBy(activities, 'action')
        return res
      },
      {}
    )
  }

  isSignable(signer: ContractSigner) {
    return (
      this.contract.status == ContractStatus.Pending &&
      signer.status !== ContractSignStatus.Signed &&
      signer.status !== ContractSignStatus.Decline
    )
  }

  generateLink(signer: ContractSigner, cb: (link: string) => void) {
    if (this.isGeneratingSignLink) {
      return
    }

    this.isGeneratingSignLink = true
    this.contractService
      .generateSignLink(this.contract.id, signer.role)
      .pipe(finalize(() => (this.isGeneratingSignLink = false)))
      .subscribe(
        (link) => {
          cb(link)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onCopySignLink(signer: ContractSigner) {
    this.generateLink(signer, (link) => {
      this.clipboardService.copy(link)
      this.toaster.success('Sign link is copied!')
    })
  }

  openSignEditor(signer: ContractSigner) {
    this.signDrawer.open(this.contract.contract_id, signer.role)
  }

  openPhotoID(signer: ContractSigner) {
    this.imageViewerDialog.open({
      title: 'Signer Photo ID/Passport',
      images: map(signer.photo_urls, (url) => ({ url, title: signer.name })),
    })
  }
}
