<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Agoda with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create or Import Rentals
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Linking AdvanceCM to Agoda</div>
              <ul class="pl-3">
                <li>Ensure you have a rental in AdvanceCM before linking to Agoda.</li>
                <li>Create or import a rental in AdvanceCM if you haven't done so.</li>
              </ul>
              <div class="text-bold">Connecting Airbnb First</div>
              <ul class="pl-3">
                <li>Connect Airbnb to AdvanceCM first for convenience.</li>
                <li>This allows you to have rentals imported to AdvanceCM before linking with Agoda.</li>
              </ul>
              <div class="text-bold">Multiple Channel Connections</div>
              <ul class="pl-3">
                <li>One AdvanceCM rental can connect to various channels.</li>
                <li>Combined availability from all connected channels will sync automatically.</li>
              </ul>
              <p>For detailed steps, follow this <a href="">guide</a>.</p>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="blockedDates.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #blockedDates>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Blocked Dates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Availability Management</div>
              <ul class="pl-3">
                <li>Your AdvanceCM rental calendar will solely determine availability.</li>
                <li>Blocked or closed dates on Agoda will be reopened, except for confirmed bookings.</li>
              </ul>
              <div class="text-bold">Maintaining Blocked Dates</div>
              <ul class="pl-3">
                <li>Add hold events to your AdvanceCM calendar to keep dates blocked.</li>
                <li>Do this before or after completing the connection steps.</li>
              </ul>
              <p>For detailed steps, follow this <a href="">guide</a>.</p>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="prepareRates.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #prepareRates>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Preparing Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Rates and Availability Disabled</div>
              <ul class="pl-3">
                <li>
                  After establishing the connection, rates and availability will be disabled until the wizard steps are
                  complete.
                </li>
                <li>Push rates from AdvanceCM to Agoda; you cannot edit them directly on Agoda.</li>
              </ul>
              <div class="text-bold">Review Previous Rates</div>
              <ul class="pl-3">
                <li>Review previous rates on the Booking.com calendar before proceeding.</li>
                <li>Once connected, previous Agoda rates will not be visible.</li>
              </ul>
              <div class="text-bold">Preparing Rates</div>
              <ul class="pl-3">
                <li>Pushing rates to Agoda is required in the wizard.</li>
                <li>Use this guide to prepare rates before beginning.</li>
                <li>
                  You can create or adjust rates within the wizard, but preparing a full rate plan in advance is
                  recommended.
                </li>
              </ul>
              <div class="text-bold">Rategenie Recommendation</div>
              <ul class="pl-3">
                <li>Use Rategenie for advanced dynamic pricing.</li>
              </ul>
              <div class="alert alert-secondary-warning2 align-items-start">
                <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
                <div class="alert-content">
                  <b>Note:</b> Ensure your rates and availability are set up correctly in AdvanceCM to streamline the
                  connection process with Agoda.
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Next
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
