<div class="text-bold mb-3">Type it in</div>
<div class="drawer-wrapper" [style.fontFamily]="signatureFontCtrl.value">
  <input
    [formControl]="signatureTextCtrl"
    [style.fontSize.px]="getFontSize | call : editorSize : signatureTextCtrl.value : signatureFontCtrl.value"
    [style.width.px]="editorSize.width"
    [style.height.px]="editorSize.height"
  />
  <div class="baseline"></div>
</div>

<div class="row">
  <div class="col-md-6">
    <mat-form-field2 class="mat-block">
      <mat-select placeholder="Change Font" [formControl]="signatureFontCtrl">
        <mat-option *ngFor="let font of fonts" [value]="font">
          {{ font }}
        </mat-option>
      </mat-select>
    </mat-form-field2>
  </div>
</div>
