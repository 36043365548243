import { find, propEq } from 'ramda'
import { AutomationTypes } from './automation-services'

export enum AutomationHandler {
  // Message
  SendEmailMessage = 'Handlers::EmailHandler',
  SendPushNotification = 'Handlers::MobilePushHandler',
  SendSMSMessage = 'Handlers::SMSHandler',

  // HTTP
  SendHTTPPost = 'Handlers::WebhookHandler',

  // Contract
  SendContract = 'Handlers::ContractHandler',
  SendDocument = 'Handlers::DocumentHandler',
  SendForm = 'Handlers::FormHandler',

  // Inquiry
  BookingMarkFollowUp = 'Handlers::Inquiry::FollowUpHandler',
  BookingUnmarkFollowUp = 'Handlers::Inquiry::UnFollowHandler',
  BookingAddTagHandler = 'Handlers::Inquiry::AddTagHandler',
  BookingRemoveTagHandler = 'Handlers::Inquiry::RemoveTagHandler',
  BookingMarkPaid = 'Handlers::Inquiry::MarkPaidHandler',
  BookingMarkUnpaid = 'Handlers::Inquiry::MarkUnPaidHandler',
  BookingMarkCheckedOut = 'Handlers::Inquiry::CheckedOutHandler',
  BookingMarkCheckedIn = 'Handlers::Inquiry::CheckedInHandler',
  BookingConfirmHandler = 'Handlers::Inquiry::ConfirmHandler',
  BookingCreateTaskHandler = 'Handlers::Inquiry::CreateTaskHandler',
  BookingRemoveTaskHandler = 'Handlers::Inquiry::RemoveTaskHandler',

  // Hold Event
  CalendarCreateHoldEvent = 'Handlers::Inquiry::HoldEventHandler',

  // Expense
  ExpenseCreateHandler = 'expense',
  ExpenseMarkPaidHandler = 'Handlers::Expense::MarkPaidHandler',
  ExpenseMarkUnpaidHandler = 'Handlers::Expense::MarkUnpaidHandler',
  ExpenseNotifyGuestHandler = 'Handlers::Expense::NotifyGuestHandler',

  // Device
  DeviceNotifyGuestHandler = 'Handlers::Lock::NotifyGuestHandler',

  // Extra
  ExtraNotifyGuestHandler = 'Handlers::Extra::NotifyGuestHandler',

  // Incident
  IncidentCreateHandler = 'Handlers::Lock::CreateIncidentHandler',
  IncidentNotifyGuestHandler = 'Handlers::Incident::NotifyGuestHandler',
  IncidentCreateTaskHandler = 'Handlers::Incident::CreateTaskHandler',

  // Rental
  RentalMarkReadyHandler = 'Handlers::Rental::MarkReadyHandler',
  RentalMarkNotReadyHandler = 'Handlers::Rental::MarkNotReadyHandler',

  // Invoice
  InvoiceMarkPaidHandler = 'Handlers::Invoice::MarkPaidHandler',
  InvoiceChargeHandler = 'Handlers::Invoice::ChargeHandler',
  InvoiceSendToGuestHandler = 'Handlers::Invoice::SendToGuestHandler',

  // Task
  TaskCreateHandler = 'task',
  TaskNotifyAssignee = 'Handlers::Task::NotifyAssigneeHandler',
  TaskNotifyOldAssignee = 'Handlers::Task::NotifyOldAssigneeHandler',
  TaskNotifyCreator = 'Handlers::Task::NotifyCreatorHandler',
  TaskMarkIncidentResolved = 'Handlers::Task::MarkIncidentResolvedHandler',

  // Review
  SendReview = 'Handlers::ReviewHandler',
  ReviewRespond = 'Handlers::Review::RespondHandler',
  ReviewDraftAIReview = 'Handlers::Review::DraftAIReviewHandler',
  ReviewSendAIReview = 'Handlers::Review::SendAIReviewHandler',

  // Message
}

export interface AutomationHandlerItem {
  title: string
  handler: AutomationHandler
  tooltip?: string
  icon?: string
  color?: string
  types?: AutomationTypes[]
}

export const automationHandlers: AutomationHandlerItem[] = [
  {
    handler: AutomationHandler.SendEmailMessage,
    title: 'Send Email Message',
    icon: 'cs-icon cs-icon-send-email',
    color: 'text-danger',
    types: [AutomationTypes.Message],
  },
  {
    handler: AutomationHandler.SendHTTPPost,
    title: 'Send HTTP Request',
    icon: 'cs-icon cs-icon-http',
    color: 'text-success',
    types: [AutomationTypes.Message],
  },
  {
    handler: AutomationHandler.SendPushNotification,
    title: 'Send Push Notification',
    icon: 'cs-icon cs-icon-send-notification',
    color: 'text-warning',
    types: [AutomationTypes.Message],
  },
  {
    handler: AutomationHandler.SendSMSMessage,
    title: 'Send SMS Message',
    icon: 'cs-icon cs-icon-send-sms',
    color: 'text-info',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Send Contract',
    handler: AutomationHandler.SendContract,
    icon: 'cs-icon cs-icon-file-signature text-success',
    tooltip:
      'A contract is a legal template which requires the recipient(s) to sign and fill in requested fields. Contracts can contain text boxes, checkboxes, and data dictionary codes.',
    types: [AutomationTypes.Document],
  },
  {
    title: 'Send Form',
    handler: AutomationHandler.SendForm,
    icon: 'cs-icon cs-icon-file-alt2 text-info',
    tooltip:
      'A form is a data-retrieval template that will allow the recipient(s) to fill in requested fields that will populate the guest details in your AdvanceCM account. Forms can contain textboxes, checkboxes, and data dictionary codes.',
    types: [AutomationTypes.Document],
  },
  {
    title: 'Send Printout',
    handler: AutomationHandler.SendDocument,
    icon: 'cs-icon cs-icon-pdf text-danger',
    tooltip:
      'A printout is a read-only, electronic template containing fields that are already filled with data from your AdvanceCM account. Documents can contain data dictionary codes.',
    types: [AutomationTypes.Document],
  },
  {
    title: 'Mark For Follow-up',
    handler: AutomationHandler.BookingMarkFollowUp,
    icon: 'fas fa-star text-warning',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Remove Follow-up Flag',
    handler: AutomationHandler.BookingUnmarkFollowUp,
    icon: 'far fa-star text-warning',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Add Booking Tag',
    handler: AutomationHandler.BookingAddTagHandler,
    icon: 'fas fa-tags text-info',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Remove Booking Tag',
    handler: AutomationHandler.BookingRemoveTagHandler,
    icon: 'far fa-tags text-info',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Mark as Paid',
    handler: AutomationHandler.BookingMarkPaid,
    icon: 'cs-icon cs-icon-dollar-circle-check text-indigo',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Mark as Unpaid',
    handler: AutomationHandler.BookingMarkUnpaid,
    icon: 'cs-icon cs-icon-dollar-circle-times text-indigo',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Mark as Checked In',
    handler: AutomationHandler.BookingMarkCheckedIn,
    icon: 'cs-icon cs-icon-checkin text-success',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Mark as Checked Out',
    handler: AutomationHandler.BookingMarkCheckedOut,
    icon: 'cs-icon cs-icon-checkout text-danger',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Create Hold Event',
    handler: AutomationHandler.CalendarCreateHoldEvent,
    icon: 'cs-icon cs-icon-calendar-pause text-danger',
    types: [AutomationTypes.Booking],
  },
  {
    title: 'Confirm Booking',
    handler: AutomationHandler.BookingConfirmHandler,
    types: [],
  },
  {
    title: 'Create Task',
    handler: AutomationHandler.BookingCreateTaskHandler,
    types: [],
  },
  {
    title: 'Remove all Booking Tasks',
    handler: AutomationHandler.BookingRemoveTaskHandler,
    types: [],
  },
  {
    title: 'Create Expense',
    handler: AutomationHandler.ExpenseCreateHandler,
    types: [],
  },
  {
    title: 'Mark Expense as Paid',
    handler: AutomationHandler.ExpenseMarkPaidHandler,
    types: [],
  },
  {
    title: 'Mark Expense as Unpaid',
    handler: AutomationHandler.ExpenseMarkUnpaidHandler,
    types: [],
  },
  {
    title: 'Notify Guest',
    handler: AutomationHandler.ExpenseNotifyGuestHandler,
    types: [],
  },
  {
    title: 'Notify Guest',
    handler: AutomationHandler.DeviceNotifyGuestHandler,
    types: [],
  },
  {
    title: 'Notify Guest',
    handler: AutomationHandler.ExtraNotifyGuestHandler,
    types: [],
  },
  {
    title: 'Create Incident',
    handler: AutomationHandler.IncidentCreateHandler,
    types: [],
  },
  {
    title: 'Notify Guest',
    handler: AutomationHandler.IncidentNotifyGuestHandler,
    types: [],
  },
  {
    title: 'Create Task from Incident',
    handler: AutomationHandler.IncidentCreateTaskHandler,
    types: [],
  },
  {
    title: 'Mark Rental as Ready',
    handler: AutomationHandler.RentalMarkReadyHandler,
    types: [],
  },
  {
    title: 'Mark Rental as Not Ready',
    handler: AutomationHandler.RentalMarkNotReadyHandler,
    types: [],
  },
  {
    title: 'Charge Invoice',
    handler: AutomationHandler.InvoiceChargeHandler,
    types: [],
  },
  {
    title: 'Mark Invoice as Paid',
    handler: AutomationHandler.InvoiceMarkPaidHandler,
    types: [],
  },
  {
    title: 'Send Invoice to Guest',
    handler: AutomationHandler.InvoiceSendToGuestHandler,
    types: [],
  },
  {
    title: 'Create Task',
    handler: AutomationHandler.TaskCreateHandler,
    types: [],
  },
  {
    title: 'Notify Task Assignee',
    handler: AutomationHandler.TaskNotifyAssignee,
    types: [],
  },
  {
    title: 'Notify Task Old Assignee',
    handler: AutomationHandler.TaskNotifyOldAssignee,
    types: [],
  },
  {
    title: 'Notify Task Creator',
    handler: AutomationHandler.TaskNotifyCreator,
    types: [],
  },
  {
    title: 'Mark Incident as Resolved',
    handler: AutomationHandler.TaskMarkIncidentResolved,
    types: [],
  },
  {
    title: 'Send Guest Review',
    handler: AutomationHandler.SendReview,
    types: [],
  },
  {
    title: 'Respond to Guest Review',
    handler: AutomationHandler.ReviewRespond,
    types: [],
  },
  {
    title: 'Draft AI Based Review',
    handler: AutomationHandler.ReviewDraftAIReview,
    types: [],
  },
  {
    title: 'Send AI Based Review',
    handler: AutomationHandler.ReviewSendAIReview,
    types: [],
  },
]

export function getAutomationHandlersByType(tag: AutomationTypes): AutomationHandlerItem[] {
  return automationHandlers.filter((h) => h.types?.includes(tag))
}

export function getAutomationHandler(value: AutomationHandler): AutomationHandlerItem | undefined {
  return find(propEq('handler', value), automationHandlers)
}
