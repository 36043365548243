<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill text-center py-5">
    <div class="w-50 m-auto">
      <img src="/assets/images/channel-wizards/complete-icon.webp" alt="" height="50" />
      <h6 class="mt-2">Congratulations!</h6>
      <p>
        You've successfully connected your Airbnb account with AdvanceCM. Now you're ready to link individual listings
        to your AdvanceCM rentals.
      </p>
      <div class="d-flex gap-3 align-items-center justify-content-center mt-4">
        <button class="btn btn-secondary-info" (click)="reset.emit()">
          <i class="far fa-link"></i> Connect another account
        </button>
        <button class="btn btn-secondary-primary" (click)="channel.emit()">
          <i class="cs-icon cs-icon-list-square"></i> Channel Details
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4 py-5">
    <img src="/assets/images/channel-wizards/airbnb/airbnb-complete2.webp" alt="" class="w-75" />
  </div>
</div>
