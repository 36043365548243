<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/step-icon2.webp" alt="" height="40" />
    <div>
      <h6 class="mb-0">Request Channel Manager Access</h6>
      <div class="text-sm">This section can take between 10 minutes and 2 hrs depending on the volume of requests.</div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #extranet>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Extranet Requirements
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <ol class="lh-lg pl-3">
              <li>
                <div class="text-semi-bold">Registering a Property</div>
                <ul class="pl-3">
                  <li>You must have a property account in the Tiket.com system. To register.</li>
                  <li>Contact the Tiket.com Market Manager team at mm@tiket.com for assistance.</li>
                  <li>Register directly via the website: tix.tiket.com/register</li>
                  <li>
                    After successful registration, a Tiket.com representative will assist you in activating your
                    extranet account and property page. They will also assign a Market Manager to help manage your
                    property listing.
                  </li>
                </ul>
              </li>
              <li>
                <div class="text-semi-bold">Establishing Channel Manager Connectivity</div>
                <div class="text-semi-bold">After receiving access to the Tiket.com extranet, follow these steps:</div>
                <ol type="a" class="pl-3">
                  <li>Room Type: Create and activate a room type in the extranet.</li>
                  <li>
                    Pricing Model: Set the correct pricing model (Room-Based or Occupancy-Based) in the extranet.
                    <ul class="pl-3">
                      <li>
                        Room-Based: Set a room rate and maximum occupancy. The same rate applies regardless of the
                        number of guests.
                      </li>
                      <li>Occupancy-Based: Set a room rate based on the number of guests, which may vary.</li>
                    </ul>
                  </li>
                  <li>Rate Plan: Create, link to the room type, and activate the rate plan.</li>
                  <li>
                    API Connectivity: Request API connectivity activation by emailing hotelconnectivity@tiket.com.
                    Tiket.com recommends channel managers request connectivity on behalf of hotel clients to avoid
                    miscommunication. Provide the following information:
                  </li>
                  <ul class="pl-3">
                    <li>Property Name</li>
                    <li>Property Address (optional)</li>
                    <li>User extranet email address (optional)</li>
                    <li>
                      Ensure the above settings are completed before initiating Channel Manager connectivity. This is
                      mandatory for Channel Managers to retrieve ARI details in the API. For any issues, email
                      hotelconnectivity@tiket.com.
                    </li>
                  </ul>
                </ol>
              </li>
              <li>
                <div class="text-semi-bold">Connectivity</div>
                <ul class="pl-3">
                  <li>
                    The Tiket.com team will provide a Hotel ID to establish connectivity in the Channel Manager's
                    interface.
                  </li>
                </ul>
              </li>
            </ol>

            <div class="text-right">
              <button class="btn btn-light-info" (click)="enterPropertyId.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #enterPropertyId>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Enter Property ID
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <p>Please enter your property or hotel ID below.</p>
            <mat-form-field2 [showLabel]="false" class="w-50">
              <input matInput placeholder="Enter Property ID" [formControl]="propertyIdCtrl" required />
              <mat-error *ngIf="propertyIdCtrl.touched && propertyIdCtrl.hasError('required')">
                Property ID is required
              </mat-error>
            </mat-form-field2>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div style="max-width: 450px">
      <img src="/assets/images/channel-wizards/tiket/step3.webp" alt="" class="w-100" />
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button
    class="btn btn-secondary-info"
    matTooltip="Please enter property ID to connect to Booking.com"
    [disabled]="propertyIdCtrl.invalid || isConnecting"
    (click)="onConnect(propertyIdCtrl)"
  >
    Connect
    <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isConnecting"></i>
  </button>
</div>
