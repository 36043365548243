import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AssignInquiryDialogComponent } from './dialogs/assign/assign-inquiry-dialog.component'
import { RenameContractDialogComponent } from './dialogs/rename/rename-contract-dialog.component'
import { ShareContractDialogComponent } from './dialogs/share/share-contract-dialog.component'
import { SignerStatusComponent } from './contract-drawer/components/signer-status/signer-status.component'
import { ContractTimelineComponent } from './contract-drawer/components/timeline/contract-timeline.component'
import { ContractOptionsComponent } from './contract-drawer/components/options/contract-options.component'
import { ImageViewerDialogComponent } from './dialogs/image-viewer/image-viewer-dialog.component'

import { ContractDrawerComponent } from './contract-drawer/contract-drawer.component'
import { SignModule } from '../sign/sign.module'
import { DocumentSharedModule } from '@tv3/containers/documents/shared/document-shared.module'
import { ContractDrawerService } from './contract-drawer/contract-drawer.service'
import { ContractHelperService } from './contract-helper.service'
import { AssignInquiryDialogService } from './dialogs/assign/assign-inquiry-dialog.service'
import { ImageViewerDialogService } from './dialogs/image-viewer/image-viewer-dialog.service'
import { RenameContractDialogService } from './dialogs/rename/rename-contract-dialog.service'
import { ShareContractDialogService } from './dialogs/share/share-contract-dialog.service'
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap'

const components = [
  AssignInquiryDialogComponent,
  RenameContractDialogComponent,
  ShareContractDialogComponent,
  ImageViewerDialogComponent,

  SignerStatusComponent,
  ContractTimelineComponent,
  ContractOptionsComponent,

  ContractDrawerComponent,
]

const pipes = []

const services = [
  //
  ContractHelperService,
  ContractDrawerService,
  AssignInquiryDialogService,
  ImageViewerDialogService,
  RenameContractDialogService,
  ShareContractDialogService,
]

const modules = [
  //
  SignModule,
  DocumentSharedModule,
  NgbCarouselModule,
]

@NgModule({
  declarations: [...components, ...pipes],
  providers: [...services],
  imports: [CommonModule, ...modules],
  exports: [...components, ...pipes, ...modules],
})
export class ContractsSharedModule {}
