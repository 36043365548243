import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { DomSanitizer } from '@angular/platform-browser'
import { SignatureFileService } from '../services/file.service'

@Pipe({
  name: 'signatureSecureImage',
})
export class SignatureSecureImagePipe implements PipeTransform {
  constructor(private fileService: SignatureFileService, private domSanitizer: DomSanitizer) {}

  transform(url: string, blank = true) {
    return new Observable<any>((observer) => {
      if (blank) {
        // This is a tiny blank image
        observer.next({
          image: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
          loading: true,
        })
      }

      this.fileService.download(url).subscribe((blob) => {
        // return new observable which emits a base64 string when blob is converted to base64
        const reader = new FileReader()
        reader.readAsDataURL(blob) // convert blob to base64
        reader.onloadend = () => {
          // @ts-ignore
          // emit the base64 string result
          observer.next({ image: this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result), loading: false })
          observer.complete()
        }
      })
    })
  }
}
