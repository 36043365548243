import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './group.reducer'
import * as state from './group.state'
import * as lodash from 'lodash'
import { ResourceGroup, ResourceGroupType } from './group.model'

export const groupState = createFeatureSelector<state.State>('group')

export const selectGroups = createSelector(groupState, reducer.selectAll)

export const selectGroupEntities = createSelector(groupState, reducer.selectEntities)

export const selectGroupsLoaded = createSelector(groupState, (state: state.State) => state.isLoaded)

export const isGroupUpdating = createSelector(groupState, (state: state.State) => state.isUpdating)

export const selectGroupById = (id: string) => createSelector(selectGroupEntities, (entities) => entities[id])

export const selectAllGroups = createSelector(selectGroups, (items): ResourceGroup[] => {
  return lodash
    .chain(items)
    .orderBy((item) => lodash.toLower(item.name))
    .value()
})

export const selectTemplateGroups = createSelector(selectGroups, (items): ResourceGroup[] => {
  return lodash
    .chain(items)
    .filter((item) => !item.type || item.type === ResourceGroupType.Template)
    .value()
})

export const selectTemplateGroupsForUser = (userId: string, isAdmin: boolean) =>
  createSelector(selectGroups, (items): ResourceGroup[] => {
    return lodash
      .chain(items)
      .filter((item) => !item.type || item.type === ResourceGroupType.Template)
      .filter((item) => isAdmin || lodash.includes(item.access, userId))
      .orderBy((item) => lodash.toLower(item.name))
      .value()
  })

export const selectDocumentGroupsForUser = (userId: string, isAdmin: boolean) =>
  createSelector(selectGroups, (items): ResourceGroup[] => {
    return (
      lodash
        .chain(items)
        // .filter(item => item.type === ResourceGroupType.Document)
        .filter((item) => isAdmin || lodash.includes(item.access, userId))
        .orderBy((item) => lodash.toLower(item.name))
        .value()
    )
  })
