import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import * as moment from 'moment'

import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { AddBillingDialogComponent } from '@tv3/shared/add-billing/add-billing-dialog.component'
import { ViewBillingDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/view-billing/view-billing-dialog.component'
import { UpdateQuoteDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/update-quote/update-quote-dialog.component'
import { UpdateInquiryDetailsDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/update-details/update-inquiry-details-dialog.component'
import { EditAdditionalGuestsComponent } from '@tv3/containers/inquiries/dialogs/shared/edit-additional-guests/edit-additional-guests.component'
import { EditGuestDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/edit-guest/edit-guest-dialog.component'
import { AddInquiryDialogParams } from '@tv3/interfaces/dialogs/add-inquiry-dialog-params'
import { AddInquiryDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/add-inquiry/add-inquiry-dialog.component'
import { DataEntityType, DialogService, asLocalDate } from '@tokeet-frontend/tv3-platform'
import { PaymentGateway } from '@tokeet-frontend/gateways'
import { GetGuest } from '@tv3/store/guest/guest.actions'
import { Store } from '@ngrx/store'
import { ManageEntityTagsDialogService } from '@tokeet-frontend/tags'

@Injectable()
export class InquirySharedDialogsService {
  constructor(
    private dialog: MatDialog,
    private sidePanel: DialogService,
    private store: Store<any>,
    private manageTags: ManageEntityTagsDialogService
  ) {}

  openAddBilling(inquiry: Inquiry) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        inquiry,
      },
    }

    return this.dialog.open(AddBillingDialogComponent, defaultConfig)
  }

  openTags(inquiry: Inquiry) {
    return this.manageTags.open({
      type: DataEntityType.Inquiry,
      id: inquiry.id,
      title: 'Inquiry Tags',
      description: `This feature allows you to add arbitrary descriptors to this inquiry which can then be used when filtering on various reports and calendars.`,
      tags: inquiry.attributes?.tags || [],
      showSuggesttedTags: true,
    })
  }

  openViewBilling(inquiry: Inquiry, payment: PaymentGateway) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      panelClass: ['variable-modal'],
      data: {
        inquiry,
        payment,
      },
    }

    return this.dialog.open(ViewBillingDialogComponent, defaultConfig)
  }

  openUpdateQuote(inquiry: Inquiry) {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        inquiry: inquiry,
      },
    }

    return this.dialog.open(UpdateQuoteDialogComponent, defaultConfig)
  }

  openUpdateDetails(inquiry: Inquiry) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        inquiry: inquiry,
      },
    }

    return this.dialog.open(UpdateInquiryDetailsDialogComponent, defaultConfig)
  }

  openEditAdditionalGuests(inquiry) {
    const defaultConfig: MatDialogConfig = {
      width: '800px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        inquiry,
      },
    }

    return this.dialog.open(EditAdditionalGuestsComponent, defaultConfig)
  }

  openEditGuest(inquiry) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        inquiry,
      },
    }

    return this.dialog.open(EditGuestDialogComponent, defaultConfig)
  }

  cloneInquiry(inquiry: Inquiry) {
    const startDate = asLocalDate(inquiry.guestArrive)
    const endDate = asLocalDate(inquiry.guestDepart)

    this.store.dispatch(GetGuest({ id: inquiry.guestId }))

    return this.openAddInquiry({
      title: inquiry.booked ? 'Clone Booking' : 'Clone Inquiry',
      start: startDate,
      end: endDate,
      isBooking: !!inquiry.booked,
      isClone: true,
      defaults: {
        arriveDate: startDate,
        departDate: endDate,
        rentalId: inquiry.rentalId,
        guestId: inquiry.guestId,
        adults: inquiry.numAdults,
        children: inquiry.numChild,
        source: inquiry.inquirySource,
      },
    })
  }

  openAddInquiry(params?: AddInquiryDialogParams) {
    return this.sidePanel.openDrawer(AddInquiryDialogComponent, {
      data: params || {},
      panelClass: ['drawer-full-60'],
    })
  }

  openCreateBooking(start?: number, end?: number, rentalId?: string) {
    const startDate = start && asLocalDate(start)
    const endDate = end && asLocalDate(end)

    return this.openAddInquiry({
      title: 'Create Booking',
      start: startDate,
      end: endDate,
      isBooking: true,
      defaults: {
        arriveDate: startDate,
        departDate: endDate,
        rentalId,
      },
    })
  }
}
