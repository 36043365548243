import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { FormControl } from '@angular/forms'
import { Observable, of } from 'rxjs'

@Component({
  selector: 'app-rental-select',
  templateUrl: './rental-select.component.html',
  styleUrls: ['./rental-select.component.scss'],
})
export class RentalSelectComponent implements OnInit {
  @Input() ctrl: FormControl
  @Input() placeholder = 'Select Rentals'
  @Input() label = 'Select Rentals'
  @Input() multiple = true
  @Input() set inline(b: boolean) {
    this.showLabel = !b
  }
  @Input() showBlankItem = false

  @Input() showLabel = true
  @Input() showPlaceholder = true
  @Input() disabled = false
  @Input() set items(items: Rental[]) {
    this.rentals$ = of(items)
  }
  @Input() rentals$: Observable<Rental[]> = this.store.pipe(select(selectAllRentals))

  @Input() showMasterCheckbox = true
  @Input() showCloseButton = false

  @Output() openedChange = new EventEmitter<boolean>()
  @Output() selectedValuesChange = new EventEmitter<string[]>()
  @Output() selectedItemsChange = new EventEmitter<Rental[]>()

  getRentalLabel = (r: Rental) => r?.nickname || r?.name

  constructor(private store: Store<any>) {}

  ngOnInit(): void {}
}
