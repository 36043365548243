<div class="modal-header">
  <h5 class="modal-title">Rename Document</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <input matInput type="text" formControlName="name" placeholder="Name" [required]="true" />
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="close()">Cancel</button>

  <button type="button" class="btn btn-secondary-info" (click)="onSave(form)"><i class="fas fa-save"></i> Save</button>
</div>
