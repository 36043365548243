import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap, toArray } from 'rxjs/operators'

import {
  CreateContractTemplate,
  CreateContractTemplateComplete,
  DeleteContractTemplate,
  DeleteContractTemplateComplete,
  DeleteMultipleContractTemplate,
  DeleteMultipleContractTemplateComplete,
  GetContractTemplate,
  GetContractTemplateComplete,
  LoadContractTemplates,
  LoadContractTemplatesComplete,
  UpdateContractTemplate,
  UpdateContractTemplateComplete,
} from './template.actions'
import { TemplateService } from './template.service'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class TemplateEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadContractTemplates),
      switchMap(() =>
        this.templateService.list().pipe(
          map((items) => LoadContractTemplatesComplete({ items })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetContractTemplate),
      switchMap(({ id }) =>
        this.templateService.get(id).pipe(
          map((item) => GetContractTemplateComplete({ item })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateContractTemplate),
      switchMap(({ payload }) =>
        this.templateService.create(payload).pipe(
          map((item) => CreateContractTemplateComplete({ item })),
          tap(() => this.toaster.success('Template created successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateContractTemplate),
      concatMap(({ id, payload, message, silent }) =>
        this.templateService.update(id, payload).pipe(
          map((res) => UpdateContractTemplateComplete({ update: { id, changes: res } })),
          tap(() => !silent && this.toaster.success(message || 'Template updated successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteContractTemplate),
      switchMap(({ id }) =>
        this.templateService.delete(id).pipe(
          map((res) => DeleteContractTemplateComplete({ id })),
          tap(() => this.toaster.success('Template deleted successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  deleteMultiple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMultipleContractTemplate),
      switchMap(({ ids }) =>
        of(...ids)
          .pipe(
            concatMap((id) => this.templateService.delete(id)),
            toArray()
          )
          .pipe(
            map((res) => DeleteMultipleContractTemplateComplete({ ids })),
            tap(() => this.toaster.success('Template(s) deleted successfully')),
            catchError((error) => of(ActionFailed({ error })))
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private store: Store<any>,
    private templateService: TemplateService
  ) {}
}
