import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { filter, take } from 'rxjs/operators'
import { SignatureDrawerComponent } from './components/signature-drawer/signature-drawer.component'
import { SignatureEditorSize, SignatureHelperService } from './components/signature-helper.service'
import { SignatureTexterComponent } from './components/signature-texter/signature-texter.component'
import { SignatureUploaderComponent } from './components/signature-uploader/signature-uploader.component'
import {
  GetCurrentUser,
  selectCurrentSignatureUser,
  UpdateCurrentSignatureUser,
  UpdateCurrentSignatureUserComplete,
  UserSignature,
  UserSignatureType,
} from '@tokeet-frontend/signature'
import { ActionFailed, Destroyable, isSomething, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

enum SignatureEditorTab {
  Drawer = 1,
  TextEditor = 2,
  Uploader = 3,
}

export enum SignatureEditorType {
  CurrentUserSignature,
  GetUserSignature,
}

export interface GetUserSignatureData {
  signature: string
  size: {
    width: number
    height: number
  }
}

@Component({
  selector: 'app-signature-editor-dialog',
  templateUrl: './signature-editor-dialog.component.html',
  styleUrls: ['./signature-editor-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class SignatureEditorDialogComponent extends Destroyable implements OnInit {
  currentTab = SignatureEditorTab.Drawer
  tabs = SignatureEditorTab

  @ViewChild('signatureDrawer') signatureDrawer: SignatureDrawerComponent
  @ViewChild('signatureTexter') signatureTexter: SignatureTexterComponent
  @ViewChild('signatureUploader') signatureUploader: SignatureUploaderComponent

  isSaving = false

  editorSize: SignatureEditorSize = {
    width: 450,
    height: 160,
  }

  minWidth = 50
  minHeight = 30
  userSignature: UserSignature

  constructor(
    private toaster: Toaster,
    private store: Store<any>,
    private actions: Actions,
    private signatureHelperService: SignatureHelperService,
    private dialogRef: MatDialogRef<SignatureEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { type: SignatureEditorType }
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(GetCurrentUser())

    this.actions.pipe(ofType(UpdateCurrentSignatureUser), untilDestroy(this)).subscribe(() => {
      this.isSaving = true
    })
    this.actions.pipe(ofType(UpdateCurrentSignatureUserComplete, ActionFailed), untilDestroy(this)).subscribe(() => {
      this.isSaving = false
    })

    this.store.pipe(select(selectCurrentSignatureUser), filter(isSomething), untilDestroy(this)).subscribe((user) => {
      this.userSignature = user
      // @ts-ignore
      this.currentTab = user?.attributes?.signature_type || this.currentTab
    })
  }

  close() {
    this.dialogRef.close()
  }

  isSavable() {
    switch (this.currentTab) {
      case SignatureEditorTab.Drawer:
        return !this.signatureDrawer?.isEmpty()
      case SignatureEditorTab.TextEditor:
        return !this.signatureTexter?.isEmpty()
      case SignatureEditorTab.Uploader:
        return !this.signatureUploader?.isEmpty()
      default:
        return true
    }
  }

  getSignature() {
    switch (this.currentTab) {
      case SignatureEditorTab.Drawer:
        return {
          signature: this.signatureDrawer.getSignature(),
          type: SignatureEditorTab.Drawer,
        }
      case SignatureEditorTab.TextEditor:
        return {
          signature: this.signatureTexter.getSignature(),
          type: SignatureEditorTab.TextEditor,
          signatureText: this.signatureTexter.getSignatureText(),
        }
      case SignatureEditorTab.Uploader:
        return {
          signature: this.signatureUploader.getSignature(),
          type: SignatureEditorTab.Uploader,
        }
    }
  }

  onReset() {
    this.store.dispatch(
      UpdateCurrentSignatureUser({
        payload: {
          signature: '',
          attributes: {
            ...this.userSignature?.attributes,
            signature_type: UserSignatureType.Empty,
            signature_text: '',
          },
        },
        message: 'Signature reset successfully.',
      })
    )
  }

  onSave() {
    if (this.data.type === SignatureEditorType.GetUserSignature) {
      this.onGetSignatureData()
    } else {
      this.onSaveUserSignature()
    }
  }

  onGetSignatureData() {
    const { signature, type, signatureText } = this.getSignature()
    this.signatureHelperService.trim(signature).subscribe(({ data, width, height }) => {
      if (width < this.minWidth || height < this.minHeight) {
        this.toaster.warning("Please make your signature larger. It doesn't meet the minimum size requirement.")
      } else {
        this.dialogRef.close({ signature: data, size: { width, height } })
      }
    })
  }

  onSaveUserSignature() {
    const { signature, type, signatureText } = this.getSignature()
    this.signatureHelperService.trim(signature).subscribe(({ data, width, height }) => {
      if (width < this.minWidth || height < this.minHeight) {
        this.toaster.warning(`Please make your signature larger. It doesn't meet the minimum size requirement.`)
      } else {
        this.store.dispatch(
          UpdateCurrentSignatureUser({
            payload: {
              signature: data,
              attributes: {
                ...this.userSignature?.attributes,
                signature_type: type as number,
                signature_text: signatureText,
              },
            },
            message: 'Signature saved successfully.',
          })
        )
        this.actions.pipe(ofType(UpdateCurrentSignatureUserComplete), take(1)).subscribe(() => {
          this.close()
        })
      }
    })
  }
}
