import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core'
import * as lodash from 'lodash'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { SignatureRole, selectAllContractTemplates, SignFieldDef, SignFieldType } from '@tokeet-frontend/signature'
import { TemplateTypes } from '@tokeet-frontend/signature'
import { FieldPlaceholderComponent } from '@tv3/containers/documents/templates/template-drawer/components/fields/field-placeholder.component'

@Injectable({ providedIn: 'root' })
export class SignPrepareService {
  constructor(private store: Store<any>, private componentFactory: ComponentFactoryResolver) {}

  getReadyTemplates() {
    return this.store.pipe(
      select(selectAllContractTemplates),
      map((items) => lodash.filter(items, (item) => this.isTemplateReady(item.type, item.roles, item.fields)))
    )
  }

  getReadyTemplateForGuest() {
    return this.getReadyTemplates().pipe(
      map((items) => lodash.filter(items, (item) => lodash.some(item.roles, (r) => !SignatureRole.isUserRole(r))))
    )
  }

  getReadyTemplateForUser() {
    return this.getReadyTemplates().pipe(
      map((items) => lodash.filter(items, (item) => lodash.some(item.roles, (r) => SignatureRole.isUserRole(r))))
    )
  }

  isTemplateReady(type: TemplateTypes, requiredRoles: string[], fields: Partial<SignFieldDef>[]) {
    let roles = []
    switch (type) {
      case TemplateTypes.Contract:
        roles = lodash.uniq(
          lodash.map(
            lodash.filter(fields, (field) => field.type === SignFieldType.Signature),
            'role'
          )
        )
        return !lodash.difference(requiredRoles, roles).length
      case TemplateTypes.Document:
        return true
      case TemplateTypes.Form:
        roles = lodash.uniq(lodash.map(fields, 'role')) // at least one field for each roles
        return !lodash.difference(requiredRoles, roles).length
    }
  }

  appendTo<T = FieldPlaceholderComponent>(com: Type<T>, container: ViewContainerRef): ComponentRef<T> {
    const factory = this.componentFactory.resolveComponentFactory(com)
    const component = factory.create(container.injector)
    container.insert(component.hostView)

    return component
  }
}
