<div class="modal-header">
  <div class="col-sm-4">
    <h5 class="modal-title"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> Task</h5>
  </div>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="modal-body p-0">
  <ul class="nav top-navbar border-bottom mb-3">
    <li [class.active]="activeTab === tabs.Details">
      <a (click)="activeTab = tabs.Details"> Details </a>
    </li>
    <li [class.active]="activeTab === tabs.Checklist">
      <a (click)="activeTab = tabs.Checklist"> Checklist </a>
    </li>
    <li [class.active]="activeTab === tabs.Recurring">
      <a (click)="activeTab = tabs.Recurring"> Recurring </a>
    </li>
    <ng-container *ngIf="isEdit">
      <li [class.active]="activeTab === tabs.InquiryDetails" *ngIf="form.get('inquiry_id').value">
        <a (click)="activeTab = tabs.InquiryDetails"> Inquiry Details </a>
      </li>
      <li [class.active]="activeTab === tabs.Attributes">
        <a (click)="activeTab = tabs.Attributes"> Custom Attributes </a>
      </li>
      <li [class.active]="activeTab === tabs.Conversations">
        <a (click)="activeTab = tabs.Conversations"> Comments </a>
      </li>
      <li [class.active]="activeTab === tabs.History">
        <a (click)="activeTab = tabs.History"> Logs </a>
      </li>
    </ng-container>
  </ul>

  <ng-container [ngSwitch]="activeTab">
    <ng-template *ngSwitchCase="tabs.Details" [ngTemplateOutlet]="taskDetails"></ng-template>
    <ng-template *ngSwitchCase="tabs.Recurring" [ngTemplateOutlet]="recurringFormTpl"></ng-template>
    <ng-template *ngSwitchCase="tabs.Checklist" [ngTemplateOutlet]="checklistFormTpl"></ng-template>
    <app-task-conversations
      *ngSwitchCase="tabs.Conversations"
      [taskId]="task?.pkey"
      class="p-3"
    ></app-task-conversations>
    <app-task-inquiry-details
      *ngSwitchCase="tabs.InquiryDetails"
      [inquiryId]="task?.inquiry_id"
      class="p-3"
    ></app-task-inquiry-details>
    <app-task-history *ngSwitchCase="tabs.History" [taskId]="task?.pkey" class="p-3"></app-task-history>
    <div class="bg-white p-3 rounded-bottom" *ngSwitchCase="tabs.Attributes">
      <app-entity-attributes
        #attrs
        entityType="task"
        [entityId]="task?.pkey"
        [showActions]="false"
      ></app-entity-attributes>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary-danger" (click)="onDelete()" *ngIf="isEdit">
    <i class="cs-icon cs-icon-trash"></i> Delete
  </button>

  <div class="flex-fill"></div>
  <button (click)="close()" type="button" class="btn btn-light">
    <span class="far fa-times"></span>
    Cancel
  </button>

  <ng-container *ngIf="activeTab === tabs.Attributes && attrs">
    <button (click)="attrs.onConfig()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-primary">
      <span class="cs-icon cs-icon-cog"></span>
      Attributes Configuration
    </button>
    <button
      (click)="onSaveAttrs()"
      [disabled]="(1 | isReadonlyRole) || attrs.isSaving"
      type="button"
      class="btn btn-secondary-info"
    >
      <i class="cs-icon cs-icon-save" *ngIf="!attrs.isSaving"></i>
      <i class="far fa-spin fa-spinner" *ngIf="attrs.isSaving"></i>
      Save
    </button>
  </ng-container>

  <button
    *ngIf="activeTab !== tabs.Attributes"
    (click)="onSave(form)"
    type="button"
    [disabled]="(1 | isReadonlyRole) || (isUpdatingTasks$ | async)"
    class="btn btn-secondary-info"
  >
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
</div>

<ng-template #taskDetails>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="p-3">
      <div class="row">
        <div class="col-md-6 d-flex gap-3">
          <mat-form-field2 class="mat-block">
            <mat-label>Task Name</mat-label>
            <input type="text" placeholder="Some task name will go here" matInput formControlName="name" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('minlength')">
              Minimum length is <strong>{{ form.get('name').errors?.minlength.requiredLength }}</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('maxlength')">
              Maximum length is <strong>{{ form.get('name').errors?.maxlength.requiredLength }}</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('allNumbers')"> Name cannot be numbers alone. </mat-error>
          </mat-form-field2>
          <div class="d-flex align-items-center gap-3" *ngIf="isEdit">
            <app-task-status [task]="data.task" class="text-sm"></app-task-status>

            <app-task-star-btn class="btn btn-outline-light btn-sm" [task]="task"></app-task-star-btn>
          </div>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input
              type="text"
              placeholder="Select Task List"
              matInput
              formControlName="list"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let list of filteredLists | async" [value]="list">
                {{ list }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('list').touched && form.get('list').hasError('required')">
              Task list is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <app-mat-date-input
              formControlName="start"
              placeholder="Start Date & Time"
              [showTime]="true"
              [min]="minDateDefault"
              [max]="form.get('due').value"
              required
            ></app-mat-date-input>

            <span matPrefix><i class="fal fa-calendar"></i> </span>
            <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
              Start Date & Time is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('start').hasError('min')">
              Min value is
              <strong>{{ form.get('start').getError('min').min | epoch : 'datetime' }}</strong>
            </mat-error>
            <mat-error *ngIf="form.get('start').hasError('max')">
              Max value is
              <strong>{{ form.get('start').getError('max').max | epoch : 'datetime' }}</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <app-mat-date-input
              formControlName="due"
              placeholder="Due Date & Time"
              [showTime]="true"
              [min]="form.get('start').value || minDateDefault"
              required
            ></app-mat-date-input>

            <span matPrefix><i class="fal fa-calendar"></i> </span>
            <mat-error *ngIf="form.get('due').touched && form.get('due').hasError('required')">
              Due Date & Time is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('due').hasError('min')">
              Min value is
              <strong>{{ form.get('due').getError('min').min | epoch : 'datetime' }}</strong>
            </mat-error>
            <mat-error *ngIf="form.get('due').hasError('max')">
              Max value is
              <strong>{{ form.get('due').getError('max').max | epoch : 'datetime' }}</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-timezone-select [ctrl]="form.get('timezone')"></app-timezone-select>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <app-select
              [items]="users$ | async"
              [formControl]="form.get('user_ids')"
              bindLabel="name"
              bindValue="id"
              [inline]="false"
              [multiple]="true"
              [searchable]="true"
              [required]="true"
              placeholder="Assign People"
            ></app-select>
            <mat-error *ngIf="form.get('user_ids').touched && form.get('user_ids').hasError('required')">
              Assign People is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-rental-select
            [ctrl]="form.get('rental_id')"
            [multiple]="false"
            placeholder="Select rental"
            (selectedItemsChange)="form.get('inquiry_id').reset()"
          >
          </app-rental-select>
        </div>
        <div class="col-md-6">
          <app-booking-select
            [ctrl]="form.get('inquiry_id')"
            [rentalId]="form.get('rental_id').value"
            (selected)="onSelectInquiry($event)"
          ></app-booking-select>
        </div>
        <div class="col-md-6">
          <app-select-search
            [items]="incidents$ | async"
            [ctrl]="form.get('incident_id')"
            [inline]="false"
            [multiple]="false"
            bindLabel="title"
            bindValue="pkey"
            placeholder="Select Incident"
          >
          </app-select-search>
        </div>
        <div class="col-md-6">
          <app-entity-form-tags
            type="task"
            [id]="task?.pkey"
            [ctrl]="form.get('tags')"
            label="Tags"
          ></app-entity-form-tags>
        </div>
        <div class="col-md-6 mb-3">
          <label>Color <sup class="text-danger">*</sup></label>
          <app-color-boxes [ctrl]="form.get('color')" [colors]="colors" [boxSize]="20"></app-color-boxes>
        </div>
        <div class="col-md-12">
          <mat-form-field2 class="mat-block">
            <mat-label>Task Notes</mat-label>
            <textarea
              matInput
              #notes
              maxlength="500"
              rows="3"
              formControlName="notes"
              placeholder="Some Task details & description of the task will come here along with supporting files & any information will go here"
              cdkTextareaAutosize
            ></textarea>
            <mat-hint align="end">{{ form.get('notes').value?.length || 0 }}/500</mat-hint>
          </mat-form-field2>
        </div>
      </div>
      <app-upload-box
        [options]="uploadBoxOptions"
        [showFileList]="false"
        *ngIf="!attachments.length && !(1 | isReadonlyRole)"
        (complete)="onUploadComplete($event)"
      ></app-upload-box>
      <div *ngFor="let item of attachments; let i = index" class="d-flex align-items-center gap-3">
        <div class="text-primary text-xxl">
          <i class="" [ngClass]="item.name | fileIcon"></i>
        </div>
        <div class="mr-4">{{ item.name }}</div>
        <div class="flex-fill text-light text-right text-sm">
          {{ item.created | epoch : 'DD-MMM-YYYY' }} at {{ item.created | epoch : 'hh:mm A' }} • {{ item.user }} •
          {{ item.size | fileSize }}
        </div>
        <div>
          <a href="javascript:;" class="btn btn-light-info btn-sm" (click)="onDownloadAttachment(item)"
            ><i class="cs-icon cs-icon-download"></i>
          </a>
          <a
            href="javascript:;"
            class="btn btn-light-danger btn-sm"
            (click)="onDeleteAttachment(item)"
            *ngIf="!(1 | isReadonlyRole)"
            ><i class="cs-icon cs-icon-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #recurringFormTpl>
  <app-recurring-form
    [form]="recurringForm"
    [repeatableCtrl]="form.get('repeat')"
    class="d-block p-3"
  ></app-recurring-form>
</ng-template>

<ng-template #checklistFormTpl>
  <app-task-checklist [form]="checklistForm" class="d-block"></app-task-checklist>
</ng-template>
