import * as lodash from 'lodash'

export enum SignerRoles {
  Administrator = 'Administrator',
  PropertyManager = 'Property Manager',
  Accounting = 'Accounting',
  HouseStaff = 'House Staff',
  PropertyOwner = 'Property Owner',
  User = 'User',
  Guest = 'Guest',
  Contact = 'Contact',
}

export const allTokeetUserRoles = [
  SignerRoles.Administrator,
  SignerRoles.Accounting,
  SignerRoles.HouseStaff,
  SignerRoles.PropertyManager,
  SignerRoles.PropertyOwner,
]

export const availableTokeetUserRoles = [...allTokeetUserRoles]

export const allUserRoles = [SignerRoles.User, ...allTokeetUserRoles]

export const allGuestRoles = [SignerRoles.Guest, SignerRoles.Contact]

export const availableSignerRoles = [SignerRoles.Guest, SignerRoles.User]

function similarRole(role1: string, role2: string): boolean {
  return role1.indexOf(role2) > -1 || role2.indexOf(role1) > -1
}

export function isGuestRole(r: string): boolean {
  r = lodash.toLower(r).trim()
  return !!getGuestSignerRolesLabel().find((role) => similarRole(lodash.toLower(role), r))
}

export function isUserRole(r: string): boolean {
  r = lodash.toLower(r).trim()
  return !!['Admin', 'Manager', 'Accounting', 'Owner', 'Staff', 'User'].find((role) =>
    similarRole(lodash.toLower(role), r)
  )
}

export function getGuestSignerRolesLabel(): string[] {
  return allGuestRoles
}

export function getAvailableSignerRolesLabel(): string[] {
  return availableSignerRoles
}
