<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Airbnb with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Linked Calendars </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              When connecting Airbnb with AdvanceCM, any previously set links between your rentals will be removed by
              Airbnb. To re-establish these links,
              <a href="http://help.tokeet.com/rental-settings/linking-rentals-together" target="_blank"
                >follow this process</a
              >. Remember to import your rentals from Airbnb before creating these links.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Smart Pricing and Rule Sets </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                When connecting Airbnb with AdvanceCM, Smart Pricing will be disabled. There is no way to retain
                Airbnb’s Smart Pricing functionality while connected to a channel manager via the official API. Changes
                to your Airbnb rates will be done through the AdvanceCM rates calendar going forward.
              </p>
              <p>
                Additionally, any rule sets created using the Airbnb rates and availability calendar will be ignored
                once you’ve established a connection with AdvanceCM.
              </p>
              <p>
                Please take note of this so that you can adjust your pricing as needed after the connection has been
                completed.
              </p>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Outside Pricing Tools </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Though you are unable to retain control of Airbnb’s pricing tools when connected via the official API,
              connections between Airbnb and outside pricing tools like PriceLabs or BeyondPricing will not be affected.
              You can continue pushing rates from these websites directly to Airbnb as long as you choose not to
              overwrite those prices by pushing rates manually from AdvanceCM.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Passing the Airbnb Review step </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Before proceeding with the connection wizard it is recommended that you ensure your listing will pass
                Airbnb’s review process.
              </p>

              The guidelines for this are as follows:
              <ul>
                <li>A minimum of 7 photos (3 of them being high quality)</li>
                <li>A listing Title that does not exceed 50 characters in length</li>
                <li>A listing Summary(Description) that is a minimum of 50 characters but no more than 500.</li>
              </ul>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Importing or Creating AdvanceCM Rentals </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              The official Airbnb API allows you to import your properties which saves you the step of creating these
              rentals in AdvanceCM. One of the benefits of doing so is that Airbnb photos and the rental description
              will be imported with the property. If you have already created rentals and choose not to import them from
              Airbnb you will be able to simply link the already existing AdvanceCM rentals to your Airbnb listing(s) as
              needed.
            </p>
            <p>
              If you would like to delete the listings you have created in order to take advantage of this Import
              function please see
              <a href="http://help.tokeet.com/rental-settings/how-do-i-delete-a-rental" target="_blank">the guide</a>
              for deleting AdvanceCM rentals.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Connecting Co-host Listings </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                If any of the listings you wish to connect with AdvanceCM are co-host listings there are some important
                items to note before proceeding.
              </p>
              <ul>
                <li>Co-host connections are not officially supported via the Airbnb API.</li>
                <li>
                  A process must be followed after connection to ensure that availability is pushed and messaging from
                  AdvanceCM to the guest via Airbnb works properly.
                </li>
                <li>
                  Rates cannot be pushed from AdvanceCM to Airbnb on co-host listings. Your rate tools on Airbnb will
                  still be accessible after using the co-host workaround steps offered later in this guide.
                </li>
                <li>
                  Multiple Airbnb accounts that share the same listing via the co-host function in Airbnb cannot be
                  connected to AdvanceCM at the same time. In order to connect both accounts, one account must remove
                  the co-host before a connection can be established with both accounts.
                </li>
              </ul>
              Please see
              <a href="https://help.tokeet.com/channel-manager/airbnb/co-hosting-with-airbnb-api-new" target="_blank"
                >the guide</a
              >
              for information on how to address these listings once you have completed the connection on all other
              listings.
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="authorize.emit()">
          Connect
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
