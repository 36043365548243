<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <app-upload-box
    [options]="getUploadBoxOptions()"
    (complete)="onFilesUploaded($event)"
    direction="v"
    image="/assets/images/upload-formate.png"
    #uploadBox
  >
  </app-upload-box>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary-info" (click)="onClose()" [disabled]="uploadedData?.length < limit">
    <i class="fas fa-save"></i>
    Save
  </button>
</div>
