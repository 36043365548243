<label [class.required]="field.required">
  <ng-container [ngSwitch]="inputType">
    <ng-container *ngSwitchCase="'phone'">
      <app-national-phone-field
        [ctrl]="ctrl"
        [required]="field.required"
        [ngStyle]="fieldStyle"
      ></app-national-phone-field>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <input
        #input
        [type]="inputType"
        [formControl]="ctrl"
        [required]="field.required"
        [ngStyle]="fieldStyle"
        [placeholder]="field.label"
        [maxlength]="textLimit"
        class="input"
      />
    </ng-container>
  </ng-container>
</label>
