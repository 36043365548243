import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { BehaviorSubject, of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { UserSignatureService } from './user-signature.service'
import {
  GetCurrentUser,
  GetCurrentUserComplete,
  UpdateCurrentSignatureUser,
  UpdateCurrentSignatureUserComplete,
} from './user-signature.actions'
import { ActionFailed, CURRENT_USER, Toaster, User } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class UserSignatureEffects {
  currentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCurrentUser),
      switchMap(() =>
        this.users.getSignatureUser(this.user$.value?.id).pipe(
          map((user) => GetCurrentUserComplete({ user })),
          catchError((error) => of(ActionFailed(error)))
        )
      )
    )
  )

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCurrentSignatureUser),
      switchMap(({ payload, message }) =>
        this.users.updateSignatureUser(this.user$.value?.id, payload).pipe(
          map((user) => UpdateCurrentSignatureUserComplete({ user })),
          tap(() => this.toaster.success(message || 'User data saved successfully.')),
          catchError((error) => of(ActionFailed(error)))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private users: UserSignatureService,
    @Inject(CURRENT_USER) private user$: BehaviorSubject<User>
  ) {}
}
