import { Component, NgZone, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { MatDialogRef } from '@angular/material/dialog'
import {
  ConfirmDialogService,
  ConfirmDialogStatus,
  isSomething,
  notAllSpacesValidator,
  RentalEmailValidator,
  SaveForm,
} from '@tokeet-frontend/tv3-platform'
import { countries } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { RentalForm } from '@tv3/interfaces/forms/rental-form'
import { addRental, selectSubdomain } from '@tokeet-frontend/tv3-platform'
import { RentalEmailNotTakenValidator } from '@tokeet-frontend/tv3-platform'
import { convertToTokeetEmail } from '@tv3/utils/functions/convert-to-tokeet-email'
import { RentalNameNotTakenValidator } from '@tokeet-frontend/tv3-platform'
import { notAllNumbersValidator } from '@tokeet-frontend/tv3-platform'
import { ParsedGooglePlace } from '@tokeet-frontend/tv3-platform'
import { Router } from '@angular/router'
import { SubdomainGuard } from '@tokeet-frontend/tv3-platform'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import { selectSomeOnce } from '@tokeet-frontend/tv3-platform'
import { selectAccount } from '@tokeet-frontend/tv3-platform'
import { MapsAPILoader } from '@agm/core'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { selectActiveTokeetPlanView } from '@tv3/store/plan/plan.selectors'
import { UserStorage } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-add-rental-dialog',
  templateUrl: './add-rental-dialog.component.html',
  styleUrls: ['./add-rental-dialog.component.scss'],
})
export class AddRentalDialogComponent extends Destroyable implements OnInit {
  countryList = countries

  form = this.fb.group({
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(140),
        notAllNumbersValidator,
        notAllSpacesValidator,
      ],
      RentalNameNotTakenValidator.createValidator(this.store),
    ],
    nickname: ['', [Validators.minLength(2), Validators.maxLength(140), notAllNumbersValidator, notAllSpacesValidator]],
    phone: ['', [Validators.required, notAllSpacesValidator]],
    email: [
      '',
      [Validators.required, Validators.minLength(3)],
      [RentalEmailNotTakenValidator.createValidator(this.store), RentalEmailValidator.createValidator(this.store)],
    ],
    color: [''],
    address: ['', [Validators.minLength(2), Validators.maxLength(140), notAllSpacesValidator]],
    city: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.required, notAllSpacesValidator]],
    state: ['', [Validators.minLength(2), Validators.maxLength(50), notAllSpacesValidator]],
    zip: ['', [Validators.minLength(2), Validators.maxLength(50), notAllSpacesValidator]],
    country: [''],
    registration: [''],
    gps: [{}],
  })

  subDomain: string
  isGoogleLoaded = false

  isStandaloneTokeetSubscription = false

  constructor(
    protected fb: FormBuilder,
    protected store: Store<any>,
    private zone: NgZone,
    private router: Router,
    private storage: UserStorage,
    private auth: AuthService,
    private confirm: ConfirmDialogService,
    private mapsApiLoader: MapsAPILoader,
    private dataCheckerService: DataCheckerService,
    public dialogRef: MatDialogRef<AddRentalDialogComponent>,
    private amplitudeService: AmplitudeService
  ) {
    super()
    this.dataCheckerService.check([SubdomainGuard])
  }

  ngOnInit() {
    this.store.pipe(select(selectActiveTokeetPlanView), untilDestroy(this)).subscribe((sub) => {
      this.isStandaloneTokeetSubscription = sub.version === 'v4'
    })

    this.mapsApiLoader.load().then(() => {
      this.isGoogleLoaded = true
    })

    this.store.pipe(select(selectSubdomain), untilDestroy(this)).subscribe((subDomain) => {
      this.subDomain = subDomain
    })
  }

  goToAccountSettings() {
    this.router.navigate(['/account'])
    this.close()
  }

  goToConnections() {
    this.router.navigate(['/channels'])
    this.close()
  }

  onNameChange(name: string) {
    const firstPart = name
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .split(/\s+/)
      .join('.')
    this.form.patchValue({ email: firstPart })
  }

  onPlaceChange(parsedPlace: ParsedGooglePlace) {
    this.form.patchValue({
      address: (parsedPlace.streetNumber ? parsedPlace.streetNumber + ' ' : '') + (parsedPlace.street || ''),
      city: parsedPlace.city,
      state: parsedPlace.state,
      country: R.find((c) => c.id === parsedPlace.countryShort, this.countryList) || {},
      gps: parsedPlace.gps || {},
      zip: parsedPlace.postCode,
    })
  }

  onCityChange(parsedPlace: ParsedGooglePlace) {
    const address = this.form.get('address').value || ''
    const formCity = this.form.get('city').value || ''
    const googleCity = (parsedPlace.city || '')?.trim()
    this.form.patchValue({
      city: isSomething(googleCity) ? googleCity : formCity,
    })
    if (R.isEmpty(address.trim()) || R.isEmpty(this.form.get('gps').value)) {
      const country = R.find((c: any) => c.id === parsedPlace.countryShort, this.countryList)
      console.log(`set gps=${parsedPlace.gps} and country=${country}`)
      this.form.patchValue({
        gps: parsedPlace.gps || {},
        country: country || {},
      })
    }
    console.log(parsedPlace)
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValue = form.getRawValue() as RentalForm
    const formResult = this.convertToTokeetEmail(formValue)
    const doAction = () => {
      this.store.pipe(selectSomeOnce(selectAccount(this.auth.user.account))).subscribe((account) => {
        this.amplitudeService.logEvent('add-rental-manually')
        this.store.dispatch(addRental({ form: formResult, account }))
        this.close()
      })
    }

    const disableNotifyKey = 'disable-create-rental-cost-notification'
    if (this.isStandaloneTokeetSubscription && !this.storage.get(disableNotifyKey)) {
      this.confirm
        .confirmExtra(
          {
            title: 'Create Rental',
            body: 'Creating a Rental will increase your pricing. Consult the billing info page for more details on cost.',
            cancelText: 'MOVE TO BILLING INFO',
            cancelBtnClass: 'btn btn-secondary-info',
            confirmText: 'CREATE RENTAL',
            extra: 'Disable this notification forever',
            defaultExtraValue: false,
          },
          true
        )
        .subscribe(({ status, isChecked }) => {
          this.storage.set(disableNotifyKey, isChecked)
          if (status === ConfirmDialogStatus.Canceled) {
            this.close()
            this.router.navigate(['/billing/info'])
          } else if (status === ConfirmDialogStatus.Confirmed) {
            doAction()
          }
        })
    } else {
      doAction()
    }
  }

  private convertToTokeetEmail(form: RentalForm): RentalForm {
    const emailWithTokeetSubDomain = convertToTokeetEmail(form.email, this.subDomain)

    return { ...form, email: emailWithTokeetSubDomain } as RentalForm
  }
}
