import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators'
import SignaturePad from 'signature_pad'
import { SignatureEditorSize, SignatureHelperService } from '../signature-helper.service'
import { selectCurrentSignatureUser, UserSignatureType } from '@tokeet-frontend/signature'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-signature-drawer',
  templateUrl: './signature-drawer.component.html',
  styleUrls: ['./signature-drawer.component.scss'],
})
export class SignatureDrawerComponent extends Destroyable implements OnInit, AfterViewInit {
  @Input() editorSize: SignatureEditorSize

  @ViewChild('signatureCanvas', { static: true }) signatureCanvas: ElementRef
  signaturePad: SignaturePad

  constructor(private store: Store<any>, private signatureHelper: SignatureHelperService) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectCurrentSignatureUser),
        debounceTime(300),
        tap((user) => {
          if (!user.attributes?.signature_type) {
            this.reset()
          }
        }),
        filter((user) => user.attributes?.signature_type === UserSignatureType.Drawer && !!user.signature),
        switchMap((user) => this.drawSignatureOnPad(user.signature)),
        untilDestroy(this)
      )
      .subscribe()
  }

  ngAfterViewInit() {
    const canvas = this.signatureCanvas.nativeElement
    this.signaturePad = new SignaturePad(canvas)
    this.signatureHelper.setCanvasRatio(this.signatureCanvas.nativeElement)
  }

  drawSignatureOnPad(signature: string) {
    const canvas = this.signatureCanvas.nativeElement
    const tmpCanvas: HTMLCanvasElement = document.createElement('canvas')
    tmpCanvas.width = canvas.width
    tmpCanvas.height = canvas.height
    return this.signatureHelper.drawImage(tmpCanvas, signature, 15, devicePixelRatio).pipe(
      tap(() => {
        this.signaturePad.fromDataURL(tmpCanvas.toDataURL(), { ratio: 1 })
      })
    )
  }

  isEmpty() {
    return this.signaturePad.isEmpty()
  }

  getSignature() {
    return this.signaturePad.toDataURL()
  }

  reset() {
    this.signaturePad.clear()
  }
}
