<ng-container *ngIf="rental$ | async as rental; else unknown">
  <app-rental-image [rental]="rental" [size]="imageSize" [circle]="imageCircle" *ngIf="image"></app-rental-image>
  <div
    [matTooltip]="rental.nickname || rental.name"
    matTooltipPosition="above"
    [matTooltipDisabled]="tooltipDisabled"
    class="text-truncate"
  >
    <div class="text-truncate">{{ rental.nickname || rental.name }}</div>
    <div class="text-truncate text-light text-xs" *ngIf="secondaryLabel">{{ secondaryLabel }}</div>
  </div>
</ng-container>
<ng-template #unknown>
  <ng-container *ngIf="rentalId"> &lt;Unknown&gt; </ng-container>
</ng-template>
