<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Tiket.com with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create or Import Rentals
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="">
              <div class="text-semi-bold">To connect your AdvanceCM rental to Tiket.com, follow these steps:</div>
              <ul class="pl-3 lh-lg">
                <li>Ensure you have created or imported a rental from Airbnb into AdvanceCM.</li>
                <li>Connecting with Airbnb first is recommended for convenience.</li>
                <li>Follow this guide for detailed instructions.</li>
              </ul>
              <div class="alert alert-secondary-warning2 align-items-start">
                <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
                <div class="alert-content">
                  <b>Note:</b> One AdvanceCM rental can be connected to multiple channels. Combined availability from
                  all channels will be synced.
                </div>
              </div>

              <div class="text-right">
                <button class="btn btn-light-info" (click)="blocked.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #blocked>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Blocked Dates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <ul class="pl-3 lh-lg">
                <li>
                  After connecting AdvanceCM with Tiket.com, your availability will be determined by your AdvanceCM
                  rental calendar.
                </li>
                <li>
                  Please ensure you replicate any manual hold events on your channels in the AdvanceCM calendar to keep
                  dates blocked. Hold events set on Tiket.com are not imported when connecting to a channel manager.
                </li>
                <li>Follow this guide for detailed instructions.</li>
              </ul>
              <div class="alert alert-secondary-warning2 align-items-start">
                <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
                <div class="alert-content">
                  <b>Note:</b> One AdvanceCM rental can be connected to multiple channels. Combined availability from
                  all channels will be synced.
                </div>
              </div>

              <div class="text-right">
                <button class="btn btn-light-info" (click)="prepare.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #prepare>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Preparing Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Once connected to Tiket.com, your rates and availability will be disabled until you complete all steps
                in the wizard. Moving forward, rates must be pushed from AdvanceCM to Tiket.com; direct edits on
                Tiket.com's rates calendar will not be possible.
              </p>
              <div class="text-semi-bold">Key Considerations</div>
              <ul class="pl-3 lh-lg">
                <li>
                  Pushing Rates: Pushing rates to Tiket.com is required. Prepare your rates using this guide before
                  beginning.
                </li>
                <li>
                  Creating Rates: You can create a rate within the connection wizard and make changes later. If your
                  rate plan is straightforward, adding the rate within the wizard is recommended.
                </li>
                <li>
                  Guest Fees: Ensure your Tiket.com rates accept additional guest fees if applicable. Finalize your rate
                  plans on Tiket.com.
                </li>
              </ul>

              <div class="alert alert-secondary-warning2 align-items-start">
                <img
                  src="/assets/images/integrations/rategenie.png"
                  alt="Rategenie"
                  class="alert-icon"
                  style="height: 1.5rem"
                />
                <div class="alert-content">
                  <b class="text-nowrap">Recommended Tool:</b> We highly recommend using
                  <a href="https://www.rategenie.io/" target="_blank">Rategenie</a> for rental rate management, as it is
                  the most advanced dynamic pricing application in the industry.
                </div>
              </div>

              <div class="text-right">
                <button class="btn btn-light-info" (click)="messaging.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #messaging>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">4</div>
                Messaging
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <ul class="pl-3 lh-lg">
                <li>Messaging your Tiket.com guests is not supported through a channel manager.</li>
                <li>
                  For any inquiries, hotel partners should email cs@tiket.com or contact the Market Manager of
                  Tiket.com, whose contact information is available on the extranet.
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Next
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
