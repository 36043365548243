import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap, toArray } from 'rxjs/operators'

import {
  CancelContract,
  CancelContractComplete,
  ContractNotFound,
  CreateContract,
  CreateContractComplete,
  DeleteContract,
  DeleteContractComplete,
  DeleteMultipleContract,
  DeleteMultipleContractComplete,
  FetchBookingContracts,
  FetchBookingContractsComplete,
  GetContract,
  GetContractComplete,
  MarkContractAsCompleted,
  MarkContractAsCompletedComplete,
  RefreshContractStats,
  RefreshContractStatsComplete,
  SearchContracts,
  SearchContractsComplete,
  SendContract,
  SendContractComplete,
  ShareContract,
  ShareContractComplete,
  UpdateContract,
  UpdateContractComplete,
} from './contract.actions'
import { ContractService } from './contract.service'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ContractEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchContracts),
      switchMap(({ params }) =>
        this.contractService.list(params).pipe(
          map((res) => SearchContractsComplete(res)),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  booking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchBookingContracts),
      switchMap(({ bookingId }) =>
        this.contractService.getContractsByInquiryId(bookingId).pipe(
          map((items) => FetchBookingContractsComplete({ items })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  stats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshContractStats),
      switchMap(({ params }) =>
        this.contractService.list(params).pipe(
          map((res) => RefreshContractStatsComplete(res)),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetContract),
      switchMap(({ id, skipError }) =>
        this.contractService.get(id).pipe(
          map((item) => GetContractComplete({ item })),
          catchError((error) => {
            const actions: any[] = []
            if (!skipError) {
              actions.push(ActionFailed({ error }))
            }
            if (error?.status === 404) {
              actions.push(ContractNotFound({ id }))
            }
            return of(...actions)
          })
        )
      )
    )
  )

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateContract),
      switchMap(({ payload, silent }) =>
        this.contractService.create(payload).pipe(
          map((item) => CreateContractComplete({ item })),
          tap(() => !silent && this.toaster.success('Document sent successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateContract),
      concatMap(({ id, payload, message, silent }) =>
        this.contractService.update(id, payload).pipe(
          map((res) => UpdateContractComplete({ update: { id, changes: res } })),
          tap(() => !silent && this.toaster.success(message || 'Document updated successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteContract),
      switchMap(({ id }) =>
        this.contractService.delete(id).pipe(
          map((res) => DeleteContractComplete({ id })),
          tap(() => this.toaster.success('Document deleted successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  deleteMultiple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteMultipleContract),
      switchMap(({ ids }) =>
        of(...ids)
          .pipe(
            concatMap((id) => this.contractService.delete(id)),
            toArray()
          )
          .pipe(
            map((res) => DeleteMultipleContractComplete({ ids })),
            tap(() => this.toaster.success('Document(s) deleted successfully')),
            catchError((error) => of(ActionFailed({ error })))
          )
      )
    )
  )

  cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelContract),
      switchMap(({ id }) =>
        this.contractService.cancel(id).pipe(
          map((res) => CancelContractComplete({ id, changes: {} })),
          tap(() => this.toaster.success('Document canceled successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  complete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkContractAsCompleted),
      switchMap(({ id }) =>
        this.contractService.complete(id).pipe(
          map((res) => MarkContractAsCompletedComplete({ id, changes: res })),
          tap(() => this.toaster.success('Document marked as completed successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  send$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendContract),
      switchMap(({ id }) =>
        this.contractService.send(id).pipe(
          map((res) => SendContractComplete({ id, changes: {} })),
          tap(() => this.toaster.success('Document sent successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  share$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShareContract),
      switchMap(({ id, payload }) =>
        this.contractService.share(id, payload).pipe(
          map((res) => ShareContractComplete()),
          tap(() => this.toaster.success('Document shared successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private store: Store<any>,
    private contractService: ContractService
  ) {}
}
