import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { reducer } from './preference.reducer'
import { EffectsModule } from '@ngrx/effects'
import { SignaturePreferenceEffects } from './preference.effects'
import { SignaturePreferenceService } from './preference.service'
import { SignaturePreferenceGuard } from './preference.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('signature-preference', reducer),
    EffectsModule.forFeature([SignaturePreferenceEffects]),
  ],
  providers: [
    //
    SignaturePreferenceGuard,
    SignaturePreferenceService,
  ],
  declarations: [],
})
export class SignaturePrefStoreModule {}
