<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 w-100">
        <div class="d-flex align-items-center gap-2">
          <h6 class="modal-title">
            {{ contract?.name }}
          </h6>
          <ul class="dot-segments-list text-muted">
            <li class="text-capitalize">
              <i [ngClass]="contract?.status | contractStatusIcon : true" class="mr-2"></i
              >{{ (contract?.status | contractStatus)?.name }}
            </li>
            <li>{{ contract?.status_at | epoch : 'date' }}</li>
            <li>{{ contract?.status_at | epoch : 'time' }}</li>
          </ul>
        </div>

        <div class="flex-fill"></div>

        <ng-template [ngTemplateOutlet]="actions"></ng-template>
      </div>
      <a (click)="close()" class="cstm-close" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>

    <ul class="nav drawer-right-navbar">
      <li [class.active]="currentTab === tabs.Details" matTooltip="Details" matTooltipPosition="left">
        <a (click)="currentTab = tabs.Details">
          <i class="cs-icon cs-icon-file-alt"></i>
        </a>
      </li>
      <li [class.active]="currentTab === tabs.Options" matTooltip="Options" matTooltipPosition="left">
        <a (click)="currentTab = tabs.Options">
          <i class="cs-icon cs-icon-cog"></i>
        </a>
      </li>
    </ul>
    <div class="modal-body">
      <div class="panel-content">
        <div class="contract-file-wrapper">
          <app-pdf-preview [pdfUrl]="contract?.pdf_file_url" [reload]="isCompleted"></app-pdf-preview>
        </div>
        <div [ngSwitch]="currentTab" class="">
          <div class="contract-sidebar container-box" *ngSwitchCase="tabs.Details">
            <app-signer-status [contract]="contract"></app-signer-status>
            <app-contract-timeline [contract]="contract"></app-contract-timeline>
          </div>
          <div class="contract-sidebar container-box" *ngSwitchCase="tabs.Options">
            <app-contract-options [contract]="contract"></app-contract-options>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actions>
  <div dropdown class="dropdown">
    <button dropdownToggle class="btn btn-secondary-warning"><i class="cs-icon cs-icon-bolt"></i> Actions</button>

    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li>
        <a
          matTooltip="Edit the name of this document."
          matTooltipPosition="left"
          (click)="helperService.onRename(contract)"
        >
          <i class="far fa-edit fa-fw" aria-hidden="true"></i> Rename
        </a>
      </li>
      <li *ngIf="contract?.status === contractStatus.Pending">
        <a
          matTooltip="Send this document to the signers again, using the latest email address saved in the guest details."
          matTooltipPosition="left"
          (click)="helperService.onResend(contract)"
        >
          <i class="far fa-paper-plane fa-fw" aria-hidden="true"></i> Resend
        </a>
      </li>
      <li *ngIf="contract?.status === contractStatus.Pending">
        <a matTooltip="" matTooltipPosition="left" (click)="helperService.onComplete(contract)">
          <i class="far fa-check fa-fw" aria-hidden="true"></i> Mark as Completed
        </a>
      </li>
      <li *ngIf="contract?.status === contractStatus.Completed">
        <a
          matTooltip="Share this document via email."
          matTooltipPosition="left"
          (click)="helperService.onShare(contract)"
        >
          <i class="far fa-share-all fa-fw" aria-hidden="true"></i> Share
        </a>
      </li>
      <li *ngIf="contract?.status === contractStatus.Pending">
        <a
          matTooltip="Cancel this document for all signers. Once canceled, the document can no longer be completed."
          matTooltipPosition="left"
          (click)="helperService.onCancel(contract)"
        >
          <i class="far fa-ban fa-fw" aria-hidden="true"></i> Cancel
        </a>
      </li>
    </ul>
  </div>
  <a
    class="btn btn-secondary-primary"
    *ngIf="contract?.status === contractStatus.Completed"
    matTooltip="Download this document as a PDF."
    (click)="helperService.onDownloadContract(contract)"
  >
    <i class="cs-icon cs-icon-download"></i> Download
  </a>
</ng-template>
