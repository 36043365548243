import { endsWith, map, omitBy } from 'lodash'

export function httpRawItemToDataItem<T>(item: any, pkey = 'pkey'): T {
  return {
    id: item[pkey],
    ...item,
  }
}

export function omitViewFields<T, R = Partial<T>>(item: T, keepId = false): R {
  // @ts-ignore
  return omitBy(item, (v, k) => {
    return (keepId && k === 'id') || endsWith(k, 'View')
  })
}

export function httpRawItemsToDataItems<T>(items: any[], pkey = 'pkey'): T[] {
  return map(items, (item) => httpRawItemToDataItem(item, pkey))
}
