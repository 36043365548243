import { EventEmitter, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  LoadSmartDeviceSettings,
  selectSmartDeviceSettings,
  SmartDeviceSettingsResponse,
  UpdateSmartDeviceSettings,
} from '@tokeet-frontend/smart-devices'
import { AlertDialogService, ConfirmDialogService, openWindow } from '@tokeet-frontend/tv3-platform'
import { CustomContractSigner, SignatureService } from '@tokeet-frontend/signature'
import { AppOverviewDialogService } from '@tokeet-frontend/billing'
import { filter, finalize, map, switchMap, takeWhile } from 'rxjs/operators'
import { environment } from '@tv3/environments/environment'
import { interval } from 'rxjs'
import { AuthService } from '@tv3/services/auth.service'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { LoadActivePlans } from '@tv3/store/plan/plan.actions'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'

@Injectable()
export class SmartDevicesSubscribeService {
  settings: SmartDeviceSettingsResponse
  isDeviceSubscribed = false
  connected = new EventEmitter()

  constructor(
    private store: Store<any>,
    private confirm: ConfirmDialogService,
    private alert: AlertDialogService,
    private auth: AuthService,
    private signature: SignatureService,
    private appOverviewDialog: AppOverviewDialogService
  ) {
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event?.origin?.includes('getseam.com')) {
          this.connected.emit()
        }
      },
      false
    )

    this.store.pipe(select(selectSmartDeviceSettings)).subscribe((s) => {
      this.settings = s
    })
    this.store.pipe(select(selectActiveProductPlanView(ProductsForPlan.SmartDevice))).subscribe((sub) => {
      this.isDeviceSubscribed = sub?.isValid
    })
  }

  checkSubscription() {
    if (!this.isDeviceSubscribed) {
      this.onSubscribeSmartDevice()
      return false
    }

    if (!this.settings?.isContractSigned || !this.settings?.contractId) {
      this.signContract()
      return false
    }
    return true
  }

  onRefreshSettings() {
    this.store.dispatch(LoadSmartDeviceSettings())
  }

  onSubscribeSmartDevice() {
    this.appOverviewDialog
      .open(ProductsForPlan.SmartDevice)
      .afterClosed()
      .subscribe((res) => {
        this.store.dispatch(LoadActivePlans({}))
      })
  }

  signContract() {
    if (this.settings?.contractId) {
      this.alert
        .openResult({
          title: 'Smart Devices Agreement Not Signed',
          body: 'Please sign Smart Devices agreement to enable Smart Devices feature',
        })
        .pipe(switchMap(() => this.openContract(this.settings.contractId)))
        .subscribe()
      return
    }
    const terms = 'https://advance.cm/smart-devices-terms-conditions/'
    this.confirm
      .confirmExtra({
        title: 'Enable Smart Devices?',
        body: 'Welcome to Smart Devices! Please take a moment to review & sign Smart Devices agreement to proceed & Accept our Terms and Conditions to ensure a smooth and secure experience.',
        extra: `I agree to the <a href="${terms}" target="_blank">Terms & Conditions</a>`,
        extraCheckedRequired: true,
        extraCheckedRequiredMsg: 'Please select checkbox to proceed',
        confirmText: 'Sign',
        confirmBtnTooltip: 'Sign Smart Devices agreement to enable Smart Devices feature',
      })
      .pipe(
        filter((res) => res.isChecked),
        switchMap(() => {
          const signer: CustomContractSigner = {
            email: this.auth.user.primaryEmail,
            name: this.auth.user.name,
            role: environment.config.tokeetAIContractSignerRole,
          }
          return this.signature.sendContractViaEmail(environment.config.smartDevicesContractId, [signer])
        }),
        switchMap((contract) => {
          this.store.dispatch(UpdateSmartDeviceSettings({ payload: { contractId: contract.contract_id } }))
          return this.openContract(contract.contract_id)
        })
      )
      .subscribe()
  }

  openContract(contractId: string) {
    return this.signature.getContractSignLink(contractId, environment.config.smartDevicesContractSignerRole).pipe(
      map(({ link }) => openWindow(link, null, 1200, 800)),
      switchMap((win: Window) => interval(5000).pipe(takeWhile(() => !win.closed))),
      finalize(() => this.checkContractSigned())
    )
  }

  checkContractSigned() {
    interval(5000)
      .pipe(takeWhile((v) => v < 5 && !this.settings?.isContractSigned))
      .subscribe(() => {
        this.onRefreshSettings()
      })
  }
}
