<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/step-icon2.webp" alt="" height="40" />
    <div>
      <h6 class="mb-0">Request Channel Manager Access</h6>
      <div class="text-sm">This section can take between 10 minutes and 2 hrs depending on the volume of requests.</div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #settings>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Connectivity Provider settings on Booking.com
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Click on the <strong>Account button</strong> at the top right, then click on the Channel Manager link in
              the drop-down menu.
            </p>

            <p>
              If you don't see the <strong>Connectivity Provider</strong> option, please contact your Booking.com
              support team to request the commercial approval and make sure the sign up tool in your extranet is
              activated.
            </p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="startConnection.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #startConnection>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              To connect to AdvanceCM from Booking.com side
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>To connect to the Advance Channel Manager (ACM) from Booking.com:</p>
            <ol class="pl-3">
              <li>Use the search bar to type <strong>Tokeet</strong>.</li>
              <li>Select <strong>Tokeet</strong> from the results.</li>
              <li>
                Choose the features you want to connect and sync, such as:
                <ul class="pl-0">
                  <li>Rates</li>
                  <li>Reservations</li>
                  <li>Reviews</li>
                  <li>Reporting</li>
                  <li>Guest messages</li>
                  <li>Performance data and insights</li>
                </ul>
              </li>
              <li>Click Connect to proceed.</li>
            </ol>
            <p>
              Once completed, you'll be directed to the <strong>Connection Request Completed</strong> screen on
              Booking.com.
              <br />
              If you need further help during the process, please don't hesitate to contact our support team
            </p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="enterPropertyId.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #enterPropertyId>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Enter your Booking.com Property ID and finalize the connection
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <mat-form-field2 [showLabel]="false" class="w-50">
              <input matInput placeholder="Enter Property ID" [formControl]="propertyIdCtrl" required />
              <mat-error *ngIf="propertyIdCtrl.touched && propertyIdCtrl.hasError('required')">
                Property ID is required
              </mat-error>
            </mat-form-field2>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div style="max-width: 350px; min-width: 350px">
      <img src="/assets/images/channel-wizards/bdc/bdc-settings.webp" alt="" class="w-100" *ngIf="settings.expanded" />
      <img
        src="/assets/images/channel-wizards/bdc/start-connection.webp"
        alt=""
        class="w-100"
        *ngIf="startConnection.expanded"
      />
      <img
        src="/assets/images/channel-wizards/bdc/enter-property-id.webp"
        alt=""
        class="w-100"
        *ngIf="enterPropertyId.expanded"
      />
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button
    class="btn btn-secondary-info"
    matTooltip="Please enter property ID to connect to Booking.com"
    [disabled]="propertyIdCtrl.invalid || isConnecting"
    (click)="onConnect(propertyIdCtrl)"
  >
    Connect
    <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isConnecting"></i>
  </button>
</div>
