import { Pipe, PipeTransform } from '@angular/core'
import { get, map, filter } from 'lodash'

@Pipe({
  name: 'signLocation',
})
export class SignLocationPipe implements PipeTransform {
  transform(location: any, fields = ['region_name', 'country_name']): string {
    return filter(map(fields, (field) => get(location, field))).join(', ')
  }
}
