<div class="modal-header">
  <h5 class="modal-title">Assign Booking</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <div>
    <p>
      Please select a booking to be assigned to this document. Documents can only be assigned to one booking at a time.
      The document will be visible on the Documents tab in the inquiry details in Advance CM.
    </p>
    <app-booking-select
      [ctrl]="inquiryIdCtrl"
      placeholder="Assigned Booking"
      [showBlankItem]="false"
      (selected)="onBookingSelected($event)"
    >
    </app-booking-select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" aria-label="Close" (click)="close()">Cancel</button>

  <button type="button" class="btn btn-secondary-info" (click)="onOkClicked(inquiryIdCtrl)">
    <i class="far fa-check" aria-hidden="true"></i> OK
  </button>
</div>
