import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import {
  countries,
  DataCheckerService,
  Destroyable,
  isRestrictingUsers,
  isSavingRental,
  isSavingTags,
  isSomething,
  isUpdatingOwners,
  notAllNumbersValidator,
  notAllSpacesValidator,
  ParsedGooglePlace,
  Rental,
  RentalEmailNotTakenValidator,
  RentalEmailValidator,
  RentalNameNotTakenValidator,
  SaveForm,
  selectSubdomain,
  SubdomainGuard,
  untilDestroy,
  updateRental,
  UpdateRentalStatus,
} from '@tokeet-frontend/tv3-platform'
import { RentalUpdateForm } from '@tv3/interfaces/forms/rental-form'
import { distinctUntilChanged, filter } from 'rxjs/operators'
import { MapsAPILoader } from '@agm/core'
import { DeleteRentalOverlayService } from '@tv3/containers/rentals/delete-rental-overlay/delete-rental-overlay.service'
import { EditBookingPreferencesDialogService } from '@tokeet-frontend/rentals'

@Component({
  selector: 'app-rental-details-basic-info',
  templateUrl: './rental-details-basic-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rental-details-basic-info.component.scss'],
})
export class RentalDetailsBasicInfoComponent extends Destroyable implements OnInit, OnChanges {
  form = this.fb.group({
    displayName: ['', [notAllSpacesValidator]],
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(140),
        notAllNumbersValidator,
        notAllSpacesValidator,
      ],
      RentalNameNotTakenValidator.createValidator(this.store),
    ],
    nickname: ['', [Validators.minLength(2), Validators.maxLength(140), notAllNumbersValidator, notAllSpacesValidator]],
    phone: ['', [Validators.required, notAllSpacesValidator]],
    email: [
      '',
      [Validators.required, Validators.minLength(3)],
      [RentalEmailNotTakenValidator.createValidator(this.store), RentalEmailValidator.createValidator(this.store)],
    ],
    color: [''],
    address: ['', [Validators.minLength(2), Validators.maxLength(140), notAllSpacesValidator]],
    city: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.required, notAllSpacesValidator]],
    state: ['', [Validators.minLength(2), Validators.maxLength(50), notAllSpacesValidator]],
    zip: ['', [Validators.minLength(2), Validators.maxLength(50), notAllSpacesValidator]],
    country: [''],
    registration: [''],
    countryCode: [''],
    currency: [''],
    gps: [{}],
    status: [''],
  })

  @Input() rental: Rental

  get exportCalendarUrl() {
    return `https://calendars.tokeet.com/calendar/rental/${this.rental.account}/${this.rental.id}`
  }

  subDomain: string

  isSaving$ = this.store.pipe(select(isSavingRental))
  isUpdatingOwners$ = this.store.pipe(select(isUpdatingOwners))
  isRestrictingUsers$ = this.store.pipe(select(isRestrictingUsers))
  isSavingTags$ = this.store.pipe(select(isSavingTags))
  isGoogleLoaded = false

  constructor(
    private fb: FormBuilder,
    private mapsApiLoader: MapsAPILoader,
    private deleteRentalOverlayService: DeleteRentalOverlayService,
    private dataCheckerService: DataCheckerService,
    private store: Store<any>,
    private editBookingPreferencesDialogService: EditBookingPreferencesDialogService
  ) {
    super()
    this.dataCheckerService.check([SubdomainGuard])
  }

  ngOnInit() {
    this.mapsApiLoader.load().then(() => {
      this.isGoogleLoaded = true
    })
    this.setForm(this.rental)

    this.store.pipe(select(selectSubdomain), untilDestroy(this)).subscribe((subDomain) => {
      this.subDomain = subDomain
    })

    this.form
      .get('status')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter((status) => status !== this.rental?.status),
        untilDestroy(this)
      )
      .subscribe((status) => {
        this.store.dispatch(UpdateRentalStatus({ rentalId: this.rental.id, status }))
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setForm(this.rental)
  }

  onEditBookingPreferences() {
    this.editBookingPreferencesDialogService.open(this.rental)
  }

  onPlaceChange(parsedPlace: ParsedGooglePlace) {
    const country = R.find((c: any) => c.id === parsedPlace.countryShort, countries)
    this.form.patchValue({
      address: (parsedPlace.streetNumber ? parsedPlace.streetNumber + ' ' : '') + (parsedPlace.street || ''),
      city: parsedPlace.city,
      state: parsedPlace.state,
      country: R.pathOr('', ['name'], country),
      countryCode: R.pathOr('', ['id'], country),
      gps: parsedPlace.gps || {},
      zip: parsedPlace.postCode,
    })
  }

  onCityChange(parsedPlace: ParsedGooglePlace) {
    const address = this.form.get('address').value || ''
    const formCity = this.form.get('city').value || ''
    const googleCity = (parsedPlace.city || '')?.trim()
    this.form.patchValue({
      city: isSomething(googleCity) ? googleCity : formCity,
    })
    if (R.isEmpty(address.trim()) || R.isEmpty(this.form.get('gps').value)) {
      const country = R.find((c: any) => c.id === parsedPlace.countryShort, countries)
      console.log(`set gps=${parsedPlace.gps} and country=${country}`)
      this.form.patchValue({
        gps: parsedPlace.gps || {},
        country: R.pathOr('', ['name'], country),
        countryCode: R.pathOr('', ['id'], country),
      })
    }
  }

  onDelete() {
    this.deleteRentalOverlayService.open(this.rental.id)
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const values = form.getRawValue()
    delete values.status
    const rentalForm: RentalUpdateForm = {
      ...values,
      email: `${values.email}@${this.subDomain}`,
    }

    this.store.dispatch(updateRental({ form: rentalForm, rentalId: this.rental.id, rental: this.rental }))
  }

  private setForm(rental: Rental) {
    this.form.patchValue({
      name: rental.name,
      nickname: rental.nickname,
      displayName: rental.displayName,
      phone: rental.phone,
      email: R.pipe(R.when(R.is(Number), R.toString), R.split('@'), R.head)(rental.email),
      address: R.pathOr('', ['address', 'address'], rental),
      city: R.pathOr('', ['address', 'city'], rental),
      state: R.pathOr('', ['address', 'state'], rental),
      country: R.pathOr('', ['address', 'country'], rental),
      countryCode: R.pathOr('', ['address', 'countryCode'], rental),
      zip: R.pathOr('', ['address', 'zip'], rental),
      color: rental.color,
      gps: rental.gps || {},
      registration: (rental.attributes as any)?.registration,
      currency: rental.currency || {},
      status: rental.status || 'ready',
    })
  }
}
