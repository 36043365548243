import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { SignaturePreference } from './preference.model'
import { map } from 'rxjs/operators'
import { httpRawItemToDataItem } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class SignaturePreferenceService {
  constructor(private http: HttpClient) {}

  get(): Observable<SignaturePreference> {
    const url = `@signatureApi/account`
    return this.http.get<any>(url).pipe(map((res) => httpRawItemToDataItem(res, 'account')))
  }

  update(payload: Partial<SignaturePreference>): Observable<SignaturePreference> {
    const url = `@signatureApi/account`
    return this.http.put<any>(url, payload).pipe(map((res) => httpRawItemToDataItem(res, 'account')))
  }
}
