import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './task.reducer'
import * as state from './task.state'
import { TaskView, defaultTaskList, TaskResponse, taskToView } from './task.model'
import * as R from 'ramda'
import { selectInquiryEntities } from '@tv3/store/inquiry/inquiry.selectors'
import { createdBySystemNameMappings, isSomething, selectUserEntities, User } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { userFullname } from '@tokeet-frontend/tv3-platform'
import { selectAutomationEntities } from '@tokeet-frontend/automations'

export const taskState = createFeatureSelector<state.State>('task')

export const selectAllTasks = createSelector(taskState, reducer.selectAll)
export const selectUnarchivedTasks = createSelector(selectAllTasks, (items) => items.filter((t) => !t.archived))

export const selectTaskEntities = createSelector(taskState, reducer.selectEntities)

export const isUpdatingTasks = createSelector(taskState, (state: state.State) => state.isUpdating)

export const selectAllTasksViews = (includeArchived = false) =>
  createSelector(
    includeArchived ? selectAllTasks : selectUnarchivedTasks,
    selectUserEntities,
    selectInquiryEntities,
    selectAutomationEntities,
    (tasks, userEntities, inquiryEntities, triggersById) => {
      return R.map((t: TaskResponse) => {
        const view: TaskView = taskToView(t)
        const trigger = triggersById[t.trigger_id]
        const createdByUser = userEntities[t.created_by]

        view.createdByView =
          trigger?.attributes?.name ||
          createdBySystemNameMappings[t.created_by] ||
          (createdByUser ? userFullname(createdByUser) : t.created_by)
        view.inquiryView = R.pathOr('', ['guestDetails', 'name'], inquiryEntities[view.inquiry_id])
        const assignees = lodash.compact(lodash.map(view.user_ids, (id) => userEntities[id]))
        view.usersView = assignees.map((t) => userFullname(t)).join(', ')
        view.user_ids = assignees.map((t) => t.id) // cleat not existing users
        return view
      }, tasks)
    }
  )

export const selectBookingTaskViews = (bookingId: string) =>
  createSelector(selectAllTasksViews(), (items) => {
    return items?.filter((t) => t.inquiry_id === bookingId)
  })

export const selectTaskViewById = (id: string) =>
  createSelector(selectAllTasksViews(), (items) => {
    return items.find((t) => t.pkey === id)
  })

export const selectTasksLoaded = createSelector(taskState, (state: state.State) => state.isLoaded)

export const selectTaskLists = createSelector(selectAllTasks, (tasks: TaskResponse[]) => {
  return R.pipe(
    R.map((t: TaskResponse) => t.list),
    R.prepend(defaultTaskList),
    R.filter((t) => !!t),
    R.uniq,
    R.map((t) => String(t)),
    R.sortBy((name) => String(name).toUpperCase())
  )(tasks)
})

export const selectTaskUsers = createSelector(selectAllTasksViews(), (tasks: TaskView[]) => {
  return R.pipe(
    R.groupBy((t: TaskView) => t.user_id),
    R.mapObjIndexed((value: TaskView[], key: string) => ({ id: key, name: value[0].usersView })),
    <any>R.filter((e: User) => isSomething(e.name)),
    R.values,
    R.sortBy((e: User) => e.name)
  )(tasks)
})
