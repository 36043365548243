import { Injectable } from '@angular/core'
import { ImageViewerDataItem, ImageViewerDialogComponent } from './image-viewer-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ImageViewerDialogService {
  constructor(private dialog: DialogService) {}

  public open(data: { images: ImageViewerDataItem[]; title: string }) {
    return this.dialog.openFixedDialog(ImageViewerDialogComponent, {
      width: '800px',
      data,
    })
  }
}
