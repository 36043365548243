<form #uploadForm>
  <input type="file" #fileInput class="d-none" (change)="onSignatureFileSelected(fileInput.files)" />
</form>
<div class="text-bold mb-3">Use a photo of your signature</div>

<div [class.d-none]="isFileUploaded">
  <button class="btn btn-secondary-info" (click)="fileInput.click()">
    <i class="cs-icon cs-icon-image-plus"></i> Add Photo
  </button>

  <div class="help-info">
    <div>Acceptable file formats: <span> png, jpg, jpeg, bmp, gif</span></div>
    <div>Max file size: <span>40MB</span></div>
  </div>
</div>

<div class="drawer-wrapper" [class.d-none]="!isFileUploaded">
  <canvas #signatureCanvas [style.width.px]="editorSize.width" [style.height.px]="editorSize.height"></canvas>
</div>
