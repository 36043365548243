import { NgModule } from '@angular/core'
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common'
import { RouterModule } from '@angular/router'
import { NgxDnDModule } from '@swimlane/ngx-dnd'
import { NgbDatepickerModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'
import { NgxPermissionsModule } from 'ngx-permissions'
import { AgmCoreModule } from '@agm/core'
import { NgProgressModule } from 'ngx-progressbar'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressRouterModule } from 'ngx-progressbar/router'

import { ScrollingModule } from '@angular/cdk/scrolling'
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial'
import { LightboxModule } from 'ngx-lightbox'
import { ColorPickerModule } from 'ngx-color-picker'

import { YesNoPipe } from '@tv3/pipes/utility/yesno.pipe'
import { SelectNavComponent } from '@tv3/shared/select-nav/select-nav.component'
import { FieldOrPathPipe } from '@tv3/pipes/utility/field-or-path.pipe'
import { RatePipe } from '@tv3/pipes/utility/rate.pipe'
import { IsNilPipe } from '@tv3/pipes/utility/is-nil.pipe'
import { ColorBoxesComponent } from '@tv3/shared/color-boxes/color-boxes.component'
import { ColorPickerComponent } from '@tv3/shared/color-picker/color-picker.component'
import { IsEmptyPipe } from '@tv3/pipes/utility/is-empty.pipe'
import { AddSubtractDayPipe } from '@tv3/pipes/utility/add-subtract-day.pipe'
import { DateDiffPipe } from '@tv3/pipes/utility/date-diff.pipe'
import { IsTimeStartOfPipe, IsUTCTimeStartOfPipe } from '@tv3/pipes/utility/is-time-start-of.pipe'
import { ButtonComponent } from '@tv3/shared/button/button.component'
import { ButtonClassPipe } from '@tv3/shared/button/button-class.pipe'
import { InvoiceStatusPipe } from '@tv3/pipes/invoice/invoice-status.pipe'
import { AirlineCodePipe } from '@tv3/pipes/flight/airline-code.pipe'
import { InitialsPipe } from '@tv3/pipes/utility/initials.pipe'
import { TimeAgoPipe } from '@tv3/pipes/utility/time-ago.pipe'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { EmptyTableComponent } from '@tv3/shared/empty-table/empty-table.component'
import { MiniEventCalendarComponent } from '@tv3/shared/mini-event-calendar/mini-event-calendar.component'
import { DayClassesPipe } from '@tv3/shared/mini-event-calendar/pipes/day-classes.pipe'
import { FeesSumPipe } from '@tv3/pipes/utility/fees-sum.pipe'
import { ToLowerPipe } from '@tv3/pipes/utility/to-lower.pipe'
import { CsvFileParserComponent } from '@tv3/shared/csv-file-parser/csv-file-parser.component'
import { ChannelLogoComponent } from '@tv3/shared/channel-logo/channel-logo.component'
import { ConnectionTypePipe } from '@tv3/pipes/channel/connection-type.pipe'
import { InquirySourceIconPipe } from '@tv3/pipes/inquiry/inquiry-source-icon.pipe'
import { SelectRentalDialogComponent } from '@tv3/shared/dialogs/select-rental-dialog/select-rental-dialog.component'
import { RentalImageComponent } from '@tv3/shared/rentals/rental-image/rental-image.component'
import { ChangePasswordDialogComponent } from '@tv3/shared/change-password/change-password-dialog/change-password-dialog.component'
import { StripHtmlPipe } from '@tv3/pipes/utility/strip-html.pipe'
import { CreateTaxDialogComponent } from '@tv3/shared/dialogs/create-tax-dialog/create-tax-dialog.component'
import { InvoiceLineTaxTotalPipe, InvoiceLineTotalPipe, InvoiceTotalPipe } from '@tv3/pipes/invoice/invoice-total.pipe'
import { SelectChannelDialogComponent } from './dialogs/select-channel-dialog/select-channel-dialog.component'
import { ImportTaskDialogComponent } from '@tv3/containers/tasks/import-task-dialog/import-task-dialog.component'
import { SelectItemDialogComponent } from './dialogs/select-item-dialog/select-item-dialog.component'
import { ActionDialogComponent } from './dialogs/action-dialog/action-dialog.component'
import { SearchPipe } from '@tv3/pipes/utility/search.pipe'
import { GenericExportDialogComponent } from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import { ClickOutsideDirective } from './click-outside/click-outside.directive'
import { FileUploadModule } from 'ng2-file-upload'
import { UploadFileDialogComponent } from '@tv3/shared/dialogs/upload-file-dialog/upload-file-dialog.component'
import { HelpWidgetComponent } from './help-widget/help-widget.component'
import { DataDictionaryDialogComponent } from '@tv3/shared/data-dictionary-dialog/data-dictionary-dialog.component'
import { NgsContenteditableModule } from '@ng-stack/contenteditable'
import { DefaultRouteIndicatorComponent } from '@tv3/shared/default-route-indicator/default-route-indicator.component'
import { AttachmentDialogComponent } from '@tv3/shared/attachment/attachment-dialog.component'
import { AttachmentUploadComponent } from '@tv3/shared/attachment/upload/attachment-upload.component'
import { AttachmentSelectComponent } from '@tv3/shared/attachment/select/attachment-select.component'
import { LoginLayoutComponent } from '@tv3/shared/login-layout/login-layout.component'
import { MoveMessageComponent } from '@tv3/containers/messages/message-list/move-message/move-message.component'
import { ModalBodyDirective } from './modal-content/modal-body.directive'
import { PrintBookingPreviewDialogComponent } from '@tv3/shared/dialogs/print-booking-preview/print-booking-preview-dialog.component'
import { NgSelectEclipsedValuePipe } from '@tv3/shared/ng-select-epipsed-value.pipe'
import { CopyToClipboardComponent } from '@tv3/shared/copy-to-clipboard/copy-to-clipboard.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { AddressPipe } from '@tv3/pipes/utility/address.pipe'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { SelectInquiryDialogComponent } from '@tv3/shared/dialogs/select-inquiry-dialog/select-inquiry-dialog.component'
import { TemplateDictionaryDialogComponent } from '@tv3/shared/template-dictionary-dialog/template-dictionary-dialog.component'
import { GetImageThumbPipe } from '@tv3/pipes/utility/get-image-thumb.pipe'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatInputModule } from '@angular/material/input'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTableModule } from '@angular/material/table'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSliderModule } from '@angular/material/slider'
import { MatBadgeModule } from '@angular/material/badge'
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog'
import { MatStepperModule } from '@angular/material/stepper'
import { MatFormFieldModule } from '@angular/material/form-field'
import { LosDiscountLibModule } from '@tokeet-frontend/los-discount'
import { DiscountCodeLibModule } from '@tokeet-frontend/discount-codes'
import { SvgIconPipe } from '@tv3/pipes/utility/svg-icon.pipe'
import { AlertModule } from 'ngx-bootstrap/alert'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { PhoneWidgetComponent } from '@tv3/shared/phone-widget/phone-widget.component'
import { PermissionModule } from '@tokeet-frontend/permission'
import { ChannelIconPipe } from '@tv3/pipes/channel/channel-icon.pipe'
import { InfoIconComponent } from './info-icon/info-icon.component'
import { PhoneNumberRequestDialogComponent } from './phone-number-request-dialog/phone-number-request-dialog.component'
import { NightsPipe } from '@tv3/pipes/inquiry/nights.pipe'
import { RentalsLibraryModule } from '@tokeet-frontend/rentals'
import { NationalPhoneInputComponent } from './national-phone-input/national-phone-input.component'
import { CustomChannelModule } from '@tokeet-frontend/channels'
import { OverlayModule } from '@angular/cdk/overlay'
import { BookingPaidStatusComponent } from './booking-paid-status/booking-paid-status.component'
import { RentalNameComponent } from './rental-name/rental-name.component'
import { FileIconPipe } from '@tv3/pipes/utility/file-type-icon.pipe'
import { SetupLayerComponent } from '@tv3/shared/setup-layer/setup-layer.component'
import { CustomAttrsLibraryModule } from '@tokeet-frontend/custom-attrs'
import { RecurringFormComponent } from './recurring-form/recurring-form.component'
import { RatePopoverV2Component } from './rate-popover/rate-popover-v2.component'
import { BookingSelectComponent } from './booking-select/booking-select.component'
import { DefaultRouteComponent } from './default-route-indicator/default-route.component'
import { EntityTagsLibraryModule } from '@tokeet-frontend/tags'
import { GuestSelectComponent } from './guest-select/guest-select.component'
import { AddBillingDialogComponent } from './add-billing/add-billing-dialog.component'
import { PaidStatusBadgeComponent } from './paid-status-badge/paid-status-badge.component'
import { EditorImageDialogComponent } from './dialogs/editor-image/editor-image-dialog.component'
import { SmartDeviceAccessStoreModule } from '@tokeet-frontend/smart-devices'
import { UserSelectComponent } from './user-select/user-select.component'
import { ImportInventoryDialogComponent } from '@tv3/containers/inventories/import-inventory-dialog/import-inventory-dialog.component'
import { RentalSelectComponent } from '@tv3/shared/rental-select/rental-select.component'

export const TV3_FORMATS = {
  parse: {
    dateInput: 'DD - MMM - YYYY',
  },
  display: {
    dateInput: 'DD - MMM - YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD - MMM - YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

const pipes = [
  RatePipe,
  AddSubtractDayPipe,
  DateDiffPipe,
  IsTimeStartOfPipe,
  NgSelectEclipsedValuePipe,
  IsUTCTimeStartOfPipe,
  FieldOrPathPipe,
  FeesSumPipe,
  IsNilPipe,
  IsEmptyPipe,
  YesNoPipe,
  ButtonClassPipe,
  InvoiceStatusPipe,
  ToLowerPipe,
  AirlineCodePipe,
  InitialsPipe,
  TimeAgoPipe,
  StripHtmlPipe,
  DayClassesPipe,
  ConnectionTypePipe,
  InquirySourceIconPipe,
  InvoiceLineTotalPipe,
  InvoiceTotalPipe,
  InvoiceLineTaxTotalPipe,
  SearchPipe,
  AddressPipe,
  GetImageThumbPipe,
  SvgIconPipe,
  ChannelIconPipe,
  NightsPipe,
  FileIconPipe,
]

const directives = [ClickOutsideDirective, ModalBodyDirective]

const dialogs = [
  SelectRentalDialogComponent,
  SelectChannelDialogComponent,
  ChangePasswordDialogComponent,
  CreateTaxDialogComponent,
  ImportTaskDialogComponent,
  SelectItemDialogComponent,
  ActionDialogComponent,
  GenericExportDialogComponent,
  UploadFileDialogComponent,
  DataDictionaryDialogComponent,
  TemplateDictionaryDialogComponent,
  AttachmentDialogComponent,
  MoveMessageComponent,
  PrintBookingPreviewDialogComponent,
  SelectInquiryDialogComponent,
  PhoneNumberRequestDialogComponent,
  RentalNameComponent,
  AddBillingDialogComponent,
  ImportInventoryDialogComponent,
]

const declarations = [
  ...pipes,
  ...directives,
  ...dialogs,
  LoginLayoutComponent,
  SelectNavComponent,
  ColorBoxesComponent,
  ColorPickerComponent,
  ButtonComponent,
  EmptyTableComponent,
  MiniEventCalendarComponent,
  CsvFileParserComponent,
  ChannelLogoComponent,
  RentalImageComponent,
  HelpWidgetComponent,
  PhoneWidgetComponent,
  DefaultRouteIndicatorComponent,
  DefaultRouteComponent,
  AttachmentUploadComponent,
  AttachmentSelectComponent,
  CopyToClipboardComponent,
  InfoIconComponent,
  NationalPhoneInputComponent,
  BookingPaidStatusComponent,
  SetupLayerComponent,
  RecurringFormComponent,
  RatePopoverV2Component,
  BookingSelectComponent,
  GuestSelectComponent,
  UserSelectComponent,
  RentalSelectComponent,
  PaidStatusBadgeComponent,
  EditorImageDialogComponent,
]

const modules = [
  CommonModule,
  RouterModule,

  Tv3PlatformModule,
  EntityTagsLibraryModule,
  SmartDeviceAccessStoreModule,

  BsDropdownModule,

  MatNativeDateModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatTooltipModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatRippleModule,

  MatBadgeModule,
  MatSliderModule,
  MatTabsModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatSnackBarModule,
  MatStepperModule,

  OverlayModule,

  TooltipModule,
  NgSelectModule,
  RxReactiveFormsModule,
  NgxPermissionsModule,
  CollapseModule,
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbPopoverModule,
  ColorPickerModule,
  AgmCoreModule,
  NgProgressModule,
  NgProgressHttpModule,
  NgProgressRouterModule,
  NgxDnDModule,
  ScrollingModule,
  FileUploadModule,
  EcoFabSpeedDialModule,
  LightboxModule,
  NgsContenteditableModule,
  Tv3PlatformModule,
  MatFormFieldModule,
  LosDiscountLibModule,
  DiscountCodeLibModule,
  RentalsLibraryModule,
  CustomAttrsLibraryModule,
  PermissionModule,
  CustomChannelModule,
  AngularSvgIconModule,
]

@NgModule({
  imports: [...modules, AlertModule.forRoot()],
  declarations,
  exports: [...declarations, ...modules, AlertModule],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    ...pipes,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: TV3_FORMATS,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { autoFocus: false, hasBackdrop: true } },
  ],
})
export class SharedModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome-solid', 'fas')
    matIconRegistry.registerFontClassAlias('fontawesome-regular', 'far')
    matIconRegistry.registerFontClassAlias('fontawesome-light', 'fal')
    matIconRegistry.registerFontClassAlias('fontawesome-brand', 'fab')
  }
}
