import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TaskView } from '@tv3/store/task/task.model'
import { TaskDialogService } from '../../shared/task-dialog/task-dialog.service'
import { TaskViewDialogService } from '@tv3/containers/tasks/shared/task-view-dialog.service'
import { NgxPermissionsService } from 'ngx-permissions'

@Component({
  selector: 'app-task-list-popup',
  templateUrl: './task-list-popup.component.html',
  styleUrls: ['./task-list-popup.component.scss'],
  host: { class: 'modal-content' },
})
export class TaskListPopupComponent implements OnInit {
  get tasks() {
    return this.data.items || []
  }
  constructor(
    private dialogRef: MatDialogRef<TaskListPopupComponent>,
    private permissions: NgxPermissionsService,
    private taskDialog: TaskDialogService,
    private taskViewDialog: TaskViewDialogService,
    @Inject(MAT_DIALOG_DATA) private data: { items: TaskView[] }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  onTask(task: TaskView) {
    if (this.permissions.getPermission('canEditTasks')) {
      this.taskDialog.open(task)
    } else {
      this.taskViewDialog.open(task)
    }
  }
}
