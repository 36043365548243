<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-download"></i> Export Inquiry Report</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="offset-md-2 col-md-8">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="year" placeholder="Year" required>
                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('year').touched && form.get('year').hasError('required')">
                Year is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-md-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="month" placeholder="Month" required>
                <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('month').touched && form.get('month').hasError('required')">
                Month is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-rental-select [items]="rentals$ | async" [ctrl]="form.get('rentals')" placeholder="Select Rental(s)">
            </app-rental-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-checkbox formControlName="onlyBooked">Only Booked</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="download(form)" type="button" class="btn btn-secondary-info create-btn" [disabled]="isExporting">
    <i class="cs-icon cs-icon-download"></i> export
  </button>
</div>
