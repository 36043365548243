import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { UserSignature } from './user-signature.model'

@Injectable()
export class UserSignatureService {
  constructor(private http: HttpClient) {}

  addSignatureUser(payload: Partial<UserSignature>): Observable<UserSignature> {
    const url = `@signatureApi/user`
    return this.http.post<any>(url, payload).pipe()
  }

  getSignatureUser(id: string): Observable<UserSignature> {
    const url = `@signatureApi/user/${id}`
    return this.http.get<any>(url).pipe()
  }

  updateSignatureUser(id: string, payload: Partial<UserSignature>): Observable<UserSignature> {
    const url = `@signatureApi/user/${id}`
    return this.http.put<any>(url, payload)
  }
}
