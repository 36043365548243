<div class="modal-header">
  <h5 class="modal-title">Add Your Signature</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <div class="signature-editor">
    <ng-template [ngTemplateOutlet]="editorTabs"></ng-template>
    <div class="tab-content">
      <ng-container [ngSwitch]="currentTab">
        <app-signature-drawer
          #signatureDrawer
          *ngSwitchCase="tabs.Drawer"
          [editorSize]="editorSize"
        ></app-signature-drawer>
        <app-signature-texter
          #signatureTexter
          *ngSwitchCase="tabs.TextEditor"
          [editorSize]="editorSize"
        ></app-signature-texter>
        <app-signature-uploader
          #signatureUploader
          *ngSwitchCase="tabs.Uploader"
          [editorSize]="editorSize"
        ></app-signature-uploader>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="text-light" style="flex: 1">I understand this is a legal representation of my signature</div>
  <button
    type="button"
    matTooltip="Clear the signature field, and start again."
    class="btn btn-light"
    [disabled]="isSaving"
    (click)="onReset()"
    *ngIf="!!userSignature?.signature"
  >
    <i class="far fa-undo"></i>
    Reset
  </button>
  <button type="button" class="btn btn-secondary-info" [disabled]="!isSavable() || isSaving" (click)="onSave()">
    <i class="fas fa-save"></i> Save
  </button>
</div>

<ng-template #editorTabs>
  <ul class="editor-tabs">
    <li class="editor-tab" [class.active]="currentTab === tabs.Drawer">
      <a href="javascript:;" (click)="currentTab = tabs.Drawer"><i class="fas fa-signature"></i></a>
    </li>
    <li class="editor-tab" [class.active]="currentTab === tabs.TextEditor">
      <a href="javascript:;" (click)="currentTab = tabs.TextEditor"><i class="fas fa-font"></i></a>
    </li>
    <li class="editor-tab" [class.active]="currentTab === tabs.Uploader">
      <a href="javascript:;" (click)="currentTab = tabs.Uploader"><i class="far fa-camera"></i></a>
    </li>
    <li class="editor-tab flex-fill"></li>
  </ul>
</ng-template>
