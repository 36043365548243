<div class="modal-header">
  <div class="d-flex gap-2 align-items-center">
    <h5 class="modal-title"><i class="fal fa-globe-americas"></i>&nbsp;<span [innerHTML]="data.title"></span></h5>
    <app-page-help videoId="-" [articleId]="data.articleId"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <input type="text" matInput required placeholder="Website Title" formControlName="title" />
      <mat-hint align="end">{{ form.get('title').value.length || 0 }}/50</mat-hint>
      <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
        Website Title is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('minlength')">
        Website Title must be at least <strong>2</strong> characters long
      </mat-error>
      <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('maxlength')">
        Website Title must be at most <strong>50</strong> characters long
      </mat-error>
    </mat-form-field2>
    <div class="d-flex gap-3">
      <mat-form-field2
        class="mat-block"
        matTooltip="Specify a subdomain to use for your website URL. You may change this to a custom domain later."
        matTooltipPosition="above"
      >
        <input type="text" matInput required placeholder="Subdomain" formControlName="subdomain" />
        <mat-hint>You may change this to anything you like later.</mat-hint>
        <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('required')">
          Subdomain is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('pattern')">
          Subdomain is <strong>invalid</strong>
        </mat-error>
        <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('allSpaces')">
          Subdomain <strong>cannot be spaces alone</strong>
        </mat-error>
        <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('allNumbers')">
          Subdomain <strong>cannot be numbers alone</strong>
        </mat-error>
        <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('domainExists')">
          Domain <strong>already exists</strong>
        </mat-error>
      </mat-form-field2>

      <app-select-search
        [items]="domains"
        [ctrl]="form.get('domain')"
        [multiple]="false"
        placeholder="Domain"
        matTooltip="Select one of the free AdvanceCM domains to use with your website."
        matTooltipPosition="above"
        [inline]="false"
      >
        <ng-container class="error-container">
          <mat-error> Domain is <strong>required</strong> </mat-error>
        </ng-container>
      </app-select-search>
    </div>
    <app-rental-select
      [ctrl]="form.get('rentals')"
      placeholder="Select Rental(s)"
      matTooltip="Select one or more rentals to include in your website."
      matTooltipPosition="above"
    >
    </app-rental-select>
    <div *ngIf="data.type !== websiteTypes.GuestPortal">
      <mat-form-field2 class="mat-block">
        <app-select
          [items]="templates$ | async"
          formControlName="template"
          bindLabel="title"
          placeholder="Select Template"
          matTooltip="Select the template for your website. These templates support a single rental. This is based on the number of selected rentals."
          matTooltipPosition="above"
        >
          <ng-template selectItem let-item="item">
            <div [matTooltip]="tooltipsForTemplate[item.name]" matTooltipPosition="right">
              {{ item.title }}
            </div>
          </ng-template>
        </app-select>
        <mat-error
          *ngIf="form.get('template').touched && form.get('template').hasError('required')"
          class="ng-select-error"
        >
          Template is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div *ngIf="data.type === websiteTypes.RentalWebsite">
      <mat-form-field2 class="mat-block">
        <textarea
          name="description"
          matInput
          placeholder="Brief Description"
          formControlName="description"
          rows="6"
          cdkTextareaAutosize
        ></textarea>
      </mat-form-field2>
    </div>
    <div class="alert alert-secondary-info" *ngIf="data.type === websiteTypes.BookingEngine">
      <i class="cs-icon cs-icon-info-circle alert-icon"></i>
      <div class="alert-content">
        If you have your own website and would like to accept online bookings then a booking engine is just what you
        need. You may use your AdvanceCM booking engine from your website to accept credit card bookings online.
      </div>
    </div>
    <div class="alert alert-secondary-info" *ngIf="data.type === websiteTypes.GuestPortal">
      <i class="cs-icon cs-icon-info-circle alert-icon"></i>
      <div class="alert-content">
        A guest portal allows your guests to easily access key information about their booking like dates, invoices,
        messages, and flight details. Your guest may also update these details as they wish.
        <br />
        Your guest portal will also provide information about your rentals like check-in and check-out instructions,
        directions, and house rules. You may edit these instructions in your rental settings.
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
