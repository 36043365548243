import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { concatMap, map, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import {
  AutomationResponse,
  AutomationNotification,
  CreateAutomationPayload,
  UpdateAutomationPayload,
  normalizeAutomation,
} from './automation.model'
import { enabledAutomationServices } from './automation-services'
import { AutomationEventTypes } from './automation-events'

@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  constructor(private http: HttpClient) {}

  all() {
    const services = enabledAutomationServices.map((s) => `${s}=1`).join('&')
    const url = `@api/trigger/all?${services}`
    return this.http.get<AutomationResponse[]>(url).pipe(map((items) => items.map(normalizeAutomation)))
  }

  create(payload: CreateAutomationPayload) {
    return this.http.post<AutomationResponse>('@api/trigger/', payload).pipe(map((res) => normalizeAutomation(res)))
  }

  createMany(payload: CreateAutomationPayload) {
    return this.http
      .post<AutomationResponse[]>('@api/triggers/', payload)
      .pipe(map((items) => items.map(normalizeAutomation)))
  }

  update(id: string, payload: UpdateAutomationPayload) {
    return this.http.put<AutomationResponse>(`@api/trigger/${id}`, payload).pipe(map((res) => normalizeAutomation(res)))
  }

  updateMany(payload: UpdateAutomationPayload[]) {
    return this.http
      .put<AutomationResponse[]>('@api/triggers/', payload)
      .pipe(map((items) => items.map(normalizeAutomation)))
  }

  deleteBatch(ids: string[]) {
    return this.http.put(`@api/trigger/delete/batch`, { ids })
  }

  pause(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.http.put<AutomationResponse>(`@api/trigger/pause/${id}`, {})),
      toArray(),
      map((items) => items.map(normalizeAutomation))
    )
  }

  resume(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.http.put<AutomationResponse>(`@api/trigger/resume/${id}`, {})),
      toArray(),
      map((items) => items.map(normalizeAutomation))
    )
  }

  archive(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.http.put<AutomationResponse>(`@api/trigger/archive/${id}`, {})),
      toArray(),
      map((items) => items.map(normalizeAutomation))
    )
  }

  unArchive(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.http.put<AutomationResponse>(`@api/trigger/unarchive/${id}`, {})),
      toArray(),
      map((items) => items.map(normalizeAutomation))
    )
  }

  getTriggerCount(services: string[], event: AutomationEventTypes) {
    const url = `@api/triggers/count?service=${services.join(',')}&event=${event}`

    return this.http.get<{ count: number }>(url).pipe(map(({ count }) => count))
  }

  getLogs(id: string) {
    return this.http.get<any[]>(`@api/trigger/logs/${id}`).pipe(
      map((response) =>
        R.map((r) => {
          let obj: any = {}
          try {
            obj = JSON.parse(r)
          } catch (exp) {}
          return obj
        }, response)
      )
    )
  }

  logs(ids: string[]): Observable<AutomationNotification[]> {
    return this.http.put<AutomationNotification[]>('@api/triggers/logs', { pkeys: ids }).pipe(
      map((items) => items.map((t) => (typeof t === 'string' ? JSON.parse(t) : t))),
      map((items: AutomationNotification[]) => R.sortBy((t) => t.sent, items).reverse())
    )
  }

  allowsChannel(event: AutomationEventTypes): boolean {
    return /^inquiry/.test(event)
  }

  allowsRental(event: AutomationEventTypes): boolean {
    return /^rate|^task|^inquiry|^time|^expense|^event|^invoice/.test(event)
  }
}
