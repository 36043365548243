export enum UserSignatureType {
  Empty = 0,
  Drawer = 1,
  Text = 2,
  Image = 3,
}

export interface UserAttributesSignature {
  signature_type: UserSignatureType
  signature_text: string
  [key: string]: any
}

export interface UserSignature {
  id: string
  user_id: string
  signature: string // data URI

  // default_path: string;
  // permissions: string[];
  // can_view_templates: boolean;
  // can_view_documents: boolean;
  // An epoch timestamp with the date and time the offer was created.
  created: number
  updated: number
  attributes: UserAttributesSignature
}
