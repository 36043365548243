import { SignFieldDef } from '../templates'
import { ContractTemplate, ContractTemplateAttrs, TemplateTypes } from '../templates'
import * as lodash from 'lodash'
import { availableContractStatuses, ContractSignerType, ContractSignStatus, ContractStatus } from './contract.constants'

export type ContractTypes = TemplateTypes

export interface ContractSigner {
  name: string
  role: string // role in role list of template
  email: string
  type: ContractSignerType
  id: string // tokeet user/guest pkey
  id_photo_url: string // @deprecated
  photo_urls: string[] // passport photo and photo ID
  id_photo_required: boolean
  selfie_photo_required: boolean
  status: ContractSignStatus
}

export interface ContractActivity {
  // who did this action at any date
  // A views this contract at 2018/02/12
  user: string // role/user_id
  action: string
  date: number
  additional: any
}

export interface ContractSignField extends SignFieldDef {
  field_id: string
}

export interface ContractAttributes extends ContractTemplateAttrs {
  guest_id?: string
  rental_id?: string
  inquiry_id?: string
}

export interface Contract
  extends Omit<ContractTemplate, 'status' | 'roles' | 'fields' | 'expired_days' | 'attributes'> {
  contract_id: string
  type: ContractTypes
  auto: boolean // is created by Automata
  status: ContractStatus
  status_at: number
  // sender_name: string;
  fields: ContractSignField[]
  // reminder: {
  //   name: string;
  //   email: string;
  //   company: string;
  // }; // any action will send email to this email
  signers: ContractSigner[] // the signers need sign this contact. will send by email
  activities: ContractActivity[]
  expired_at: number
  attributes: ContractAttributes
}

export interface ContractView extends Contract {
  signerNamesView: string
  signersView: string[]
  statusCfgView: any
}

export function toContractView(item: Contract): ContractView {
  if (!item) {
    return null
  }
  const statusCfg = lodash.find(availableContractStatuses, { id: item.status })

  return {
    ...item,
    statusCfgView: statusCfg,
    signerNamesView: lodash.map(item.signers, 'name').join(', '),
    signersView: lodash.map(item.signers, (s) => `${s.name}: ${s.email}`),
  }
}
