import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ContractStatusIconPipe, ContractStatusPipe } from './contract-status-icon.pipe'
import { SignatureSecurePdfPipe } from './secure-pdf.pipe'
import { SignStatusBGColorPipe, SignStatusColorPipe, SignStatusIconPipe } from './sign-status-data.pipe'
import { TemplateIconPipe } from './template-icon.pipe'
import { SignLocationPipe } from './location.pipe'
import { SignatureSecureImagePipe } from './secure-image.pipe'

export const pipes = [
  //
  ContractStatusPipe,
  ContractStatusIconPipe,
  SignatureSecureImagePipe,
  SignatureSecurePdfPipe,
  SignStatusIconPipe,
  SignStatusColorPipe,
  SignStatusBGColorPipe,
  TemplateIconPipe,
  SignLocationPipe,
]

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class SignaturePipesModule {}
