import { Injectable } from '@angular/core'
import { ShareContractDialogComponent } from './share-contract-dialog.component'
import { Contract } from '@tokeet-frontend/signature'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ShareContractDialogService {
  constructor(private dialog: DialogService) {}

  public open(contract: Contract) {
    return this.dialog.openVariableDialog(ShareContractDialogComponent, {
      width: '650px',
      data: { contract },
    })
  }
}
