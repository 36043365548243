import { createFeatureSelector, createSelector, props } from '@ngrx/store'
import * as reducer from './template.reducer'
import * as state from './template.state'
import * as lodash from 'lodash'
import { ContractTemplate, ContractTemplateView, isAutomationAvailable, toContractTemplateView } from './template.model'
import { TemplateTypes } from './template-types'

export const templateState = createFeatureSelector<state.State>('template')

export const selectAllContractTemplates = createSelector(templateState, reducer.selectAll)
export const selectAllContractTemplateEntities = createSelector(templateState, reducer.selectEntities)

export const selectContractTemplatesLoaded = createSelector(templateState, (state: state.State) => state.isLoaded)

export const isContractTemplateUpdating = createSelector(templateState, (state: state.State) => state.isUpdating)

export const selectContractTemplateViewById = (templateId: string) =>
  createSelector(selectAllContractTemplateEntities, (itemsById) => {
    if (!itemsById || !itemsById[templateId]) {
      return
    }
    return toContractTemplateView(itemsById[templateId])
  })

export const selectContractTemplateViews = createSelector(
  selectAllContractTemplates,
  (items): ContractTemplateView[] => {
    return lodash.map(items, (i) => toContractTemplateView(i))
  }
)

export const selectContractTemplateViewsForUser = (groupIds: string[], isAdmin: boolean) =>
  createSelector(selectAllContractTemplates, (items): ContractTemplateView[] => {
    items = lodash.filter(items, (item) => isAdmin || lodash.intersection(groupIds, item.groups).length > 0)
    return lodash.map(items, (i) => toContractTemplateView(i))
  })

export const selectContractTemplatesForAutomations = createSelector(
  selectAllContractTemplates,
  (items: ContractTemplate[]): ContractTemplate[] => {
    return lodash.filter(items, (item) => isAutomationAvailable(item.roles))
  }
)

export const selectContractTemplatesForAutomationsByType = (type: TemplateTypes) =>
  createSelector(selectAllContractTemplates, (items: ContractTemplate[]): ContractTemplate[] => {
    return lodash.filter(items, (item) => item.type === type && isAutomationAvailable(item.roles))
  })

export const selectAllContractTemplateTags = createSelector(
  selectAllContractTemplates,
  (items: ContractTemplateView[]) => {
    return lodash
      .chain(items)
      .map((item) => (item.attributes && item.attributes.tags) || [])
      .flatten()
      .compact()
      .map((tag) => lodash.toLower(tag))
      .uniq()
      .sort()
      .value()
  }
)

export const selectContractTemplateTypeStats = (groupIds: string[], isAdmin: boolean) =>
  createSelector(selectContractTemplateViewsForUser(groupIds, isAdmin), (templates) => {
    return lodash
      .chain(templates)
      .map((item) => item.type)
      .countBy((t) => t)
      .value()
  })
