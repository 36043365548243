<div class="modal-header">
  <h5 class="modal-title">Decline to sign this Document</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <mat-form-field2 class="mat-block">
    <textarea
      matInput
      [formControl]="reasonCtrl"
      placeholder="Reason for Declining"
      cdkTextareaAutosize
      rows="5"
      [required]="true"
    ></textarea>
  </mat-form-field2>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" aria-label="Close" (click)="close()">
    <i class="far fa-times" aria-hidden="true"></i> Cancel
  </button>

  <button type="button" class="btn btn-secondary-danger" (click)="onSave(reasonCtrl)" [disabled]="reasonCtrl.invalid">
    <i class="far fa-minus-circle" aria-hidden="true"></i> Decline
  </button>
</div>
