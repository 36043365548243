import { adapter, initialState, State } from './group.state'
import {
  CreateGroup,
  CreateGroupComplete,
  DeleteGroup,
  DeleteGroupComplete,
  LoadGroupsComplete,
  UpdateGroup,
  UpdateGroupComplete,
} from './group.actions'
import { Action, createReducer, on } from '@ngrx/store'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadGroupsComplete, (state, { items }) => adapter.upsertMany(items, { ...state, isLoaded: true })),
  on(CreateGroup, UpdateGroup, DeleteGroup, (state) => ({ ...state, isUpdating: true })),
  on(CreateGroupComplete, (state, { item }) => adapter.upsertOne(item, { ...state, isUpdating: false })),
  on(UpdateGroupComplete, (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })),
  on(DeleteGroupComplete, (state, { id }) => adapter.removeOne(id, { ...state, isUpdating: false })),
  on(ActionFailed, (state) => ({ ...state, isUpdating: false }))
)

export function groupReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
