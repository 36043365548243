import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable, of } from 'rxjs'

import { get, map as loMap, omit, pick } from 'lodash'
import { stringify } from 'qs'
import { ContractListParams } from './models/list.request'
import { Contract } from './contract.model'
import { catchError, map, tap } from 'rxjs/operators'
import { ContractTemplate } from '../templates'
import {
  compactObject,
  deserializeArray,
  httpRawItemsToDataItems,
  httpRawItemToDataItem,
  omitViewFields,
} from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class ContractService {
  signLinkCache = new Map<string, string>()
  constructor(private http: HttpClient) {}

  list(params: ContractListParams): Observable<{ items: Contract[]; statusStats; tagStats; pagination }> {
    const url = `@signatureApi/contracts?${stringify(params)}`
    return this.http.get<any>(url).pipe(
      map(({ items, metadata }) => ({
        items: httpRawItemsToDataItems<Contract>(items, 'contract_id'),
        statusStats: get(metadata, 'statusCounts'),
        tagStats: get(metadata, 'tagCounts'),
        pagination: pick(metadata, ['total', 'limit', 'offset']),
      }))
    )
  }

  getContractsByInquiryId(inquiryId: string): Observable<Contract[]> {
    return this.http
      .get<any[]>(`@signatureApi/contracts/inquiry/${inquiryId}`)
      .pipe(map((res) => httpRawItemsToDataItems<Contract>(res, 'contract_id')))
  }

  get(id: string): Observable<any> {
    const url = `@signatureApi/contracts/${id}`
    return this.http.get<any>(url).pipe(map((res) => httpRawItemToDataItem(res, 'contract_id')))
  }

  create(payload): Observable<any> {
    const url = `@signatureApi/contracts`
    return this.http.post<any>(url, payload).pipe(map((res) => httpRawItemToDataItem(res, 'contract_id')))
  }

  update(id: string, payload: Partial<Contract>): Observable<any> {
    const url = `@signatureApi/contracts/${id}`
    return this.http
      .put<any>(url, omitViewFields(payload))
      .pipe(map((res) => httpRawItemToDataItem(res, 'contract_id')))
  }

  delete(id: string): Observable<any> {
    const url = `@signatureApi/contracts/${id}`
    return this.http.delete<any>(url)
  }

  cancel(id: string): Observable<any> {
    const url = `@signatureApi/contracts/${id}/cancel`
    return this.http.put<any>(url, {})
  }

  complete(id: string): Observable<Contract> {
    const url = `@signatureApi/contracts/${id}/complete`
    return this.http.put<any>(url, {}).pipe(map((res) => httpRawItemToDataItem(res, 'contract_id')))
  }

  send(id: string): Observable<any> {
    const url = `@signatureApi/contracts/${id}/send`
    return this.http.put<any>(url, {})
  }

  generateSignLink(id: string, role: string): Observable<string> {
    const storeKey = `${id}-${role}`
    if (this.signLinkCache.has(storeKey)) {
      return of(this.signLinkCache.get(storeKey))
    }

    const url = `@signatureApi/contracts/${id}/sign-link`
    return this.http.post<{ link: string }>(url, { role }).pipe(
      map(({ link }) => link),
      tap((link) => {
        this.signLinkCache.set(storeKey, link)
      })
    )
  }

  getStatusStats(filters: { start?: number; end?: number }): Observable<{ [status: string]: number }> {
    const url = `@signatureApi/contracts/status/stats`
    return this.http.get<any>(url, { params: compactObject(filters) })
  }

  share(id: string, payload: any): Observable<any> {
    const url = `@signatureApi/contracts/${id}/share`
    return this.http.put<any>(url, payload)
  }

  createContractFromTemplate(template: ContractTemplate, data: any) {
    return {
      fields: loMap(template.fields, (field) => omit(field, '_id')),
      ...pick(template, [
        'account',
        'template_id',
        'type',
        'origin_files',
        'pdf_file_url',
        'attributes.tags',
        'name',
        'title',
        'message',
        'font',
        'font_size',
        'groups',
      ]),
      ...data,
    }
  }
}
