import { ChannelNameTokens, isSomething } from '@tokeet-frontend/tv3-platform'
import { DBObject } from '@tv3/constants/dbobjects'
import { TemplateType } from '@tokeet-frontend/templates'
import { AutomationHandler } from './automation-handlers'
import { AutomationServiceTypes, AutomationTypes } from './automation-services'
import * as R from 'ramda'
import { AutomationEventTypes } from './automation-events'

export const automationDisabledChannels: string[] = [ChannelNameTokens.GoogleVacationRentals]

export interface AutomationResponse {
  pkey: string
  service: AutomationServiceTypes
  handler: AutomationHandler
  event: AutomationEventTypes
  type: AutomationTypes

  settings: {
    days: number
    queue: any
    hours: number
    rental: string
    users: (string | number)[]
    creator: string
    template: string
    channel: string
    not_rentals: any
    late_bookings: 0 | 1
    //
    task?: any
    expense?: any
    review?: any
    hold?: any
    // incident
    categories?: string[]
    incident?: any
  }
  users: (string | number)[]

  status: number
  timeevent: string
  conditions: any[]
  attributes: {
    [id: string]: any
  }
  group_id?: string
  subscription: string
  created: number
  lastupdate: number

  template: string //

  // for reviews
  templates: string[]
  ai_enabled: boolean

  archived: number
  account: number
  creator: string
}

export interface AutomationViewGroup extends Omit<AutomationResponse, 'pkey'> {
  ids: string[]
  rentals: string[]
  channels: string[]
  items: AutomationResponse[]
}

export function getAutomationGroupId(automation: AutomationResponse) {
  return automation.group_id || automation.pkey
}

export function normalizeAutomation(automation: AutomationResponse): AutomationResponse {
  return {
    ...automation,
    type:
      automation.type ||
      (automation.service === AutomationServiceTypes.TV3 ? AutomationTypes.Message : (automation.service as any)),
  }
}

export interface AutomationNotification {
  account: number
  key: string
  object: DBObject
  pkey: string
  sent: number
  service: string
  notes: string[]
  inquiry_id?: string
}

export function eventHandlerToMessageTemplateType(handler: AutomationHandler): TemplateType {
  switch (handler) {
    case AutomationHandler.SendPushNotification:
    case AutomationHandler.SendSMSMessage:
      return TemplateType.PushNotification
    case AutomationHandler.SendHTTPPost:
      return TemplateType.Payload
    case AutomationHandler.SendEmailMessage:
    default:
      return TemplateType.Email
  }
}

export interface AutomationFormData {
  service: AutomationServiceTypes
  type: AutomationTypes
  handler: AutomationHandler
  event: AutomationEventTypes
  timeevent?: {
    event: string
    days: number
    hours: number
  }
  status: boolean
  name: string
  delayed?: number // delayed send the message in x hours
  late_bookings?: boolean

  // Message
  guest?: boolean
  template?: string
  users?: string[]
  postUrl?: string
  rentals?: string[]
  channels?: string[]
  bookingTags?: string[]
  // incident
  categories?: string[]
  // Review
  templates?: string[]
  ai_enabled?: boolean
  // Task, Expense
  data?: any
  attributes?: any
}

export interface CreateAutomationPayload {
  status: 0 | 1

  service: AutomationServiceTypes
  type: AutomationTypes
  event: string
  handler: string
  group_id?: string

  timeevent?: string
  days?: number
  hours?: number
  late_bookings?: 0 | 1

  // Message
  users?: any[]
  template?: string
  channels?: string[]
  rentals?: string[]
  attributes?: any

  // incident
  categories?: string[]
  // Review
  templates?: string[]
  ai_enabled?: boolean
  // Task
  task?: any
  // Expense
  expense?: any
  review?: any
  hold?: any
  incident?: any
}

export interface UpdateAutomationPayload extends Omit<CreateAutomationPayload, 'status' | 'channels' | 'rentals'> {
  pkey: string

  channel?: string
  rental?: string
}

export function getAutomationPayload(form: AutomationFormData) {
  const payload: CreateAutomationPayload = {
    event: form.event,
    timeevent: form.timeevent?.event,
    days: form.timeevent?.days,
    hours: form.timeevent?.hours,
    late_bookings: form.late_bookings ? 1 : 0,
    status: form.status ? 1 : 0,
    handler: form.handler || '',
    service: form.service,
    type: form.type,
    attributes: {
      ...form.attributes,
      name: form.name,
      bookingTags: form.bookingTags,
      delayed: form.delayed,
    },
    users: form.users || [],
    template: form.template || '',
  }

  const addGuest = () => {
    let users = R.clone(form.users || [])
    if (form.guest) {
      if (R.isEmpty(users)) {
        users = ['111']
      } else {
        users = R.append('111', users)
      }
    }
    return users
  }

  const addPostUrl = () => {
    let users = R.clone(form.users)
    if (form.handler === AutomationHandler.SendHTTPPost && form.postUrl) {
      users = [form.postUrl]
    }
    return users
  }

  if (form.guest) {
    form.users = addGuest()
    form.users = addPostUrl()
    payload.users = R.isEmpty(form.users) ? [] : form.users
    payload.template = form.template
  }
  if (isSomething(form.categories)) {
    payload.categories = form.categories
  }

  if (payload.type === AutomationTypes.Task) {
    payload.task = form.data
  } else if (payload.type === AutomationTypes.Expense) {
    payload.expense = form.data
  } else if (payload.type === AutomationTypes.Incident) {
    payload.incident = form.data
  } else if (payload.type === AutomationTypes.Review) {
    payload.ai_enabled = !!form.ai_enabled
    payload.templates = form.templates || []
  } else if (payload.type === AutomationTypes.Booking) {
    payload.hold = form.data
  }

  // in order for batch create to work, default to array with single empty string
  payload.channels = !isSomething(form.channels) ? [''] : form.channels
  payload.rentals = !isSomething(form.rentals) ? [''] : form.rentals

  return payload
}
