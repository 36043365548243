import { Injectable } from '@angular/core'
import { LegalConfirmComponent } from './legal-confirm.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class LegalConfirmDialogService {
  constructor(private dialog: DialogService) {}

  public open() {
    return this.dialog.openFixedDialog<LegalConfirmComponent, 'agree'>(LegalConfirmComponent, {
      width: '500px',
      disableClose: true,
    })
  }
}
