<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Expedia with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create or Import Rentals
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Link Existing AdvanceCM Rental</div>
              <ul class="pl-3">
                <li>Linking an existing AdvanceCM rental to your Expedia property is required.</li>
              </ul>
              <div class="text-bold">Prepare Rentals</div>
              <ul class="pl-3">
                <li>If you haven't created or imported a rental from Airbnb, do so before proceeding.</li>
                <li>
                  It's often convenient to connect Airbnb first to import rentals to AdvanceCM before linking with
                  Expedia.
                </li>
              </ul>
              <div class="text-bold">Multiple Channel Connections</div>
              <ul class="pl-3">
                <li>One AdvanceCM rental can connect to multiple channels.</li>
                <li>Combined availability will be pushed to all connected channels.</li>
              </ul>
              <p>Follow this guide for instructions.</p>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="blockedDates.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #blockedDates>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Blocked Dates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Availability Management</div>
              <ul class="pl-3">
                <li>Your AdvanceCM rental calendar will solely determine availability after connecting to Expedia.</li>
              </ul>
              <div class="text-bold">Replicating Hold Events</div>
              <ul class="pl-3">
                <li>Manually replicate any hold events on AdvanceCM to ensure dates remain blocked.</li>
                <li>Hold events on Expedia are not imported to AdvanceCM.</li>
              </ul>
              <p>For details on adding hold events, refer to this <a href="">guide</a>.</p>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="prepareRates.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #prepareRates>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Preparing Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Rates and Availability:</div>
              <ul class="pl-3">
                <li>Rates and availability will be disabled until all wizard steps are completed.</li>
                <li>Push rates from AdvanceCM to Expedia; editing directly on Expedia is not possible.</li>
              </ul>
              <div class="text-bold">Preparing Rates</div>
              <ul class="pl-3">
                <li>
                  Pushing rates to Expedia is required. Use this
                  <a target="_blank" href="https://help.tokeet.com/rates-management/rates-overview">guide</a> if rates
                  are not prepared.
                </li>
                <li>
                  You can create and adjust rates within the wizard, but pre-setting a full rate plan is recommended for
                  a smoother process.
                </li>
              </ul>
              <div class="text-bold">Additional Considerations</div>
              <ul class="pl-3">
                <li>Ensure your rates in Expedia include additional guest fees.</li>
                <li>Finalize your rate plans in Expedia.</li>
              </ul>
              <div class="alert alert-secondary-warning2 align-items-start">
                <img
                  src="/assets/images/integrations/rategenie.png"
                  alt="Rategenie"
                  class="alert-icon"
                  style="height: 1.5rem"
                />
                <b>Recommended Tool:</b> Use Rategenie for advanced dynamic pricing.
              </div>
              <div class="text-right">
                <button class="btn btn-light-info" (click)="expediaMessaging.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #expediaMessaging>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">4</div>
                Expedia Messaging
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div class="text-bold">Availability Management</div>
              <ul class="pl-3">
                <li>Your AdvanceCM rental calendar will solely determine availability after connecting to Expedia.</li>
              </ul>
              <div class="text-bold">Replicating Hold Events</div>
              <ul class="pl-3">
                <li>Manually replicate any hold events on AdvanceCM to ensure dates remain blocked.</li>
                <li>Hold events on Expedia are not imported to AdvanceCM.</li>
              </ul>
              <p>For details on adding hold events, refer to this <a href="">guide</a>.</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Next
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
