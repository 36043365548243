<!--<div class="zoom-controllers">-->
<!--  <button-->
<!--    type="button"-->
<!--    (click)="onZoomToFitScreen()"-->
<!--    class="zoom-btn"-->
<!--    container="body"-->
<!--    placement="left"-->
<!--    ngbTooltip="Fit To Screen"-->
<!--    *ngIf="zoomController?.getScale() === 1"-->
<!--  >-->
<!--    <i class="fal fa-expand-wide"></i>-->
<!--  </button>-->
<!--  <button-->
<!--    type="button"-->
<!--    (click)="onResetZoom()"-->
<!--    class="zoom-btn"-->
<!--    container="body"-->
<!--    placement="left"-->
<!--    ngbTooltip="Reset View"-->
<!--    *ngIf="zoomController?.getScale() !== 1"-->
<!--  >-->
<!--    <i class="fal fa-compress"></i>-->
<!--  </button>-->
<!--</div>-->

<div class="content" #scrollableElement>
  <div class="doc-container text-center" #zoomContent>
    <app-loading-section size="3rem" class="mt-5" *ngIf="isLoading"></app-loading-section>
    <div class="field-container">
      <ng-template #fieldContainer></ng-template>
    </div>
    <pdf-viewer
      [src]="contract?.pdf_file_url | signatureSecurePdf | async"
      [render-text]="true"
      [external-link-target]="'_blank'"
      [original-size]="false"
      [fit-to-page]="true"
      [autoresize]="true"
      (after-load-complete)="onContractPDFLoaded()"
      (text-layer-rendered)="modifyAnchorElementAttributes($event)"
      class="editor"
      style="display: block"
    >
    </pdf-viewer>
  </div>
</div>
