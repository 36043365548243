<div class="modal-header">
  <h5 class="modal-title"><i class="far fa-box-up"></i> {{ isEdit ? 'Edit' : 'Add' }} Min Stay Automation</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body bg-none p-0">
  <form [formGroup]="form" (submit)="onSubmit(form)" [formEditablePermission]="form">
    <div class="container-box p-3">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field2 class="mat-block">
            <input type="text" placeholder="Name" matInput formControlName="name" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('allNumbers')"> Name cannot be numbers alone. </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6">
          <mat-form-field2 matTooltip="This automation will apply to only selected dates">
            <app-mat-daterange-input
              placeholder="Date Range"
              formControlName="range"
              [minDate]="minDate"
              [required]="true"
              [showQuickActions]="false"
              [ranges]="usedRange$ | async"
            ></app-mat-daterange-input>
            <mat-error *ngIf="form.get('range').touched && form.get('range').hasError('required')">
              Date Range is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('range').hasError('overlap')">
              Date Range is <strong>overlapping</strong> with other automation
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-lg-6">
          <app-rental-select
            [ctrl]="form.get('rentals')"
            placeholder="Rentals"
            matTooltip="Select Rental you want the min nights automation to apply to"
          >
          </app-rental-select>
        </div>
      </div>
    </div>

    <div class="container-box p-3 mt-3">
      <div class="row">
        <div class="col-lg-3" *ngFor="let item of daysForm.controls; let i = index">
          <mat-form-field2
            class="mat-block"
            matTooltip="The minimum nights requirement for the booking that starts on selected day"
          >
            <mat-label>{{ weekdayOptions[i] }}</mat-label>
            <input matInput type="number" [formControl]="item" placeholder="Min Nights" />
            <mat-error *ngIf="item.touched && item.hasError('required')">
              Min nights is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="item.hasError('positive') || item.hasError('integer')">
              Min nights should be positive integer
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="onSubmit(form)"
    [disabled]="form.disabled || (1 | isReadonlyRole)"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i *ngIf="!form.disabled" class="cs-icon cs-icon-save"></i>
    <i *ngIf="form.disabled" class="far fa-spinner fa-spin"></i>
    Save
  </button>
</div>
