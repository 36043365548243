import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  ChannelNameTokens,
  ConfirmDialogService,
  isSomething,
  loadRentals,
  Rental,
  selectAllRentals,
  selectOnce,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { catchError, concatMap, filter, mapTo, switchMap, take, tap, toArray } from 'rxjs/operators'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { SelectItemDialogService } from '@tv3/shared/dialogs/select-item-dialog/select-item-dialog.service'
import { AirbnbUpdateBookingPrefDialogService } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-update-booking-pref-dialog/airbnb-update-booking-pref-dialog.service'
import { AirbnbUpdatePriceSettingsDialogService } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-update-price-settings-dialog/airbnb-update-price-settings-dialog.service'
import { LoadConnectionsByChannel } from '@tv3/store/connection/connection.actions'
import { EMPTY, of } from 'rxjs'
import { SubscriptionPermissionCheckerService } from '@tv3/services/subscription-permission-checker.service'
import { AirbnbTaxSettingsDialogService } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-tax-settings-dialog/airbnb-tax-settings-dialog.service'
import { Connection, getRealChannelId } from '@tv3/store/connection/connection.model'
import { AirbnbChannelService, airbnbListingSyncTypes } from '@tokeet-frontend/channels'

@Injectable({
  providedIn: SharedModule,
})
export class AirbnbHelperService {
  constructor(
    private store: Store<fromRoot.State>,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    private selectItemDialog: SelectItemDialogService,
    private airbnbUpdateBookingPrefDialog: AirbnbUpdateBookingPrefDialogService,
    private airbnbUpdatePriceSettingsDialog: AirbnbUpdatePriceSettingsDialogService,
    private airbnbTaxSettingsDialogService: AirbnbTaxSettingsDialogService,
    private subscriptionPermissionCheckerService: SubscriptionPermissionCheckerService,
    private connectionAirbnbService: ConnectionAirbnbService,
    private airbnbChannelService: AirbnbChannelService
  ) {}

  private preImportRooms(newRentals: number) {
    return this.store.pipe(
      selectOnce(selectAllRentals),
      switchMap((rentals) =>
        this.subscriptionPermissionCheckerService.check({
          checkAddRentalPermission: true,
          rentals: rentals ? rentals.length : 0,
          newRentals,
        })
      ),
      filter((allowed) => allowed)
    )
  }

  importRooms(connections: Connection[]) {
    return this.preImportRooms(connections.length).pipe(
      switchMap(() =>
        this.confirmDialog.confirm({
          title: 'Import listings from channel?',
          body: `This will import your listing data from the selected channel into AdvanceCM. AdvanceCM will only import listings
            that are not already imported. A new rental will be created in AdvanceCM with the data.
            <br><br>Are you sure you want to import?`,
        })
      ),
      switchMap(() =>
        of(...connections).pipe(
          concatMap((connection) =>
            this.connectionAirbnbService.importRoom(
              ChannelNameTokens.AirBnBV2API,
              connection.channelId,
              connection.roomId,
              connection.propertyId
            )
          ),
          toArray()
        )
      ),
      tap(() => this.store.dispatch(loadRentals({}))), // refresh rentals
      tap(() => this.toaster.info('Listings Import Scheduled.')),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      }),
      take(1)
    )
  }

  importRoom(channelName: ChannelNameTokens, connection: Connection) {
    return this.preImportRooms(1).pipe(
      switchMap(() =>
        this.confirmDialog.confirm({
          title: 'Import property from channel?',
          body: `This will import your property listing data from the selected channel into AdvanceCM. AdvanceCM will only import properties
            that are not already imported. A new rental will be created in AdvanceCM with the data.
            <br><br>Are you sure you want to import?`,
        })
      ),
      switchMap(() =>
        this.connectionAirbnbService.importRoom(
          channelName,
          connection.channelId,
          connection.roomId,
          connection.propertyId
        )
      ),
      tap(() => this.store.dispatch(loadRentals({}))), // refresh rentals
      tap(() =>
        this.store.dispatch(
          LoadConnectionsByChannel({ channelName, channelId: getRealChannelId(connection.channelId) })
        )
      ),
      tap(() => this.toaster.success('Property imported successfully.')),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      }),
      take(1)
    )
  }

  setVisibilityV2(connection: ConnectionView) {
    return this.connectionAirbnbService
      .getVisibilityStatusV2(connection.channelId, connection.propertyId, connection.roomId)
      .pipe(
        switchMap(({ visible }) =>
          this.confirmDialog
            .confirm({
              title: 'Listing Visibility',
              body: visible
                ? 'This listing is currently <b>visible</b>. Would you like to hide it?'
                : 'This listing is currently <b>not visible</b>. Would you like to make it visible?',
              cancelText: 'Cancel',
              confirmText: visible ? 'Hide' : 'Set Visible',
            })
            .pipe(mapTo(!visible))
        ),
        switchMap((visible) =>
          this.connectionAirbnbService
            .setVisibilityStatusV2(connection.channelId, connection.propertyId, connection.roomId, visible)
            .pipe(mapTo(visible))
        ),
        tap(() => this.toaster.success('Property visibility updated successfully.')),
        catchError((error) => {
          this.store.dispatch(ActionFailed({ error }))
          return EMPTY
        }),
        take(1)
      )
  }

  updateBookingPref(connection: ConnectionView) {
    this.airbnbUpdateBookingPrefDialog.open(connection)
  }

  updatePriceSettingsV2(connection: Connection) {
    return this.airbnbUpdatePriceSettingsDialog.open(connection).afterClosed()
  }

  updateTaxSettings(connection: ConnectionView) {
    return this.airbnbTaxSettingsDialogService.open(connection).afterClosed()
  }

  setSyncCategoryV2(connection: Connection) {
    return this.selectItemDialog
      .open({
        title: 'Change Synchronization',
        help: `You may change the synchronization between AdvanceCM and Airbnb for this listing. By default AdvanceCM will sync only rates and
       availability with your Airbnb listing. Rates and availability synchronization allows you to continue managing your listing settings
       in Airbnb. You may also choose a "Full Sync" between Airbnb and AdvanceCM which will allow you to update your Airbnb listing content,
       like images, via AdvanceCM.`,
        list: airbnbListingSyncTypes,
        bindValue: 'value',
        bindLabel: 'name',
        placeholder: 'Synchronization category',
      })
      .pipe(
        filter((res) => isSomething(res)),
        switchMap((category: string) =>
          this.airbnbChannelService
            .updateListingSyncCategory({
              category: category,
              propertyId: connection.propertyId,
              roomid: connection.roomId,
              channelId: connection.channelId,
              name: connection.name,
            })
            .pipe(
              catchError((error) => {
                this.store.dispatch(ActionFailed({ error }))
                return EMPTY
              })
            )
        ),
        take(1),
        tap(() => this.toaster.success('Sync category updated successfully.'))
      )
  }

  syncImageV2(connection: ConnectionView) {
    this.confirmDialog
      .confirm({
        title: 'Sync images with channel?',
        body: `This will push your AdvanceCM rental images to the channel listing. Images which have not already been uploaded will be added to your channel.
        <br/><br/> Do you want to proceed?`,
      })
      .pipe(
        switchMap(() => {
          return this.connectionAirbnbService.syncImagesV2(
            connection.channelId,
            // @ts-ignore
            connection.propertyId,
            connection.roomId
          )
        }),
        take(1)
      )
      .subscribe(
        () => {
          this.toaster.success('Images are being synced.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  updateListingDetailsV2(connection: ConnectionView) {
    this.confirmDialog
      .confirm({
        title: 'Update listing details?',
        body: `This will update your channel listing details with AdvanceCM rental data. <br/><br/> Do you want to proceed?`,
        confirmBtnClass: 'btn btn-secondary-success',
        cancelBtnClass: 'btn btn-light',
      })
      .pipe(
        switchMap(() => {
          return this.connectionAirbnbService.updateListingDetailsV2(
            connection.channelId,
            // @ts-ignore
            connection.propertyId,
            connection.roomId
          )
        }),
        take(1)
      )
      .subscribe(
        () => {
          this.toaster.success('Details updated successfully.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  pushRoomsV2(connection: ConnectionView, rental: Rental) {
    this.connectionAirbnbService
      .pushRoomsV2(connection.propertyId, connection.rentalId, rental.abb_listing_id)
      .subscribe(
        () => {
          this.toaster.success('Rooms pushed successfully.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  setReviewStatusV2(connection: ConnectionView) {
    this.selectItemDialog
      .open({
        title: 'Listing Review Status',
        help: `You may update the review status of your listing. For listings created using AdvanceCM
            you should set the review status to "ready for review" which will trigger Airbnb's
            automated review process. Once your listing is reviewed it can be published on the
            Airbnb website.`,
        list: [
          { name: 'Ready for review', value: 'ready for review' },
          { name: 'New', value: 'new' },
        ],
        bindValue: 'value',
        bindLabel: 'name',
        placeholder: 'Review status',
      })
      .pipe(
        filter((res) => isSomething(res)),
        // @ts-ignore
        switchMap((status: string) =>
          this.connectionAirbnbService.setReviewStatusV2(
            connection.channelId,
            // @ts-ignore
            connection.propertyId,
            connection.roomId,
            status
          )
        ),
        take(1)
      )
      .subscribe(
        () => {
          this.toaster.success('Review status updated successfully.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  deleteListingV2(connection: ConnectionView) {
    this.confirmDialog
      .confirm({
        title: 'Delete your listing?',
        body: `This will delete your listing on the channel. This action cannot be undone once confirmed and your listing will be permanently
        deleted. You will have to create a new listing if you want the rental to reappear on the channel.
        <br/><br/> Do you want to proceed?`,
      })
      .pipe(
        switchMap(() => {
          // @ts-ignore
          return this.connectionAirbnbService.deleteListingV2(
            connection.channelId,
            // @ts-ignore
            connection.propertyId,
            connection.roomId
          )
        }),
        take(1)
      )
      .subscribe(
        () => {
          this.toaster.success('Listing deleted from the channel.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }
}
