<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="created" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="ratename">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Channel Category</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.ratename }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header>AdvanceCM Category</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.category }}
        </mat-cell>
      </ng-container>
      <!--      <ng-container matColumnDef="frequency">-->
      <!--        <mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</mat-header-cell>-->
      <!--        <mat-cell *matCellDef="let element">-->
      <!--          {{ element.frequency | titleCase }}-->
      <!--        </mat-cell>-->
      <!--      </ng-container>-->
      <ng-container matColumnDef="autosync">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span
            matTooltip="When enabled AdvanceCM will automatically update your channel rates as your availability changes."
            matTooltipPosition="below"
          >
            Auto Sync <i class="fas fa-info-circle"></i>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            #slideToggle
            [disabled]="1 | isReadonlyRole"
            [checked]="!!element.autosync"
            [inline]="true"
            (toggleChange)="onChangeSync(element)"
            [matTooltip]="
              element.autosync
                ? 'Synchronization is enabled for this rate mapping.'
                : 'Synchronization is disabled for rate mapping.'
            "
          >
          </app-form-switch>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Mapped</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.created | epoch">
            {{ element.created | epoch : 'DD - MMM - YYYY' }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i> Edit</button>
              </li>
              <li>
                <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Delete</button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEdit(row)"></mat-row>
    </mat-table>

    <app-empty-table *ngIf="isEmptyTable$ | async" type="RentalRateMappings" class="py-4"> </app-empty-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="true"
      [pageSize]="100"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>
    <div class="text-center" [class.my-3]="!(isEmptyTable$ | async)">
      <button (click)="onAdd()" type="button" class="btn btn-secondary-info">Add Rate Mapping</button>
    </div>
  </table>
</div>
