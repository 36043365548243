import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  ActionFailed,
  DataCheckerService,
  DataEntityType,
  Destroyable,
  SaveForm,
  Toaster,
  positive,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { EntityAttributeResponse, EntityAttributesService } from '../../store/attrs'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import * as lodash from 'lodash'
import { AttributeDescriptionGuard, selectAttributeDescriptionByEntityType } from '../../store/attr-desc'
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs'
import { catchError, finalize, switchMap, tap } from 'rxjs/operators'
import { AttributeFieldDef, AttributeFieldType } from '../../store/attr-desc/model'
import { EntityAttributesConfigOverlayService } from '../entity-attributes-config-overlay/entity-attributes-config-overlay.service'

interface EntityAttributesFormDataItem {
  label: string
  description: string
  type: string
  key: string
  value: any
}

@Component({
  selector: 'app-entity-attributes',
  templateUrl: './entity-attributes.component.html',
  styleUrls: ['./entity-attributes.component.scss'],
})
export class EntityAttributesComponent extends Destroyable implements OnInit {
  _entityId: string = null

  @Input() entityType: DataEntityType
  @Input() set entityId(id: string) {
    this._entityId = id
    setTimeout(() => this.init(), 10)
  }
  get entityId() {
    return this._entityId
  }
  @Input() showActions = false
  @Input() actionsPosition: 'start' | 'center' | 'end' = 'start'

  fieldDefsByKey: Record<string, any> = {}
  isFieldDefsEmpty = true

  dataTypes = AttributeFieldType

  form = this.fb.group({
    attrs: this.fb.array([]),
  })

  get attrsArrayForm() {
    return this.form.get('attrs') as FormArray
  }

  isLoading = false
  isSaving = false

  refresh$ = new BehaviorSubject(true)

  sub: Subscription
  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private toaster: Toaster,
    private attrService: EntityAttributesService,
    private dataCheckerService: DataCheckerService,
    private entityAttributesConfigOverlay: EntityAttributesConfigOverlayService
  ) {
    super()
    this.dataCheckerService.check([AttributeDescriptionGuard])
  }

  ngOnInit(): void {}

  init() {
    if (this.sub) this.sub.unsubscribe()

    this.sub = combineLatest([
      this.store.pipe(select(selectAttributeDescriptionByEntityType(this.entityType))),
      this.refresh$.pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() => this.attrService.getAttributes(this.entityType, this.entityId)),
        catchError(() => of(undefined)),
        tap(() => (this.isLoading = false))
      ),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([description, attr]) => {
        this.fieldDefsByKey = lodash.keyBy(description?.attribute_fields, (t) => t.attribute)
        this.isFieldDefsEmpty = lodash.isEmpty(this.fieldDefsByKey)

        this.setForm(description?.attribute_fields || [], attr)
      })
  }

  refresh() {
    this.refresh$.next(true)
  }

  setForm(fields: AttributeFieldDef[], attrs?: EntityAttributeResponse) {
    fields = lodash.orderBy(fields, [
      (t) => (t.field_type === AttributeFieldType.Boolean ? 1 : 0),
      (t) => lodash.toLower(t.label),
    ])
    this.attrsArrayForm.clear()
    lodash.forEach(fields, (field) => {
      const value = attrs?.attributes?.[field.attribute]
      this.attrsArrayForm.push(
        this.fb.group({
          label: [field.label],
          description: [field.description],
          type: [field.field_type],
          key: [field.attribute],
          value: [value, field.field_type === AttributeFieldType.PositiveNumber ? [positive()] : []],
        })
      )
    })
  }

  onConfig() {
    this.entityAttributesConfigOverlay.open(this.entityType)
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { attrs }: { attrs: EntityAttributesFormDataItem[] } = form.getRawValue()

    this.isSaving = true
    const payload = lodash.mapValues(
      lodash.keyBy(attrs, (item) => item.key),
      (t) => t.value
    )
    this.attrService
      .create(this.entityType, this.entityId, payload)
      .pipe(finalize(() => (this.isSaving = false)))
      .subscribe(
        () => {
          this.toaster.success('Attributes saved successfully')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }
}
