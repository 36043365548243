import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import {
  Destroyable,
  isSavingDetailInfo,
  Rental,
  rentalLocales,
  RentalService,
  SaveForm,
  untilDestroy,
  updateRentalDetails,
  RentalChannelAmenityAvailableItem,
  selectAllApiChannelViews,
  ChannelNameTokens,
  DataCheckerService,
  Channel,
} from '@tokeet-frontend/tv3-platform'
import { rentalTypes } from '@tokeet-frontend/tv3-platform'
import { integer, numberStepValidator } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { map } from 'rxjs/operators'
import { AirbnbCheckinType } from 'libs/channels/src/lib/store/airbnb/checkin_type'
import { AirbnbChannelService } from '@tokeet-frontend/channels'

const channelNamesMapping = {
  [ChannelNameTokens.AirBnBAPI]: 'abb',
  [ChannelNameTokens.AirBnBV2API]: 'abb',
  [ChannelNameTokens.Hvmi]: 'hvmi',
  [ChannelNameTokens.Holidu]: 'holidu',
  [ChannelNameTokens.RentalsUnited]: 'ru',
  [ChannelNameTokens.GoogleVacationRentals]: 'gvr',
  // [ChannelNameTokens.Tiket]: 'tiket',
  [ChannelNameTokens.Homeaway]: 'vrbo',
}
@Component({
  selector: 'app-rental-details-detail-info',
  templateUrl: './rental-details-detail-info.component.html',
  styleUrls: ['./rental-details-detail-info.component.scss'],
})
export class RentalDetailsDetailInfoComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental

  types = rentalTypes
  details: RentalChannelAmenityAvailableItem[] = []
  checkinTypes: AirbnbCheckinType[] = []

  locales = rentalLocales

  channelFilterForAmenityCtrl = new FormControl()
  apiChannels$ = this.store.pipe(
    select(selectAllApiChannelViews),
    map((data) => {
      const channels = lodash.keys(channelNamesMapping)
      return lodash.filter(data, (t) => channels.includes(t.name))
    })
  )
  channelAmenities: Record<string, RentalChannelAmenityAvailableItem>
  channelsById: Record<string, Channel>

  form = this.fb.group({
    type: [''],
    checkin_type: [''],
    description: [''],
    bedrooms: ['', [integer()]],
    bathrooms: ['', [numberStepValidator(0.25)]],
    locale: [''],
    sleepMin: [
      '',
      [
        Validators.min(1),
        integer(),
        (c: AbstractControl) => {
          if (!this.form) return
          const v = c.value
          const max = this.form.get('sleepMax').value
          return v > max ? { max } : null
        },
      ],
    ],
    sleepMax: [
      '',
      [
        Validators.min(1),
        integer(),
        (c: AbstractControl) => {
          if (!this.form) return
          const v = c.value
          const min = this.form.get('sleepMin').value
          return v < min ? { min } : null
        },
      ],
    ],
    sizeMetric: [''],
    size: [''],
    detail: this.fb.array([]),
    guest_minimum_age: [null, [Validators.min(0), Validators.max(200)]],
    children_allowed: [false],
    events_allowed: [false],
    smoking_allowed: [false],
    pets_allowed: [false],
  })

  isSavingDetailInfo$ = this.store.pipe(select(isSavingDetailInfo))

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private rentalService: RentalService,
    private dataChecker: DataCheckerService,
    private airbnbChannelService: AirbnbChannelService
  ) {
    super()
    this.dataChecker.check([ChannelGuard])
  }

  ngOnInit() {
    const sleepMaxCtrl = this.form.get('sleepMax')
    const sleepMinCtrl = this.form.get('sleepMin')
    sleepMaxCtrl.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      sleepMinCtrl.updateValueAndValidity({ emitEvent: false })
    })
    sleepMinCtrl.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      sleepMaxCtrl.updateValueAndValidity({ emitEvent: false })
    })
    this.rentalService.getChannelAmenities().subscribe((data) => {
      data = [...data].sort((item1, item2) => {
        return lodash
          .toString(item1.name)
          .localeCompare(lodash.toString(item2.name), undefined, { numeric: true, sensitivity: 'base' })
      })

      this.details = data
      this.channelAmenities = lodash.keyBy(data, (t) => t.id)
      this.setRentalDetailsForm()
    })

    this.apiChannels$.pipe(untilDestroy(this)).subscribe((channels) => {
      this.channelsById = lodash.keyBy(channels, (c) => c.name)
    })
    this.airbnbChannelService.getCheckinTypes().subscribe((types) => {
      this.checkinTypes = types
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh()
  }

  refresh() {
    this.setForm(this.rental)
    this.setRentalDetailsForm()
  }

  setRentalDetailsForm() {
    const controls = R.map((d) => this.fb.control(R.indexOf(d.id, R.keys(this.rental.detail)) !== -1), this.details)
    this.form.setControl('detail', this.fb.array(controls))
  }

  isAmenityAvailable(
    id: string,
    channelName: ChannelNameTokens,
    channelAmenities: Record<string, RentalChannelAmenityAvailableItem>
  ) {
    if (!channelName || !channelAmenities) {
      return true
    }

    const t = channelAmenities[id]
    if (!t) return false
    return !!t[channelNamesMapping[channelName] || channelName]
  }

  getIcons(
    id: string,
    channelAmenities: Record<string, RentalChannelAmenityAvailableItem>,
    channelsById: Record<string, Channel>
  ) {
    if (!channelAmenities) {
      return []
    }
    const item = channelAmenities[id]
    const data = lodash.invert(channelNamesMapping)
    const items = lodash.sortBy(lodash.toPairs(lodash.omit(item, ['id', 'name'])), ([k, v]) => k)
    return lodash
      .map(items, ([k, v]) => {
        if (!v) {
          return {}
        }
        const channelName = data[k] || k
        return {
          name: channelName,
          label: channelsById[channelName]?.friendlyName,
        }
      })
      .filter((t) => !!t?.name)
  }

  private setForm(rental: Rental) {
    this.form.patchValue({
      type: rental.type,
      checkin_type: rental.checkin_type || 'host_checkin',
      description: rental.description || '',
      locale: rental.locale,
      bedrooms: R.defaultTo('', rental.bedrooms),
      bathrooms: R.defaultTo('', rental.bathrooms),
      sleepMin: R.defaultTo('', rental.sleepMin),
      sleepMax: R.defaultTo('', rental.sleepMax),
      sizeMetric: rental.sizeMetric || 'SQFT',
      size: rental.size,
      guest_minimum_age: rental.guest_minimum_age,
      children_allowed: R.is(Number, rental.children_allowed) && rental.children_allowed > 0,
      events_allowed: R.is(Number, rental.events_allowed) && rental.events_allowed > 0,
      smoking_allowed: R.is(Number, rental.smoking_allowed) && rental.smoking_allowed > 0,
      pets_allowed: R.is(Number, rental.pets_allowed) && rental.pets_allowed > 0,
    })
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { detail: detailValues, ...formValues } = form.getRawValue()
    const detail = this.details.filter((_, i) => detailValues[i]).reduce((acc, cur) => ({ ...acc, [cur.id]: true }), {})
    const payload = {
      ...formValues,
      detail,
      children_allowed: formValues.children_allowed ? 1 : 0,
      events_allowed: formValues.events_allowed ? 1 : 0,
      smoking_allowed: formValues.smoking_allowed ? 1 : 0,
      pets_allowed: formValues.pets_allowed ? 1 : 0,
    }

    this.store.dispatch(updateRentalDetails({ form: payload, rental: this.rental }))
  }
}
