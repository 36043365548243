import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { map, pick, head } from 'lodash'
import { SignService } from './sign.service'
import { PhotoUploaderService } from './dialogs/photo-uploader/photo-uploader.service'
import { LegalConfirmDialogService } from './dialogs/legal-confirm/legal-confirm.service'
import { DeclineConfirmDialogService } from './dialogs/decline-confirm/decline-confirm.service'
import { filter, finalize, switchMap, tap } from 'rxjs/operators'
import { ContractSignResponse } from './model/contract-sign.response'
import { of } from 'rxjs'
import { SignEditorComponent } from './sign-editor/sign-editor.component'
import { Store } from '@ngrx/store'
import { GetContract, ContractSignField } from '@tokeet-frontend/signature'
import { Toaster, isSomething } from '@tokeet-frontend/tv3-platform'
import { SignatureFileService } from 'libs/signature/src/lib/services/file.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './sign-drawer.component.html',
  styleUrls: ['./sign-drawer.component.scss'],
})
export class SignDrawerComponent implements OnInit {
  @ViewChild('editor') editor: SignEditorComponent
  contract: ContractSignResponse
  photoUrls: string[] = []

  get signer() {
    return head(this.contract?.signers)?.role
  }

  get idPhotoRequired() {
    return this.contract?.id_photo_required
  }

  get selfiePhotoRequired() {
    return this.contract?.selfie_photo_required
  }

  get isPhotoRequired() {
    return this.idPhotoRequired || this.selfiePhotoRequired
  }

  isProcessing = false

  constructor(
    private dialogRef: MatDialogRef<SignDrawerComponent>,
    private store: Store<any>,
    private signService: SignService,
    private fileService: SignatureFileService,
    private toaster: Toaster,
    private photoUploader: PhotoUploaderService,
    private legalConfirmDialog: LegalConfirmDialogService,
    private declineConfirmDialog: DeclineConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) private data: { contractId: string; role: string }
  ) {}

  ngOnInit() {
    this.signService.get(this.data.contractId, this.data.role).subscribe(
      (contract) => {
        this.contract = contract
      },
      (error) => {
        this.toaster.error('', 'Unable to load contract for signing', error)
        this.close()
      }
    )
  }

  close() {
    this.dialogRef.close()
  }

  refreshContract() {
    this.store.dispatch(GetContract({ id: this.data.contractId, skipError: true }))
    this.fileService.disableFileCache(this.contract.pdf_file_url)
  }

  uploadIDPhoto() {
    return this.photoUploader
      .open({
        isSelfieRequired: this.selfiePhotoRequired,
        isIDRequired: this.idPhotoRequired,
      })
      .pipe(tap((files) => (this.photoUrls = files.map((f) => f.url))))
  }

  showLegalConfirm() {
    return this.legalConfirmDialog
      .open()
      .afterClosed()
      .pipe(filter((res) => res === 'agree'))
  }

  onSignContract(fields: ContractSignField[]) {
    const signIt = () => {
      const fieldsData = map(fields, (field) => {
        return pick(field, ['field_id', 'value'])
      })
      return this.signService.sign(this.data.contractId, this.data.role, {
        fields: fieldsData,
        photo_urls: this.photoUrls,
      })
    }

    this.isProcessing = true
    ;(this.isPhotoRequired ? this.uploadIDPhoto() : of(true))
      .pipe(
        switchMap(() => this.showLegalConfirm()),
        switchMap(() => signIt()),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe(
        () => {
          this.close()
          this.refreshContract()
        },
        () => {
          this.toaster.error('Something wrong, please contact support for help.')
        }
      )
  }

  onDeclineContract() {
    this.declineConfirmDialog
      .open()
      .afterClosed()
      .pipe(filter(isSomething))
      .subscribe(({ reason }) => {
        this.isProcessing = true
        this.signService
          .decline(this.data.contractId, this.data.role, reason)
          .pipe(finalize(() => (this.isProcessing = false)))
          .subscribe(
            () => {
              this.toaster.success('This contract has been declined.')
              this.refreshContract()
              this.close()
            },
            () => {
              this.toaster.error('Oops! Something went wrong. Please contact support for help.')
            }
          )
      })
  }
}
