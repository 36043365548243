<div class="modal-header">
  <h5 class="modal-title">Share Document</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <app-select
        [items]="tokeetUsers"
        bindLabel="name"
        bindValue="id"
        formControlName="recipients"
        [multiple]="true"
        [searchable]="true"
        placeholder="Recipients"
        [required]="true"
      >
      </app-select>
      <mat-error *ngIf="form.get('recipients').touched && form.get('recipients').hasError('required')">
        Recipients is required
      </mat-error>
    </mat-form-field2>
    <div>
      <mat-form-field2 class="mat-block">
        <input matInput type="text" formControlName="title" placeholder="Title" required />
        <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field2>
    </div>
    <div>
      <mat-form-field2 class="mat-block">
        <textarea
          matInput
          rows="3"
          cdkTextareaAutosize
          formControlName="message"
          placeholder="Message"
          required
        ></textarea>
        <mat-error *ngIf="form.get('message').touched && form.get('message').hasError('required')">
          Message is required
        </mat-error>
      </mat-form-field2>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="close()">Cancel</button>

  <button type="button" class="btn btn-secondary-info" (click)="onSave(form)">
    <i class="far fa-paper-plane"></i> Share
  </button>
</div>
