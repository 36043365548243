<div class="filters-box">
  <mat-form-field2
    class="d-block"
    [showLabel]="false"
    matTooltip="Filter the below discounts by type."
    matTooltipPosition="above"
    matTooltipClass="text-center"
  >
    <mat-select placeholder="Type" [formControl]="filters.get('type')">
      <mat-option value="all">All</mat-option>
      <mat-option value="pct">Percent</mat-option>
      <mat-option value="flat">Flat</mat-option>
    </mat-select>
  </mat-form-field2>
  <app-select-search
    class=""
    bindValue="id"
    bindLabel="friendlyName"
    [items]="channels$ | async"
    [ctrl]="filters.get('channels')"
    placeholder="Channels"
    matTooltip="Filter the below discounts by channel."
    matTooltipPosition="above"
    matTooltipClass="text-center"
  >
  </app-select-search>
  <app-rental-select
    [inline]="true"
    [ctrl]="filters.get('rentals')"
    placeholder="Rentals"
    matTooltip="Filter the below discounts by rental."
  >
  </app-rental-select>
  <app-cancel-filters
    [isFiltering]="filters | isFiltering"
    [filterControl]="filters"
    [searchCom]="search"
  ></app-cancel-filters>

  <app-button
    (click)="onRefresh()"
    icon="far fa-sync"
    class="btn btn-secondary-primary"
    [loading]="isRefreshing$ | async"
    matTooltip="Refresh"
    matTooltipPosition="above"
  >
  </app-button>

  <div class="flex-fill"></div>

  <app-table-search
    #search
    (search)="onSearch($event)"
    [filterGroup]="filterGroup"
    matTooltip="Search by any values visible in the table below"
    matTooltipPosition="above"
    matTooltipClass="text-center"
  ></app-table-search>
  <button
    type="button"
    class="btn btn-secondary-danger"
    (click)="onDeleteSelected()"
    [disabled]="selection.isEmpty() || (1 | isReadonlyRole)"
  >
    <i class="cs-icon cs-icon-trash"></i> Delete
  </button>

  <button type="button" class="btn btn-secondary-info" (click)="onAdd()" [disabled]="1 | isReadonlyRole">
    <i class="far fa-plus"></i> Add LOS Discount
  </button>
</div>
<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="text-truncate" [matTooltip]="element.name" matTooltipPosition="above">{{ element.name }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.type === 'pct' ? element.amount + '%' : (element.amount | number : '.2-2')
          }}<ng-container *ngIf="hasLOS">{{ element.modality === modalities.PerNight ? '(PN)' : '(PS)' }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalsView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rentals</mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [matTooltip]="element.rentalsView"
          [matTooltipHideDelay]="element.rentalsView?.length"
          matTooltipPosition="above"
          matTooltipClass="tooltip-lg"
        >
          {{ element.rentals?.length || 'All Rentals' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="channelsView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Channels</mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.channelsView" matTooltipPosition="above">
          {{ element.channels?.length || 'All Channels' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.startView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endView">
        <mat-header-cell *matHeaderCellDef mat-sort-header="end">End</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.endView }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="length">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Length</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.length || '' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="guests">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Guests</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.guests || '' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.created | epoch" matTooltipPosition="above">
          {{ element.createdView || '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> EDIT </mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom left" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button"><i class="fal fa-file-alt"></i>View</button>
              </li>
              <li>
                <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Delete</button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" (click)="onEdit(row)"></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white"></app-empty-table>
  </table>
</div>
