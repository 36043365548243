<div class="modal-header">
  <div class="d-flex align-items-center gap-3 w-100">
    <div class="modal-title d-flex gap-2 align-items-center">
      Add LOS Discount
      <app-page-help articleId="k782tt9e6k"></app-page-help>
    </div>
    <div class="flex-fill"></div>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body bg-none p-0 m-0">
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="p-3">
      <div class="row">
        <div class="col-md-6">
          <label class="form-field-label">Type <span class="text-danger">*</span></label>
          <div class="d-flex gap-3">
            <button
              class="btn flex-1"
              (click)="form.get('type').setValue('flat')"
              [ngClass]="form.get('type').value == 'flat' ? 'btn-success' : 'btn-outline-success'"
              matTooltip="Exact value set in Amount field will be discounted from booking total"
            >
              <i class="fas fa-dollar-sign"></i> Flat
            </button>
            <button
              class="btn flex-1"
              (click)="form.get('type').setValue('pct')"
              [ngClass]="form.get('type').value == 'pct' ? 'btn-info' : 'btn-outline-info'"
              matTooltip="Percentage of booking total will be discounted"
            >
              <i class="fas fa-percent"></i> Percentage
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="d-block" matTooltip="Enter the name for this discount" matTooltipPosition="below">
            <input matInput placeholder="Name" formControlName="name" type="text" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('maxlength')"> Name must be at most 50 characters </mat-error>
            <mat-error *ngIf="form.get('name').hasError('allWhitespace')"> Name is invalid </mat-error>
            <mat-hint align="end"> {{ form.get('name').value.length || 0 }}/50</mat-hint>
          </mat-form-field2>
        </div>
        <div class="col-md-6" *ngIf="hasModality">
          <label class="form-field-label">Modality <span class="text-danger">*</span></label>
          <div class="d-flex gap-3">
            <button
              class="btn flex-1"
              (click)="form.get('modality').setValue(modalities.PerStay)"
              [ngClass]="form.get('modality').value == modalities.PerStay ? 'btn-indigo' : 'btn-outline-indigo'"
              matTooltip="Discount will be applied on Stay as a whole"
            >
              <i class="cs-icon cs-icon-bed"></i> Per Stay
            </button>
            <button
              class="btn flex-1"
              (click)="form.get('modality').setValue(modalities.PerNight)"
              [ngClass]="form.get('modality').value == modalities.PerNight ? 'btn-secondary' : 'btn-outline-secondary'"
              matTooltip="Discount will be applied for each night"
            >
              <i class="cs-icon cs-icon-moon"></i> Per Night
            </button>
          </div>
        </div>

        <div [ngClass]="hasModality ? 'col-md-6' : 'col-md-12'">
          <mat-form-field2
            class="d-block"
            matTooltip="Enter the description for this discount"
            matTooltipPosition="below"
          >
            <textarea
              matInput
              placeholder="Description"
              formControlName="description"
              type="text"
              rows="2"
              required
              cdkTextareaAutosize
              [cdkAutosizeMaxRows]="3"
            ></textarea>
            <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('required')">
              Description is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('description').hasError('maxlength')">
              Description must be at most 150 characters
            </mat-error>
            <mat-hint align="end"> {{ form.get('description').value.length || 0 }}/150</mat-hint>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="p-3 border-top">
      <div class="row">
        <div class="col-md-3">
          <mat-form-field2 class="d-block" matTooltip="Enter the discount amount" matTooltipPosition="above">
            <input
              matInput
              type="number"
              [placeholder]="'Amount' + (form.get('type').value === 'pct' ? '(%)' : '')"
              formControlName="amount"
              required
              step="1"
            />
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('percent')">
              Amount should be greater than <b>0</b> and less than <b>100</b>
            </mat-error>
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('positive')">
              Amount should be greater than <b>0</b>
            </mat-error>
            <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
              Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2
            class="d-block"
            matTooltip="Enter the minimum length of stay to which this discount will be applied"
            matTooltipPosition="above"
          >
            <input matInput type="number" placeholder="Length (nights)" formControlName="length" required step="1" />
            <mat-error *ngIf="form.get('length').touched && form.get('length').hasError('min')">
              Length should not be less than <strong>1</strong>
            </mat-error>
            <mat-error *ngIf="form.get('length').touched && form.get('length').hasError('required')">
              Length is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2
            class="d-block"
            matTooltip="Minimum number of guests required for the discount."
            matTooltipPosition="above"
          >
            <input matInput type="number" placeholder="Guests" formControlName="guests" required step="1" />
            <mat-error *ngIf="form.get('guests').touched && form.get('guests').hasError('min')">
              Max guests should not be less than <strong>1</strong>
            </mat-error>
            <mat-error *ngIf="form.get('guests').touched && form.get('guests').hasError('required')">
              Max guests is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Select the start date for this discount"
            matTooltipPosition="above"
          >
            <input
              matInput
              [matDatepicker]="startPicker"
              [max]="maxStartDate.value"
              [min]="this.now"
              placeholder="Start"
              (focus)="startPicker.open()"
              formControlName="start"
              tabindex="4"
              readonly
            />
            <i matSuffix (click)="startPicker.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>

        <div class="col-md-6">
          <mat-form-field2
            class="mat-block"
            matTooltip="Select the end date for this discount"
            matTooltipPosition="above"
          >
            <input
              matInput
              [matDatepicker]="endPicker"
              [min]="minEndDate.value"
              placeholder="End"
              (focus)="endPicker.open()"
              formControlName="end"
              tabindex="4"
              readonly
            />
            <i matSuffix (click)="endPicker.open()" class="cs-icon cs-icon-calendar"></i>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="form.get('end').touched && form.get('end').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('end').hasError('matDatepickerMin')">
              Min value is
              <strong>{{ form.get('end').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-rental-select
            [ctrl]="form.get('rentals')"
            [matTooltipHideDelay]="item?.rentalsView?.length"
            [matTooltipClass]="isEdit && item.rentals?.length > 20 ? 'text-center tooltip-lg' : 'text-center'"
            [matTooltip]="
              isEdit
                ? item?.rentalsView
                : 'Click here to select the rentals for which this discount will be applied. If no Rental is selected, the discount will apply on all Rental(s)'
            "
            matTooltipPosition="above"
            placeholder="Rental(s)"
          ></app-rental-select>
        </div>
        <div class="col-md-6">
          <app-select-search
            [items]="channels"
            [ctrl]="form.get('channels')"
            [multiple]="true"
            bindValue="id"
            bindLabel="friendlyName"
            [inline]="false"
            [matTooltip]="
              isEdit
                ? item?.channelsView
                : 'Click here to select the channels for which this discount will be applied. If no channel is selected, the discount will apply on all Channel(s)'
            "
            matTooltipPosition="above"
            placeholder="Channel(s)"
          ></app-select-search>
        </div>
      </div>
      <div *ngIf="hasModality" class="mt-3 alert alert-secondary-info">
        <i class="cs-icon cs-icon-info-circle alert-icon"></i>
        <div class="alert-content">
          Please note that flat per stay LOS discounts are not sent to channels. Only percentage and flat per night LOS
          discounts are sent to channels.
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer border-top">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
