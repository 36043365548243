import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthenticatedGuard } from '@tv3/guards/authenticated.guard'
import { NgxPermissionsGuard } from 'ngx-permissions'
import { InquiryGuard } from '@tv3/guards/inquiry.guard'
import { PreferencesGuard } from '@tv3/guards/preferences.guard'
import { UserGuard } from '@tv3/guards/user.guard'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { AccountGuard, SubdomainGuard } from '@tokeet-frontend/tv3-platform'
import { GuestGuard } from '@tv3/guards/guest.guard'
import { defaultLocalCalendarMapping, calendarRouteMapping } from './constants/calendar-route-mapping'
import { TemplateGuard } from '@tv3/guards/template.guard'
import { SubscriptionPermissionGuard } from '@tv3/guards/subscription-permission.guard'
import { WebsiteGuard } from '@tv3/guards/website.guard'
import { UnauthenticatedGuard } from '@tv3/guards/unathenticated.guard'
import { WebsiteTemplateGuard } from '@tv3/guards/website-template.guard'
import { CustomDomainGuard } from '@tokeet-frontend/ssl'
import { RentalsPrimaryImageGuard } from '@tv3/guards/rentals-primary-image.guard'
import { DefaultRouteGuard } from './guards/default-route.guard'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [DefaultRouteGuard],
    children: [],
  },
  {
    path: 'rates',
    redirectTo: `/calendar/${calendarRouteMapping.multiCalendar.url}`,
    // data: {
    //   permissions: {
    //     only: 'canSeeRates',
    //   },
    // },
    // canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    // canActivate: [SubscriptionPermissionGuard],
    // loadChildren: () => import('./containers/rates/rates.module').then((m) => m.RatesModule),
  },
  {
    path: 'channels',
    data: {
      permissions: {
        only: 'canSeeChannels',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/channels/channels.module').then((m) => m.ChannelsModule),
  },
  {
    path: 'dashboard',
    data: {
      permissions: {
        only: 'canSeeDashboard',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, AccountGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'feeds',
    data: {
      permissions: {
        only: 'canSeeDataFeeds',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/feeds/feeds.module').then((m) => m.FeedsModule),
  },
  {
    path: 'expenses',
    data: {
      permissions: {
        only: 'canSeeExpenses',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, AccountGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/expenses/expenses.module').then((m) => m.ExpensesModule),
  },
  {
    path: 'extras',
    data: {
      permissions: {
        only: 'canSeeExpenses',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, AccountGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/extras/extras.module').then((m) => m.ExtrasModule),
  },
  {
    path: 'formulas',
    data: {
      permissions: {
        only: 'canSeeExpenses',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, AccountGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/formulas/formulas.module').then((m) => m.FormulasModule),
  },
  {
    path: 'integrations',
    data: {
      permissions: {
        only: 'canSeeIntegrations',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () =>
      import('./containers/integrations-new/integrations-new.module').then((m) => m.IntegrationsNewModule),
  },
  {
    path: 'inventories',
    data: {
      permissions: {
        only: 'canSeeInventories',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/inventories/inventories.module').then((m) => m.InventoriesModule),
  },
  {
    path: 'incidents',
    data: {
      permissions: {
        only: 'canSeeIncidents',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/incidents/incidents.module').then((m) => m.IncidentsModule),
  },
  {
    path: 'tasks',
    data: {
      permissions: {
        only: 'canSeeTasks',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/tasks/tasks.module').then((m) => m.TasksModule),
  },
  {
    path: 'calendar',
    data: {
      permissions: {
        only: 'canSeeCalendar',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, PreferencesGuard, ChannelGuard, RentalsPrimaryImageGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'inquiries',
    data: {
      permissions: {
        only: 'canSeeInquiries',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, PreferencesGuard, ChannelGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/inquiries/inquiries.module').then((m) => m.InquiriesModule),
  },
  {
    path: 'billing',
    data: {
      permissions: {
        only: 'canSeeBilling',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    loadChildren: () => import('./containers/billings/billings.module').then((m) => m.BillingsModule),
  },
  {
    path: 'migration',
    data: {
      permissions: {
        only: 'canMigrateToSympl',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    loadChildren: () => import('./containers/migration/migration.module').then((m) => m.MigrationModule),
  },
  {
    path: 'invoices',
    data: {
      permissions: {
        only: 'canSeeInvoices',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'charges',
    data: {
      permissions: {
        only: 'canSeeCharges',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/charges/charges.module').then((m) => m.ChargesModule),
  },
  {
    path: 'discounts',
    data: {
      permissions: {
        only: 'canSeeDiscounts',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/discounts/discounts.module').then((m) => m.DiscountsModule),
  },
  {
    path: 'guests',
    data: {
      permissions: {
        only: 'canSeeGuests',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, GuestGuard, InquiryGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/guests/guests.module').then((m) => m.GuestsModule),
  },
  {
    path: 'payments',
    data: {
      permissions: {
        only: 'canSeePayments',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: 'sharing',
    data: {
      permissions: {
        only: 'canSeeSharing',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/sharing/sharing.module').then((m) => m.SharingModule),
  },
  {
    path: 'devices',
    data: {
      permissions: {
        only: 'canSeeSmartDevices',
        // allowedAddonPlan: ProductsForPlan.SmartDevice,
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/smart-devices/smart-devices.module').then((m) => m.SmartDevicesModule),
  },
  {
    path: 'documents',
    data: {
      permissions: {
        only: 'canSeeDocuments',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'settings',
    data: {
      permissions: {
        only: 'canSeeSettings',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'templates',
    data: {
      permissions: {
        only: 'canSeeTemplates',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, TemplateGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/templates/templates.module').then((m) => m.TemplatesModule),
  },
  {
    path: 'rentals',
    data: {
      permissions: {
        only: 'canSeeRentals',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, SubdomainGuard, UserGuard, ChannelGuard],
    loadChildren: () => import('./containers/rentals/rentals.module').then((m) => m.RentalsModule),
  },
  {
    path: 'websites',
    data: {
      permissions: {
        only: 'canSeeWebsites',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard, WebsiteGuard, WebsiteTemplateGuard, CustomDomainGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/websites/websites.module').then((m) => m.WebsitesModule),
  },
  {
    path: 'widgets',
    data: {
      permissions: {
        only: 'canSeeWidgets',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/widgets/widgets.module').then((m) => m.WidgetsModule),
  },
  {
    path: 'automations',
    data: {
      permissions: {
        only: 'canSeeAutomations',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/automations/automations.module').then((m) => m.AutomationsModule),
  },
  {
    path: 'stealth',
    data: {
      permissions: {
        only: 'canSeeStealth',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/stealth/stealth.module').then((m) => m.StealthModule),
  },
  {
    path: 'account',
    data: {
      permissions: {
        only: 'canSeeAccount',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    loadChildren: () => import('./containers/accounts/accounts.module').then((m) => m.AccountsModule),
  },
  {
    path: 'reviews',
    data: {
      permissions: {
        only: 'canSeeReviews',
      },
    },
    canLoad: [AuthenticatedGuard, NgxPermissionsGuard],
    canActivate: [SubscriptionPermissionGuard],
    loadChildren: () => import('./containers/reviews/reviews.module').then((m) => m.ReviewsModule),
  },
  {
    path: 'login',
    data: {
      permissions: {},
      public: true,
    },
    loadChildren: () => import('./containers/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot',
    data: {
      permissions: {},
      public: true,
    },
    canLoad: [UnauthenticatedGuard],
    loadChildren: () =>
      import('./containers/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'user/password/recover',
    data: {
      permissions: {},
      public: true,
    },
    canLoad: [UnauthenticatedGuard],
    loadChildren: () => import('./containers/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: '**',
    redirectTo: `/calendar/${defaultLocalCalendarMapping.url}`,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
