import { Pipe, PipeTransform } from '@angular/core'
import * as lodash from 'lodash'
import { availableContractStatuses, ContractStatus } from '../store'

@Pipe({
  name: 'contractStatus',
})
export class ContractStatusPipe implements PipeTransform {
  transform(value: ContractStatus, color = false) {
    return lodash.find(availableContractStatuses, { id: value })
  }
}

@Pipe({
  name: 'contractStatusIcon',
})
export class ContractStatusIconPipe implements PipeTransform {
  transform(value: ContractStatus, color = false): string {
    const type = lodash.find(availableContractStatuses, { id: value })
    if (!type) {
      return ''
    }
    if (color) {
      return `${type?.icon} ${type?.colorClass}`
    }

    return type?.icon
  }
}
