import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import {
  CreateGroup,
  CreateGroupComplete,
  DeleteGroup,
  DeleteGroupComplete,
  LoadGroups,
  LoadGroupsComplete,
  UpdateGroup,
  UpdateGroupComplete,
} from './group.actions'
import { GroupService } from './group.service'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class GroupEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadGroups),
      switchMap(() =>
        this.groupService.list().pipe(
          map((items) => LoadGroupsComplete({ items })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  create1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateGroup),
      switchMap(({ name, groupType }) =>
        this.groupService.create(name, groupType).pipe(
          map((item) => CreateGroupComplete({ item })),
          tap(() => this.toaster.success('Group created successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateGroup),
      switchMap(({ id, payload, message }) =>
        this.groupService.update(id, payload).pipe(
          map((res) => UpdateGroupComplete({ update: { id, changes: res } })),
          tap(() => this.toaster.success(message || 'Group updated successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteGroup),
      switchMap(({ id }) =>
        this.groupService.delete(id).pipe(
          map((res) => DeleteGroupComplete({ id })),
          tap(() => this.toaster.success('Group deleted successfully')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private store: Store<any>,
    private groupService: GroupService
  ) {}
}
