import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { SaveForm } from '@tokeet-frontend/tv3-platform'

@Component({
  templateUrl: './decline-confirm.component.html',
  styleUrls: ['./decline-confirm.component.scss'],
  host: { class: 'modal-content' },
})
export class DeclineConfirmComponent implements OnInit {
  reasonCtrl = new FormControl('', [Validators.required])

  constructor(private dialogRef: MatDialogRef<DeclineConfirmComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(ctrl: FormControl) {
    this.dialogRef.close({ reason: this.reasonCtrl.value })
  }
}
