import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Rental, selectRentalChildren } from '../stores'
import { Observable } from 'rxjs'

@Pipe({ name: 'rentalChildren' })
export class RentalChildrenPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  transform(rentalId: string): Observable<Rental[]> {
    return this.store.pipe(select(selectRentalChildren(rentalId)))
  }
}
