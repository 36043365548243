import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SignatureService } from './signature.service'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import {
  SignatureContractStoreModule,
  SignatureGroupStoreModule,
  SignatureTemplatesStoreModule,
  UserSignatureStoreModule,
} from './store'
import { SignatureFileService } from './services/file.service'
import { SignaturePipesModule } from './pipes/pipes.module'
import { DragDropManagerService } from './services/drag-drop-manager.service'
import { SignaturePrefStoreModule } from './store/pref'

const services = [
  //
  SignatureService,
  SignatureFileService,
  DragDropManagerService,
]

const pipes = [
  //
]

const modules = [
  //
  Tv3PlatformModule,
  SignaturePipesModule,
  SignatureContractStoreModule,
  SignatureGroupStoreModule,
  SignatureTemplatesStoreModule,
  UserSignatureStoreModule,
  SignaturePrefStoreModule,
]

@NgModule({
  imports: [CommonModule, ...modules],
  providers: [...services],
  exports: [...modules, ...pipes],
  declarations: [...pipes],
})
export class SignatureLibraryModule {}
