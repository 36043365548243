import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AiResponderService } from '@tv3/containers/ai-responder/store/ai-responder.service'
import { AiResponderSettingsComponent } from './settings/ai-responder-settings.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { AIResponderSettingsService } from './settings/ai-responser-settings.service'
import { MatTabsModule } from '@angular/material/tabs'
import { AiResponderDocumentsComponent } from './settings/documents/ai-responder-documents.component'
import { AiResponderWebsitesComponent } from './settings/websites/ai-responder-websites.component'
import { AiResponderFaqComponent } from './settings/faq/ai-responder-faq.component'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { EditorModule } from '@tinymce/tinymce-angular'
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap'
import { AiResponderRentalDetailsComponent } from './settings/rental-details/ai-responder-rental-details.component'
import { AiResponderIgnoreListComponent } from './settings/ignore-list/ai-responder-ignore-list.component'
import { IgnoreAIToggleComponent } from '@tv3/containers/ai-responder/ignore-ai-toggle/ignore-ai-toggle.component'
import { AISettingsStoreModule } from '@tv3/containers/ai-responder/store/ai-settings.module'
import { MatTooltipModule } from '@angular/material/tooltip'
import { AiAutoCreditRechargeComponent } from '@tv3/containers/ai-responder/settings/auto-credit-recharge/ai-auto-credit-recharge.component'
import { SignatureLibraryModule } from '@tokeet-frontend/signature'
import { CreditChargeStoreModule } from '@tokeet-frontend/billing'

@NgModule({
  declarations: [
    AiResponderDocumentsComponent,
    AiResponderWebsitesComponent,
    AiResponderFaqComponent,
    AiResponderSettingsComponent,
    AiResponderRentalDetailsComponent,
    AiResponderIgnoreListComponent,
    IgnoreAIToggleComponent,
    AiAutoCreditRechargeComponent,
  ],
  exports: [IgnoreAIToggleComponent],
  imports: [
    CommonModule,
    PopoverModule,
    SharedModule,
    MatTabsModule,
    MatTooltipModule,
    EditorModule,
    NgbProgressbarModule,
    AISettingsStoreModule,
    SignatureLibraryModule,
    CreditChargeStoreModule,
  ],
  providers: [AiResponderService, AIResponderSettingsService],
})
export class AiResponderModule {}
