import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  Destroyable,
  isSavingRental,
  isSomething,
  Rental,
  SaveForm,
  selectAvailableRentalChildren,
  selectAvailableRentalParents,
  selectRentalChildren,
  selectRentalParentChildrenGroups,
  setRentalChildren,
  setRentalChildrenComplete,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { debounceTime, startWith, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { Observable } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-parent-child-rental-dialog',
  templateUrl: './parent-child-rental-dialog.component.html',
  styleUrls: ['./parent-child-rental-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class ParentChildRentalDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    parent_id: ['', [Validators.required]],
    children: [[]],
  })

  get parentIdCtrl() {
    return this.form.get('parent_id') as FormControl
  }

  availableParents$ = this.store.pipe(select(selectAvailableRentalParents))
  availableChildren$: Observable<Rental[]>

  children: Rental[] = []

  loading$ = this.store.pipe(select(isSavingRental))

  constructor(
    public dialogRef: MatDialogRef<ParentChildRentalDialogComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    private actions: Actions,
    @Inject(MAT_DIALOG_DATA) public data: { rentalId?: string }
  ) {
    super()
    if (this.data.rentalId) {
      this.form.patchValue({ parent_id: this.data.rentalId })
    }
  }

  ngOnInit(): void {
    const parentId$ = this.parentIdCtrl.valueChanges.pipe(startWith(this.parentIdCtrl.value))
    parentId$
      .pipe(
        switchMap((id) => this.store.pipe(select(selectRentalChildren(id)))),
        untilDestroy(this)
      )
      .subscribe((children) => {
        this.children = children
        this.form.patchValue({ children: children?.map((r) => r.id) })
      })

    this.availableChildren$ = parentId$.pipe(
      switchMap((id) => this.store.pipe(select(selectAvailableRentalChildren(id))))
    )

    this.actions
      .pipe(
        ofType(setRentalChildrenComplete),
        debounceTime(100),
        switchMap(() => this.store.pipe(select(selectRentalParentChildrenGroups))),
        untilDestroy(this)
      )
      .subscribe((groups) => {
        const clearParentIds = lodash
          .toPairs(groups)
          .filter(([parentId, children]) => !children.length)
          .map(([id]) => id)
        this.store.dispatch(
          setRentalChildren({
            rentalId: '',
            addedChildren: [],
            removedChildren: clearParentIds,
            silent: true,
          })
        )
        this.close()
      })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { children: newChildrenIds, parent_id } = this.form.getRawValue()
    const oldChildrenIds = this.children.map((t) => t.id)

    if (
      !lodash.difference(oldChildrenIds, newChildrenIds).length &&
      !lodash.difference(newChildrenIds, oldChildrenIds).length
    ) {
      this.close()
      return
    }

    const addedChildren = lodash.difference(newChildrenIds, oldChildrenIds)
    const removedChildren = lodash.difference(oldChildrenIds, newChildrenIds)

    // set parent its self
    if (!isSomething(oldChildrenIds) && isSomething(addedChildren)) {
      addedChildren.push(parent_id)
    }

    // clear parent its self
    if (isSomething(oldChildrenIds) && !isSomething(newChildrenIds)) {
      removedChildren.push(parent_id)
    }

    this.store.dispatch(
      setRentalChildren({
        rentalId: parent_id,
        addedChildren,
        removedChildren,
      })
    )
  }
}
