import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './legal-confirm.component.html',
  styleUrls: ['./legal-confirm.component.scss'],
  host: { class: 'modal-content' },
})
export class LegalConfirmComponent implements OnInit {
  isCancelable = true

  constructor(private dialogRef: MatDialogRef<LegalConfirmComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }

  onSave() {
    this.dialogRef.close('agree')
  }
}
