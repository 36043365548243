<div class="container-box p-3">
  <!-- Basic Information -->
  <form [formGroup]="form" [formEditablePermission]="form" [class.loading-section]="isSaving$ | async">
    <div class="d-flex align-items-center gap-3">
      <app-ignore-ai-toggle type="rental" view="button" [item]="rental"></app-ignore-ai-toggle>

      <div class="dropdown" dropdown *ngIf="form.get('status') as statusCtrl">
        <button
          class="btn dropdown-toggle"
          dropdownToggle
          [ngClass]="statusCtrl.value === 'ready' ? 'btn-success' : 'btn-danger'"
          matTooltip="The Rental Status indicates whether a property is ready or not ready for guests. This can be updated by Service Providers (if using Checklist) or by Administrators and Property Manager users. This does not affect your rental availability."
          matTooltipPosition="above"
        >
          <i class="cs-icon" [ngClass]="statusCtrl.value === 'ready' ? 'cs-icon-check' : 'cs-icon-times'"></i>
          {{ statusCtrl.value === 'ready' ? 'Ready' : 'Not Ready' }}
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <li>
            <a
              class="dropdown-item"
              (click)="statusCtrl.setValue('not-ready')"
              matTooltip="Not Available for bookings"
              matTooltipPosition="left"
            >
              <i class="cs-icon cs-icon-times text-sm"></i> Not Ready
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              (click)="statusCtrl.setValue('ready')"
              matTooltip="Available for bookings"
              matTooltipPosition="left"
            >
              <i class="cs-icon cs-icon-check text-sm"></i> Ready
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-6">
        <div class="">
          <h6 class="text-uppercase">Rental Information</h6>
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="name" matInput required placeholder="Name" autocomplete="off" />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('allNumbers')">
              Name cannot be numbers alone.
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
              Name must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
              Name must be at most <strong>140</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('nameTaken')">
              Name is already in use by another rental.
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('allSpaces')">
              Name cannot be spaces alone.
            </mat-error>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="nickname" matInput placeholder="Nickname" autocomplete="off" />
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <input
              matTooltip="Create a display name which will be visible to guests on your AdvanceCM website only."
              matTooltipPosition="above"
              matInput
              type="text"
              placeholder="Display Name (Website Name)"
              formControlName="displayName"
            />
            <mat-hint align="end">{{ form.get('displayName').value?.length || 0 }}/255</mat-hint>
            <mat-error *ngIf="form.get('displayName').touched && form.get('displayName').hasError('allSpaces')">
              Display name cannot be spaces alone.
            </mat-error>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <input
              type="email"
              formControlName="email"
              matInput
              required
              placeholder="AdvanceCM Email"
              autocomplete="off"
            />
            <span matSuffix>@{{ subDomain }}</span>
            <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('minlength')">
              Email handle must be at least <strong>3</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailTaken')">
              Email is already in use by another rental.
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('rentalEmail')">
              Email is <strong>invalid.</strong>
            </mat-error>
          </mat-form-field2>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="phone" matInput required placeholder="Phone" />
                <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('required')">
                  Phone is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('allSpaces')">
                  Phone cannot be spaces alone.
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-6">
              <app-currency-select
                [ctrl]="form.get('currency')"
                matTooltip="Adjust the currency for all rates created on this rental. This setting will automatically change the currency on any previously created rates."
                matTooltipPosition="above"
                [required]="true"
              ></app-currency-select>
            </div>
          </div>
          <div class="copy-id-row">
            <strong>Rental Id</strong>: <span>{{ rental.id }}</span>
            <app-copy-icon [content]="rental.id" class="ml-1"></app-copy-icon>
          </div>
          <app-color-boxes [ctrl]="form.get('color')"></app-color-boxes>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="">
          <h6 class="text-uppercase">Rental Location</h6>
          <mat-form-field2 *ngIf="isGoogleLoaded" class="mat-block">
            <input
              appGooglePlaces
              (onAddressChange)="onPlaceChange($event)"
              autocomplete="address-1"
              type="text"
              formControlName="address"
              matInput
              placeholder="Address"
            />
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('minlength')">
              Address must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('maxlength')">
              Address must be at most <strong>140</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
              Address cannot be spaces alone.
            </mat-error>
          </mat-form-field2>
          <mat-form-field2 *ngIf="!isGoogleLoaded" class="mat-block">
            <input type="text" formControlName="address" autocomplete="address-1" matInput placeholder="Address" />
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('minlength')">
              Address must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('maxlength')">
              Address must be at most <strong>140</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
              Address cannot be spaces alone.
            </mat-error>
          </mat-form-field2>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field2 *ngIf="isGoogleLoaded" class="mat-block">
                <input
                  appGooglePlaces
                  (onAddressChange)="onCityChange($event)"
                  type="text"
                  formControlName="city"
                  matInput
                  required
                  placeholder="City"
                />
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('minlength')">
                  City must be at least <strong>2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('maxlength')">
                  City must be at most <strong>50</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('allSpaces')">
                  City cannot be spaces alone.
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 *ngIf="!isGoogleLoaded" class="mat-block">
                <input type="text" formControlName="city" matInput required placeholder="City" />
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('minlength')">
                  City must be at least <strong>2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('maxlength')">
                  City must be at most <strong>50</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('allSpaces')">
                  City cannot be spaces alone.
                </mat-error>
              </mat-form-field2>
            </div>

            <div class="col-sm-6">
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="state" matInput placeholder="State / Province / Region" />
                <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('minlength')">
                  Value must be at least <strong>2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('maxlength')">
                  Value must be at most <strong>50</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('allSpaces')">
                  State cannot be spaces alone.
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-6">
              <app-country-select
                [nameCtrl]="form.get('country')"
                [codeCtrl]="form.get('countryCode')"
              ></app-country-select>
            </div>
            <div class="col-sm-6">
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="zip" matInput placeholder="Postal Code" />
                <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('minlength')">
                  Value must be at least <strong>2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('maxlength')">
                  Value must be at most <strong>50</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('allSpaces')">
                  Postal code cannot be spaces alone.
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-6">
              <mat-form-field2 class="mat-block">
                <input
                  type="email"
                  formControlName="registration"
                  matInput
                  placeholder="Registration Number"
                  autocomplete="off"
                />
              </mat-form-field2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="alert alert-secondary-info mt-3" *ngIf="rental">
  <i class="fal fa-info-circle alert-icon"></i>
  <div class="alert-content">
    The iCal URL for this rental is located below for reference. You can use this URL to connect channels that support
    iCal integrations.
    <br />
    {{ exportCalendarUrl }}
  </div>
</div>
