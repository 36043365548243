import { Component, Input, OnInit } from '@angular/core'
import { SignatureEditorSize } from '../signature-helper.service'
import * as lodash from 'lodash'
import { FormControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { selectCurrentSignatureUser, UserSignatureType } from '@tokeet-frontend/signature'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-signature-texter',
  templateUrl: './signature-texter.component.html',
  styleUrls: ['./signature-texter.component.scss'],
})
export class SignatureTexterComponent extends Destroyable implements OnInit {
  @Input() editorSize: SignatureEditorSize

  fonts = ['Dancing Script', 'Satisfy', 'Sacramento', 'Long Cang', 'Parisienne']

  signatureFontCtrl = new FormControl(lodash.head(this.fonts))

  signatureTextCtrl = new FormControl()

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectCurrentSignatureUser),
        filter((user) => user.attributes?.signature_type === UserSignatureType.Text),
        untilDestroy(this)
      )
      .subscribe((user) => {
        this.signatureTextCtrl.patchValue(user.attributes?.signature_text)
      })
  }

  getFontSize(editorSize: SignatureEditorSize, text: string, font: string): number {
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
    canvas.width = editorSize.width
    canvas.height = editorSize.height

    let defaultFontSize = 80
    const step = 5

    ctx.font = `${defaultFontSize}px '${font}'`

    // lower the font size until the text fits the canvas
    while (ctx.measureText(text).width > canvas.width) {
      defaultFontSize -= step
      ctx.font = `${defaultFontSize}px '${font}'`
    }

    return defaultFontSize
  }

  isEmpty() {
    return !this.signatureTextCtrl.value
  }

  getSignatureText(): string {
    return this.signatureTextCtrl.value
  }

  getSignature() {
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')
    canvas.width = this.editorSize.width
    canvas.height = this.editorSize.height
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillStyle = '#000000'
    const font = this.signatureFontCtrl.value
    const signatureText = this.signatureTextCtrl.value

    const fontSize = this.getFontSize(this.editorSize, signatureText, font)

    ctx.font = `${fontSize}px ${font},sans-serif`
    ctx.fillText(signatureText, this.editorSize.width / 2, this.editorSize.height / 2)
    return canvas.toDataURL()
  }
}
