import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  deleteRateMapping,
  isEmptyTable,
  localeCompareSort,
  toggleRateMappingsSync,
} from '@tokeet-frontend/tv3-platform'
import { ConfirmDialogService, Destroyable, RateMappingResponse } from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { AddRateMappingDialogService } from '../add-rate-mapping-dialog/add-rate-mapping-dialog.service'
import { ConnectionView } from '@tv3/store/connection/connection.view'

@Component({
  selector: 'app-connection-rate-mappings-table',
  templateUrl: './connection-rate-mappings-table.component.html',
  styleUrls: ['./connection-rate-mappings-table.component.scss'],
})
export class ConnectionRateMappingsTableComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rateMappings: RateMappingResponse[] = []
  @Input() connection: ConnectionView

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['ratename', 'category', 'created', 'autosync', 'edit'] // 'frequency'

  dataSource = new MatTableDataSource<RateMappingResponse>()
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(
    private store: Store<fromRoot.State>,
    private confirmDialog: ConfirmDialogService,
    private addRateMappingDialog: AddRateMappingDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = this.rateMappings || []
  }

  onEdit(item: RateMappingResponse) {
    this.addRateMappingDialog.open({ mapping: item })
  }

  onAdd() {
    this.addRateMappingDialog.open({
      rentalId: this.connection.rentalId,
      connection: this.connection,
    })
  }

  onChangeSync(rateMapping: RateMappingResponse) {
    this.store.dispatch(
      toggleRateMappingsSync({
        items: [
          {
            rentalId: rateMapping.rental_id,
            mappingId: rateMapping.key,
            enabled: !rateMapping.autosync,
          },
        ],
      })
    )
  }

  onDelete(rateMapping: RateMappingResponse) {
    this.confirmDialog.confirm().subscribe(() => {
      this.store.dispatch(deleteRateMapping({ rentalId: rateMapping.rental_id, mappingId: rateMapping.key }))
    })
  }
}
