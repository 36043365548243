<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center flex-fill gap-3">
        <div class="d-flex ali-items-center gap-2">
          <h6 class="modal-title mb-0">AI Settings</h6>
          <app-page-help articleId="iq9oar78ng" videoId="mt1mm-Y0hiA"></app-page-help>
        </div>
        <div class="flex-fill"></div>
        <button
          class="btn btn-outline-ai btn-sm btn-pill px-3"
          (click)="togglesForm.get('isEnabled').setValue(!aiSettings?.isEnabled)"
          [class.active]="aiSettings?.isEnabled"
          matTooltip="Switch the Advance Intelligence feature on or off here."
        >
          <i class="cs-icon cs-icon-ai"></i> Advance Intelligence
        </button>
        <button
          class="btn btn-secondary-primary"
          matTooltip="Try out the AI! Ask a question and see how it responds."
          (click)="isTestPopoverOpen = !isTestPopoverOpen"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
        >
          <i class="cs-icon cs-icon-ai"></i>
          Test AI
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="fas fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="alert alert-secondary-warning" *ngIf="aiSettings?.contractId && !aiSettings?.isContractSigned">
        Please sign the contract to use Advance Intelligence feature. If you have signed the contract, please click the
        Refresh button to refresh the AI settings.

        <button class="btn btn-secondary-info ml-3" (click)="onRefreshSettings()">Refresh</button>
        <button class="btn btn-warning ml-3" (click)="onSignAIContract()">Sign</button>
      </div>
      <div class="d-flex align-items-center">
        <h6 class="text-uppercase mb-0">Train Your AI</h6>
        <div class="flex-fill"></div>
        <app-form-switch
          label="Automatically send AI replies"
          [inline]="true"
          [formControl]="togglesForm.get('isAutomateSendReplies')"
          labelPosition="before"
          matTooltip="Enable this toggle for automatically sending AI based messages to guests"
        ></app-form-switch>
      </div>
      <div class="mt-3 container-box">
        <div class="d-flex align-items-center border-bottom pr-3 pt-2">
          <ul class="nav top-navbar">
            <li [class.active]="activeTab === 0" (click)="activeTab = 0">
              <a class="text-link">
                <span matTooltip="Upload Docs & add FAQs to train Advance Intelligence.">Knowledge Base</span>
                <a
                  href="https://advance.cm/help/tokeet-ai/w7n415de3j"
                  target="_blank"
                  class="text-dark"
                  matTooltip="Click here to see the associated help article."
                  matTooltipPosition="above"
                >
                  <i class="cs-icon cs-icon-info-circle"></i>
                </a>
              </a>
            </li>
            <li [class.active]="activeTab === 1" (click)="activeTab = 1">
              <a class="text-link">
                <span matTooltip="Edit settings of Advance Intelligence related features.">Settings</span>
                <a
                  href="https://advance.cm/help/tokeet-ai/i1fj8vsy22"
                  target="_blank"
                  class="text-dark"
                  matTooltip="Click here to see the associated help article."
                  matTooltipPosition="above"
                >
                  <i class="cs-icon cs-icon-info-circle"></i>
                </a>
              </a>
            </li>
            <li [class.active]="activeTab === 2" (click)="activeTab = 2">
              <a class="text-link">
                <span matTooltip="Control permissions for Advance Intelligence on all rental related fields."
                  >Rental Details</span
                >
                <a
                  href="https://advance.cm/help/tokeet-ai/8ptpayt8ks"
                  target="_blank"
                  class="text-dark"
                  matTooltip="Click here to see the associated help article."
                  matTooltipPosition="above"
                >
                  <i class="cs-icon cs-icon-info-circle"></i>
                </a>
              </a>
            </li>
            <li [class.active]="activeTab === 3" (click)="activeTab = 3">
              <a class="text-link">
                <span
                  matTooltip="Block specific Booking conversation, Email address, Rental or Regex Pattern from Advance Intelligence auto reponses"
                >
                  Ignore List
                </span>

                <a
                  href="https://advance.cm/help/tokeet-ai/fv6x6tt69i"
                  target="_blank"
                  class="text-dark"
                  matTooltip="Click here to see the associated help article."
                  matTooltipPosition="above"
                >
                  <i class="cs-icon cs-icon-info-circle"></i>
                </a>
              </a>
            </li>
            <li [class.active]="activeTab === 4" (click)="activeTab = 4">
              <a class="text-link">
                <span matTooltip="Control AI subscription, recharge & balance options">Credit Recharge</span>
                <a
                  href="https://advance.cm/help/tokeet-ai/9tvfav7kag"
                  target="_blank"
                  class="text-dark"
                  matTooltip="Click here to see the associated help article."
                  matTooltipPosition="above"
                  ><i class="cs-icon cs-icon-info-circle"></i>
                </a>
              </a>
            </li>
          </ul>
          <div class="flex-fill"></div>
        </div>
        <ng-template [ngTemplateOutlet]="base" *ngIf="activeTab === 0"></ng-template>
        <ng-template [ngTemplateOutlet]="settings" *ngIf="activeTab === 1"></ng-template>
        <ng-container *ngIf="activeTab === 2">
          <app-ai-responder-rental-details [settings]="aiSettings"></app-ai-responder-rental-details>
        </ng-container>
        <ng-container *ngIf="activeTab === 3">
          <app-ai-responder-ignore-list [settings]="aiSettings"></app-ai-responder-ignore-list>
        </ng-container>
        <ng-container *ngIf="activeTab === 4">
          <app-ai-auto-credit-recharge></app-ai-auto-credit-recharge>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #base>
  <mat-accordion [hideToggle]="true" class="p-3 d-flex flex-column gap-3">
    <mat-expansion-panel
      class="shadow-none border rounded m-0"
      [expanded]="true"
      [class.bg-active2]="!panel2.expanded"
      #panel2
    >
      <mat-expansion-panel-header [class.border-bottom]="panel2.expanded" class="rounded-0">
        <mat-panel-title
          class="d-flex align-items-center"
          matTooltip="AI takes help of added Documents while responding to guests"
        >
          <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel2.expanded ? 'down' : 'right')"></i> Documents
        </mat-panel-title>
        <mat-panel-description class="d-flex" (click)="$event.stopPropagation()">
          <div class="flex-fill"></div>
          <app-form-switch
            matTooltip="Include or exclude uploaded documents from Advance Intelligence generated responses."
            label="Active"
            [inline]="true"
            labelPosition="before"
            [formControl]="togglesForm.get('isDocumentsEnabled')"
          ></app-form-switch>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ai-responder-documents [documents]="aiSettings?.docs"></ai-responder-documents>
    </mat-expansion-panel>
    <mat-expansion-panel class="shadow-none border rounded m-0" [class.bg-active2]="!panel3.expanded" #panel3>
      <mat-expansion-panel-header [class.border-bottom]="panel3.expanded" class="rounded-0">
        <mat-panel-title
          class="d-flex align-items-center"
          matTooltip="AI takes help of added FAQs while responding to guests"
        >
          <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel3.expanded ? 'down' : 'right')"></i> FAQ
        </mat-panel-title>
        <mat-panel-description class="d-flex" (click)="$event.stopPropagation()">
          <div class="flex-fill"></div>
          <app-form-switch
            matTooltip="Include or exclude added FAQs from Advance Intelligence generated responses."
            label="Active"
            [inline]="true"
            labelPosition="before"
            [formControl]="togglesForm.get('isFaqEnabled')"
          ></app-form-switch>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ai-responder-faq *ngIf="panel3.expanded" [faqs]="aiSettings?.faqs"></ai-responder-faq>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
<ng-template #settings>
  <form [formGroup]="settingsForm" novalidate>
    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem" class="p-4">
      <div class="bg-default2 p-3 rounded">
        <mat-form-field2
          matTooltip="This name will appear in AI based message signature which is sent to guests."
          matTooltipPosition="above"
        >
          <mat-label>AI Name</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error *ngIf="settingsForm.get('name').hasError('required') && settingsForm.get('name').touched">
            AI Name is required
          </mat-error>
        </mat-form-field2>
        <div>
          <mat-form-field2
            matTooltip="It determines which channels the AI responds to. Applied to All if no channel is selected. "
            matTooltipPosition="above"
          >
            <app-select
              [items]="channels$ | async"
              [formControl]="settingsForm.get('channels')"
              [multiple]="true"
              bindValue="id"
              bindLabel="name"
              [searchable]="true"
              placeholder="Select Channels"
            >
              <ng-template selectLabel let-items="items" let-removeItem="removeItem">
                <ng-container *ngFor="let item of items">
                  <div
                    class="d-inline-flex align-items-center gap-1 bg-default3 btn-pill p-1"
                    (click)="$event.stopPropagation()"
                  >
                    <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{ item: item }"></ng-template>
                    <a href="javascript:;" (click)="removeItem(item)" class="text-light"
                      ><i class="far fa-times-circle"></i
                    ></a>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template selectItem let-item="item">
                <div class="d-flex align-items-center gap-1">
                  <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{ item: item }"></ng-template>
                </div>
              </ng-template>
              <ng-template #image let-item="item">
                <ng-container *ngIf="item">
                  <img [src]="item.id | inquirySourceIcon" class="w-100" style="max-width: 16px; max-height: 16px" />
                  {{ item.name | titleCase }}
                </ng-container>
              </ng-template>
            </app-select>
          </mat-form-field2>
        </div>
        <div></div>
        <div class="d-flex flex-column" style="gap: 1rem; font-size: 15px">
          <div
            class="d-flex align-items-center"
            [class.text-black]="settingsForm.get('isAutoRespondToNewBookings').value"
            [class.text-bold]="settingsForm.get('isAutoRespondToNewBookings').value"
          >
            Automatically respond to new bookings
            <app-form-switch
              class="ml-auto"
              matTooltip="Automatically send Advance Intelligence’s generated replies to new booking inquiries"
              matTooltipPosition="right"
              [inline]="true"
              [formControl]="settingsForm.get('isAutoRespondToNewBookings')"
            ></app-form-switch>
          </div>
          <div
            class="d-flex align-items-center"
            [class.text-black]="settingsForm.get('isDelayResponse').value"
            [class.text-bold]="settingsForm.get('isDelayResponse').value"
          >
            Delay response by
            <input
              formControlName="delayResponse"
              class="form-control mx-1 px-2"
              matTooltip="Set how many minutes Advance Intelligence holds off before replying"
              type="number"
              step="1"
              style="width: 80px"
              [class.text-danger]="settingsForm.get('delayResponse').invalid"
            />
            minutes
            <app-form-switch
              class="ml-auto"
              matTooltip="Set a time delay for Advance Intelligence’s automated responses to guests."
              matTooltipPosition="right"
              [inline]="true"
              [formControl]="settingsForm.get('isDelayResponse')"
            ></app-form-switch>
          </div>
          <div *ngIf="settingsForm.get('delayResponse').invalid">
            <mat-error *ngIf="settingsForm.get('delayResponse').errors?.min"
              >Delay response must be greater than 1</mat-error
            >
            <mat-error *ngIf="settingsForm.get('delayResponse').errors?.integer"
              >Delay response must be integer</mat-error
            >
          </div>
          <div
            class="d-flex align-items-center"
            [class.text-black]="settingsForm.get('isRespondEvenIfResponded').value"
            [class.text-bold]="settingsForm.get('isRespondEvenIfResponded').value"
          >
            Respond even if human response is already sent
            <app-form-switch
              class="ml-auto"
              matTooltip="Allow AI to reply even after a manual response has been sent."
              matTooltipPosition="right"
              [inline]="true"
              [formControl]="settingsForm.get('isRespondEvenIfResponded')"
            ></app-form-switch>
          </div>
          <div class="row">
            <div
              class="col-sm-6"
              matTooltip="Limit the number of automatic replies sent to guests in a single conversation"
              matTooltipPosition="right"
            >
              <div class="d-flex align-items-center">
                <div class="text-nowrap">Maximum Replies</div>
                <input type="number" class="form-control mx-1 px-2" formControlName="maxReplies" style="width: 80px" />
              </div>
              <div *ngIf="settingsForm.get('maxReplies').invalid">
                <mat-error *ngIf="settingsForm.get('maxReplies').errors?.required"
                  >Maximum Replies is required</mat-error
                >
                <mat-error *ngIf="settingsForm.get('maxReplies').errors?.min"
                  >Maximum Replies must be greater than 1</mat-error
                >
                <mat-error *ngIf="settingsForm.get('maxReplies').errors?.integer"
                  >Maximum Replies must be integer</mat-error
                >
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center"
            [class.text-black]="settingsForm.get('isCreateDrafts').value"
            [class.text-bold]="settingsForm.get('isCreateDrafts').value"
          >
            Create draft replies
            <app-form-switch
              class="ml-auto"
              matTooltip="Allow AI to create draft message when guest messages are received."
              matTooltipPosition="right"
              [inline]="true"
              [formControl]="settingsForm.get('isCreateDrafts')"
            ></app-form-switch>
          </div>
          <div
            class="d-flex align-items-center"
            [class.text-black]="settingsForm.get('notification').value"
            [class.text-bold]="settingsForm.get('notification').value"
          >
            Notify Me by AI
            <app-form-switch
              class="ml-auto"
              matTooltip="Email Notification will be sent out to AdvanceCM Account email address  by AI if guest orders or complain about any thing."
              matTooltipPosition="right"
              [inline]="true"
              checkedValue="email"
              uncheckedValue="none"
              [formControl]="settingsForm.get('notification')"
            ></app-form-switch>
          </div>
        </div>
      </div>
      <div class="bg-default2 p-3 rounded">
        <label class="d-block mb-3 text-black">
          Tone of Voice
          <i
            class="fal fa-info-circle"
            matTooltip="Set the tone for how the AI will chat with guests."
            matTooltipPosition="above"
          ></i>
        </label>
        <mat-radio-group formControlName="tone" class="d-flex flex-column" style="gap: 1rem">
          <ng-container *ngFor="let item of toneOptions">
            <mat-radio-button [value]="item.id">
              <div>
                <div class="text-black text-bold">{{ item.label }}</div>
                <div class="text-muted" style="font-size: 12px">{{ item.example }}</div>
              </div>
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </div>
    <div class="d-flex px-4 pb-4">
      <button
        class="btn btn-secondary-info ml-auto"
        matTooltip="Save your changes every time you make any change in AI Settings"
        [disabled]="isSaving$ | async"
        (click)="onSaveSettings(settingsForm)"
      >
        <i class="cs-icon cs-icon-save"></i> Save
      </button>
    </div>
  </form>
</ng-template>

<ng-template
  #test
  cdkConnectedOverlay
  [cdkConnectedOverlayWidth]="500"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayDisableClose]="true"
  (overlayOutsideClick)="isTestPopoverOpen = false"
  [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isTestPopoverOpen"
  cdkConnectedOverlayPanelClass="tokeet-ai-chat-panel"
>
  <form [formGroup]="testForm" class="bg-white dropdown-shadow overflow-auto rounded">
    <div>
      <div class="text-primary p-4 d-flex align-items-center tokeet-ai-chat-header gap-3">
        <div class="rounded-circle bg-white d-flex align-items-center justify-content-center tokeet-ai-chat-icon">
          <i class="cs-icon cs-icon-ai"></i>
        </div>
        <div>
          <h6 class="text-xxl mb-1">I'm Advance Intelligence</h6>
          <div>Chat with me now that your bot has been trained and I'll do my best to answer.</div>
        </div>
      </div>
      <div class="px-4 pt-4 pb-2">
        <app-rental-select [multiple]="false" [ctrl]="testForm.get('rentalId')" label="Rental"> </app-rental-select>
        <mat-form-field2>
          <mat-label>Inquiry</mat-label>
          <app-select
            bindValue="id"
            bindLabel="guestDetails.name"
            [multiple]="false"
            [searchable]="true"
            [required]="true"
            [itemHeight]="60"
            [items]="bookings$ | async"
            [formControl]="testForm.get('inquiryId')"
          >
            <ng-template selectItem let-item="item">
              <div style="line-height: 1.5" class="py-2">
                <div class="text-truncate">
                  <b>{{ item.guestDetails?.name }}</b> at {{ item.rentalId | rentalName | async }}
                </div>
                <small class="text-muted">
                  starting {{ item.guestArrive | epoch : 'DD - MMM - YYYY' }} ending
                  {{ item.guestDepart | epoch : 'DD - MMM - YYYY' }}
                </small>
              </div>
            </ng-template>
          </app-select>
        </mat-form-field2>
        <mat-form-field2>
          <mat-label>Tone of Voice</mat-label>
          <app-select
            bindValue="id"
            bindLabel="label"
            [searchable]="false"
            [multiple]="false"
            [required]="true"
            [items]="toneOptions"
            [formControl]="testForm.get('tone')"
          >
          </app-select>
        </mat-form-field2>
        <mat-form-field2>
          <mat-label>Question</mat-label>
          <textarea
            matInput
            rows="5"
            formControlName="message"
            cdkTextareaAutosize
            placeholder="Ask questions based on the documents/FAQs you added in Knowledge base"
            required
          ></textarea>
          <mat-error *ngIf="testForm.get('message').touched && testForm.get('message').errors?.required">
            Question is required
          </mat-error>
        </mat-form-field2>
        <div
          class="border2 rounded p-3 mt-3 w-100 bg-light"
          *ngIf="!!testForm.get('answer').value"
          style="height: 200px; overflow: auto"
        >
          <div [innerHtml]="testForm.get('answer').value"></div>
        </div>
      </div>
      <div class="d-flex justify-content-end px-4 pt-0 pb-4 gap-3">
        <button class="btn btn-outline-light" (click)="isTestPopoverOpen = false">
          <i class="far fa-times"></i> Cancel
        </button>
        <button
          class="btn btn-secondary-info"
          [disabled]="!isTestFormChanged || !testForm.valid || isGeneratingResponse"
          (click)="onTest(testForm)"
        >
          <i class="fa fa-spin fa-spinner" *ngIf="isGeneratingResponse"></i>
          <i *ngIf="!isGeneratingResponse" class="cs-icon cs-icon-send"></i>
          Send
        </button>
      </div>
    </div>
  </form>
</ng-template>
