import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './contract.reducer'
import * as state from './contract.state'
import { chain, map, toLower } from 'lodash'
import { toContractView } from './contract.model'

export const contractState = createFeatureSelector<state.State>('contract')

export const selectAllContracts = createSelector(contractState, reducer.selectAll)

export const selectAllContractEntities = createSelector(contractState, reducer.selectEntities)

export const selectContractsLoading = createSelector(contractState, (state: state.State) => state.isLoading)

export const selectContractStatusStats = createSelector(contractState, (state: state.State) => state.statusStats)

export const selectContractTagStats = createSelector(contractState, (state: state.State) => state.tagStats)

export const selectContractsPagination = createSelector(contractState, (state: state.State) => state.pagination)

export const selectContractViews = createSelector(selectAllContracts, (items) => {
  return map(items, (i) => toContractView(i))
})

export const selectContractViewsByBookingId = (bookingId: string) =>
  createSelector(selectAllContracts, (items) => {
    return map(items, (i) => toContractView(i)).filter((t) => t.attributes.inquiry_id === bookingId)
  })

export const selectContractViewById = createSelector(selectAllContractEntities, (itemsById, props) => {
  return toContractView(itemsById[props.id])
})

export const selectAllContractTags = createSelector(selectContractTagStats, (tagStats) => {
  return chain(tagStats)
    .keys()
    .compact()
    .map((tag) => toLower(tag))
    .sort()
    .uniq()
    .value()
})
