import { Component, Input, OnInit } from '@angular/core'
import * as moment from 'moment'
import { FormBuilder, FormControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'
import { ContractView, UpdateContract, ContractStatus, selectAllContractTags } from '@tokeet-frontend/signature'
import { Destroyable, untilDestroy, asUTCEpoch, asLocalDate, selectSome } from '@tokeet-frontend/tv3-platform'
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators'
import { selectInquiriesByIds } from '@tv3/store/inquiry/inquiry.selectors'
import { FetchInquiries } from '@tv3/store/inquiry/inquiry.actions'

@Component({
  selector: 'app-contract-options',
  templateUrl: './contract-options.component.html',
  styleUrls: ['./contract-options.component.scss'],
})
export class ContractOptionsComponent extends Destroyable implements OnInit {
  @Input() contract: ContractView

  form = this.fb.group({
    bookingId: [],
    expiredAt: [],
    tags: [[]],
  })

  get expiredAtCtrl() {
    return this.form.get('expiredAt') as FormControl
  }

  get bookingIdCtrl() {
    return this.form.get('bookingId') as FormControl
  }

  get tagsCtrl() {
    return this.form.get('tags') as FormControl
  }

  minExpiredDate = moment.utc().startOf('day').toDate()
  tags$ = this.store.pipe(select(selectAllContractTags))

  get inquiryId(): string {
    return lodash.get(this.contract, 'attributes.inquiry_id')
  }

  constructor(private store: Store, private fb: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.setForm()

    if (this.inquiryId) {
      this.store.dispatch(FetchInquiries({ ids: [this.inquiryId] }))
    }

    this.bookingIdCtrl.valueChanges
      .pipe(
        switchMap((id) =>
          this.store.pipe(
            selectSome(selectInquiriesByIds([id])),
            map((items) => lodash.head(items))
          )
        ),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        untilDestroy(this)
      )
      .subscribe((item) => {
        this.store.dispatch(
          UpdateContract({
            id: this.contract.id,
            payload: {
              attributes: {
                ...this.contract.attributes,
                guest_id: item?.guestId,
                rental_id: item?.rentalId,
                inquiry_id: item?.id,
              },
            },
          })
        )
      })

    //   Expired date
    this.expiredAtCtrl.valueChanges.pipe(distinctUntilChanged(), untilDestroy(this)).subscribe((date) => {
      this.store.dispatch(
        UpdateContract({
          id: this.contract.id,
          payload: { expired_at: date ? asUTCEpoch(date) : null },
          message: 'The expiration date has been updated.',
        })
      )
    })

    this.tagsCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((tags: string[]) => {
      this.store.dispatch(
        UpdateContract({
          id: this.contract.id,
          payload: {
            attributes: {
              ...this.contract.attributes,
              tags,
            },
          },
          message: 'Tags updated successfully.',
        })
      )
    })
  }

  setForm() {
    this.form.patchValue(
      {
        tags: this.getTags(this.contract),
        expiredAt: asLocalDate(this.contract.expired_at),
        bookingId: this.inquiryId,
      },
      { emitEvent: false }
    )

    if (this.contract.status !== ContractStatus.Expired && this.contract.status !== ContractStatus.Pending) {
      this.expiredAtCtrl.disable({ emitEvent: false })
    }
  }

  getTags(contract: ContractView): string[] {
    return lodash.get(contract, 'attributes.tags', [])
  }
}
