<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h5 class="modal-title">Booking Preferences</h5>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="save(form)" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" class="container-box">
        <div class="row">
          <div class="col-sm-12">
            <p>
              Please specify the booking preferences that you would like to set for this rental. These values will be
              updated for this specific rental only.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="The number of hours required for minimum notice before booking. Allowed values: 0-24, 48, 72, 168."
              matTooltipPosition="above"
            >
              <input type="number" formControlName="leadtime" required matInput placeholder="Minimum leadtime" />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="The number of days of preparation time required between reservations. Either 0, 1 or 2."
              matTooltipPosition="above"
            >
              <input
                type="number"
                formControlName="turnover"
                required
                matInput
                placeholder="Turnover notice"
                min="0"
                max="2"
                step="1"
              />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="The default minimum nights requirement for reservations."
              matTooltipPosition="above"
            >
              <input
                type="number"
                formControlName="minstay"
                required
                matInput
                placeholder="Minimum nights stay"
                min="0"
                max="1123"
                step="1"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="The default maximum nights allowed for reservations."
              matTooltipPosition="above"
            >
              <input
                type="number"
                formControlName="maxstay"
                required
                matInput
                placeholder="Maximum nights stay"
                min="0"
                max="1124"
                step="1"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-3 mt-2">
            <mat-checkbox
              matTooltip="Allow guest to create a Request to Book for reservations with length of stay above the max nights stay."
              matTooltipPosition="above"
              formControlName="allow_rtb_above_max_nights"
              class="align-input-row-end"
            >
              Allow Booking above max nights
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <p></p>
            <app-select-search
              [inline]="false"
              placeholder="How far in advance may a guest book?"
              [ctrl]="form.get('maxleadtime')"
              [items]="maxNoticeDaysOptions"
              bindValue="value"
              bindLabel="name"
              [multiple]="false"
            >
            </app-select-search>
          </div>
        </div>
        <div class="form-block" formArrayName="day_of_week_min_nights">
          <h6>Weekday Min Nights</h6>
          <ng-container
            *ngFor="let week of weekMinNights?.controls; let index = index; let last = last"
            [formGroupName]="index"
          >
            <div class="row">
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <mat-select formControlName="day_of_week" placeholder="Day of week" required>
                    <mat-option *ngFor="let day of weekdays; let index = index" [value]="index">{{ day }}</mat-option>
                  </mat-select>
                </mat-form-field2>
              </div>
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <input
                    type="number"
                    formControlName="min_nights"
                    required
                    matInput
                    placeholder="Min nights"
                    step="1"
                  />
                </mat-form-field2>
              </div>
              <div class="col-sm-3 mt-4">
                <a href="javascript:;" class="text-danger" (click)="removeWeekDay(index)">
                  <i class="cs-icon cs-icon-trash"></i>
                </a>
              </div>
            </div>
          </ng-container>
          <div *ngIf="weekMinNights?.length < 7" class="mb-3">
            <a href="javascript:;" (click)="newWeekDayMinNightsGroup()"><i class="fas fa-plus"></i> Add New Item</a>
          </div>
        </div>

        <div class="form-block" formArrayName="seasonal_min_nights">
          <h6>Seasonal Min Nights</h6>
          <ng-container
            *ngFor="let seasonal of seasonalMinNights?.controls; let index = index; let last = last"
            [formGroupName]="index"
          >
            <div class="row">
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <input
                    matInput
                    [matDatepicker]="startPicker"
                    [max]="seasonal.get('end_date').value"
                    placeholder="From:"
                    [required]="true"
                    (focus)="startPicker.open()"
                    formControlName="start_date"
                    tabindex="4"
                    readonly
                  />
                  <i matSuffix (click)="startPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #startPicker></mat-datepicker>
                  <mat-error *ngIf="seasonal.get('start_date').hasError('required')">
                    Value is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="seasonal.get('start_date').hasError('overlap')">
                    Date range is <strong>overlapping</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <input
                    matInput
                    [matDatepicker]="endPicker"
                    [min]="seasonal.get('start_date').value"
                    placeholder="Until:"
                    [required]="true"
                    (focus)="endPicker.open()"
                    formControlName="end_date"
                    tabindex="5"
                    readonly
                  />
                  <i matSuffix (click)="endPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #endPicker></mat-datepicker>
                  <mat-error *ngIf="seasonal.get('end_date').hasError('required')">
                    Value is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="seasonal.get('end_date').hasError('overlap')">
                    Date range is <strong>overlapping</strong>
                  </mat-error>
                  <mat-error *ngIf="seasonal.get('end_date').hasError('matDatepickerMin')">
                    Min value is
                    <strong>{{
                      seasonal.get('end_date').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY'
                    }}</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <input
                    type="number"
                    formControlName="min_nights"
                    required
                    matInput
                    placeholder="Min nights"
                    step="1"
                  />
                </mat-form-field2>
              </div>
              <div class="col-sm-3 mt-4">
                <a href="javascript:;" class="text-danger" (click)="removeSeasonal(index)">
                  <i class="cs-icon cs-icon-trash"></i>
                </a>
              </div>
            </div>
          </ng-container>
          <div *ngIf="seasonalMinNights?.length < 7" class="mb-3">
            <a href="javascript:;" (click)="newSeasonalMinNightsGroup()"><i class="fas fa-plus"></i> Add New Item</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
