import { createAction, props } from '@ngrx/store'
import { CustomCode } from './custom-code.model'
import { AddCustomCodeRequest, UpdateCustomCodeRequest } from '@tv3/interfaces/requests/custom-code.request'
import { Update } from '@ngrx/entity'

export const GetAllCustomCodes = createAction('[Custom Code] Get All Custom Codes')
export const GetAllCustomCodesComplete = createAction(
  '[Custom Code] Get All Custom Codes Complete',
  props<{ codes: CustomCode[] }>()
)

export const DeleteCustomCode = createAction('[Custom Code] Delete Custom Code', props<{ ids: string[] }>())
export const DeleteCustomCodeComplete = createAction(
  '[Custom Code] Delete Custom Code Complete',
  props<{ ids: string[] }>()
)

export const AddCustomCode = createAction('[Custom Code] Add Custom Code', props<{ request: AddCustomCodeRequest }>())
export const AddCustomCodeComplete = createAction(
  '[Custom Code] Add Custom Code Complete',
  props<{ code: CustomCode }>()
)

export const UpdateCustomCode = createAction(
  '[Custom Code] Edit Custom Code',
  props<{ request: UpdateCustomCodeRequest }>()
)
export const UpdateCustomCodeComplete = createAction(
  '[Custom Code] Edit Custom Code Complete',
  props<{ update: Update<CustomCode> }>()
)
