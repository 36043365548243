import { getTemplateTypeLabel } from './template.constants'
import * as lodash from 'lodash'
import { SignFieldDef, SignFieldType } from './sign-field'
import { TemplateTypes } from './template-types'
import { isGuestRole, isUserRole } from '../signer-roles'

export interface TemplateUploadResponse {
  origin: {
    mimetype: string
    name: string
    size: number
    url: string
  }
  pdf_file_url: string
}

export interface ContractTemplateAttrs {
  tags: string[]
}

export interface SignerSettings {
  role: string
  order?: number
  id_photo_required?: boolean
  selfie_photo_required?: boolean
}

export interface TemplateOriginFileInfo {
  url: string
  name: string
  size: number
  mimetype: string
}

export interface ContractTemplate {
  id: string
  template_id: string
  // The account to which the template belongs.
  account: string
  user_id: string // who modify this doc
  type: TemplateTypes
  status: string
  roles: string[]
  /**  @deprecated */
  id_photo_required: boolean
  /**  @deprecated */
  passport_photo_required: boolean
  signers?: SignerSettings[]
  fields: SignFieldDef[]
  expired_days?: number
  // original file
  origin_files: TemplateOriginFileInfo[]
  pdf_file_url: string // this is pdf file for signed content.
  name: string // template name
  description: string
  title: string
  message: string
  groups: string[]
  font: string
  font_size: number
  // An epoch timestamp with the date and time the offer was created.
  created: number
  updated: number
  attributes: ContractTemplateAttrs
}

export function unserializeTemplateFields(fields: SignFieldDef[]): SignFieldDef[] {
  return lodash.map(fields, (f) => ({
    ...f,
    type: f.attributes?.prefill_type ? SignFieldType.Prefill : f.type,
  }))
}

export function serializeTemplateFields(fields: SignFieldDef[]): SignFieldDef[] {
  return lodash.map(fields, (f) => ({
    ...f,
    type: f.attributes?.prefill_type ? SignFieldType.Textbox : f.type,
  }))
}

export interface ContractTemplateView extends ContractTemplate {
  typeView: string
}

export function toContractTemplateView(item: ContractTemplate): ContractTemplateView {
  return {
    ...item,
    typeView: getTemplateTypeLabel(item.type),
  }
}

export function getFillBeforeSendingFields(fields: SignFieldDef[]): SignFieldDef[] {
  return lodash.filter(fields, (t) => t.attributes?.fill_before_sent)
}

export function isAutomationAvailable(roles: string[]): boolean {
  if (!roles || roles.length > 2) {
    return false
  }
  const [role1, role2] = roles

  const isValidRole = (role) => {
    return isGuestRole(role) || isUserRole(role)
  }

  if (role1 && !isValidRole(role1)) {
    return false
  }

  if (role2 && !isValidRole(role2)) {
    return false
  }

  return true
}
