import * as lodash from 'lodash'
import { TemplateTypes } from './template-types'

export const availableTemplateTypes: {
  id: TemplateTypes
  name: string
  icon: string
  colorClass: string
}[] = [
  {
    id: TemplateTypes.Contract,
    name: 'Contract',
    icon: 'cs-icon cs-icon-file-signature',
    colorClass: 'text-success',
  },
  {
    id: TemplateTypes.Form,
    name: 'Form',
    icon: 'cs-icon cs-icon-file-alt2',
    colorClass: 'text-info',
  },
  {
    id: TemplateTypes.Document,
    name: 'Printout',
    icon: 'cs-icon cs-icon-pdf',
    colorClass: 'text-danger',
  },
]

export const emailSubjectByTemplateType = {
  [TemplateTypes.Contract]: 'Your signature is requested',
  [TemplateTypes.Document]: 'Here is your document',
  [TemplateTypes.Form]: 'You have been requested to complete a form',
}

export function getTemplateTypeLabel(type: TemplateTypes): string {
  return lodash.find(availableTemplateTypes, { id: type })?.name
}
