export class Settings {
  static readonly CHECKBOX_FIELD_WIDTH = 24
  static readonly DEFAULT_FIELD_WIDTH = 200
  static readonly DEFAULT_FIELD_HEIGHT = 24

  static FILE_TYPE_2_MIMES = {
    // get mime types from http://filext.com/
    // pdf
    pdf: [
      'application/pdf',
      'application/x-pdf',
      'application/acrobat',
      'applications/vnd.pdf',
      'text/pdf',
      'text/x-pdf',
    ],
    // png
    png: ['image/png', 'application/png', 'application/x-png'],
    // jpg
    jpg: [
      'image/jpeg',
      'image/jpg',
      'image/jp_',
      'application/jpg',
      'application/x-jpg',
      'image/pjpeg',
      'image/pipeg',
      'image/vnd.swiftview-jpeg',
      'image/x-xbitmap',
    ],
    // doc
    doc: [
      'application/msword [official]',
      'application/doc',
      'appl/text',
      'application/vnd.msword',
      'application/vnd.ms-word',
      'application/winword',
      'application/word',
      'application/x-msw6',
      'application/x-msword',
    ],
    // docx
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // txt
    txt: ['text/plain', 'application/txt', 'browser/internal', 'text/anytext', 'widetext/plain', 'widetext/paragraph'],
    // rtf
    rtf: [
      'application/rtf',
      'application/x-rtf',
      'text/rtf',
      'text/richtext',
      'application/msword',
      'application/doc',
      'application/x-soffice',
    ],
  }

  static readonly SIGN_DATE_FORMAT = 'YYYY-MM-DD'
}
