import { Injectable } from '@angular/core'
import { SignatureEditorDialogComponent, SignatureEditorType } from './signature-editor-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class SignatureEditorDialogService {
  constructor(private dialog: DialogService) {}

  public open(type?: SignatureEditorType) {
    return this.dialog.openFixedDialog(SignatureEditorDialogComponent, { data: { type } })
  }
}
