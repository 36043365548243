<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title d-flex align-items-center gap-2">
        Push Rates & Availability
        <app-page-help articleId="x7j62kmrns" videoId="vME7vbq4DE0"></app-page-help>
      </h6>
      <div class="d-flex gap-3">
        <div class="btn-group dropdown" role="group" dropdown [isDisabled]="1 | isReadonlyRole">
          <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle">
            <span class="cs-icon cs-icon-bolt"></span> Actions
          </button>
          <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <li>
              <button (click)="table?.onPushRate()" type="button" [disabled]="!table?.isAnySelected()">
                <span class="fas fa-arrow-up"></span>
                Pushed Mapped Rates
              </button>
            </li>
            <li>
              <button (click)="table?.onPushAvailability()" type="button" [disabled]="!table?.isAnySelected()">
                <span class="far fa-calendar-alt"></span>
                Push Availability
              </button>
            </li>
            <li>
              <button (click)="table?.onDisableAutoSync()" type="button" [disabled]="!table?.isAnySelected()">
                <span class="far fa-pause"></span>
                Disable Auto Sync
              </button>
            </li>
            <li>
              <button (click)="table?.onEnableAutoSync()" type="button" [disabled]="!table?.isAnySelected()">
                <span class="far fa-play"></span>
                Enable Auto Sync
              </button>
            </li>
            <!--            <li>-->
            <!--              <button (click)="table?.onSetFrequency('daily')" type="button" [disabled]="!table?.isAnySelected()">-->
            <!--                <span class="far fa-calendar-day"></span>-->
            <!--                Set Sync Daily-->
            <!--              </button>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <button (click)="table?.onSetFrequency('weekly')" type="button" [disabled]="!table?.isAnySelected()">-->
            <!--                <span class="far fa-calendar-week"></span>-->
            <!--                Set Sync Weekly-->
            <!--              </button>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box">
        <app-channel-rate-mappings-table #table></app-channel-rate-mappings-table>
      </div>
    </div>
  </div>
</div>
