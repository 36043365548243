<a routerLink="/" class="logo-holder" [class.loading]="isLoading$ | async">
  <svg-icon class="logo" src="/assets/logo.svg"></svg-icon>
  <img src="assets/app-loading.gif" alt="" title="" class="loading-logo" />
</a>
<app-overflow-scroll class="top_items">
  <div class="sidebar_items_wrapper" data-overflow-scroll-content-container>
    <a
      class="sidebar_item"
      *ngxPermissionsOnly="['canSeeCalendar']"
      matTooltip="Calendars"
      matTooltipPosition="right"
      routerLinkActive="active"
      routerLink="/calendar/multi"
    >
      <i class="cs-icon cs-icon-calendars text-xl"></i>
    </a>
    <a
      class="sidebar_item"
      *ngxPermissionsOnly="['canSeeGuests']"
      matTooltip="Guests"
      matTooltipPosition="right"
      routerLinkActive="active"
      routerLink="/guests"
    >
      <i class="cs-icon cs-icon-guests text-xl"></i>
    </a>
    <a
      class="sidebar_item"
      *ngxPermissionsOnly="['canSeeInquiries']"
      matTooltip="Inquiries"
      matTooltipPosition="right"
      routerLinkActive="active"
      routerLink="/inquiries/inbox"
    >
      <i class="cs-icon cs-icon-inquiries"></i>
    </a>
    <ng-container *ngxPermissionsOnly="['canSeeInvoices']">
      <a
        class="sidebar_item"
        matTooltip="Finances"
        matTooltipPosition="right"
        (click)="isPaymentsMenuOpen = !isPaymentsMenuOpen"
        cdkOverlayOrigin
        #paymentTrigger="cdkOverlayOrigin"
      >
        <i class="cs-icon cs-icon-dollar-circle"></i>
      </a>
      <ng-template
        cdkConnectedOverlay
        (overlayOutsideClick)="isPaymentsMenuOpen = false"
        [cdkConnectedOverlayOffsetX]="20"
        [cdkConnectedOverlayOffsetY]="-100"
        [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
        [cdkConnectedOverlayOrigin]="paymentTrigger"
        [cdkConnectedOverlayOpen]="isPaymentsMenuOpen"
      >
        <div>
          <ul class="dropdown-menu show position-relative" style="top: 0" (click)="isPaymentsMenuOpen = false">
            <li class="py-2 px-3 text-uppercase text-semi-bold text-light text-sm">Finances</li>
            <li class="menu-item-success">
              <a routerLink="/invoices/list"> <i class="cs-icon cs-icon-file-dollar text-xl"></i> Invoices </a>
            </li>
            <li class="menu-item-success">
              <a routerLink="/charges"> <i class="cs-icon cs-icon-dollar-square"></i> Charges </a>
            </li>
            <li class="menu-item-success">
              <a routerLink="/extras"> <i class="cs-icon cs-icon-dollar-circle-plus-circle text-xxl"></i> Extras </a>
            </li>
            <li class="dropdown-divider"></li>
            <li class="menu-item-danger" *ngxPermissionsOnly="['canSeeExpenses']">
              <a routerLink="/expenses"> <i class="cs-icon cs-icon-bill-dollar text-xl"></i> Expenses </a>
            </li>
            <li class="dropdown-divider"></li>
            <li class="menu-item-indigo">
              <a routerLink="/formulas">
                <i class="cs-icon cs-icon-calendar-dollar-circle text-xl"></i> Booking Formulas
              </a>
            </li>
            <li class="menu-item-indigo">
              <a routerLink="/discounts"> <i class="cs-icon cs-icon-discount text-xl"></i> Discounts </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngxPermissionsOnly="['canSeeTasks', 'canSeeIncidents', 'canSeeInventories']">
      <a
        class="sidebar_item"
        matTooltip="Operations"
        matTooltipPosition="right"
        (click)="isTasksMenuOpen = !isTasksMenuOpen"
        cdkOverlayOrigin
        #tasksTrigger="cdkOverlayOrigin"
      >
        <i class="cs-icon cs-icon-tasks"></i>
      </a>
      <ng-template
        cdkConnectedOverlay
        (overlayOutsideClick)="isTasksMenuOpen = false"
        [cdkConnectedOverlayOffsetX]="20"
        [cdkConnectedOverlayOffsetY]="-100"
        [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
        [cdkConnectedOverlayOrigin]="tasksTrigger"
        [cdkConnectedOverlayOpen]="isTasksMenuOpen"
      >
        <div>
          <ul class="dropdown-menu show position-relative" style="top: 0" (click)="isTasksMenuOpen = false">
            <li class="py-2 px-3 text-uppercase text-semi-bold text-light text-sm">Operations</li>
            <li class="menu-item-success" *ngxPermissionsOnly="'canSeeTasks'">
              <a routerLink="/tasks/list"> <i class="cs-icon cs-icon-tasks text-xxl"></i> Tasks </a>
            </li>
            <li class="menu-item-danger" *ngxPermissionsOnly="'canSeeIncidents'">
              <a routerLink="/incidents"> <i class="cs-icon cs-icon-building-incident text-xl"></i> Incidents </a>
            </li>
            <li class="menu-item-indigo" *ngxPermissionsOnly="'canSeeInventories'">
              <a routerLink="/inventories"> <i class="cs-icon cs-icon-inventory text-lg"></i> Inventories </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </ng-container>
    <a
      class="sidebar_item"
      *ngxPermissionsOnly="['canSeeReviews']"
      routerLinkActive="active"
      routerLink="/reviews"
      matTooltip="Reviews"
      matTooltipPosition="right"
    >
      <i class="cs-icon cs-icon-reviews text-xl"></i>
    </a>
    <a
      *ngxPermissionsOnly="['canSeeSmartDevices']"
      class="sidebar_item"
      routerLinkActive="active"
      routerLink="/devices"
      matTooltip="Smart Devices"
      matTooltipPosition="right"
    >
      <i class="cs-icon cs-icon-smart-devices text-xl"></i>
    </a>
    <ng-container *ngxPermissionsOnly="['canSeeDocuments']">
      <a
        class="sidebar_item"
        matTooltip="Documents"
        matTooltipPosition="right"
        routerLinkActive="active"
        routerLink="/documents"
      >
        <i class="far fa-file-signature text-xl"></i>
      </a>
    </ng-container>
    <a
      class="sidebar_item"
      *ngxPermissionsOnly="['canSeeReports']"
      [href]="reportsUrl()"
      target="_blank"
      matTooltip="Reports"
      matTooltipPosition="right"
    >
      <i class="cs-icon cs-icon-reports text-xl"></i>
    </a>
  </div>
</app-overflow-scroll>
<div class="bottom_items gap-3">
  <div class="bottom_item">
    <app-help-widget></app-help-widget>
  </div>
  <div class="bottom_item">
    <app-avatar
      [text]="username"
      (click)="isUserActionsMenuOpen = !isUserActionsMenuOpen"
      cdkOverlayOrigin
      #userTrigger="cdkOverlayOrigin"
      class="clickable"
    ></app-avatar>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="isUserActionsMenuOpen = false"
  [cdkConnectedOverlayOffsetX]="20"
  [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' }]"
  [cdkConnectedOverlayOrigin]="userTrigger"
  [cdkConnectedOverlayOpen]="isUserActionsMenuOpen"
>
  <div
    class="bg-white shadow2 border rounded overflow-hidden"
    style="width: 416px"
    (click)="isUserActionsMenuOpen = false"
  >
    <div class="user-actions-menu-header">
      <app-avatar [text]="username" [circle]="true" [size]="70"></app-avatar>
      <div class="d-flex flex-column gap-1 w-100 text-truncate">
        <div class="text-dark font-weight-bolder text-lg d-flex align-items-center">
          <div class="text-truncate flex-1">
            {{ username }}
          </div>
          <a routerLink="/account" *ngxPermissionsOnly="['canSeeAccount']" class="text-dark">
            <i class="cs-icon cs-icon-pencil"></i>
          </a>
        </div>
        <div>{{ user.primaryEmail }}</div>
        <div>
          {{ user.account }}
          <app-copy-icon [content]="user.account"></app-copy-icon>
        </div>
        <div>
          <span class="badge badge-secondary-info">{{ role }}</span>
        </div>
      </div>
    </div>
    <ul class="list-group list-group-flush user-actions-menu actions-menu">
      <li
        *ngxPermissionsOnly="['canSeeAccount']"
        class="list-group-item"
        matTooltip="Manage your account preferences, company details, and domain name."
        matTooltipPosition="left"
      >
        <a
          routerLink="/account"
          (mouseenter)="icon1.classList.add('focus')"
          (mouseleave)="icon1.classList.remove('focus')"
        >
          <span class="btn btn-sm btn-secondary-primary-reverse" #icon1>
            <i class="cs-icon cs-icon-user2"></i>
          </span>
          Account Info
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['canChangePassword']"
        class="list-group-item"
        matTooltip="Change your password."
        matTooltipPosition="left"
      >
        <a
          (click)="onChangePassword()"
          (mouseenter)="icon2.classList.add('focus')"
          (mouseleave)="icon2.classList.remove('focus')"
        >
          <span class="btn btn-sm btn-secondary-indigo-reverse text-lg" #icon2>
            <i class="cs-icon cs-icon-key2"></i>
          </span>
          Change Password
        </a>
      </li>
      <li class="list-group-item" matTooltip="Configure MFA" matTooltipPosition="left">
        <a (click)="onMFA()" (mouseenter)="icon6.classList.add('focus')" (mouseleave)="icon6.classList.remove('focus')">
          <span class="btn btn-sm btn-secondary-warning-reverse text-lg" #icon6>
            <i class="cs-icon cs-icon-cog"></i>
          </span>
          Configure MFA
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['canSeeBilling']"
        class="list-group-item"
        matTooltip="Manage your subscription, payment details, and view your billing history."
        matTooltipPosition="left"
      >
        <a
          routerLink="/billing/info"
          (mouseenter)="icon3.classList.add('focus')"
          (mouseleave)="icon3.classList.remove('focus')"
        >
          <span class="btn btn-sm btn-secondary-success-reverse text-lg" #icon3>
            <i class="cs-icon cs-icon-file-dollar"></i>
          </span>
          Billing Info
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['canSeeChangelog']"
        class="list-group-item"
        matTooltip="Stay updated about changes and new features in AdvanceCM."
        matTooltipPosition="left"
      >
        <a
          (click)="goToChangelog()"
          (mouseenter)="icon4.classList.add('focus')"
          (mouseleave)="icon4.classList.remove('focus')"
        >
          <span class="btn btn-sm btn-secondary-info-reverse text-lg" #icon4>
            <i class="cs-icon cs-icon-file-arrow-border"></i>
          </span>
          AdvanceCM Changelog
        </a>
      </li>
      <li class="list-group-item">
        <a
          (click)="logout()"
          role="link"
          (mouseenter)="icon5.classList.add('focus')"
          (mouseleave)="icon5.classList.remove('focus')"
        >
          <span class="btn btn-sm btn-secondary-danger-reverse text-lg" #icon5>
            <i class="cs-icon cs-icon-logout2"></i>
          </span>
          Logout</a
        >
      </li>
    </ul>
  </div>
</ng-template>
