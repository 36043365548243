<div class="modal-header">
  <h5 class="modal-title"><i class="far fa-box-up"></i> {{ isEdit ? 'Edit' : 'Add' }} Channel Rate Automation</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="description">
    Specify an absolute or percentage adjustment which should be made for rates pushed to a specific channel. This
    adjustment is only applied to AdvanceCM generated channel rates and not to rate categories you create. Channel
    adjustments will cause the default rate to be pushed to your connected API channels, even if your rate mapping
    specifies a different rate category to be pushed.
  </div>

  <form [formGroup]="form" (submit)="onSubmit(form)" [formEditablePermission]="form" class="mt-3">
    <mat-form-field2 class="mat-block" matTooltip="Select a channel to adjust.">
      <app-select
        bindValue="channelId"
        bindLabel="label"
        [items]="channels$ | async"
        [formControl]="form.get('channel_id')"
        [multiple]="false"
        [searchable]="true"
        placeholder="Select Channel"
        [required]="true"
        [disabled]="form.get('channel_id').disabled"
      >
      </app-select>
      <mat-error *ngIf="form.get('channel_id').touched && form.get('channel_id').hasError('required')">
        Channel is <strong>required</strong>
      </mat-error>
    </mat-form-field2>

    <div class="alert alert-secondary-info" *ngIf="customChannelsById[form.get('channel_id').value]">
      Adjusted rates for Custom Channels will be used to Push Rates on Websites and Widgets, It won’t be applied to
      bookings created through AdvanceCM Dashboard
    </div>

    <app-rental-select
      [items]="filteredRentals$ | async"
      [ctrl]="form.get('rentals')"
      placeholder="Select Rentals"
      class="mat-block"
      matTooltip="Select rentals to adjust"
    >
    </app-rental-select>

    <mat-radio-group [name]="adjustType" formControlName="adjustType">
      <div class="row">
        <div class="col-sm-6">
          <div class="adjust-container">
            <mat-radio-button [name]="adjustType" value="amount"></mat-radio-button>
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                matInput
                [required]="adjustType === 'amount'"
                placeholder="Adjust Amount"
                formControlName="adjust_amt"
                tabindex="3"
              />
              <mat-error *ngIf="form.get('adjust_amt').touched && form.get('adjust_amt').hasError('required')">
                Value is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="adjust-container">
            <mat-radio-button [name]="adjustType" value="percent"></mat-radio-button>
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                matInput
                [required]="adjustType === 'percent'"
                placeholder="Adjust Percent (%)"
                formControlName="adjust_pct"
                tabindex="5"
              />
              <mat-error *ngIf="form.get('adjust_pct').touched && form.get('adjust_pct').hasError('required')">
                Value is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </mat-radio-group>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light" tabindex="202">
    <i class="far fa-times"></i> Cancel
  </button>
  <button
    (click)="onSubmit(form)"
    [disabled]="form.disabled || (1 | isReadonlyRole)"
    type="button"
    class="btn btn-secondary-info create-btn"
    tabindex="201"
  >
    <i *ngIf="!form.disabled" class="cs-icon cs-icon-save"></i>
    <i *ngIf="form.disabled" class="far fa-spinner fa-spin"></i>
    Save
  </button>
</div>
