import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { pathOr, trim } from 'ramda'
import { Rental } from './rental.models'

export interface State extends EntityState<Rental> {
  selectedId: string
  isLoaded: boolean
  isImageLoaded: boolean
  isPrimaryImagesLoaded: boolean
  isRatesLoaded: boolean
  isSavingRental: boolean
  isSavingTags: boolean
  isSavingCustom: boolean
  isSavingDetailInfo: boolean
  isRestrictingUsers: boolean
  isSavingRateMapping: boolean
  isSavingRentalInstructions: boolean
  isSavingBaseRate: 'save' | 'clear'
  isUpdatingOwners: boolean
  subdomain: string
  archivedRentals: Rental[]
  imageCountsLoaded: boolean
}

export const adapter: EntityAdapter<Rental> = createEntityAdapter<Rental>({
  sortComparer: (a: Rental, b: Rental) => {
    const name1 = trim(String(a.nickname || a.name || ''))
    const name2 = trim(String(b.nickname || b.name || ''))
    return name1.localeCompare(name2, 'en-US', { numeric: true, sensitivity: 'base' })
  },
})

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  isLoaded: false,
  isImageLoaded: false,
  isPrimaryImagesLoaded: false,
  isRatesLoaded: false,
  isSavingRental: false,
  isSavingTags: false,
  isSavingCustom: false,
  isSavingDetailInfo: false,
  isSavingRentalInstructions: false,
  isSavingBaseRate: null,
  isSavingRateMapping: false,
  isRestrictingUsers: false,
  isUpdatingOwners: false,
  subdomain: null,
  archivedRentals: [],
  imageCountsLoaded: false,
})
