import { AbstractControl } from '@angular/forms'

const reg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validateEmail(email: string) {
  return reg.test(String(email).toLowerCase())
}

export function emailValidator(ctrl: AbstractControl) {
  const value = ctrl.value
  if (!value) {
    return null
  }

  if (!validateEmail(value)) {
    return { email: true }
  }
  return null
}
