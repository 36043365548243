import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromUser from './user-signature.reducer'
import { EffectsModule } from '@ngrx/effects'
import { UserSignatureEffects } from './user-signature.effects'
import { UserSignatureService } from './user-signature.service'
import { UserSignatureGuard } from './user-signature.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('signature', fromUser.userReducer),
    EffectsModule.forFeature([UserSignatureEffects]),
  ],
  declarations: [],
  providers: [UserSignatureGuard, UserSignatureService],
})
export class UserSignatureStoreModule {}
