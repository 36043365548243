import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { InvoiceService } from './invoice.service'
import {
  AddInvoice,
  AddInvoiceComplete,
  ArchiveInvoice,
  ArchiveInvoiceComplete,
  ArchiveInvoices,
  ArchiveInvoicesComplete,
  DeleteInvoice,
  DeleteInvoiceComplete,
  DeleteInvoices,
  DeleteInvoicesComplete,
  DisableInvoiceOnlinePayment,
  DisableInvoiceOnlinePaymentComplete,
  EnableInvoiceOnlinePayment,
  EnableInvoiceOnlinePaymentComplete,
  FetchInvoices,
  FetchInvoicesComplete,
  LoadInvoice,
  LoadInvoiceComplete,
  PayInvoiceWithSavedCard,
  PayInvoiceWithSavedCardComplete,
  RefundInvoice,
  RefundInvoiceComplete,
  ScheduleInvoice,
  ScheduleInvoiceComplete,
  SearchElasticInvoicesComplete,
  SendInvoices,
  SendInvoicesComplete,
  SetInvoicePaymentGateway,
  SetInvoicePaymentGatewayComplete,
  UnArchiveInvoice,
  UnArchiveInvoiceComplete,
  UnArchiveInvoices,
  UnArchiveInvoicesComplete,
  UpdateInvoice,
  UpdateInvoiceAddress,
  UpdateInvoiceAddressComplete,
  UpdateInvoiceComplete,
  UpdateInvoiceStatus,
  UpdateInvoiceStatusComplete,
} from './invoice.actions'
import { getInvoiceStatus, Invoice, InvoiceStatus } from './invoice.model'
import { catchError, concatMap, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as R from 'ramda'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment/moment'

@Injectable()
export class InvoiceEffects {
  @Effect()
  fetchElasticInvoices$ = this.actions$.pipe(
    ofType(SearchElasticInvoicesComplete),
    concatMap(({ invoices }) => {
      if (R.isEmpty(R.reject((i: Invoice) => !isSomething(i), invoices))) {
        return of(ActionSkipped())
      } else {
        return of(FetchInvoices({ ids: R.map((i) => i.id, invoices) }))
      }
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  @Effect()
  fetchInvoices$ = this.actions$.pipe(
    ofType(FetchInvoices),
    concatMap(({ ids }) =>
      this.invoices.fetch(ids).pipe(
        map((invoices) => FetchInvoicesComplete({ invoices })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addInvoice$ = this.actions$.pipe(
    ofType(AddInvoice),
    concatMap(({ invoice }) => {
      return this.invoices.add(this.invoices.newInvoicePayload(invoice)).pipe(
        tap(() => this.toaster.success('Invoice created successfully.')),
        map((invoice) => AddInvoiceComplete({ invoice })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateInvoice$ = this.actions$.pipe(
    ofType(UpdateInvoice),
    concatMap(({ invoice }) => {
      return this.invoices.update(invoice.id, this.invoices.editInvoicePayload(invoice)).pipe(
        tap(() => this.toaster.success('Invoice updated successfully.')),
        map((changes) => UpdateInvoiceComplete({ update: { id: invoice.id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateInvoiceStatus$ = this.actions$.pipe(
    ofType(UpdateInvoiceStatus),
    concatMap(({ invoiceId, status }) => {
      return this.invoices.status(invoiceId, status).pipe(
        tap(() => this.toaster.success('Invoice Status updated successfully.')),
        map((res) => UpdateInvoiceStatusComplete({ update: { id: invoiceId, changes: res } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateInvoiceAddress$ = this.actions$.pipe(
    ofType(UpdateInvoiceAddress),
    concatMap(({ id, addressKey }) => {
      return this.invoices.update(id, { address_key: addressKey }).pipe(
        tap(() => this.toaster.success('Invoice Address updated successfully.')),
        map((res) => UpdateInvoiceAddressComplete({ update: { id, changes: res } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  setInvoicePaymentGateway$ = this.actions$.pipe(
    ofType(SetInvoicePaymentGateway),
    concatMap(({ invoiceId, gatewayId }) => {
      return this.invoices.setGateway(invoiceId, gatewayId).pipe(
        tap(() => this.toaster.success('Invoice gateway updated successfully.')),
        map((res) =>
          SetInvoicePaymentGatewayComplete({
            update: {
              id: invoiceId,
              changes: { gatewayId: gatewayId, onlinePayment: 1 },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  enableInvoiceOnlinePayment$ = this.actions$.pipe(
    ofType(EnableInvoiceOnlinePayment),
    concatMap(({ invoiceId }) => {
      return this.invoices.enablePayment(invoiceId).pipe(
        tap(() => this.toaster.success('Invoice online payment enabled successfully.')),
        map((res) => EnableInvoiceOnlinePaymentComplete({ update: { id: invoiceId, changes: { onlinePayment: 1 } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  disableInvoiceOnlinePayment$ = this.actions$.pipe(
    ofType(DisableInvoiceOnlinePayment),
    concatMap(({ invoiceId }) => {
      return this.invoices.disablePayment(invoiceId).pipe(
        tap(() => this.toaster.success('Invoice online payment disabled successfully.')),
        map((res) => DisableInvoiceOnlinePaymentComplete({ update: { id: invoiceId, changes: { onlinePayment: 0 } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  scheduleInvoice$ = this.actions$.pipe(
    ofType(ScheduleInvoice),
    concatMap(({ invoiceId, date }) => {
      return this.invoices.schedule(invoiceId, date).pipe(
        tap(() => {
          if (date) {
            this.toaster.info('Invoice scheduled successfully.')
          } else {
            this.toaster.success('Invoice schedule canceled.')
          }
        }),
        map((res) => ScheduleInvoiceComplete({ update: { id: invoiceId, changes: { scheduled: date } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  loadInvoice$ = this.actions$.pipe(
    ofType(LoadInvoice),
    concatMap(({ invoiceId }) =>
      this.invoices.get(invoiceId).pipe(
        map((invoice) => LoadInvoiceComplete({ invoice })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  refundInvoice$ = this.actions$.pipe(
    ofType(RefundInvoice),
    concatMap(({ invoiceId }) =>
      this.invoices.refund(invoiceId).pipe(
        tap(() => this.toaster.success('Invoice refunded successfully.')),
        map((invoiceResponse) => RefundInvoiceComplete({ update: { id: invoiceId, changes: invoiceResponse } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveInvoice$ = this.actions$.pipe(
    ofType(ArchiveInvoice),
    concatMap(({ id }) =>
      this.invoices.archive(id).pipe(
        tap(() => this.toaster.success('Invoice archived successfully.')),
        map((invoiceResponse) => ArchiveInvoiceComplete({ update: { id, changes: invoiceResponse } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unarchiveInvoice$ = this.actions$.pipe(
    ofType(UnArchiveInvoice),
    concatMap(({ id }) =>
      this.invoices.unarchive(id).pipe(
        tap(() => this.toaster.success('Invoice unarchived successfully.')),
        map((invoiceResponse) => UnArchiveInvoiceComplete({ update: { id, changes: invoiceResponse } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInvoice$ = this.actions$.pipe(
    ofType(DeleteInvoice),
    concatMap(({ id }) =>
      this.invoices.delete(id).pipe(
        tap(() => this.toaster.success('Invoice deleted successfully.')),
        map(() => DeleteInvoiceComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveInvoices$ = this.actions$.pipe(
    ofType(ArchiveInvoices),
    concatMap(({ ids }) =>
      this.invoices.archiveBatch(ids).pipe(
        tap(() => this.toaster.success('Invoices archived successfully.')),
        map((invoiceResponse) =>
          ArchiveInvoicesComplete({ update: ids.map((id) => ({ id, changes: { archived: 1 } })) })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  sendInvoices$ = this.actions$.pipe(
    ofType(SendInvoices),
    concatMap(({ invoices }) =>
      this.invoices.sendBatch(invoices).pipe(
        tap(() => this.toaster.success('Invoices sent successfully.')),
        map(() =>
          SendInvoicesComplete({
            update: invoices.map((invoice) => {
              const status = invoice.status !== InvoiceStatus.Paid ? InvoiceStatus.Unpaid : invoice.status
              return {
                id: invoice.id,
                changes: {
                  status,
                  invoiceStatus: getInvoiceStatus(status),
                  sent: moment().unix(),
                },
              }
            }),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unarchiveInvoices$ = this.actions$.pipe(
    ofType(UnArchiveInvoices),
    concatMap(({ ids }) =>
      this.invoices.unarchiveBatch(ids).pipe(
        tap(() => this.toaster.success('Invoices unarchived successfully.')),
        map((invoiceResponse) =>
          UnArchiveInvoicesComplete({ update: ids.map((id) => ({ id, changes: { archived: 0 } })) })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInvoices$ = this.actions$.pipe(
    ofType(DeleteInvoices),
    concatMap(({ ids }) =>
      this.invoices.deleteBatch(ids).pipe(
        tap(() => this.toaster.success('Invoices deleted successfully.')),
        map(() => DeleteInvoicesComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  payWithSavedCard$ = this.actions$.pipe(
    ofType(PayInvoiceWithSavedCard),
    concatMap(({ invoiceId, total, description }) =>
      this.invoices.payWithSavedCard(invoiceId, total, description).pipe(
        tap(() => this.toaster.success('Invoice charged successfully.')),
        map((invoice) => PayInvoiceWithSavedCardComplete({ update: { id: invoiceId, changes: invoice } })),
        catchError((error) => {
          this.toaster.info(
            'Please send the invoice to the guest so that they can re-enter their card details and submit the payment.',
            'Unable to charge invoice'
          )
          return of(ActionFailed({ error }))
        })
      )
    )
  )

  constructor(
    private actions$: Actions,
    private invoices: InvoiceService,
    private store: Store<any>,
    private toaster: Toaster
  ) {}
}
