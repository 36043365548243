<div class="panel-title">Details</div>
<div class="panel-content" *ngFor="let signer of contract?.signers">
  <div class="item-row">
    <div class="item-label d-flex align-items-center justify-content-between">
      <div>
        Signer Name
        <a href="javascript:;" matTooltip="copy sign link." *ngIf="isSignable(signer)" (click)="onCopySignLink(signer)">
          <i class="fal" [ngClass]="isGeneratingSignLink ? 'fa-spin fa-spinner' : 'fa-copy'"></i>
        </a>
      </div>
      <div *ngIf="isSignable(signer)">
        <button
          matTooltip="open sign editor for this signer."
          class="btn btn-secondary-primary btn-sm"
          (click)="openSignEditor(signer)"
        >
          <i class="far" [ngClass]="isGeneratingSignLink ? 'fa-spin fa-spinner' : 'fa-signature'"></i>
          Sign Now
        </button>
      </div>
    </div>
    <div class="item-value">
      <span [matTooltip]="signer.email" class="signer-name d-inline-block" (click)="onSignerClick(signer)">{{
        signer.name
      }}</span>
      <a
        href="javascript:;"
        class="ml-3"
        *ngIf="signer.status === signerStatuses.Signed && !!signer.photo_urls?.length"
        (click)="openPhotoID(signer)"
        matTooltip="view signer's photo ID or passport."
      >
        <i class="fal fa-id-card-alt"></i>
      </a>
      <span class="text-capitalize signer-status ml-2">
        <i [ngClass]="signer.status | signStatusIcon : true" class="mr-2"> </i
        >{{ signer.status === 'sent' ? 'received' : signer.status }}
      </span>
    </div>
  </div>
  <div class="item-row" *ngIf="activities[signer.role]?.sent">
    <div class="item-label">Sent On</div>
    <div class="item-value">
      <ul class="dot-segments-list">
        <li>{{ activities[signer.role]?.sent?.date | epoch : 'DD - MMM - YYYY' }}</li>
        <li>{{ activities[signer.role]?.sent?.date | epoch : 'h:mm A' }}</li>
      </ul>
    </div>
  </div>
  <div class="item-row" *ngIf="activities[signer.role]?.signed">
    <div class="item-label">Signed on</div>
    <div class="item-value">
      <ul class="dot-segments-list">
        <li>{{ activities[signer.role]?.signed?.date | epoch : 'DD - MMM - YYYY' }}</li>
        <li>{{ activities[signer.role]?.signed?.date | epoch : 'h:mm A' }}</li>
      </ul>
    </div>
  </div>
  <div class="item-row" *ngIf="signer.status === signerStatuses.Failed && activities[signer.role]?.failed">
    <div class="item-value text-warning">Please check if the signer's email is correct and try to resend it.</div>
  </div>
  <div class="item-row" *ngIf="signer.status === signerStatuses.Decline">
    <div class="item-label">Decline Reason</div>
    <div class="item-value">
      <app-show-more [content]="activities[signer.role].declined?.additional?.reason" [maxLength]="50"> </app-show-more>
    </div>
  </div>
  <div class="item-row" *ngIf="activities[signer.role]?.signed?.additional && contract.type !== 'document'">
    <div class="item-label">Sign Location</div>
    <div class="item-value">
      {{ activities[signer.role]?.opened?.additional?.location | signLocation }}
    </div>
  </div>
</div>
