import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
  AccountStoreModule,
  ApiKeyStoreModule,
  AttachmentStoreModule,
  ChannelStoreModule,
  ExpenseStoreModule,
  RateStoreModule,
  RentalStoreModule,
  TagStoreModule,
  UserStoreModule,
} from '@tokeet-frontend/tv3-platform'

import { InquiryModule } from '@tv3/store/inquiry/inquiry.module'
import { InvoiceModule } from '@tv3/store/invoice/invoice.module'
import { PreferenceModule } from '@tv3/store/preferences/preferences.module'
import { ForwardModule } from '@tv3/store/forward/forward.module'
import { TaskStoreModule } from '@tv3/store/task/task.module'
import { CalendarModule } from '@tv3/store/calendar/calendar.module'
import { GuestModule } from '@tv3/store/guest/guest.module'
import { ItineraryModule } from '@tv3/store/itinerary/itinerary.module'
import { NoteModule } from '@tv3/store/note/note.module'
import { MessageModule } from '@tv3/store/message/message.module'
import { PlanModule } from '@tv3/store/plan/plan.module'
import { DataFeedModule } from '@tv3/store/datafeed/datafeed.module'
import { ConnectionModule } from '@tv3/store/connection/connection.module'
import { UserPreferenceModule } from '@tv3/store/user-preferences/user-preferences.module'
import { WebsiteModule } from '@tv3/store/website/website.module'
import { PaymentRuleModule } from '@tv3/store/payment-rule/payment-rule.module'
import { SharedContentModule } from '@tv3/store/shared-content/shared-content.module'
import { NotificationModule } from '@tv3/store/notification/notification.module'
import { CustomCodeModule } from '@tv3/store/custom-codes/custom-code.module'
import { WebsiteImageModule } from '@tv3/store/website-image/website-image.module'
import { OverlayModule } from '@tv3/store/overlay/overlay.module'
import { StealthModule } from './stealth/stealth.module'
import { ChannelPropertySettingModule } from '@tv3/store/channel-property-setting/channel-property-setting.module'
import { BillingStoreModule, CardStoreModule } from '@tokeet-frontend/billing'
import { TemplatesLibraryModule } from '@tokeet-frontend/templates'
import {
  InvoiceLibraryModule,
  InvoiceRuleLibraryModule,
  InvoiceTemplateStoreLibraryModule,
} from '@tokeet-frontend/invoices'
import { GatewayLogModule, GatewaysModule, GatewayTypeModule } from '@tokeet-frontend/gateways'

@NgModule({
  imports: [
    CommonModule,
    BillingStoreModule,
    CardStoreModule,
    AccountStoreModule,
    ApiKeyStoreModule,
    ExpenseStoreModule,
    InquiryModule,
    RentalStoreModule,
    ChannelStoreModule,
    InvoiceModule,
    OverlayModule,
    ItineraryModule,
    PreferenceModule,
    ForwardModule,
    TaskStoreModule,
    UserStoreModule,
    UserPreferenceModule,
    CalendarModule,
    AttachmentStoreModule,
    GuestModule,
    CustomCodeModule,
    NoteModule,
    MessageModule,
    TagStoreModule,
    PlanModule,
    DataFeedModule,
    ConnectionModule,
    WebsiteModule,
    WebsiteImageModule,
    RateStoreModule,
    ConnectionModule,
    GatewaysModule,
    GatewayTypeModule,
    GatewayLogModule,
    PaymentRuleModule,
    SharedContentModule,
    NotificationModule,
    StealthModule,
    ChannelPropertySettingModule,
    InvoiceLibraryModule,
    InvoiceRuleLibraryModule,
    InvoiceTemplateStoreLibraryModule,
    TemplatesLibraryModule,
  ],
  declarations: [],
})
export class RootStoreModule {}
