import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromContract from './template.reducer'
import { EffectsModule } from '@ngrx/effects'
import { TemplateEffects } from './template.effects'
import { TemplateIconPipe } from './template-icon.pipe'
import { SignatureTemplateGuard } from './template.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('template', fromContract.templateReducer),
    EffectsModule.forFeature([TemplateEffects]),
  ],
  providers: [SignatureTemplateGuard],
  declarations: [TemplateIconPipe],
  exports: [],
})
export class SignatureTemplatesStoreModule {}
