import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { BaseFieldComponent } from '../base-field.component'
import { untilDestroy } from '@tokeet-frontend/tv3-platform'

const CHAR_WIDTH = 7

@Component({
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss', '../global.scss'],
})
export class TextboxFieldComponent extends BaseFieldComponent implements OnInit {
  @Input() set field(f) {
    this._field = f
    if (f.required) {
      this.ctrl.setValidators([Validators.required])
    }
    this.ctrl.setValue(f.value)
  }
  get field() {
    return this._field
  }

  textLimit = 10

  ctrl = new FormControl()

  @HostBinding('style.display') cssDisplay = 'block'
  constructor() {
    super()
  }

  ngOnInit() {
    this.textLimit = Math.floor(this.field.width / CHAR_WIDTH)
    this.cssDisplay = this.field.value ? 'none' : 'block'
    this.ctrl.valueChanges.pipe(untilDestroy(this)).subscribe((v) => {
      this.field.value = v
    })
  }
}
