import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SignatureFileService } from 'libs/signature/src/lib/services/file.service'
import * as lodash from 'lodash'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

export interface ImageViewerDataItem {
  title?: string
  description?: string
  url: string
}

@Component({
  selector: 'app-image-viewer-dialog',
  templateUrl: './image-viewer-dialog.component.html',
  styleUrls: ['./image-viewer-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ImageViewerDialogComponent implements OnInit {
  activeImageIndex = 0
  loadedWrap = { isImageLoaded: false }
  isDownloading = false
  isImageInvalid = false
  constructor(
    private dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    private fileService: SignatureFileService,
    @Inject(MAT_DIALOG_DATA) public data: { images: ImageViewerDataItem[]; title: string }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  watchLoading(observer: Observable<{ loading: boolean }>, wrap: any) {
    return observer.pipe(
      tap(({ loading }) => {
        wrap.isImageLoaded = !loading
      })
    )
  }

  onImageLoadError() {
    this.isImageInvalid = true
  }

  onDownload() {
    const image = this.data.images[this.activeImageIndex]
    const ext = lodash.last(lodash.split(image.url, '.'))
    this.isDownloading = true
    this.fileService.downloadToLocal(image.url, `${image.title}.${ext}`).subscribe(() => {
      this.isDownloading = false
    })
  }
}
