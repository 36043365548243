import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { Observable, of } from 'rxjs'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { selectContractTemplatesLoaded } from './template.selectors'
import { LoadContractTemplates } from './template.actions'

@Injectable()
export class SignatureTemplateGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: Store<any>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    console.log('LoadContractTemplates')
    this.store.dispatch(LoadContractTemplates())
    return this.store.pipe(
      select(selectContractTemplatesLoaded),
      tap((isLoaded) => {
        console.log('isLoaded')
        if (!isLoaded) {
          this.store.dispatch(LoadContractTemplates())
        }
      }),
      map(() => true),
      take(1)
    )
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate()
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
