<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-calendar-alt"></i> Import Calendar
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <!-- Name -->
      <div class="col-sm-12">
        <mat-form-field2
          class="mat-block"
          matTooltip="Give your calendar a name which will be displayed in AdvanceCM."
          matTooltipPosition="above"
        >
          <input
            type="text"
            matInput
            required
            placeholder="Give your calendar a name"
            formControlName="name"
            maxlength="50"
          />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
            Name must be at most <strong>50</strong> characters long
          </mat-error>
          <mat-hint align="end">{{ form.get('name').value.length || 0 }}/50</mat-hint>
        </mat-form-field2>
      </div>

      <div class="col-sm-12" *ngIf="data?.isChannelNameRequired">
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            matInput
            placeholder="Channel Name"
            formControlName="channel"
            maxlength="50"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let name of availableChannelNames$ | async" [value]="name">
              {{ name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('channel').touched && form.get('channel').hasError('required')">
            Channel name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('channel').touched && form.get('channel').hasError('maxlength')">
            Channel name must be at most <strong>50</strong> characters long
          </mat-error>
          <mat-hint align="end">{{ form.get('channel').value.length || 0 }}/50</mat-hint>
        </mat-form-field2>
      </div>

      <div class="col-sm-12" *ngIf="rentals$">
        <app-rental-select
          [multiple]="false"
          [ctrl]="form.get('rental')"
          placeholder="Rental"
          matTooltip="Select the AdvanceCM rental to import into. This rental's calendar will be updated with the events from your imported calendar."
        >
        </app-rental-select>
      </div>

      <!-- Url -->
      <div class="col-sm-12">
        <mat-form-field2
          class="mat-block"
          matTooltip="Enter the iCal URL of the external calendar you wish to import."
          matTooltipPosition="above"
        >
          <input type="url" #urlInput matInput required placeholder="Calendar URL" formControlName="calendar" />
          <mat-icon matSuffix>
            <app-copy-to-clipboard [input]="urlInput" successMessage="URL copied"></app-copy-to-clipboard>
          </mat-icon>
          <mat-error *ngIf="form.get('calendar').touched && form.get('calendar').hasError('required')">
            Calendar URL is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-12">
        <div class="alert alert-secondary-info">
          <i class="fal fa-info-circle alert-icon"></i>
          <div class="alert-content">
            When you import a calendar URL AdvanceCM will periodically pull data from this calendar and create hold
            events in your calendar based on the information it receives. AdvanceCM also handles updates and deletions
            that occur in the calendar data.
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="create(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-download"></i> import
  </button>
</div>
