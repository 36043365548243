import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'

import { get } from 'lodash'
import { BaseFieldComponent } from '../base-field.component'
import { untilDestroy } from '@tokeet-frontend/tv3-platform'

const CHAR_WIDTH = 7

@Component({
  templateUrl: './datadic.component.html',
  styleUrls: ['./datadic.component.scss', '../global.scss'],
})
export class DatadicFieldComponent extends BaseFieldComponent implements OnInit {
  @Input() set field(f) {
    this._field = f
    this.setInputType(f)
    this.ctrl.setValue(f.value)
  }
  get field() {
    return this._field
  }

  inputType: string
  ctrl = new FormControl()

  @HostBinding('class.invalid')
  get isFieldValueInvalid() {
    return this.ctrl.touched && this.ctrl.invalid
  }

  textLimit = 10

  constructor() {
    super()
  }

  ngOnInit() {
    this.textLimit = Math.floor(this.field.width / CHAR_WIDTH)

    this.ctrl.valueChanges.pipe(untilDestroy(this)).subscribe((v) => {
      this.field.value = v
    })
  }

  setInputType(field) {
    const dataType = get(field, 'attributes.data_type', 'string')
    const dataKey = get(field, 'attributes.key')
    const validators: any[] = []
    if (field.required) {
      validators.push(Validators.required)
    }

    let type = 'text'
    if (/GUEST:PHONE/gi.test(dataKey)) {
      type = 'phone'
    } else {
      switch (dataType) {
        case 'number':
          type = 'number'
          break
        case 'date':
          type = 'date'
          break
        case 'email':
          type = 'email'
          validators.push(Validators.email)
          break
        // case 'time':
        //   type = 'time';
        //   break;
        case 'string':
        default:
          type = 'text'
          break
      }
    }
    this.ctrl.setValidators(validators)
    this.inputType = type
  }
}
