<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill text-center py-5">
    <div class="w-75 m-auto">
      <img src="/assets/images/channel-wizards/complete-icon.webp" alt="" height="50" />
      <h6 class="mt-2">Congratulations!</h6>
      <p>
        Now that you've connected AdvanceCM and your Rentals United account, you can connect your listings to the
        appropriate AdvanceCM rentals. The next screen will present all visible Rentals United listings. To complete the
        remaining connection steps, please select one of the listings under mapped rentals and follow the resulting
        steps.
      </p>
      <p>
        We have sent you a confirmation email with the steps which you have to perform next with the Rentals United
        team. If you have any questions feel free to contact us via Live Chat at the bottom right of your screen.
      </p>
      <div class="alert alert-secondary-warning2 align-items-start text-left">
        <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
        <div class="alert-content">
          <b>Note:</b> If you cannot see your listings under mapped rentals, please give Rentals United time to fulfil
          your request and refresh the room list using the Actions button.
        </div>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4 py-5">
    <img src="/assets/images/channel-wizards/ru/complete.webp" alt="" class="w-100" />
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end">
  <button class="btn btn-secondary-info" (click)="done.emit()">
    <i class="cs-icon cs-icon-tasks"></i>
    Done
  </button>
</div>
