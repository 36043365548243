<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/listing-icon3.webp" alt="" height="40" />
      <div>
        <h6 class="mb-0">Review</h6>
        <div class="text-success">Congratulations!</div>
        <div class="text-sm">
          You're almost done. A few final steps are recommended to ensure you are seeing the correct rates and
          availability.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Check Availability
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Your connection to Tiket.com should now be fully established. However, before proceeding we recommend
                you do a few final checks to make sure the steps have all been completed properly. Please reload/refresh
                your Tiket.com rates calendar which should now match the availability on your AdvanceCM calendar.
              </p>

              <p>
                Dates which are blocked by either bookings or hold events on your AdvanceCM calendar should show up as
                Yellow/Unavailable.
              </p>

              <p>Dates which are open for new reservations should show as Green/Available.</p>
              <div class="d-flex align-items-center gap-3">
                <div class="flex-fill"></div>
                <button class="btn btn-light-info" (click)="checkRates.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #checkRates>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Check Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Pushing rates to Tiket.com can occasionally result in mismatches due to occupancy based pricing settings
                in the Tiket.com admin portal. It is important that you check to make sure the rates that appear after
                completing the connection wizard match what you are intending to push from AdvanceCM.
              </p>

              <p>Some important things to note</p>
              <ul>
                <li>
                  The additional guest fee set in Tiket.com cannot be changed by pushing a different value from
                  AdvanceCM. If you have a different additional guest fee set in Tiket.com than what you are pushing
                  from AdvanceCM, this could result in mismatched rates.
                </li>
                <li>
                  Maximum guests is a setting on the Property Details in Tiket.com which cannot be changed by pushing a
                  different value from AdvanceCM. Please ensure that you are pushing the same value from AdvanceCM.
                </li>
                <li>
                  Guest Count (When to charge additional guest fee. For example: After 2 guests charge 10$. The guest
                  count is 2.) This value cannot be changed by pushing a different value from AdvanceCM. If you see a
                  mismatch in your rates and determine it is due to a mismatch of one of the above values, please
                  contact your Tiket.com account manager or support representative and ask them to set your occupancy
                  based pricing to match. Once this is done, you will be able to manage your rates from AdvanceCM
                  without further difficulty.
                </li>
              </ul>

              <p>This is discussed in more detail on the following help guide.</p>
              <a
                href="https://help.tokeet.com/channel-manager/booking-com/bookingcom-rates-and-additional-guest-pricing"
                target="_blank"
                >https://help.tokeet.com/channel-manager/booking-com/bookingcom-rates-and-additional-guest-pricing</a
              >

              <div class="d-flex align-items-center gap-3 mt-3">
                <div class="flex-fill"></div>
                <button class="btn btn-light-info" (click)="complete.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #complete>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Connection Complete
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p class="text-success">Congratulations!</p>
              <p>
                You’ve successfully established a connection between AdvanceCM and Tiket.com for this property. To begin
                the connection process for another property
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.webp" alt="" class="w-100" />
  </div>
</div>
<div class="mt-3 d-flex align-items-center">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <div class="flex-fill"></div>
  <button class="btn btn-secondary-info" (click)="done.emit()">
    <i class="cs-icon cs-icon-tasks"></i>
    Done
  </button>
</div>
