import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map, mapTo, switchMap, take, tap, toArray } from 'rxjs/operators'
import { combineLatest, from, of } from 'rxjs'
import * as moment from 'moment'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import {
  AddExportedCalendarConnection,
  AddExportedCalendarConnectionComplete,
  AddImportedCalendarConnection,
  AddImportedCalendarConnectionComplete,
  CreateConnectionsForChannel,
  CreateConnectionsForChannelComplete,
  DeleteCalendarConnections,
  DeleteCalendarConnectionsComplete,
  DeleteExportedCalendarConnection,
  DeleteExportedCalendarConnectionComplete,
  DeleteImportedCalendarConnection,
  DeleteImportedCalendarConnectionComplete,
  GetAirbnbListingStatus,
  GetAirbnbListingStatusComplete,
  GetConnectionStatues,
  GetConnectionStatuesComplete,
  HoldEventForEmptyCalendarPush,
  ImportBookingFromABBV2Connection,
  ImportBookingFromABBV2ConnectionComplete,
  ImportBookingsFromConnection,
  ImportBookingsFromConnectionComplete,
  LinkConnectionWithRental,
  LinkConnectionWithRentalComplete,
  LoadConnection,
  LoadConnectionComplete,
  LoadConnections,
  LoadConnectionsByChannel,
  LoadConnectionsByChannelComplete,
  LoadConnectionsComplete,
  ManualRefreshImportedCalendarConnection,
  ManualRefreshImportedCalendarConnectionComplete,
  ManualRefreshImportedCalendarConnections,
  PushAvailabilityToConnection,
  PushAvailabilityToConnectionComplete,
  PushAvailabilityToConnectionForWizard,
  PushAvailabilityToConnectionForWizardComplete,
  PushRatesToConnection,
  PushRatesToConnectionComplete,
  RefreshConnectionsForChannel,
  RefreshConnectionsForChannelComplete,
  UnlinkConnectionWithRental,
  UnlinkConnectionWithRentalComplete,
  UnlinkConnectionsWithRental,
} from '@tv3/store/connection/connection.actions'
import {
  AlertDialogService,
  asUTCEpoch,
  ChannelNameTokens,
  ConfirmDialogService,
  ConfirmDialogStatus,
  RentalService,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { Connection, getChannelConnectionId } from '@tv3/store/connection/connection.model'
import { ConnectionStatusResponse } from '@tv3/interfaces/responses/connection-status.response'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { CalendarEventTypeOther } from '@tv3/store/calendar/calendar.model'
import { ClearHoldEvents, DeleteHoldEvent } from '@tv3/store/calendar/calendar.actions'
import { select, Store } from '@ngrx/store'
import { selectAllHoldEvents } from '../calendar/calendar.selectors'
import {
  TiketChannelService,
  PushAvailabilityToTiketListing,
  PushRatesToTiketListing,
  CtripChannelService,
  PushRatesToCtripListing,
  PushAvailabilityToCtripListing,
} from '@tokeet-frontend/channels'
import { UnlinkConnectionWithRentalPayload } from '@tv3/store/connection/connection.types'
import { ConnectionAirbnbService } from './connection-airbnb.service'
import { Update } from '@ngrx/entity'

@Injectable()
export class ConnectionEffects {
  private loadConnectionsStatus(connections: Connection[]) {
    const propertyIds = R.pipe(
      R.map((c: Connection) => c.propertyId),
      R.reject(R.isNil)
    )(connections)
    const roomIds = R.pipe(
      R.map((c: Connection) => c.roomId),
      R.reject(R.isNil)
    )(connections)

    if (R.isEmpty(propertyIds) || R.isEmpty(roomIds)) {
      return of(connections)
    }
    return this.connectionsService.getConnectionsStatuses(propertyIds, roomIds).pipe(
      map((statuses: ConnectionStatusResponse[]) => {
        const updates = R.map((status: ConnectionStatusResponse) => {
          return {
            id: getChannelConnectionId(status),
            lastavailpull: status.lastavailpull,
            lastavailpush: status.lastavailpush,
            lastratepush: status.lastratepush,
          }
        }, statuses)

        return lodash.mapValues(
          lodash.groupBy(updates, (t) => t.id),
          (items) => {
            return {
              lastavailpull: lodash.maxBy(items, (t) => t.lastavailpull)?.lastavailpull,
              lastavailpush: lodash.maxBy(items, (t) => t.lastavailpush)?.lastavailpush,
              lastratepush: lodash.maxBy(items, (t) => t.lastratepush)?.lastratepush,
            }
          }
        )
      }),
      map((updates) => {
        return connections.map((c) => {
          const status = updates[c.id]
          if (!status) return c
          c.lastavailpull = status.lastavailpull
          c.lastavailpush = status.lastavailpush
          c.lastratepush = status.lastratepush
          return c
        })
      }),
      catchError((error) => of(connections))
    )
  }

  @Effect()
  loadConnections$ = this.actions$.pipe(
    ofType(LoadConnections),
    switchMap(() =>
      this.connectionsService.all().pipe(
        switchMap((items) => this.loadConnectionsStatus(items)),
        map((connections) => LoadConnectionsComplete({ connections })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadConnection$ = this.actions$.pipe(
    ofType(LoadConnection),
    switchMap(({ channelName, roomId, propertyId }) =>
      this.connectionsService.get(channelName, roomId, propertyId).pipe(
        map((connection) => LoadConnectionComplete({ connection })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadStatuses$ = this.actions$.pipe(
    ofType(GetConnectionStatues),
    exhaustMap(({ propertyIds, roomIds }) =>
      this.connectionsService.getConnectionsStatuses(propertyIds, roomIds).pipe(
        map((statuses: ConnectionStatusResponse[]) => {
          const updates = R.map((status: ConnectionStatusResponse) => {
            return {
              id: getChannelConnectionId(status),
              changes: {
                lastavailpull: status.lastavailpull,
                lastavailpush: status.lastavailpush,
                lastratepush: status.lastratepush,
              },
            }
          }, statuses)

          return R.map((data) => {
            return {
              id: R.head(data).id,
              changes: {
                lastavailpull: lodash.max(R.map((t) => t.changes.lastavailpull, data)),
                lastavailpush: lodash.max(R.map((t) => t.changes.lastavailpush, data)),
                lastratepush: lodash.max(R.map((t) => t.changes.lastratepush, data)),
              },
            }
          }, R.values(R.groupBy((t) => t.id, updates)))
        }),
        map((updates) => GetConnectionStatuesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadAirbnbListingStatuses$ = this.actions$.pipe(
    ofType(GetAirbnbListingStatus),
    exhaustMap(({ items }) =>
      of(...items).pipe(
        concatMap((item) =>
          this.connectionAirbnbService
            .getListingDetailsV2(item.propertyId, item.listing)
            .pipe(
              map((d): Update<Connection> => ({ id: item.id, changes: { airbnbStatus: d.status?.status_category } }))
            )
        ),
        toArray(),
        map((updates) => GetAirbnbListingStatusComplete({ updates })),
        catchError((error) => of(ActionSkipped()))
      )
    )
  )

  @Effect()
  loadConnectionsByChannel$ = this.actions$.pipe(
    ofType(LoadConnectionsByChannel),
    switchMap(({ channelName, channelId }) =>
      this.connectionsService.allByChannel(channelName, channelId).pipe(
        switchMap((items) => this.loadConnectionsStatus(items)),
        map((connections) => LoadConnectionsByChannelComplete({ connections })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  refreshConnectionsForChannel$ = this.actions$.pipe(
    ofType(RefreshConnectionsForChannel),
    switchMap(({ channelName, channelId, silent }) =>
      this.connectionsService.refreshForChannel(channelName, channelId).pipe(
        tap(() => !silent && this.toaster.info('Room refresh scheduled.')),
        map((res) => RefreshConnectionsForChannelComplete()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createConnectionsForChannel$ = this.actions$.pipe(
    ofType(CreateConnectionsForChannel),
    switchMap(({ channelName, data }) =>
      this.connectionsService.createListing(channelName, data).pipe(
        tap(() => this.toaster.info('Listing created successfully.')),
        map((res) => CreateConnectionsForChannelComplete()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addImportedCalendarConnection$ = this.actions$.pipe(
    ofType(AddImportedCalendarConnection),
    switchMap(({ payload }) =>
      this.connectionsService.addImportedCalendarConnection(payload).pipe(
        tap((res: Connection) => this.toaster.info(`Calendar scheduled for import.`)),
        map((connection) => AddImportedCalendarConnectionComplete({ connection })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addExportedCalendarConnection$ = this.actions$.pipe(
    ofType(AddExportedCalendarConnection),
    switchMap(({ payload }) =>
      this.connectionsService.addExportedCalendarConnection(payload).pipe(
        tap((res: Connection) => this.toaster.success(`Connection saved successfully.`)),
        map((connection) => AddExportedCalendarConnectionComplete({ connection })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  link$ = this.actions$.pipe(
    ofType(LinkConnectionWithRental),
    switchMap(({ connId, data, channelName }) => {
      const doLink = () => {
        switch (channelName) {
          case ChannelNameTokens.AirBnBAPI:
            return this.connectionsService.linkABBV1(data)
          case ChannelNameTokens.AirBnBV2API:
            return this.connectionsService.linkABBV2(data)
          case ChannelNameTokens.Tiket:
            return this.tiketService.link(data.roomId as any, data.rentalId, data.propertyId)
          case ChannelNameTokens.Trip:
            return this.ctripService.link(data.roomId as any, data.rentalId, data.propertyId)
          default:
            return this.connectionsService.link(data)
        }
      }
      return doLink().pipe(
        tap(() => this.toaster.success('Property linked successfully.')),
        map((res) =>
          LinkConnectionWithRentalComplete({
            update: {
              id: connId,
              changes: { rentalId: data.rentalId, linkDate: moment.utc().unix() },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  private doUnlink(channelName: ChannelNameTokens, data: UnlinkConnectionWithRentalPayload) {
    switch (channelName) {
      case ChannelNameTokens.AirBnBAPI:
        return this.connectionsService.unlinkABB(data)
      case ChannelNameTokens.AirBnBV2API:
        return this.connectionsService.unlinkABBV2(data)
      case ChannelNameTokens.Tiket:
        return this.tiketService.unlink(data.roomId as any, data.propertyId)
      case ChannelNameTokens.Trip:
        return this.ctripService.unlink(data.roomId as any, data.propertyId)
      default:
        return this.connectionsService.unlink(data)
    }
  }

  @Effect()
  unlink$ = this.actions$.pipe(
    ofType(UnlinkConnectionWithRental),
    switchMap(({ connId, data, channelName }) => {
      return this.doUnlink(channelName, data).pipe(
        tap(() => this.toaster.success('Property unlinked successfully.')),
        map((res) =>
          UnlinkConnectionWithRentalComplete({
            update: {
              id: connId,
              changes: { rentalId: undefined, linkDate: undefined },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  unlinks$ = this.actions$.pipe(
    ofType(UnlinkConnectionsWithRental),
    switchMap(({ items }) => {
      const successItemIds: string[] = []
      return of(...items).pipe(
        concatMap((item) =>
          this.doUnlink(item.channelName, item.data).pipe(
            tap(() => successItemIds.push(item.connId)),
            catchError(() => of(null))
          )
        ),
        toArray(),
        tap(() => this.toaster.success('Property unlinked successfully.')),
        switchMap(() => {
          return lodash.map(successItemIds, (id) =>
            UnlinkConnectionWithRentalComplete({
              update: {
                id: id,
                changes: { rentalId: undefined, linkDate: undefined },
              },
            })
          )
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  deleteExported$ = this.actions$.pipe(
    ofType(DeleteExportedCalendarConnection),
    switchMap(({ id }) =>
      this.connectionsService.deleteExportedCalendar(id).pipe(
        tap(() => this.toaster.success('Connection disconnected successfully.')),
        map(() => DeleteExportedCalendarConnectionComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  importBookings$ = this.actions$.pipe(
    ofType(ImportBookingsFromConnection),
    switchMap(({ connId, channelId, channelName, data }) =>
      this.connectionsService.importBookings(channelName, channelId, data).pipe(
        tap(() => this.toaster.success('Booking import scheduled.')),
        map((res) =>
          ImportBookingsFromConnectionComplete({
            update: {
              id: connId,
              changes: { lastavailpull: moment.utc().unix() },
            },
          })
        ),
        catchError((error) => {
          if (
            channelName === ChannelNameTokens.AirBnBAPI ||
            channelName === ChannelNameTokens.AirBnBV2API ||
            channelName === ChannelNameTokens.AgodaAPI
          ) {
            this.toaster.info('Your import is still processing.')
            return of(ActionSkipped())
          } else {
            return of(ActionFailed({ error }))
          }
        })
      )
    )
  )

  @Effect()
  importBookingABBV2$ = this.actions$.pipe(
    ofType(ImportBookingFromABBV2Connection),
    concatMap(({ inquiryId, channelId, data }) =>
      this.connectionsService.importBookingByInquiryIdABBV2(channelId, data, inquiryId).pipe(
        tap(() => this.toaster.success('Booking imported successfully.')),
        map((res) => ImportBookingFromABBV2ConnectionComplete()),
        catchError((error) => {
          return of(ActionFailed({ error }))
        })
      )
    )
  )

  @Effect()
  pushAvailabilityTiket$ = this.actions$.pipe(
    ofType(PushAvailabilityToTiketListing),
    switchMap(({ connId, roomTypeCode, data }) =>
      this.tiketService.pusAvailability(roomTypeCode, data).pipe(
        tap(() => this.toaster.info('Availability update scheduled successfully.')),
        map((res) =>
          PushAvailabilityToConnectionComplete({
            update: {
              id: connId,
              changes: { lastavailpush: moment.utc().unix() },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pushAvailabilityCtrip$ = this.actions$.pipe(
    ofType(PushAvailabilityToCtripListing),
    switchMap(({ connId, roomTypeCode, data }) =>
      this.ctripService.pusAvailability(roomTypeCode, data).pipe(
        tap(() => this.toaster.info('Availability update scheduled successfully.')),
        map((res) =>
          PushAvailabilityToConnectionComplete({
            update: {
              id: connId,
              changes: { lastavailpush: moment.utc().unix() },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pushAvailability$ = this.actions$.pipe(
    ofType(PushAvailabilityToConnection),
    switchMap(({ connId, channelId, channelName, data }) =>
      this.connectionsService.pushAvailability(channelName, channelId, data).pipe(
        tap(() => this.toaster.info('Availability update scheduled successfully.')),
        map((res) =>
          PushAvailabilityToConnectionComplete({
            update: {
              id: connId,
              changes: { lastavailpush: moment.utc().unix() },
            },
          })
        ),
        catchError((error) => {
          if (R.pathEq(['status'], 424, error)) {
            this.alertDialog.open({
              title: 'Warning!',
              body: 'Your calendar is empty and you cannot push an empty calendar to a channel. Please first import bookings from your channel to fill your calendar or create a hold event on your calendar.',
            })
            return of(ActionSkipped())
          }
          return of(ActionFailed({ error }))
        })
      )
    )
  )

  @Effect()
  pushAvailabilityForWizard$ = this.actions$.pipe(
    ofType(PushAvailabilityToConnectionForWizard),
    switchMap(({ connId, channelId, channelName, tmpHoldEventId, data }) =>
      this.connectionsService.pushAvailability(channelName, channelId, data).pipe(
        tap(() => this.toaster.info('Availability update scheduled successfully.')),
        switchMap(() => {
          const actions: any[] = [
            PushAvailabilityToConnectionForWizardComplete({
              update: {
                id: connId,
                changes: { lastavailpush: moment.utc().unix() },
              },
            }),
          ]
          if (tmpHoldEventId) {
            // delete temporary hold event
            actions.push(DeleteHoldEvent({ id: tmpHoldEventId, silent: true }))
          }
          return actions
        }),
        catchError((error) => {
          if (R.pathEq(['status'], 424, error)) {
            return this.confirmDialog
              .openRegular({
                title: 'Warning!',
                body: `You are attempting to push an empty calendar. This may be because bookings have not had time to import to AdvanceCM, or your calendar is empty on the originating channel. To complete this step now, please create a temporary hold event using the Add Hold option below. We will make a temporary hold event 2 years in the future and delete it as soon as bookings are imported from the originating channel. If you would like to wait for bookings to import, please hit OK and allow some time for bookings to import.`,
                cancelText: 'OK',
                cancelBtnClass: 'btn btn-outline-primary',
                confirmText: 'Add Hold',
              })
              .pipe(
                switchMap((status): any => {
                  if (status === ConfirmDialogStatus.Confirmed) {
                    return this.calendarService
                      .create([
                        {
                          title: 'Temporary Hold Event',
                          type: CalendarEventTypeOther.id,
                          start: asUTCEpoch(moment().add(2, 'year')),
                          end: asUTCEpoch(moment().add(2, 'year').add(1, 'day')),
                          source: 'tokeet',
                          expires: 0,
                          rolling: 0,
                          rental_id: data.rentalId,
                        },
                      ])
                      .pipe(
                        tap(() =>
                          this.toaster.success(
                            'Temporary hold event created successfully, you can try to push availability now.'
                          )
                        ),
                        map((res) => HoldEventForEmptyCalendarPush({ connId, holdEvent: R.head(res) }))
                      )
                  }
                  return [ActionSkipped()]
                })
              )
          }
          return of(ActionFailed({ error }))
        })
      )
    )
  )

  @Effect()
  pushRates$ = this.actions$.pipe(
    ofType(PushRatesToConnection),
    switchMap(({ connId, channelId, channelName, data }) =>
      this.connectionsService.pushRates(channelName, channelId, data).pipe(
        tap(() => this.toaster.info('Rate push scheduled.')),
        map((res) =>
          PushRatesToConnectionComplete({ update: { id: connId, changes: { lastratepush: moment.utc().unix() } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pushRatesTiket$ = this.actions$.pipe(
    ofType(PushRatesToTiketListing),
    switchMap(({ connId, roomTypeCode, data }) =>
      this.tiketService.pushRates(roomTypeCode, data).pipe(
        tap(() => this.toaster.info('Rate push scheduled.')),
        map((res) =>
          PushRatesToConnectionComplete({ update: { id: connId, changes: { lastratepush: moment.utc().unix() } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pushRatesCtrip$ = this.actions$.pipe(
    ofType(PushRatesToCtripListing),
    switchMap(({ connId, roomTypeCode, data }) =>
      this.ctripService.pushRates(roomTypeCode, data).pipe(
        tap(() => this.toaster.info('Rate push scheduled.')),
        map((res) =>
          PushRatesToConnectionComplete({ update: { id: connId, changes: { lastratepush: moment.utc().unix() } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  refreshCalendar$ = this.actions$.pipe(
    ofType(ManualRefreshImportedCalendarConnection),
    switchMap(({ connId, data }) =>
      this.connectionsService.refreshImportedCalendar(data).pipe(
        tap(() => this.toaster.info('Calendar scheduled for import.')),
        map((connection) =>
          ManualRefreshImportedCalendarConnectionComplete({ update: { id: connId, changes: connection } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  refreshCalendars$ = this.actions$.pipe(
    ofType(ManualRefreshImportedCalendarConnections),
    switchMap(({ payloads }) => from(payloads)),
    concatMap(({ connId, data }) =>
      this.connectionsService.refreshImportedCalendar(data).pipe(
        map((connection) =>
          ManualRefreshImportedCalendarConnectionComplete({ update: { id: connId, changes: connection } })
        ),
        tap(() => this.toaster.info('Calendars scheduled for import.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteImported$ = this.actions$.pipe(
    ofType(DeleteImportedCalendarConnection),
    switchMap(({ connId, rentalId, removeAllEvents }) =>
      this.connectionsService.deleteImportedCalendar(connId, rentalId, removeAllEvents).pipe(
        tap(() => this.toaster.success('Calendar deleted successfully.')),
        switchMap((res) => {
          const actions: any[] = [DeleteImportedCalendarConnectionComplete({ id: connId })]
          if (removeAllEvents) {
            return this.store.pipe(
              select(selectAllHoldEvents),
              take(1),
              switchMap((items) => {
                actions.push(ClearHoldEvents({ ids: items.map((t) => t.id) }))
                return actions
              })
            )
          } else {
            return actions
          }
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteCalendars$ = this.actions$.pipe(
    ofType(DeleteCalendarConnections),
    switchMap(({ imported, exported }) => {
      const observers = [
        ...lodash.map(exported, (id) => this.connectionsService.deleteExportedCalendar(id).pipe(mapTo(id))),
        ...lodash.map(imported?.items, ({ connId, rentalId }) =>
          this.connectionsService.deleteImportedCalendar(connId, rentalId, imported.removeAllEvents).pipe(mapTo(connId))
        ),
      ]

      return of(...lodash.chunk(observers, 5)).pipe(
        concatMap((items) => combineLatest(items)),
        toArray(),
        tap(() => this.toaster.success('Calendars deleted successfully.')),
        switchMap((data) => {
          const actions: any[] = [DeleteCalendarConnectionsComplete({ ids: lodash.flatten(data) })]
          if (imported?.removeAllEvents) {
            actions.push(ClearHoldEvents({ ids: [] }))
          }
          return actions
        })
      )
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private toaster: Toaster,
    private rentalService: RentalService,
    private alertDialog: AlertDialogService,
    private calendarService: CalendarService,
    private confirmDialog: ConfirmDialogService,
    private connectionAirbnbService: ConnectionAirbnbService,
    private connectionsService: ConnectionService,
    private tiketService: TiketChannelService,
    private ctripService: CtripChannelService
  ) {}
}
