import { Pipe, PipeTransform } from '@angular/core'
import { keyBy } from 'lodash'
import { availableContractEventTypes, ContractSignStatus } from '../store'

@Pipe({
  name: 'signStatusIcon',
})
export class SignStatusIconPipe implements PipeTransform {
  cfgByStatus = keyBy(availableContractEventTypes, 'id')

  transform(value: ContractSignStatus | string, withColor = false, iconType: 0 | 1 = 0): string {
    const status = this.cfgByStatus[value]
    if (!status) {
      return ''
    }

    const icon = iconType === 0 ? status.icon : status.icon2

    if (!withColor) {
      return icon
    }

    return `${icon} ${status.color}`
  }
}

@Pipe({
  name: 'signStatusColor',
})
export class SignStatusColorPipe implements PipeTransform {
  cfgByStatus = keyBy(availableContractEventTypes, 'id')

  transform(value: ContractSignStatus | string): string {
    const status = this.cfgByStatus[value]
    if (!status) {
      return ''
    }

    return status.color
  }
}

@Pipe({
  name: 'signStatusBGColor',
})
export class SignStatusBGColorPipe implements PipeTransform {
  cfgByStatus = keyBy(availableContractEventTypes, 'id')

  transform(value: ContractSignStatus | string): string {
    const status = this.cfgByStatus[value]
    if (!status) {
      return ''
    }

    return status.bgColor
  }
}
