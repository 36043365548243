<div class="modal-body">
  <div class="text-center">
    <i class="cs-icon cs-icon-paper-sign text-success" style="font-size: 5rem"></i>
    <h5 class="title mt-3">Confirmation</h5>
    <div class="subtitle">
      I agree to be legally bound by this document and
      <br />
      the <a href="https://www.usesignature.com/terms.html" target="_blank">Signature Terms of Service</a>.
      <br />
      <br />
      Click on <span class="text-semi-bold">I Agree</span> to sign this document.
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" aria-label="Close" *ngIf="isCancelable" (click)="close()">
    <i class="far fa-times" aria-hidden="true"></i> Cancel
  </button>

  <button type="button" class="btn btn-secondary-info" (click)="onSave()">
    <i class="far fa-check" aria-hidden="true"></i> I Agree
  </button>
</div>
