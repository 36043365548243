<div class="modal-header">
  <h5 class="modal-title"><i class="fas fa-flask"></i> Set Booking Formula</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <mat-select
            formControlName="base"
            placeholder="Start the formula with:"
            [disabled]="!isBaseTypeCustomizable"
            required
          >
            <mat-option [value]="item.value" *ngFor="let item of baseTypes">{{ item.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('base').touched && form.get('base').hasError('required')">
            This is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6" *ngIf="!data.channel">
        <mat-form-field2 class="mat-block">
          <app-select
            [items]="channels"
            formControlName="channel_id"
            bindValue="id"
            bindLabel="name"
            placeholder="Channel"
            matTooltipPosition="above"
            [required]="true"
          >
          </app-select>
          <mat-error *ngIf="form.get('channel_id').touched && form.get('channel_id').hasError('required')">
            Channel is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="name" matInput required placeholder="Formula Name" />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            Formula Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-3">
        <app-form-switch (valueChange)="onToggleStatus($event)" formControlName="status" class="status-toggle"
          >Status</app-form-switch
        >
      </div>
      <div class="col-sm-3">
        <div class="col-sm-6">
          <mat-checkbox
            formControlName="cumulative"
            matTooltip="This function allows you to change the order in which the line items are deducted."
            class="cumulative-toggle"
          >
            {{ form.get('cumulative').value ? 'Cumulative' : 'Aggregate' }}
          </mat-checkbox>
        </div>
      </div>
      <div class="col-sm-6">
        <app-rental-select
          [ctrl]="form.get('rentals')"
          placeholder="Rentals"
          [matTooltipClass]="rentalIds.length > 20 ? 'text-center tooltip-lg' : 'text-center'"
          [matTooltip]="getRentalsView(rentalIds)"
        >
        </app-rental-select>
      </div>
      <div class="col-sm-3">
        <mat-form-field2
          class="mat-block"
          matTooltip="This indicates the time from which month the formula will be applied to a booking."
        >
          <mat-select formControlName="start_month" placeholder="Start Month" required>
            <mat-option [value]="item.value" *ngFor="let item of months">{{ item.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('start_month').touched && form.get('start_month').hasError('required')">
            Start month is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('start_month').hasError('max')">
            Start month should not greater than end month
          </mat-error>
          <mat-error *ngIf="form.get('start_month').hasError('overlap')">
            Date range is overlapping with other formulas.
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-3">
        <mat-form-field2
          class="mat-block"
          matTooltip="This indicates the time until which month the formula will be applied to a booking."
        >
          <mat-select formControlName="end_month" placeholder="End Month" required>
            <mat-option [value]="item.value" *ngFor="let item of months">{{ item.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('end_month').touched && form.get('end_month').hasError('required')">
            End month is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('end_month').hasError('min')">
            End month should not less than start month
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <div
      formArrayName="formula"
      *ngFor="let item of formulaItems.controls; let i = index"
      class="bg-default p-3 rounded pl-4 position-relative mb-3"
    >
      <div [formGroupName]="i" class="d-flex gap-3">
        <button
          (click)="removeFormula(i)"
          class="btn btn-secondary-danger btn-circle position-absolute"
          type="button"
          style="left: -1rem; top: 2.5rem"
        >
          <i class="cs-icon cs-icon-trash"></i>
        </button>
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="name" matInput required placeholder="Item Name" />
          <mat-error *ngIf="item['controls'].name.touched && item.hasError('required', ['name'])">
            Item Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2 class="mat-block">
          <mat-label>Amount</mat-label>
          <input type="number" min="0" formControlName="amount" required matInput />
          <mat-error *ngIf="item['controls'].amount.touched && item.hasError('required', ['amount'])">
            Amount is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="item['controls'].amount.touched && item.hasError('percent', ['amount'])">
            Amount should be greater than <b>0</b> and less than <b>100</b>
          </mat-error>
          <mat-error *ngIf="item['controls'].amount.touched && item.hasError('positive', ['amount'])">
            Amount should be greater than <b>0</b>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="type" placeholder="Type">
            <mat-option value="percent">Percent</mat-option>
            <mat-option value="flat">Flat</mat-option>
          </mat-select>
        </mat-form-field2>
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="operation" placeholder="Operation">
            <mat-option value="add">Add</mat-option>
            <mat-option value="subtract">Subtract</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
    </div>
    <div>
      <button (click)="addFormula()" class="btn btn-secondary-info w-100" type="button">
        <i class="far fa-plus"></i> New Item
      </button>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
