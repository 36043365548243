import { ElementRef, Injectable } from '@angular/core'
import * as dragula from 'dragula'
import { find, includes, forEachRight } from 'lodash'
import { Subject } from 'rxjs'

export type DragDropElementDataGetter<T> = (el: Element) => T

@Injectable()
export class DragDropManagerService {
  draggableElementClass: string
  dragulaDrake: dragula.Drake

  draggableContainers = []
  droppableContainers = []

  private mirrorElement: Element
  drop$ = new Subject<any>()

  isInitialed = false
  elementDataGetter: DragDropElementDataGetter<any>

  constructor() {}

  setupDragula(): dragula.Drake {
    const drake = dragula({
      copy: true,
      accepts: (el, target, source, sibling) => {
        return true // this.isDroppableContainer(target) && this.isDraggableContainer(source)
      },
      // This method should return true for elements that shouldn't trigger a drag
      invalid: (el, handle) => {
        return !includes(handle.classList, this.draggableElementClass)
      },
    })

    drake.on('drag', (el, source) => {
      el.classList.add('dragging')
    })

    drake.on('over', (el, container, source) => {
      container.classList.add('dragging-over')
    })

    drake.on('out', (el, container) => {
      container.className = container.className.replace('dragging-over', '')
    })

    drake.on('cloned', (clone, original, type) => {
      this.mirrorElement = clone
    })

    drake.on('drop', (el, target, source, sibling) => {
      el.remove() // remove from dom
      if (!this.mirrorElement) {
        return
      }
      if (!this.elementDataGetter) {
        throw new Error('No element data getter.')
      }
      const data = this.elementDataGetter(el)
      this.drop$.next({
        el: this.mirrorElement, // use mirror element to get the position of dropped element. (don't use *el*)
        target,
        data,
      })
    })

    return drake
  }

  isDraggableContainer(el: Element) {
    return !!find(this.draggableContainers, el)
  }

  isDroppableContainer(el: Element) {
    return !!find(this.droppableContainers, el)
  }

  private addContainer(el: Element) {
    this.dragulaDrake.containers.push(el)
  }

  clearDroppableContainer() {
    forEachRight(this.dragulaDrake.containers, (c, i) => {
      if (includes(this.droppableContainers, c)) {
        this.dragulaDrake.containers.splice(i, 1)
      }
    })
    this.droppableContainers = []
  }

  addDroppableContainer(el: ElementRef | Element) {
    if (!this.isInitialed) {
      throw new Error('Drag and drop manager is not setup.')
    }
    if (!el) {
      return
    }
    let nativeElement: Element
    if (el instanceof ElementRef) {
      nativeElement = el.nativeElement
    } else {
      nativeElement = el
    }

    if (find(this.draggableContainers, nativeElement)) {
      return
    }

    this.droppableContainers.push(nativeElement)
    this.addContainer(nativeElement)
  }

  addDraggableContainer(el: ElementRef | Element) {
    if (!this.isInitialed) {
      throw new Error('Drag and drop manager is not setup.')
    }
    let nativeElement: Element
    if (el instanceof ElementRef) {
      nativeElement = el.nativeElement
    } else {
      nativeElement = el
    }

    this.draggableContainers.push(nativeElement)
    this.addContainer(nativeElement)
  }

  setup(draggableElementClass: string, dataGetter: DragDropElementDataGetter<any>) {
    if (this.dragulaDrake) {
      this.dragulaDrake.destroy()
    }
    this.draggableElementClass = draggableElementClass
    this.elementDataGetter = dataGetter
    this.dragulaDrake = this.setupDragula()
    this.draggableContainers = []
    this.droppableContainers = []
    this.isInitialed = true
  }
}
