import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { CURRENT_ACCOUNT_ID } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class SignatureFileService {
  cache = new Map<string, Blob>()
  mergedFiles = new Map<string, string>()
  constructor(private http: HttpClient, @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>) {}

  disableFileCache(url: string) {
    this.cache.delete(url)
  }

  download(resourceUrl: string, refresh = false): Observable<Blob> {
    if (!refresh && this.cache.has(resourceUrl)) {
      return of(this.cache.get(resourceUrl))
    }

    const account = this.accountId$.value
    const url = `@signatureApi/${account}/files?url=${encodeURIComponent(resourceUrl)}`
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap((blob: Blob) => {
        return this.cache.set(resourceUrl, blob)
      })
    )
  }

  downloadToLocal(resourceUrl, name) {
    return this.download(resourceUrl).pipe(
      tap((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = name || 'download'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
      })
    )
  }

  merge(s3FileUrls: string[]) {
    const account = this.accountId$.value
    const url = `@signatureApi/${account}/files/merge`

    const cacheKey = s3FileUrls.join('|')
    if (this.mergedFiles.has(cacheKey)) {
      return of(this.mergedFiles.get(cacheKey))
    }

    return this.http.post<{ url: string }>(url, { files: s3FileUrls }).pipe(
      map(({ url }) => url),
      tap((url) => this.mergedFiles.set(cacheKey, url))
    )
  }
}
