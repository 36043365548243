<div class="d-flex gap-3 mt-3">
  <div class="container-box shadow-none flex-fill p-3 d-flex flex-column">
    <div class="d-flex gap-3 align-items-end" style="margin-top: -2.5rem" *ngIf="!isLoading">
      <app-avatar [text]="userName" [border]="8" borderColor="white" size="86"></app-avatar>
      <div>
        <h6 class="mb-0">Connected Airbnb Account</h6>
        <div class="d-flex gap-2 align-items-center" *ngIf="airbnbAccount">
          <div>{{ userName }} (#{{ airbnbAccount.propertyId }})</div>
          <span class="bg-active2 badge-pill text-sm px-3 py-1"
            ><b>{{ airbnbAccount.rooms?.length || 0 }}</b> Active Listings</span
          >
        </div>
      </div>
    </div>
    <div style="margin: 1rem -1rem 0">
      <ng-container *ngIf="isLoading; else listings">
        <app-loading-section></app-loading-section>
      </ng-container>
      <!-- <ng-template [ngTemplateOutlet]="listings"></ng-template> -->
    </div>
    <div class="flex-fill"></div>
    <div class="d-flex gap-3 align-items-center">
      <ng-container *ngIf="airbnbAccount">
        <button class="btn btn-secondary-danger" (click)="onDisconnect()">
          Disconnect <i class="cs-icon cs-icon-unlink"></i>
        </button>
        <div class="flex-fill"></div>
        <button
          class="btn btn-secondary-warning"
          matTooltip="Import selected listings into AdvanceCM"
          matTooltipPosition="above"
          [disabled]="selection.isEmpty() || isImporting"
          (click)="onImport()"
        >
          <i class="far fa-spin fa-spinner" *ngIf="isImporting"></i>
          <i class="cs-icon cs-icon-download2" *ngIf="!isImporting"></i> Import Listing
        </button>
      </ng-container>
      <button class="btn btn-secondary-info" (click)="done.emit()"><i class="cs-icon cs-icon-tasks"></i> Done</button>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4 py-5">
    <img src="/assets/images/channel-wizards/airbnb/airbnb-complete1.webp" alt="" class="w-75" />
  </div>
</div>

<ng-template #listings>
  <div class="text-italic text-light px-3 py-2 border-top border-bottom">
    These listings are available for import into AdvanceCM.
  </div>
  <mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
      <mat-cell *matCellDef="let element" class="d-flex align-items-center gap-2">
        <app-avatar-name
          [name]="element.name || element.roomName || element.propertyName"
          [imageSize]="25"
        ></app-avatar-name>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="onMasterChange($event.checked)" [checked]="isMasterChecked()"> </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          (change)="onSelect(element, $event.checked)"
          [checked]="selection.isSelected(element)"
          *ngIf="!isImported(element) && !isImporting"
        >
        </mat-checkbox>
        <i class="far fa-spin fa-spinner" *ngIf="isImporting && selection.isSelected(element)"></i>
        <i class="cs-icon cs-icon-check text-success" matTooltip="Listing is imported" *ngIf="isImported(element)"></i>
      </mat-cell>
    </ng-container>

    <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="!dataSource.data?.length" class="p-5 text-light text-center">There is no listing</div>
  <mat-paginator2
    #paginator
    *ngIf="dataSource.data?.length"
    [length]="dataSource?.data?.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>
</ng-template>
