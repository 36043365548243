<div class="panel-title">Options</div>
<div class="row">
  <div class="col-12">
    <app-booking-select
      [ctrl]="bookingIdCtrl"
      matTooltip="Select a booking to assign to this document. In AdvanceCM, this document will appear in the booking details."
      placeholder="Assigned Booking"
      [showBlankItem]="true"
    >
    </app-booking-select>
  </div>
  <div class="col-12">
    <mat-form-field2 class="mat-block">
      <app-mat-date-input
        [formControl]="expiredAtCtrl"
        placeholder="Expiration Date"
        [min]="minExpiredDate"
        matTooltip="Change the date that this document will expire."
      ></app-mat-date-input>

      <span matPrefix><i class="fal fa-calendar"></i> </span>
      <span matSuffix (click)="expiredAtCtrl.reset()" class="clickable" *ngIf="expiredAtCtrl.value">
        <i class="far fa-times"></i>
      </span>
    </mat-form-field2>
  </div>
  <div class="col-12">
    <app-form-tags2
      [formControl]="tagsCtrl"
      label="Select or Create Tag(s)"
      [items]="tags$ | async"
      placeholder="Type to pick or create tag"
      matTooltip="Select an existing tag, or create a new tag to assign to this document. Tags allow you to quickly filter templates that have certain attributes."
    ></app-form-tags2>
  </div>
</div>
