import { adapter, initialState, State } from './contract.state'
import {
  CancelContractComplete,
  CreateContractComplete,
  DeleteContractComplete,
  DeleteMultipleContractComplete,
  FetchBookingContractsComplete,
  GetContractComplete,
  MarkContractAsCompletedComplete,
  RefreshContractStatsComplete,
  SearchContracts,
  SearchContractsComplete,
  UpdateContractComplete,
} from './contract.actions'
import { Action, createReducer, on } from '@ngrx/store'
import { ContractStatus } from './contract.constants'
import { state } from '@angular/animations'

export const reducer = createReducer(
  initialState,
  on(SearchContracts, (state) => ({ ...state, isLoading: true })),
  on(SearchContractsComplete, (state, { items, statusStats, tagStats, pagination }) =>
    adapter.setAll(items, {
      ...state,
      isLoading: false,
      statusStats,
      tagStats,
      pagination,
    })
  ),
  on(FetchBookingContractsComplete, (state, { items }) => adapter.addMany(items, state)),
  on(RefreshContractStatsComplete, (state, { statusStats, tagStats, pagination }) => ({
    ...state,
    statusStats,
    tagStats,
    pagination,
  })),
  on(GetContractComplete, (state, { item }) => adapter.upsertOne(item, state)),
  on(CreateContractComplete, (state, { item }) => adapter.upsertOne(item, state)),
  on(UpdateContractComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(MarkContractAsCompletedComplete, (state, { id, changes }) => adapter.updateOne({ id, changes }, state)),
  on(DeleteContractComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteMultipleContractComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(CancelContractComplete, (state, { id }) =>
    adapter.updateOne(
      {
        id,
        changes: { status: ContractStatus.Canceled },
      },
      state
    )
  )
)

export function contractReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
