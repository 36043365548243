import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import {
  BaseRate,
  Rental,
  RentalCheckTime,
  RentalStatus,
  TaxV3,
  TokeetImage,
  TokeetImagePayload,
} from './rental.models'
import { Account } from '../account/account.models'
import {
  RentalCustomForm,
  RentalDetailInfoForm,
  RentalForm,
  RentalInstructionsForm,
  RentalUpdateForm,
} from './rental.interfaces'
import { RentalPaymentSchedule } from './schedule.model'

export const selectRental = createAction('[Rental] Select', props<{ id: string }>())

export const loadRentals = createAction('[Rental] Load Rentals', props<{ less?: boolean }>())
export const loadRentalsComplete = createAction('[Rental] Load Rentals Complete', props<{ rentals: Rental[] }>())

export const loadRentalRates = createAction('[Rental] Load Rental Rates')
export const loadRentalRatesComplete = createAction(
  '[Rental] Load Rental Rates Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const loadRentalsPrimaryImages = createAction('[Rental] Load Primary Images', props<{ rentalIds?: string[] }>())
export const loadRentalsPrimaryImagesComplete = createAction(
  '[Rental] Load Primary Images Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const loadRental = createAction('[Rental] Load Details', props<{ id: string }>())
export const loadRentalComplete = createAction('[Rental] Load Details Complete', props<{ rental: Rental }>())

export const loadRentalImages = createAction('[Rental] Load Images', props<{ id: string }>())
export const loadRentalImagesComplete = createAction(
  '[Rental] Load Images Complete',
  props<{ update: Update<Rental> }>()
)

export const loadRentalsImageCounts = createAction('[Rental] Load Image Counts', props<{ ids: string[] }>())
export const loadRentalsImageCountsComplete = createAction(
  '[Rental] Load Image Counts Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const deleteRental = createAction('[Rental] Delete', props<{ rentalId: string; redirectPath?: string }>())
export const deleteRentalComplete = createAction('[Rental] Delete Complete', props<{ id: string }>())

export const addRental = createAction('[Rental] Add', props<{ form: RentalForm; account: Account }>())
export const addRentalComplete = createAction('[Rental] Add Complete', props<{ rental: Rental }>())

export const loadArchivedRentals = createAction('[Rental] Load Archived')
export const loadArchivedRentalsComplete = createAction(
  '[Rental] Load Archived Complete',
  props<{ rentals: Rental[] }>()
)

export const archiveRental = createAction('[Rental] Archive', props<{ rental: Rental }>())
export const archiveRentalComplete = createAction('[Rental] Archive Complete', props<{ rental: Rental }>())

export const setRentalChildren = createAction(
  '[Rental] set rental children',
  props<{ rentalId: string; addedChildren: string[]; removedChildren: string[]; silent?: boolean }>()
)
export const setRentalChildrenComplete = createAction(
  '[Rental] set rental children Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const unArchiveRental = createAction('[Rental] UnArchive', props<{ rental: Rental }>())
export const unArchiveRentalComplete = createAction('[Rental] UnArchive Complete', props<{ rental: Rental }>())

export const addRentalImages = createAction(
  '[Rental] Add Images',
  props<{ rentalId: string; data: TokeetImagePayload[] }>()
)
export const addRentalImagesComplete = createAction('[Rental] Add Images Complete', props<{ image: TokeetImage }>())

export const removeRentalImage = createAction('[Rental] Remove Image', props<{ id: string }>())
export const removeRentalImageComplete = createAction('[Rental] Remove Image Complete', props<{ image: TokeetImage }>())

export const removeRentalImages = createAction('[Rental] Remove Images', props<{ ids: string[] }>())
export const removeRentalImagesComplete = createAction(
  '[Rental] Remove Images Complete',
  props<{ images: TokeetImage[] }>()
)

export const makeRentalImagePrimary = createAction(
  '[Rental] Make Image Primary',
  props<{ rentalId: string; primaryImageId: string; images: TokeetImage[] }>()
)

export const editRentalImage = createAction(
  '[Rental] Edit Image',
  props<{ imageId: string; category?: number; description?: string }>()
)
export const editRentalImageComplete = createAction('[Rental] Edit Image Complete', props<{ image: TokeetImage }>())

export const updateRental = createAction(
  '[Rental] Update',
  props<{ form: RentalUpdateForm; rentalId: string; rental?: Rental }>()
)
export const updateRentalComplete = createAction('[Rental] Update Complete', props<{ update: Update<Rental> }>())

export const updateMultipleRentalsComplete = createAction(
  '[Rental] Update Multiple Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const updateRentalImageOrder = createAction(
  '[Rental] Update Image Order',
  props<{ images: { [key: string]: number }; rental: Rental }>()
)
export const updateRentalImageOrderComplete = createAction(
  '[Rental] Update Image Order Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalTaxes = createAction(
  '[Rental] Update Taxes',
  props<{ taxes: TaxV3[]; rentalId: string; silent?: boolean }>()
)
export const updateRentalTaxesComplete = createAction(
  '[Rental] Update Taxes Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalPaymentSchedule = createAction(
  '[Rental] Update Payment Schedule',
  props<{ payload: RentalPaymentSchedule; rentalId: string; silent?: boolean }>()
)
export const updateRentalPaymentScheduleComplete = createAction(
  '[Rental] Update Payment Schedule Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalCustomFields = createAction(
  '[Rental] Update Rental Custom',
  props<{ form: RentalCustomForm; rental: Rental }>()
)
export const updateRentalCustomFieldsComplete = createAction(
  '[Rental] Update Rental Custom Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalDetails = createAction(
  '[Rental] Update Rental Details',
  props<{ form: RentalDetailInfoForm; rental: Rental }>()
)
export const updateRentalDetailsComplete = createAction(
  '[Rental] Update Rental Details Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalInstructions = createAction(
  '[Rental] Update Rental Instructions',
  props<{ form: RentalInstructionsForm; rental: Rental }>()
)
export const updateRentalInstructionsComplete = createAction(
  '[Rental] Update Rental Instructions Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRentalBaseRate = createAction(
  '[Rental] Update Rental Base Rate',
  props<{ form: BaseRate; rental: Rental; ignoreBDC?: boolean }>()
)
export const updateRentalBaseRateComplete = createAction(
  '[Rental] Update Rental Base Rate Complete',
  props<{ update: Update<Rental> }>()
)

export const updateOwners = createAction(
  '[Rental] Update Owners',
  props<{ rental: Rental; addOwnerIds: string[]; removeOwnerIds: string[]; currentOwnerIds: string[] }>()
)
export const updateOwnersComplete = createAction('[Rental] Update Owners Complete', props<{ update: Update<Rental> }>())

export const toggleOwnerRentals = createAction(
  '[Rental] Toggle Owner Rentals',
  props<{ ownerId: string; rentals: Rental[]; selected: boolean }>()
)
export const toggleOwnerRentalsComplete = createAction(
  '[Rental] Toggle Owner Rentals Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const restrictUsers = createAction(
  '[Rental] Restrict Users',
  props<{ rental: Rental; addUserIds: string[]; removeUserIds: string[]; currentUserIds: string[] }>()
)
export const restrictUsersComplete = createAction(
  '[Rental] Restrict Users Complete',
  props<{ update: Update<Rental> }>()
)

export const loadSubdomain = createAction('[Rental] Load SubDomain')
export const loadSubdomainComplete = createAction('[Rental] Load SubDomain Complete', props<{ domain: string }>())

export const addTagsToRental = createAction(
  '[Tag] Add Tags To Rental',
  props<{ rental: Rental; tags: string[]; newTags: string[] }>()
)
export const addTagsToRentalComplete = createAction(
  '[Tag] Add Tags To Rental Complete',
  props<{ update: Update<Rental> }>()
)

export const restrictUser = createAction('[Rental] Restrict User', props<{ rental: Rental; userId: string }>())
export const restrictUserComplete = createAction('[Rental] Restrict User Complete', props<{ update: Update<Rental> }>())

export const restrictUserAll = createAction(
  '[Rental] Restrict User All',
  props<{ rentals: Rental[]; userId: string }>()
)
export const restrictUserAllComplete = createAction(
  '[Rental] Restrict User All Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const unRestrictUser = createAction('[Rental] UnRestrict User', props<{ rental: Rental; userId: string }>())
export const unRestrictUserComplete = createAction(
  '[Rental] UnRestrict User Complete',
  props<{ update: Update<Rental> }>()
)

export const unRestrictUserAll = createAction(
  '[Rental] UnRestrict User All',
  props<{ rentals: Rental[]; userId: string }>()
)
export const unRestrictUserAllComplete = createAction(
  '[Rental] UnRestrict User All Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const updateRentalGPS = createAction('[Rental] Update GPS', props<{ gps: any; rentalId: string }>())
export const updateRentalGPSComplete = createAction('[Rental] Update GPS Complete', props<{ update: Update<Rental> }>())

export const updateAllRentalsCheckTimes = createAction(
  '[Rental] Update All Rentals Checkin/Checkout',
  props<{ checkin: RentalCheckTime; checkout: RentalCheckTime }>()
)
export const updateAllRentalsCheckTimesComplete = createAction(
  '[Rental] Update All Rentals Checkin/Checkout Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const removeRateMappingForChannel = createAction(
  '[Rental] Remove Rate Mapping for Channel',
  props<{ channelId: string }>()
)

export const UpdateRentalStatus = createAction(
  '[Rental] Update Status',
  props<{ rentalId: string; status: RentalStatus }>()
)
export const UpdateRentalStatusComplete = createAction(
  '[Rental] Update Status Complete',
  props<{ update: Update<Rental> }>()
)

export const UpdateRentalsStatus = createAction(
  '[Rental] Update Rentals Status',
  props<{ rentalIds: string[]; status: RentalStatus }>()
)
export const UpdateRentalsStatusComplete = createAction(
  '[Rental] Update Rentals Status Complete',
  props<{ updates: Update<Rental>[] }>()
)
