import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'

import * as fromRoot from '@tv3/store/state'

/* Store Rental */
import {
  ConfirmDialogService,
  Rental,
  selectAllRentals,
  TimePickerUtils,
  updateRentalInstructions,
} from '@tokeet-frontend/tv3-platform'
import { RentalService } from '@tokeet-frontend/tv3-platform'
import { isSavingRentalInstructions } from '@tokeet-frontend/tv3-platform'

/* Decorators */
import { SaveForm } from '@tokeet-frontend/tv3-platform'

/* Interfaces */
import { RentalInstructionsForm } from '@tv3/interfaces/forms/rental-instructions.form'

/* Utils */
import { Destroyable, getTimezones } from '@tokeet-frontend/tv3-platform'
import { mapTo, switchMap, take } from 'rxjs/operators'
import { CopyRentalInfoDialogService } from '@tv3/containers/rentals/copy-rental-info-dialog/copy-rental-info-dialog.service'

@Component({
  selector: 'app-rental-details-instructions',
  templateUrl: './rental-details-instructions.component.html',
  styleUrls: ['./rental-details-instructions.component.scss'],
})
export class RentalDetailsInstructionsComponent extends Destroyable implements OnChanges {
  timezone = 'Africa/Blantyre'
  timezoneList = getTimezones()

  @Input() rental: Rental

  form = this.fb.group({
    checkIn: ['', [Validators.required]],
    checkOut: ['', [Validators.required]],
    timezone: [R.head(this.timezoneList), [Validators.required]],
    directionInstructions: [''],
    checkInInstructions: [''],
    checkOutInstructions: [''],
    specialInstructions: [''],
    wifiPassword: [''],
    wifiName: [''],
    securityCode: [''],
    keyPickup: [''],
    houseRules: [''],
    paymentTerms: [''],
    paymentInstructions: [''],
  })

  isFormInitialized = false

  isSaving$ = this.store.pipe(select(isSavingRentalInstructions))

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private confirmDialog: ConfirmDialogService,
    private copyRentalInfoDialog: CopyRentalInfoDialogService
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setForm(this.rental)
  }

  copy() {
    this.onCopyTerms()
  }

  onCopyTerms() {
    this.store
      .pipe(
        select(selectAllRentals),
        take(1),
        switchMap((rentals) => this.copyRentalInfoDialog.open(R.filter((r) => r.id !== this.rental.id, rentals))),
        switchMap(({ data, rental }) => {
          return this.confirmDialog
            .confirm({
              title: 'Copy Terms?',
              body: `This action will overwrite your current terms, are you sure you want to copy terms from ${rental.name}?`,
            })
            .pipe(mapTo(data))
        })
      )
      .subscribe((data) => {
        this.form.patchValue(data)
        this.onSave(this.form)
      })
  }

  private tryFixNumberString(str) {
    if (Number.isNaN(str * 1)) {
      return str
    }

    if (`${str}`[0] !== '0') {
      return str
    }

    return `(${str})`
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue() as RentalInstructionsForm
    if (formValues.securityCode) {
      formValues.securityCode = this.tryFixNumberString(formValues.securityCode)
    }
    if (formValues.keyPickup) {
      formValues.keyPickup = this.tryFixNumberString(formValues.keyPickup)
    }

    this.store.dispatch(updateRentalInstructions({ form: formValues, rental: this.rental }))
  }

  private setForm(rental: Rental) {
    if (this.isFormInitialized) {
      return
    }
    this.isFormInitialized = true
    const instructions =
      rental.instructions ||
      ({} as {
        checkin: string
        directions: string
        checkout: string
        rules: string
      })

    const checkInInstructions = (instructions.checkin || '').replace(/<br[^>]*>/gi, '\n')
    const checkOutInstructions = (instructions.checkout || '').replace(/<br[^>]*>/gi, '\n')
    const houseRules = (instructions.rules || '').replace(/<br[^>]*>/gi, '\n')
    const directions = (instructions.directions || '').replace(/<br[^>]*>/gi, '\n')
    const timezone = RentalService.getTimeZone(rental)

    const checkin = TimePickerUtils.formatTime(rental.checkin)

    const checkout = TimePickerUtils.formatTime(rental.checkout)

    this.form.patchValue({
      checkIn: checkin,
      checkOut: checkout,
      timezone: timezone,
      directionInstructions: directions,
      checkInInstructions: checkInInstructions,
      checkOutInstructions: checkOutInstructions,
      specialInstructions: R.pathOr('', ['special_inst'], rental.specifics),
      wifiName: R.pathOr('', ['wifi_name'], rental.specifics),
      wifiPassword: R.pathOr('', ['wifi_pass'], rental.specifics),
      securityCode: R.pathOr('', ['sec_code'], rental.specifics).replace(/[\(\)]/g, ''),
      keyPickup: R.pathOr('', ['key_pickup'], rental.specifics).replace(/[\(\)]/g, ''),
      houseRules: houseRules,
      paymentInstructions: rental.paymentInstructions,
      paymentTerms: rental.paymentTerms,
    })
  }
}
