import { AutomationTypes } from './automation-services'

export interface AutomationEventGroup {
  title: string
  event: AutomationEventTypes
  tooltip?: string
  icon?: string
  color?: string
  types?: AutomationTypes[]
}

export enum AutomationEventTypes {
  // Booking
  InquiryCreated = 'inquiry.create',
  BookingConfirmed = 'inquiry.book',
  BookingCanceled = 'inquiry.cancel',

  BookingDatesUpdated = 'inquiry.update.dates',
  BookingRentalUpdated = 'inquiry.update.rental',
  BookingIsPaid = 'inquiry.update.paid',
  BookingCostUpdated = 'inquiry.update.cost',
  BookingGuestUpdated = 'inquiry.update.guest',

  // Invoice
  InvoiceCreated = 'invoice.create',
  InvoicePaid = 'invoice.paid',
  InvoiceCanceled = 'invoice.cancel',

  // Rental Access
  RentalAccessCreated = 'rentalaccess.created',
  RentalAccessUpdated = 'rentalaccess.updated',

  // Device
  DeviceConnected = 'device.connected',
  DeviceLowBattery = 'device.notification.low_battery',
  DeviceCodeFirstUsed = 'device.notification.code_first_used',
  DeviceIssuesRequireAttention = 'device.notification.issues_require_attention',

  DeviceAccessCreated = 'smartdeviceaccess.created',
  DeviceAccessUpdated = 'smartdeviceaccess.updated',

  // Card
  PaymentCardAccepted = 'payment.card.accepted',
  PaymentCardDeclined = 'payment.card.declined',
  PaymentCardDeleted = 'payment.card.deleted',
  PaymentCardRejected = 'payment.card.rejected',

  // Contract
  ContractSent = 'contract.sent',
  ContractSigned = 'contract.signed',
  ContractRejected = 'contract.rejected',
  ContractViewed = 'contract.viewed',

  // Guest
  GuestCreated = 'guest.create',
  GuestUpdated = 'guest.update',
  GuestDeleted = 'guest.delete',

  // Message sent to guest
  GuestMessageCreated = 'message.create',

  ChannelDisabled = 'system.error.channel.disabled',

  // Extra
  ExtraCreated = 'extra.create',
  ExtraUpdated = 'extra.update',
  ExtraDeleted = 'extra.delete',
  ExtraPaid = 'extra.paid',

  // Charge
  ChargeCreated = 'charge.create',
  ChargePaid = 'charge.success',
  ChargeFailed = 'charge.fail',
  ChargeDeleted = 'charge.delete',

  // Expense
  ExpenseCreated = 'expense.create',
  ExpensePaid = 'expense.paid',
  ExpenseApproved = 'expense.approve',
  ExpenseDenied = 'expense.deny',
  ExpenseDeleted = 'expense.delete',

  // Task
  TaskCreated = 'task.create',
  TaskCompleted = 'task.complete',
  TaskReassigned = 'task.reassign',
  TaskOverdue = 'task.overdue',

  // Incident
  IncidentCreated = 'incident.create',
  IncidentUpdated = 'incident.update',
  IncidentDeleted = 'incident.delete',
  IncidentEscalated = 'incident.escalate',
  IncidentCritical = 'incident.critical',
  IncidentResolved = 'incident.resolve',
  IncidentOnHold = 'incident.hold',

  // Review
  ReviewHostSubmitted = 'review.submitted.host',
  ReviewPending = 'review.pending',
  ReviewGuestReceived = 'review.submitted.guest',

  // Rates
  RateCreated = 'rate.created',

  // Inventory
  InventoryCreate = 'inventory.create',
  InventoryUpdate = 'inventory.update',
  InventoryDelete = 'inventory.delete',
  InventoryCountAlert = 'inventory.count.alert',
  InventoryDamage = 'inventory.damage',

  // Time-based
  TimeBasedEvent = 'time',
  TimeAfterInquiryCreated = 'time.after.inquiry.created',
  TimeAfterBookingConfirm = 'time.after.booking.confirm',
  TimeAfterBookingCancel = 'time.after.booking.cancel',

  TimeBeforeBookingCheckIn = 'time.before.booking.start',
  TimeAfterBookingCheckIn = 'time.after.booking.start',

  TimeBeforeBookingCheckout = 'time.before.booking.end',
  TimeAfterBookingCheckout = 'time.after.booking.end',

  TimeBeforeInvoiceDue = 'time.before.invoice.due',
  TimeAfterInvoiceDue = 'time.after.invoice.due',
}

export const automationEvents: AutomationEventGroup[] = [
  {
    title: 'Inquiry Created',
    event: AutomationEventTypes.InquiryCreated,
    tooltip: 'Inquiry is created via the dashboard, a AdvanceCM website or widget, or an API channel connection.',
    icon: 'cs-icon cs-icon-booking-created',
    color: 'text-danger',
    types: [
      //
      AutomationTypes.Message,
      AutomationTypes.Task,
      AutomationTypes.Expense,
      AutomationTypes.Invoice,
      AutomationTypes.Charge,
      AutomationTypes.Booking,
      AutomationTypes.Document,
    ],
  },
  {
    title: 'Booking Confirmed',
    event: AutomationEventTypes.BookingConfirmed,
    tooltip: 'Booking confirmed via dashboard or connected API channel.',
    icon: 'cs-icon cs-icon-booking-confirmed',
    color: 'text-indigo',
    types: [
      //
      AutomationTypes.Message,
      AutomationTypes.Task,
      AutomationTypes.Expense,
      AutomationTypes.Invoice,
      AutomationTypes.Charge,
      AutomationTypes.Booking,
      AutomationTypes.Document,
    ],
  },
  {
    title: 'Booking Cancelled',
    event: AutomationEventTypes.BookingCanceled,
    tooltip: 'Booking cancelled via dashboard or connected API channel.',
    icon: 'cs-icon cs-icon-booking-canceled',
    color: 'text-info',
    types: [
      //
      AutomationTypes.Message,
      AutomationTypes.Task,
      AutomationTypes.Expense,
      AutomationTypes.Invoice,
      AutomationTypes.Charge,
      AutomationTypes.Booking,
    ],
  },

  {
    title: 'Booking Cost Changed',
    event: AutomationEventTypes.BookingCostUpdated,
  },

  {
    title: 'Booking is Paid',
    event: AutomationEventTypes.BookingIsPaid,
  },
  {
    title: 'Booking Guest Changed',
    event: AutomationEventTypes.BookingGuestUpdated,
  },
  {
    title: 'Booking Dates Changed',
    event: AutomationEventTypes.BookingDatesUpdated,
  },
  {
    title: 'Booking Rental Changed',
    event: AutomationEventTypes.BookingRentalUpdated,
  },
  {
    title: 'Invoice Created',
    event: AutomationEventTypes.InvoiceCreated,
    tooltip: 'Invoice created on any AdvanceCM inquiry.',
    icon: 'cs-icon cs-icon-invoice-created',
    color: 'text-primary',
    types: [
      AutomationTypes.Message,
      AutomationTypes.Booking,
      AutomationTypes.Task,
      AutomationTypes.Expense,
      AutomationTypes.Document,
    ],
  },
  {
    title: 'Invoice Paid',
    event: AutomationEventTypes.InvoicePaid,
    tooltip: 'Invoice status changes to paid on any AdvanceCM invoice.',
    icon: 'cs-icon cs-icon-invoice-paid',
    color: 'text-success',
    types: [
      AutomationTypes.Message,
      AutomationTypes.Booking,
      AutomationTypes.Task,
      AutomationTypes.Expense,
      AutomationTypes.Document,
    ],
  },
  {
    title: 'Invoice Cancelled',
    event: AutomationEventTypes.InvoiceCanceled,
    tooltip: 'Any AdvanceCM invoice is marked as canceled.',
    icon: 'cs-icon cs-icon-invoice-canceled',
    color: 'text-danger',
    types: [AutomationTypes.Message, AutomationTypes.Booking, AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    title: 'Guest Created',
    event: AutomationEventTypes.GuestCreated,
    tooltip:
      'Guest is created either via the dashboard, from a AdvanceCM website or widget, or from a channel connection.',
    icon: 'cs-icon cs-icon-guest-created',
    color: 'text-primary',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Guest Updated',
    event: AutomationEventTypes.GuestUpdated,
    tooltip: 'Guest is updated via the dashboard or a channel connection.',
    icon: 'cs-icon cs-icon-guest-updated',
    color: 'text-success',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Guest Deleted',
    event: AutomationEventTypes.GuestDeleted,
    tooltip: 'Guest is deleted from the dashboard.',
    icon: 'cs-icon cs-icon-guest-deleted',
    color: 'text-danger',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Guest Message',
    event: AutomationEventTypes.GuestMessageCreated,
    tooltip: 'Message is received from a guest.',
    icon: 'cs-icon cs-icon-message-received',
    color: 'text-primary',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Channel Disabled',
    event: AutomationEventTypes.ChannelDisabled,
    tooltip: 'When access is revoked by originating channel.',
    icon: 'cs-icon cs-icon-channel-disabled',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Card Accepted',
    event: AutomationEventTypes.PaymentCardAccepted,
    tooltip: 'Credit card info submitted to Stripe has been accepted.',
    icon: 'cs-icon cs-icon-card-accepted',
    color: 'text-success',
    types: [AutomationTypes.Message, AutomationTypes.Booking, AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    title: 'Card Rejected',
    event: AutomationEventTypes.PaymentCardRejected,
    tooltip: 'Credit card info submitted to Stripe has been rejected.',
    icon: 'cs-icon cs-icon-card-rejected',
    color: 'text-danger',
    types: [AutomationTypes.Message, AutomationTypes.Booking, AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    title: 'Card Declined',
    event: AutomationEventTypes.PaymentCardDeclined,
    tooltip: 'Credit card has been declined after Stripe attempts processing.',
    icon: 'cs-icon cs-icon-card-declined',
    color: 'text-warning',
    types: [AutomationTypes.Message, AutomationTypes.Booking, AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    title: 'Card Deleted',
    event: AutomationEventTypes.PaymentCardDeleted,
    tooltip: 'Credit card has been deleted from Stripe.',
    icon: 'cs-icon cs-icon-card-deleted',
    color: 'text-indigo',
    types: [AutomationTypes.Message, AutomationTypes.Booking, AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    title: 'Contract Sent',
    event: AutomationEventTypes.ContractSent,
    tooltip: 'A Signature Contract has been sent.',
    icon: 'cs-icon cs-icon-contract-sent',
    color: 'text-primary',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Contract Viewed',
    event: AutomationEventTypes.ContractViewed,
    tooltip: 'A Signature Contract has been opened by a signer after sending.',
    icon: 'cs-icon cs-icon-contract-viewed',
    color: 'text-info',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Contract Signed',
    event: AutomationEventTypes.ContractSigned,
    tooltip: 'A Signature Contract has been signed.',
    icon: 'cs-icon cs-icon-contract-signed',
    color: 'text-success',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Contract Rejected',
    event: AutomationEventTypes.ContractRejected,
    tooltip: 'A Signature Contract has been rejected.',
    icon: 'cs-icon cs-icon-contract-rejected',
    color: 'text-danger',
    types: [AutomationTypes.Message],
  },
  {
    title: 'Smart Device Connected',
    event: AutomationEventTypes.DeviceConnected,
  },
  {
    title: 'Rental Access Created',
    event: AutomationEventTypes.RentalAccessCreated,
    tooltip: 'Rental access is created: door code, Wifi password, etc.',
    icon: 'cs-icon cs-icon-house-hashtag',
    color: 'text-primary',
    types: [AutomationTypes.Message, AutomationTypes.Booking],
  },
  {
    title: 'Rental Access Updated',
    event: AutomationEventTypes.RentalAccessUpdated,
    tooltip: 'Rental access is updated: door code, Wifi password, etc.',
    icon: 'cs-icon cs-icon-house-lock',
    color: 'text-info',
    types: [AutomationTypes.Message, AutomationTypes.Booking],
  },
  {
    title: 'Smart Device Code Generated',
    event: AutomationEventTypes.DeviceAccessCreated,
    tooltip: 'Messages for Any code generated through Smart Devices will be sent from here',
    icon: 'cs-icon cs-icon-smart-lock2',
    color: 'text-primary',
    types: [AutomationTypes.Message, AutomationTypes.Device],
  },
  {
    title: 'Smart Device Code Updated',
    event: AutomationEventTypes.DeviceAccessUpdated,
    tooltip: 'Information regarding code changes (Dates, time etc) will be send through this event',
    icon: 'cs-icon cs-icon-smart-lock',
    color: 'text-info',
    types: [AutomationTypes.Message, AutomationTypes.Device],
  },
  {
    title: 'Device Battery is Low',
    event: AutomationEventTypes.DeviceLowBattery,
    tooltip: 'Device battery is low.',
    icon: 'far fa-user-lock',
    color: 'text-warning',
    types: [AutomationTypes.Device],
  },
  {
    title: 'Device Code is first used',
    event: AutomationEventTypes.DeviceCodeFirstUsed,
    tooltip: 'Device Code is first used',
    icon: 'far fa-user-lock',
    color: 'text-info',
    types: [AutomationTypes.Device],
  },
  {
    title: 'Device has Issues',
    event: AutomationEventTypes.DeviceIssuesRequireAttention,
    tooltip: 'Device has Issues needs attention',
    icon: 'far fa-user-lock',
    color: 'text-danger',
    types: [AutomationTypes.Device],
  },
  {
    title: 'Extra Created',
    event: AutomationEventTypes.ExtraCreated,
  },
  {
    title: 'Extra Updated',
    event: AutomationEventTypes.ExtraUpdated,
  },
  {
    title: 'Extra Deleted',
    event: AutomationEventTypes.ExtraDeleted,
  },
  {
    title: 'Extra Paid',
    event: AutomationEventTypes.ExtraPaid,
  },
  {
    title: 'Charge Created',
    event: AutomationEventTypes.ChargeCreated,
  },
  {
    title: 'Charge Paid',
    event: AutomationEventTypes.ChargePaid,
  },
  {
    title: 'Charge Payment Failed',
    event: AutomationEventTypes.ChargeFailed,
  },
  {
    title: 'Charge Deleted',
    event: AutomationEventTypes.ChargeDeleted,
  },

  {
    title: 'Expense Created',
    event: AutomationEventTypes.ExpenseCreated,
  },
  {
    title: 'Expense Paid',
    event: AutomationEventTypes.ExpensePaid,
  },
  {
    title: 'Expense Approved',
    event: AutomationEventTypes.ExpenseApproved,
  },
  {
    title: 'Expense Denied',
    event: AutomationEventTypes.ExpenseDenied,
  },

  {
    title: 'Expense Deleted',
    event: AutomationEventTypes.ExpenseDeleted,
  },

  {
    title: 'Task Created',
    event: AutomationEventTypes.TaskCreated,
  },
  {
    title: 'Task Completed',
    event: AutomationEventTypes.TaskCompleted,
  },
  {
    title: 'Task Reassigned',
    event: AutomationEventTypes.TaskReassigned,
  },
  {
    title: 'Task Overdue',
    event: AutomationEventTypes.TaskOverdue,
  },

  {
    title: 'Incident Created',
    event: AutomationEventTypes.IncidentCreated,
  },
  {
    title: 'Incident Updated',
    event: AutomationEventTypes.IncidentUpdated,
  },
  {
    title: 'Incident Deleted',
    event: AutomationEventTypes.IncidentDeleted,
  },
  {
    title: 'Incident Escalated',
    event: AutomationEventTypes.IncidentEscalated,
  },
  {
    title: 'Incident Critical',
    event: AutomationEventTypes.IncidentCritical,
  },
  {
    title: 'Incident Resolved',
    event: AutomationEventTypes.IncidentResolved,
  },
  {
    title: 'Incident On Hold',
    event: AutomationEventTypes.IncidentOnHold,
  },

  {
    title: 'Host Submitted Review',
    event: AutomationEventTypes.ReviewHostSubmitted,
  },
  {
    title: 'Review Pending',
    event: AutomationEventTypes.ReviewPending,
  },
  {
    title: 'Received Guest Review',
    event: AutomationEventTypes.ReviewGuestReceived,
  },

  {
    title: 'Inventory Created',
    event: AutomationEventTypes.InventoryCreate,
  },
  {
    title: 'Inventory Updated',
    event: AutomationEventTypes.InventoryUpdate,
  },
  {
    title: 'Inventory Deleted',
    event: AutomationEventTypes.InventoryDelete,
  },
  {
    title: 'Inventory Count Alert',
    event: AutomationEventTypes.InventoryCountAlert,
  },
  {
    title: 'Inventory Damaged',
    event: AutomationEventTypes.InventoryDamage,
  },

  {
    title: 'Time-based Event',
    event: AutomationEventTypes.TimeBasedEvent,
    tooltip: '',
    icon: 'cs-icon cs-icon-calendar-clock2',
    color: 'text-success',
    types: [
      //
      AutomationTypes.Invoice,
      AutomationTypes.Charge,
      AutomationTypes.Booking,
      AutomationTypes.Document,
    ],
  },
]

export const automationTimeEvents = [
  {
    title: 'After Inquiry Created',
    event: AutomationEventTypes.TimeAfterInquiryCreated,
  },
  {
    title: 'After Booking Confirmed',
    event: AutomationEventTypes.TimeAfterBookingConfirm,
  },
  {
    title: 'After Booking Cancelled',
    event: AutomationEventTypes.TimeAfterBookingCancel,
  },
  {
    title: 'Before Check-in',
    event: AutomationEventTypes.TimeBeforeBookingCheckIn,
  },
  {
    title: 'After Check-in',
    event: AutomationEventTypes.TimeAfterBookingCheckIn,
  },
  {
    title: 'Before Check-out',
    event: AutomationEventTypes.TimeBeforeBookingCheckout,
  },
  {
    title: 'After Check-out',
    event: AutomationEventTypes.TimeAfterBookingCheckout,
  },
  {
    title: 'Before Invoice Due',
    event: AutomationEventTypes.TimeBeforeInvoiceDue,
  },
  {
    title: 'After Invoice Due',
    event: AutomationEventTypes.TimeAfterInvoiceDue,
  },
]

export function getAvailableAutomationEvents(type: AutomationTypes) {
  return automationEvents.filter((e) => e.types?.includes(type))
}

export function getAutomationEventName(event: string) {
  const e = automationEvents.find((t) => t.event === event)
  if (e) return e.title
  return automationTimeEvents.find((t) => t.event === event)?.title
}

export const automationStatuses = [
  {
    name: 'Active',
    key: 'active',
  },
  {
    name: 'All Unarchived',
    key: 'unarchived',
  },
  {
    name: 'Archived',
    key: 'archived',
  },
  {
    name: 'Paused',
    key: 'paused',
  },
]
