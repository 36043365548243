import { adapter, initialState, State } from './automation.state'
import * as R from 'ramda'
import { createReducer, on, Action } from '@ngrx/store'
import {
  LoadAutomationsComplete,
  PauseAutomationComplete,
  UnPauseAutomationComplete,
  ArchiveAutomationComplete,
  UnArchiveAutomationComplete,
  AddAutomationComplete,
  DeleteAutomationComplete,
  UpdateAutomationsComplete,
  AddAutomation,
  UpdateAutomations,
  UnArchiveAutomation,
  ArchiveAutomation,
  UnPauseAutomation,
  PauseAutomation,
  RecreateAutomation,
} from './automation.actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadAutomationsComplete, (state, { automations }) =>
    adapter.setAll(automations, R.merge(state, { isLoaded: true }))
  ),
  on(
    //
    PauseAutomationComplete,
    UnPauseAutomationComplete,
    ArchiveAutomationComplete,
    UnArchiveAutomationComplete,
    (state, { changes }) =>
      adapter.updateMany(changes, {
        ...state,
        isUpdating: false,
      })
  ),
  on(AddAutomation, RecreateAutomation, (state) => ({
    ...state,
    isAdding: true,
  })),
  on(AddAutomationComplete, (state, { automations }) =>
    adapter.addMany(automations, {
      ...state,
      isAdding: false,
    })
  ),
  on(UpdateAutomations, UnArchiveAutomation, ArchiveAutomation, UnPauseAutomation, PauseAutomation, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(UpdateAutomationsComplete, (state, { updates }) =>
    adapter.updateMany(updates, {
      ...state,
      isUpdating: false,
    })
  ),
  on(DeleteAutomationComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(ActionFailed, (state) => ({
    ...state,
    isAdding: false,
    isUpdating: false,
  }))
)

export function automationsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
